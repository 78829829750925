import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { IGlpStudySpecifics, IGlpStudySpecificsDetail } from 'src/app/models/glp-study-specifics.model';
import { ActionButtonService } from '../../shared/action-button/action-button.service';
import { ActiveIngredientService } from '../../shared/active-ingredient-selector/active-ingredient.service';
import { GridService } from '../../shared/grid-components/grid/grid.service';
import { InnerGridService } from '../../shared/grid-components/inner-grid/inner-grid.service';
import { GlpStudySpecificsResiduesService } from './glp-study-specifics-residues.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { GridApi } from 'ag-grid-community';
import { IResponse } from 'src/app/models/response.model';
import { IActiveIngredient } from 'src/app/models/active-ingredient.model';
import { UserService } from 'src/app/providers/user.service';




@Component({
  selector: 'app-glp-study-specifics-residues',
  templateUrl: './glp-study-specifics-residues.component.html',
  styleUrls: ['./glp-study-specifics-residues.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class GlpStudySpecificsResiduesComponent implements OnInit {
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentActiveIngredient: IActiveIngredient;

  public stabilityColumnDefs: (ColDef | ColGroupDef)[] = [];
  public magnitudeColumnDefs: (ColDef | ColGroupDef)[] = [];


  public rowData: IGlpStudySpecifics[];
  public stabilityDataRow: IGlpStudySpecifics[];
  public magnitudeDataRow: IGlpStudySpecifics[];

  public selectedRow: IGlpStudySpecifics | null;
  public selectedNode;
  public rowSelection = 'single';
  public studySection: any;
  private actionButtonSubscription: any;
  public rowHeight: number = 50;
  public IsResidues: boolean = true;
  public ResiduesActionButtons: MenuItem[];

  public innerGridRowIndex = 0;


  constructor(private glpStudySpecificsServiceResidues: GlpStudySpecificsResiduesService,
    private messageService: MessageService,
    public AiService: ActiveIngredientService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private actionButtonService: ActionButtonService,
    private gridService: GridService,
    private innerGridService: InnerGridService,
    private userService: UserService,
  ) { }
  ngOnInit(): void {
    this.ResiduesActionButtons = [
      {label: 'Stability',icon: 'pi pi-plus', command: () => {this.onAddGLPStudySpecifics("Stability",this.stabilityDataRow)}},
      {label: 'Magnitude', icon: 'pi pi-plus', command: () => {this.onAddGLPStudySpecifics("Magnitude",this.magnitudeDataRow)}},
    ];

    this.actionButtonService.Editable = false;
    this.actionButtonService.UseEditMode = true;
    this.studySection = this.route.snapshot.url[0].path.toString();
    this.loadReadOnlyColumnDefs();

    if (this.AiService.SelectedActiveIngredient) {
      this.getGLPStudySpecifics(this.AiService.SelectedActiveIngredient.material_id, this.studySection);
    }
    this.AiService.selectedActiveIngredient$.pipe(takeUntil(this.destroy$)).subscribe(async selectedActiveIngredient => {
      if (selectedActiveIngredient && selectedActiveIngredient != this.currentActiveIngredient){
        this.currentActiveIngredient = selectedActiveIngredient;
        await this.getGLPStudySpecifics(selectedActiveIngredient.material_id, this.studySection);
      }
    });
    this.selectedRow = null;
    this.actionButtonService.editable$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) this.reload(false);
      if (!value) this.reload(true);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.innerGridRowIndex = 0;
  }

  //  Description: On Selection Changed Event
  //  Author: George Ulecia
  //  Creation date: 23/Feb/2023   
  //  <history>
  //     <update> H00 - RD-78
  //              Description: Creation.
  //     </update>
  // </history>
  onSelectionChanged(event) {
    this.selectedRow = event as IGlpStudySpecifics;
  }

  //  Description: Load Read Only Columns Definition Method
  //  Author: George Ulecia
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-78
  //              Description: Creation.
  //     </update>
  // </history>
  loadReadOnlyColumnDefs() {
    const stabilityColsDef = this.glpStudySpecificsServiceResidues.getGLPStudySpecificsColumnsDef(this.studySection, false, "Stability").toPromise();
    stabilityColsDef.then(ColDefs => {
      this.stabilityColumnDefs = ColDefs;
    });
    const magnitudeColsDef = this.glpStudySpecificsServiceResidues.getGLPStudySpecificsColumnsDef(this.studySection, false, "Magnitude").toPromise();
    magnitudeColsDef.then(ColDefs => {
      this.magnitudeColumnDefs = ColDefs;
    });
  }

  //  Description: Load Editable Columns Definition Method
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  //  <history>
  //     <update> H00 - RD-78
  //              Description: Creation.
  //     </update>
  // </history>
  loadEditableColumnDefs() {
    const stabilityColsDef = this.glpStudySpecificsServiceResidues.getGLPStudySpecificsColumnsDef(this.studySection, true, "Stability").toPromise();
    stabilityColsDef.then(ColDefs => {
      this.stabilityColumnDefs = ColDefs;
    });
    const magnitudeColsDef = this.glpStudySpecificsServiceResidues.getGLPStudySpecificsColumnsDef(this.studySection, true, "Magnitude").toPromise();
    magnitudeColsDef.then(ColDefs => {
      this.magnitudeColumnDefs = ColDefs;
    });
  }



  reload(readyOnlyMode: boolean) {
    if (this.AiService.SelectedActiveIngredient) {
      this.getGLPStudySpecifics(this.AiService.SelectedActiveIngredient?.material_id, this.studySection).then(() => {
        if (readyOnlyMode) this.loadReadOnlyColumnDefs();
        if (!readyOnlyMode) this.loadEditableColumnDefs();
      });
    }
  }

  /// <summary>
  ///     Description: Confirmation windonw
  ///     Author: George Ulecia
  //  Date: 23/Feb/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-78
  ///         Description: Creation, not working as it will be worked on a separate story.
  ///     </update>
  /// </history>
  deleteConfirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
      },
      reject: () => {
        callback(false);
      }
    });
  }
  /// <summary>
  ///     Description: Cancel Confirmation windonw
  ///     Author: George Ulecia
  //  Date: 23/Feb/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-78
  ///         Description: Creation, not working as it will be worked on a separate story.
  ///     </update>
  ///     <update> H01 - RD-120
  ///         Description: Changed dialog message.
  ///     </update>
  /// </history>
  cancelConfirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      header: 'Are you sure?',
      message: 'Are you sure you want to cancel the changes?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
      },
      reject: () => {
        callback(false);
      }
    });
  }

  async getGLPStudySpecifics(materialId: number, section: string) {
    if (!materialId) return;
    await this.glpStudySpecificsServiceResidues?.getGLPStudySpecificsDataByMaterialID(materialId, section)
      .pipe(take(1))
      .subscribe((result: IGlpStudySpecifics[]) => {
        this.rowData = result;
        this.stabilityDataRow = this.filterAndMapRowData(this.rowData, '6.1');
        this.magnitudeDataRow = this.filterAndMapRowData(this.rowData, '6.3');
      })
  }

  filterAndMapRowData(rowData: IGlpStudySpecifics[], dossierPoint: string) {
    return rowData.filter(row => row.dossier_point.includes(dossierPoint));
  }

  //  Description: On Add Inner Grid Event
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  //  <history>
  //     <update> H00 - RD-78
  ///         Description: Creation, not working(disabled) as it will be worked on a separate story.
  //     </update>
  // </history>
  onAddInnerGrid(event) {
    
    const selectedNode = event.selectedNode;
    const data = event.parentNode.data;
    if (!data.children) data.children = [];
    let newChildren;

    if (selectedNode == undefined || selectedNode?.level == 0)
      newChildren = this.newChild();

    if (selectedNode?.level == 1)
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk);

    if (selectedNode?.level == 2 && selectedNode?.group == false)
      newChildren = this.newChild(selectedNode.data.biosystemLevel1DropdownFieldValuePk, selectedNode.data.biosystemLevel2DropdownFieldValuePk);

    if (selectedNode?.level == 2 && selectedNode?.group == true)
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel2DropdownFieldValuePk);

    if (selectedNode?.level == 3 && selectedNode?.group == false)
      newChildren = this.newChild(selectedNode.data.biosystemLevel1DropdownFieldValuePk, selectedNode.data.biosystemLevel2DropdownFieldValuePk,selectedNode.data.biosystemLevel3DropdownFieldValuePk);
    if (selectedNode?.level == 3 && selectedNode?.group == true)
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel2DropdownFieldValuePk,selectedNode.allLeafChildren[0].data.biosystemLevel3DropdownFieldValuePk);
    if (selectedNode?.level == 4 && selectedNode?.group == false)
      newChildren = this.newChild(selectedNode.data.biosystemLevel1DropdownFieldValuePk, selectedNode.data.biosystemLevel2DropdownFieldValuePk,selectedNode.data.biosystemLevel3DropdownFieldValuePk,selectedNode.data.biosystemLevel4DropdownFieldValuePk);
    if (selectedNode?.level == 4 && selectedNode?.group == true)
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel2DropdownFieldValuePk,selectedNode.allLeafChildren[0].data.biosystemLevel3DropdownFieldValuePk,selectedNode.allLeafChildren[0].data.biosystemLevel4DropdownFieldValuePk);
      if (selectedNode?.level == 5 && selectedNode?.group == false)
      newChildren = this.newChild(selectedNode.data.biosystemLevel1DropdownFieldValuePk, selectedNode.data.biosystemLevel2DropdownFieldValuePk,selectedNode.data.biosystemLevel3DropdownFieldValuePk,selectedNode.data.biosystemLevel4DropdownFieldValuePk,selectedNode.data.biosystemLevel5DropdownFieldValuePk);
    if (selectedNode?.level == 5 && selectedNode?.group == true)
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel2DropdownFieldValuePk,selectedNode.allLeafChildren[0].data.biosystemLevel3DropdownFieldValuePk,selectedNode.allLeafChildren[0].data.biosystemLevel4DropdownFieldValuePk,selectedNode.allLeafChildren[0].data.biosystemLevel5DropdownFieldValuePk);

    data.children.unshift(newChildren);
    data.haveChanges = true;

    this.innerGridService.addTransaction([newChildren]);

    if (selectedNode?.level > 0) {
      this.innerGridService.updateTransaction(this.selectedRow.children);
      selectedNode.parent?.setExpanded(true);
      selectedNode.parent?.parent?.setExpanded(true);
      selectedNode.allLeafChildren?.forEach(node => {
        node.setExpanded(true);
      })
    }
  }
  //  Description: On Delete Inner Grid Event
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  //  <history>
  //     <update> H00 - RD-78
  ///         Description: Creation, not working(disabled) as it will be worked on a separate story.
  //     </update>
  // </history>
  onDeleteInnerGrid(event) {
    const selectedNode = event.selectedNode;
    const data = event.parentNode.data;
    let deleteRows = [];
    if (selectedNode.group) {
      event.selectedNode.allLeafChildren.map(x => {
        deleteRows.push(x.data);
      });
    }
    if (!selectedNode.group) deleteRows.push(selectedNode.data);
    data.children = data.children.filter(x => !deleteRows.includes(x));
    this.innerGridService.removeTransaction(deleteRows);
    data.haveChanges = true;
  }

  /// <summary>
  ///     Description: Add GLPSpecifics to gridview (Memory)
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  //  <history>
  //     <update> H00 - RD-78
  ///         Description: Creation, not working(disabled) as it will be worked on a separate story.
  //     </update>
  // </history>
  /// </summary>

  onAddGLPStudySpecifics(area, dataRow) {
    let temp = [...dataRow];
    const newRow = this.newMainRow();
    if (!temp) temp = [];
    temp.unshift(newRow);
    if(area=="Stability") this.stabilityDataRow = temp;
    else if(area=="Magnitude") this.magnitudeDataRow = temp;
  }

  /// <summary>
  //  Description: New Main Row Method
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  //  <history>
  //     <update> H00 - RD-78
  ///         Description: Creation, not working(disabled) as it will be worked on a separate story.
  //     </update>
  // </history>
  /// </summary>
  newMainRow(): IGlpStudySpecifics {
    return {
      material_id_core: this.AiService.SelectedActiveIngredient?.material_id,
      study_number: "",
      study_section: "",
      study_year: null,
      study_title: "",
      dossier_point_dropdown_field_value_pk: 0,
      method_type_dropdown_field_value_pk: 0,
      pk: -Math.abs(this.rowData.length + 1),
      glp_study_specifics_pk: 0,
      children: [],
      listGLPStudySpecificsMetabolismDTO: [],
      listGLPStudySpecificsResiduesDTO: [],
      toxicology_specific_data: [],
      study_status_dropdown_field_value_pk: 0,
      haveChanges: true,
      created_by :  this.userService.profile.displayName,
      created_date: new Date(),
      updated_by : this.userService.profile.displayName,
      updated_date: new Date(),
      is_external: false,
      readOnly: false,
    };
  }


  //  Description: New Level 0 Method
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  //  <history>
  //     <update> H00 - RD-78
  //         Description: Creation, not working(disabled) as it will be worked on a separate story.
  //     </update>
  // </history>
  newChild(level1?: any, level2?: any, level3?:any,level4?:any, formulation?: string): IGlpStudySpecificsDetail {
    this.innerGridRowIndex++;
    return {
      pk: -Math.abs(this.innerGridRowIndex),
      biosystemLevel1DropdownFieldValuePk: level1 ? level1 : -Math.abs(this.innerGridRowIndex),
      biosystemLevel2DropdownFieldValuePk: level2 ? level2 : -Math.abs(this.innerGridRowIndex),
      biosystemLevel3DropdownFieldValuePk: level3 ? level3 : -Math.abs(this.innerGridRowIndex),
      biosystemLevel4DropdownFieldValuePk: level4 ? level4 : -Math.abs(this.innerGridRowIndex),
      validationDataDropdownFieldValuePk: 0,

      analyteMaterialId: undefined,
      analyteMaterialName: undefined,
      haveChanges: true,
      metabolitesRelevanceList: [],
      glpStudySpecificsResiduePk: 0,
      formulation: formulation ? formulation : String(-Math.abs(this.innerGridRowIndex)),
      samplingEvent: undefined,
      residueMin: 0,
      residueMax: 0,
      residueMedian: 0,
      sampleSize: 0,
      method_type: undefined,
      loq: undefined,
      fortification: undefined,
      recoveryMean: undefined,
      daysOfStorage: undefined,
      recoveryRange: undefined,
      sd: undefined,
      rsd: undefined,
      n: undefined,
      validation_data: undefined,
      massTransition: undefined,

    };
  }

  /// <summary>
  ///     Description: method to confirm delete command
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-78
  //         Description: Creation, not working(disabled) as it will be worked on a separate story.
  ///     </update>
  /// </history>
  onDeleteGLPStudySpecifics(event) {
    this.deleteConfirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.deleteGLPStudySpecifics(this.selectedRow);
      }
    });
  }


  /// <summary>
  //     Description: Delete method
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-78
  //         Description: Creation, not working(disabled) as it will be worked on a separate story.
  ///     </update>
  /// </history>
  deleteGLPStudySpecifics(data: IGlpStudySpecifics) {
    if (data.pk < 0) {
        this.stabilityDataRow = this.stabilityDataRow.filter(x => x.pk != data.pk);
        this.magnitudeDataRow = this.magnitudeDataRow.filter(x => x.pk != data.pk);
      return;
    }

    this.glpStudySpecificsServiceResidues.deleteGLPStudySpecificsDataByID(data.glp_study_specifics_pk)
      .pipe(take(1))
      .subscribe(response => {
      if (response.statusCode == 200) {
        this.endEditMode();
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }

  //  Description: On Cancel Button Event
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  //  <history>
  //     <update> H00 - RD-78
  //         Description: Creation, not working(disabled) as it will be worked on a separate story.
  //     </update>
  // </history>
  onCancelClicked(event) {
    this.cancelConfirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.endEditMode();
      }
    });
  }

  /// <summary>
  ///     Description: Button action
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-78
  //         Description: Creation, not working(disabled) as it will be worked on a separate story.
  ///     </update>
  /// </history>
  onSaveClick(event) {
    this.rowData = [];
    this.rowData =  this.rowData.concat(this.stabilityDataRow, this.magnitudeDataRow);
    this.processDatarow(this.rowData)
  }

  // <summary>
  ///     Description: Process Data Rows to Save them.
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-78
  //         Description: Creation, not working(disabled) as it will be worked on a separate story.
  ///     </update>
  /// </history>
  processDatarow(data: IGlpStudySpecifics[]) {
    
    var materialID = this.AiService.SelectedActiveIngredient?.material_id;
    if (!materialID) {
      this.showNoChangesMessage();
      return;
    }
    const [tempDataSave, baseData] = data.reduce(
      ([tempDataSave, baseData], element) =>
        element.haveChanges ? [[...tempDataSave, element], baseData] : [tempDataSave, [...baseData, element]],
      [[], []]
    );
    tempDataSave.forEach(element => {
      element.dossier_point_dropdown_field_value_pk = Number(element.dossier_point_dropdown_field_value_pk);
      element.study_status_dropdown_field_value_pk = Number(element.study_status_dropdown_field_value_pk);
      element.method_type_dropdown_field_value_pk = Number(element.method_type_dropdown_field_value_pk);
      element.material_id_core = materialID;
      
      element.study_section = this.studySection;
      
      element.created_by = element.glp_study_specifics_pk == 0 || !element.created_by ? this.userService.profile.displayName : element.created_by;
      element.created_date = element.glp_study_specifics_pk == 0 || !element.created_date ? new Date() : element.created_date;
      element.updated_by = this.userService.profile.displayName;
      element.updated_date = new Date();
    });
    if(this.isStudyNumberAnnexPointAvailable(tempDataSave,baseData)) {
    if (tempDataSave.length > 0) {
      this.saveGLPStudySpecifics(tempDataSave);
    }

    if (tempDataSave.length == 0) {
      this.showNoChangesMessage();
    }
  }
  }
  
  isStudyNumberAnnexPointAvailable(tempDataSave: IGlpStudySpecifics[], baseData: IGlpStudySpecifics[]) {
    const duplicatesExist = tempDataSave.some(item => {
      return baseData.some(row => {
        return row.dossier_point_dropdown_field_value_pk == item.dossier_point_dropdown_field_value_pk && row.study_number == item.study_number ;
      });
    });
    if (duplicatesExist) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Data duplicity detected.' });
    }
    return !duplicatesExist;
  }

  showNoChangesMessage() {
    this.messageService.add({ severity: 'warn', summary: 'No Changes', detail: 'There are no changes to save.' });
  }

 // <summary>
  ///     Description: Call GLPStudySpecifics Service to Save edited data.
  //  Author: George Ulecia
  //  Date: 23/Feb/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-78
  //         Description: Creation, not working(disabled) as it will be worked on a separate story.
  ///     </update>
  /// </history>
  saveGLPStudySpecifics(data: IGlpStudySpecifics[]) {
    this.glpStudySpecificsServiceResidues.saveGLPStudySpecificsData(data)
    .pipe(take(1))
    .subscribe((response: IResponse) => {
      if (response.statusCode == undefined && response.message.includes('Error')) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while saving changes' });
        return;
      }
      this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Changes Saved' });
      this.endEditMode();
    });
  }

  endEditMode() {
    if (this.actionButtonService.UseEditMode) this.actionButtonService.Editable = false;
    this.innerGridRowIndex = 0;
  }

}
