/// <summary>
///     Description: New List Box Component
///     Author: Karina Villalobos S.
///     Date: 21/Oct/2022
/// </summary>
/// <history>
///     <update> H00 - RD-38
///         Description: Creation.
///     </update>
/// </history>

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { SelectItem } from "primeng/api";
import { ValueBuildCombinationsService } from 'src/app/components/value-build-combinations/value-build-combinations.service';
import { IDropDownField } from 'src/app/models/dropdown-field.model';
import { DropdownFieldService } from 'src/app/providers/dropdown-field.service';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';
import { UserService } from 'src/app/providers/user.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ValidatorService } from 'src/app/providers/validator.service';
import { DropDownListService } from '../drop-down-list/drop-down-list.service';
import { ActiveIngredientService } from '../../active-ingredient-selector/active-ingredient.service';

@Component({
  selector: 'app-listBox-selection',
  templateUrl: './list-box-selection.component.html',
  styleUrls: ['./list-box-selection.component.scss'],
})

export class ListBoxSelectionComponent implements ICellRendererAngularComp, OnDestroy {
  public params: any;
  public listItems: SelectItem[];
  public defaultItem: any;
  public selectedValues: any[];
  public field: any;
  public colDef: any;
  public parent: any;
  public dropdownFieldCode;
  public style = 'small';
  public module;
  public source;
  public link: string;
  public readOnly: boolean = false;
  public isGroupRendered: boolean = false;
  public componentStyle: any = { 'height': '112px', 'max-height': '112px' };
  public loadDropDownServerList: boolean = true;
  public sourcePk: number;
  public bioSystemList: any = [
    [{ code: 'code', value: 'BMLI' }]
  ];

  GetAllUserSubscription: Subscription;
  DropdownFieldServiceSubscription: Subscription;
  DropdownFieldValueServiceSubscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(private dropdownFieldService: DropdownFieldService,
    private dropdownValueService: DropdownValueService,
    private combinationService: ValueBuildCombinationsService,
    private userService: UserService,
    private validatorService: ValidatorService,
    private dropdownListService: DropDownListService,
    private AiService: ActiveIngredientService) { }

  private result: any[] = [];

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  /// <summary>
  ///     Description: List box ag Init method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-120
  ///         Description: Added isGroupRendered validator.
  ///     </update>
  //      <update> H02 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
  //          Description: Set readonly with validator service
  //      </update>
  /// </history>
  agInit(params: any): void {
    this.params = params;
    this.selectedValues = params.value;
    this.parent = params.context.componentParent;
    this.field = params.colDef.field;
    this.colDef = params.colDef;
    this.listItems = params.list;
    this.dropdownFieldCode = params.code;
    this.style = params.style ?? this.style;
    this.module = params.module;
    this.link = params.link;
    this.source = params.source;

    if (this.params.sourceField)
      this.sourcePk = this.params.data[this.params.sourceField];

    if (params.height) {
      this.componentStyle = { 'height': params.height, 'max-height': params.height };
    }
    //Ref: H02
    this.readOnly = this.validatorService.isComponentReadOnly(params);
    this.isGroupRendered = params.node.group ?? false;

    const list = this.dropdownFieldService.getDropDownList();

    if (list.length <= 0 && this.dropdownFieldCode == undefined) {
      this.dropdownFieldService.GetByParams(this.bioSystemList).pipe(take(1)).
        subscribe(fields => {
          this.dropdownFieldService.setDropDownList(fields);
          this.initList();
        });
    } else {
      this.initList();
    }
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  /// <summary>
  ///     Description: initList
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  initList() {
    if (!this.listItems) {
      if (this.source == "compound") this.getCompound();
      if (this.params.type) this.getUsers(this.params.type);
      if (!this.module && this.dropdownFieldCode) { this.getDropDownFieldValues(); }
      if (this.module && this.dropdownFieldCode == 'BMLI') this.getDropDownCombinationByField();
      if (this.dropdownFieldCode == undefined) this.getDropDownField(this.sourcePk);
    }
  }

  getCompound() {
    const params = {
      source: this.params.source,
      materialId: this.AiService.SelectedActiveIngredient ? this.AiService.SelectedActiveIngredient.material_id : 0,
      valueDefinition: this.params.valueDefinition
    }

    this.dropdownListService.getDropDownListData(params)
      .pipe(take(1))
      .subscribe(data => {
        if (data.length > 0)
          this.listItems = data.map(val => this.getCompoundAsObject(val)).sort((a, b) => a.text > b.text ? 1 : -1);
          this.dropdownFieldService.setDropDownData(params, [{ name: 'code', value: 'compound' }], data, 'listBoxComponent');
        if (this.readOnly && this.selectedValues) this.listItems = this.listItems.filter(x => this.selectedValues.includes(x.value));
        if (this.readOnly && !this.selectedValues) this.listItems = [];
      });
  }

  getCompoundAsObject(val) {
    return { value: Number(val.value), text: val.text };
  }

  /// <summary>
  ///     Description: Get List Values
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-58 - 17/Nov/2022 - Juan Carlos Arce
  ///         Description: Add Validation for Selected Values.
  ///     </update>
  /// </history>
  getDropDownFieldValues(fieldCode?: string) {
    const code = fieldCode ? fieldCode : this.dropdownFieldCode;
    let metID: any = [{ name: 'code', value: code }];
    const list = this.dropdownFieldService.getDropDownListDataLocal(metID, "listBoxComponent");

    if (list.length > 0) {
      this.listItems = list[0].list.filter(x => x.active).map(val => this.getDropDownFieldValueAsObject(val)).sort((a, b) => a.text > b.text ? 1 : -1);

      this.setSelectedInformation()
    } else {
      this.getDropDownInformationFromServer(metID);
    }
  }

  /// <summary>
  ///     Description: Get information that is not trigger from the ag-grid component
  ///     Author: Reinier Rodriguez V.
  ///     Date: 13/Jun/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-148
  ///         Description: Creation.
  ///     </update>
  /// </history>
  getDropDownField(sourcePk: number) {

    let item: any = this.dropdownFieldService.getDropDownList().filter(x => x.dropdown_field_pk == sourcePk);
    let metID: any;

    if (item.length > 0)
      metID = [{ name: 'code', value: item[0].code }];

    const list = this.dropdownFieldService.getDropDownListSourceField(sourcePk, "listBoxComponent");

    if (list.length > 0) {
      this.listItems = list[0].list.filter(x => x.active).map(val => this.getDropDownFieldValueAsObject(val)).sort((a, b) => a.text > b.text ? 1 : -1);
      this.setSelectedInformation()
    } else {
      if (metID)
        this.getDropDownInformationFromServer(metID);
    }
  }


  /// <summary>
  ///     Description: Get List Values
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-58 - 17/Nov/2022 - Juan Carlos Arce
  ///         Description: Add Validation for Selected Values.
  ///     </update>
  /// </history>
  getDropDownInformationFromServer(params: any) {
    this.dropdownFieldService.GetByParams(params).pipe(take(1)).
      subscribe(fields => fields.map(field => {
        this.dropdownValueService.GetByParams([{ name: 'id', value: field.dropdown_field_pk.toString() }]).pipe(take(1)).
          subscribe(values => {

            this.listItems = values.filter(x => x.active).map(val => this.getDropDownFieldValueAsObject(val)).sort((a, b) => a.text > b.text ? 1 : -1);
            this.setSelectedInformation();
            if (this.listItems.length > 0)
              this.dropdownFieldService.setDropDownData(field, params, values, "listBoxComponent");
          });
      }));
  }

  /// <summary>
  ///     Description: Set selected information in lists
  ///     Author: Reinier Rodriguez V.
  ///     Date: 13/Jun/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-148
  ///         Description: Creation.
  ///     </update>
  /// </history>
  setSelectedInformation() {
    if (this.readOnly && this.selectedValues) this.listItems = this.listItems.filter(x => this.selectedValues.includes(x.value));
    if (this.readOnly && !this.selectedValues) this.listItems = [];
  }


  getDropDownFieldValueAsObject(val) {
    return { value: val.dropdown_field_value_pk, text: val.dropdown_field_value };
  }

  //  Description: Get Users method to validate whether to call the server or use the local List
  //  Author: Reinier Rodriguez V
  //  Creation: 23/Jun/2023  
  //  <history>
  //     <update> H00 - RD-143
  //              Description: Creation.
  //     </update>
  // </history>
  getUsers(type: string) {

    let metID: any = [{ name: 'code', value: type }];

    const list = this.dropdownFieldService.getDropDownListDataLocal(metID, "listBoxComponent");

    if (list.length > 0) {
      this.setUserSelectedOptions(list[0].list.filter(x => x.showInProposed == type || x.showInProposed == 'Both').map(val => this.getUserListObject(val)));
    } else {

      this.getUsersFromServer(metID);
    }
  }

  //  Description: Get Users from User Service
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-139 - 15/May/2023 - Reinier Rodriguez V.
  //              Description: new validation and parameter to only display user with the role selected.
  //     </update>
  // </history>
  getUsersFromServer(metID: any) {
    this.GetAllUserSubscription = this.userService.GetAll().pipe(take(1)).subscribe(users => {
      this.listItems = [];
      let list = users.filter(x => x.showInProposed == metID[0].value || x.showInProposed == 'Both').map(val => this.getUserListObject(val)); //Ref. H01

      this.dropdownFieldService.setDropDownData('N/A', metID, users, "listBoxComponent");

      this.setUserSelectedOptions(list)
    });
  }

  //  Description: Set user selected values
  //  Author: Reinier Rodriguez V
  //  Creation: 23/Jun/2023  
  //  <history>
  //     <update> H00 - RD-143
  //              Description: Creation.
  //     </update>
  // </history>
  setUserSelectedOptions(list: any) {

    if (this.readOnly && this.selectedValues) {
      list = list.filter(x => this.selectedValues?.includes(x.value));
    } else if (this.readOnly && !this.selectedValues) {
      list = [];
    }

    if (list.length > 0) {
      this.listItems = list;
    }
    this.subscriptions.push(this.GetAllUserSubscription);
  }

  //  Description: Get Users list as listBox object
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  getUserListObject(val) {
    return { value: val.usersPk, text: val.userName };
  }


  //  Description: Get DropDownCombinationsByField
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  async getDropDownCombinationByField(sourceSelectedValues?: number[]) {
    try {
      if (sourceSelectedValues?.length == 0) {
        this.listItems = [];
        this.selectedValues = [];
        return;
      }
      let items = [];

      //Get Dropdown Field
      let sourceField: IDropDownField;
      await this.dropdownFieldService.GetByParams([{ name: 'code', value: this.dropdownFieldCode }]).toPromise().then(f => sourceField = f[0]);

      //Fill combinations list
      let sourceCombinations = [];
      this.combinationService.moduleCombinations.filter(x => x.source_dropdown_field_pk == sourceField.dropdown_field_pk && x.moduleCode == this.module)
        .map(source => source.source_dropdown_field_value_pk.map(value => sourceCombinations.push(value)));
      sourceCombinations = [...new Set(sourceCombinations)];

      if (sourceSelectedValues) {

        let relatedCombinations = this.combinationService.moduleCombinations.filter(x => x.destination_dropdown_field_pk == sourceField.dropdown_field_pk && x.moduleCode == this.module)

        let validCombinations = []
        relatedCombinations.map(x => {
          if (x.source_dropdown_field_value_pk.some(y => sourceSelectedValues.includes(y))) {
            validCombinations.push(x);
          }
        });

        let validValues = [];
        validCombinations.map(val => val.destination_dropdown_field_value_pk.map(v => validValues.push(v)));
        sourceCombinations = validValues;
      }

      const values = await this.dropdownValueService.GetByBulkIDs(sourceCombinations).toPromise();
      if (values) values.map(val => items.push(this.getListObject(val)));

      if (this.readOnly) items = items.filter(x => this.selectedValues.includes(x.value));

      this.listItems = items.sort((a, b) => a.text > b.text ? 1 : -1);
      if (this.listItems.length > 0)
        this.colDef.refData = this.listItems;

      if (this.selectedValues) this.changeParent(this.selectedValues)
    } catch (err) {
      console.warn(err);
    }
  }

  /// <summary>
  ///     Description: Get Object List Box
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  getListObject(val) {
    return { value: val.dropdown_field_value_pk, text: val.dropdown_field_value };
  }


  /// <summary>
  ///     Description: List box on chance method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  public onChange({ value }: any): void {
    this.changeParent(value, true);
  }


  //  Description: On Change call lisboxChanged on Parent
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  public changeParent(value: any, isEvent?: boolean) {
    this.params.data[this.field] = value;
    const linkParams = { columns: [this.link], rowNodes: [this.params.node] };
    const params = {
      data: this.params.data,
      value,
      link: this.link,
      linkParams,
      api: this.params.api,
      isEvent
    };
    this.parent.lisboxChanged(
      params
    );
  }


  /// <summary>
  ///     Description: Set List values
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  setListValues(fieldCode) {
    this.getDropDownFieldValues(fieldCode);
  }
  /// <summary>
  ///     Description: List box on Show method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  public onShow(param): void {
    var items: any = document.getElementsByClassName('ag-row-no-focus');
    for (let i = 0; i < items.length; i++) {
      let element = items[i];
      element.style.zIndex = -9999;
    }
  }

  /// <summary>
  ///     Description: List box on hide method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  public onHide(param): void {
    var items: any = document.getElementsByClassName('ag-row-no-focus');
    for (let i = 0; i < items.length; i++) {
      let element = items[i];
      element.style.zIndex = "auto";
    }
  }
}

