import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IRadiolabeledSample } from '../../models/radiolabeled-sample.model';
import { CompoundService } from '../../providers/compound.service';
import { IResponse } from '../../models/response.model';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExportExcel } from '../../providers/export-excel.service'
import { DropdownFieldService } from '../../providers/dropdown-field.service';
import { DropDownListService } from '../../components/shared/grid-components/drop-down-list/drop-down-list.service';

@Component({
  selector: 'app-radiolabeled-samples',
  templateUrl: './radiolabeled-samples.component.html',
  styleUrls: ['./radiolabeled-samples.component.scss']
})
export class RadiolabeledSamplesComponent implements OnInit, OnDestroy {

  public rowData: (IRadiolabeledSample | any)[] = [];
  public rowSelection = 'single';
  public columnDefs: (ColDef | ColGroupDef | any)[] = [];

  public ngModel: any;
  colDefSubscription: Subscription;
  GetRadioLabeledSamplesSubscription: Subscription;

  subscriptions: Subscription[] = [];

  @Input() materialId: number;
  public gridApi: GridApi;

  //  Description: contructor
  //  Author: Reinier Rodriguez V
  //  Date: 06/Feb/2023
  //  <history>
  //     <update> H00 - RD-52
  //              Description: Creation.
  //     </update>
  // </history>
  constructor(private compoundService: CompoundService,
              private exportExcel: ExportExcel,
              private dropdownFieldService: DropdownFieldService,
              private dropDownListService: DropDownListService) { }


  //  Description: NgOnInit
  //  Author: Reinier Rodriguez V
  //  Date: 06/Feb/2023
  //  <history>
  //     <update> H00 - RD-52
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-162 - Karina Villalobos S.
  //              Description: Column and the data Metabolite Number are added to be exported.
  //     </update>
  // </history>
  ngOnInit(): void {

    this.GetRadioLabeledSamplesSubscription = this.compoundService.GetRadioLabeledSamples(this.materialId)
      .pipe(take(1))
      .subscribe(data => {
        //Ref. H01
        data.map((element: any) => {
          element.x_number = this.materialId;
        });
        this.rowData = data;
      });
    this.subscriptions.push(this.GetRadioLabeledSamplesSubscription);

    this.colDefSubscription = this.compoundService.GetRadiolabeledSamplesColumsDefs()
      .pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefs = ColDefs;
      });
    this.subscriptions.push(this.colDefSubscription);

  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  /// <summary>
  //  Description: Add onGridReady functionality to get and set gridApi information and use it in the export to excel process.
  //  Author: Reinier Rodriguez V.
  //  SDte: 18/May/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-143
  //              Description: Creation
  //     </update>
  // </history>
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.exportExcel.setDetailInformation(this.gridApi, null);
  }

  // <summary>
  //  Description: Add new call to export excel functionality.
  //  Author: Reinier Rodriguez V.
  //  SDte: 18/May/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-143
  //              Description: Creation
  //     </update>
  // </history>
  exportToExcel(): void {
    this.exportExcel.exportDetailToExcel();
  }
}
