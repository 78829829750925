//  Description: Residue Definition Service
//  Author: Juan Carlos Arce
//  Creation date: 13/Abr/2023    
//  <history>
//     <update> H00 - RD-114
//              Description: Creation.
//     </update>
// </history>

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { settings } from 'src/settings/settings';
import { map } from 'rxjs/operators';
import { IResponse } from '../../models/response.model';
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { IResidueDefinition } from 'src/app/models/residue-definition.model';

@Injectable({
  providedIn: 'root'
})
export class ResidueDefinitionService {
  
  headers = new HttpHeaders({
    'x-rapidapi-host': 'random-facts2.p.rapidapi.com',
    'x-rapidapi-key': 'your-api-key'
  });

  constructor(@Inject(LOCALE_ID) public locale: string,
              private http: HttpClient) { }

  //#region API Calls

//  Description: Get Residue Definition Data
//  Author: Juan Carlos Arce
//  Creation date: 13/Abr/2023    
//  <history>
//     <update> H00 - RD-114
//              Description: Creation.
//     </update>
// </history>
  getResidueDefinition(materialId: number) : Observable<IResidueDefinition[]>
	{
    let query = settings.ServiceUrl + 'api/residuedefinition/params?MaterialIdCore=' + materialId;
    return this.http.get(query).pipe(map(response => this.transformAPIData(response)));
  }

 //  Description: Save Residue Definition
 //  Author: Juan Carlos Arce
 //  Creation date: 13/Abr/2023    
 //  <history>
 //     <update> H00 - RD-114
 //              Description: Creation.
 //     </update>
 // </history>
  saveResidueDefinition(data: IResidueDefinition[]) : Observable<any>
	{
    const query = settings.ServiceUrl + 'api/residuedefinition';
    return this.http.put(query, data);
  }

 //  Description: Delete Residue Definition
 //  Author: Juan Carlos Arce
 //  Creation date: 13/Abr/2023    
 //  <history>
 //     <update> H00 - RD-114
 //              Description: Creation.
 //     </update>
 // </history>
  deleteResidueDefinition(id: number) : Observable<any>
	{
    const query = settings.ServiceUrl + 'api/residuedefinition/' + id;
    return this.http.delete(query);
  }

 //  Description: Get Columns Definition
 //  Author: Juan Carlos Arce
 //  Creation date: 13/Abr/2023    
 //  <history>
 //     <update> H00 - RD-114
 //              Description: Creation.
 //     </update>
 // </history>
  getColumsDefs() : Observable<any>
	{
    return this.http.get("../../../assets/column_defs/residue_definition/ColumnsDef.json")
          .pipe(map((colDef: any) => {
            return colDef.map( item => {
              if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
              if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
              return item;
            })
          }));
  }
  //#endregion


  //#region Methods

 //  Description: Save Residue Definition Data
 //  Author: Juan Carlos Arce
 //  Creation date: 13/Abr/2023    
 //  <history>
 //     <update> H00 - RD-114
 //              Description: Creation.
 //     </update>
 // </history>
  saveResidueDefinitionData(data: IResidueDefinition[])
  {
    return this.saveResidueDefinition(data);
  }

 //  Description: Delete Residue Definition Data
 //  Author: Juan Carlos Arce
 //  Creation date: 13/Abr/2023    
 //  <history>
 //     <update> H00 - RD-114
 //              Description: Creation.
 //     </update>
 // </history>
  deleteResidueDefinitionData(data: IResidueDefinition)
  {
    return this.deleteResidueDefinition(data.residueDefinitionPk)
  }

 //  Description: Transform Data from API
 //  Author: Juan Carlos Arce
 //  Creation date: 13/Abr/2023    
 //  <history>
 //     <update> H00 - RD-114
 //              Description: Creation.
 //     </update>
 // </history>
  transformAPIData(data: IResponse) : IResidueDefinition[]
  {
    return data.value.map(item => {
      item.showButton = true;
      return this.transformItem(item)
    });
  }

 //  Description: Transform Item from API Data
 //  Author: Juan Carlos Arce
 //  Creation date: 13/Abr/2023    
 //  <history>
 //     <update> H00 - RD-114
 //              Description: Creation.
 //     </update>
 // </history>
  transformItem(item: any): IResidueDefinition {
    item.compartment = [];
    item.crop = [];
    item.geography = [];
    item.purpose = [];
    item.compound = [];
    item.decisionMaking = item.decisionMaking == null ? "new" : item.decisionMaking;
    item.residueDefinitionDetail?.forEach(element => {
      switch(element.columnName){
        case "compartment":
          item.compartment.push(element.dropdownFieldValuePk);
          break;
        case "crop":
          item.crop.push(element.dropdownFieldValuePk);
          break;
        case "geography":
          item.geography.push(element.dropdownFieldValuePk);
          break;
        case "purpose":
          item.purpose.push(element.dropdownFieldValuePk);
          break;
        case "compound":
          item.compound.push(Number(element.columnValue));
          break;
      }
    });
    item.readOnly = item.readOnly != undefined ? item.readOnly : item.signOff;
    item.haveChanges = false;
    return item;
  }
  //#endregion

  sendRequestUnlock(data: IResidueDefinition[],reason:string) : Observable<any>
	{
    let params = {
      data,
      reason
    };

    const query = settings.ServiceUrl + 'api/residuedefinition/requestUnlock';
    return this.http.post(query, params);
  }
}


