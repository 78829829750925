import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IQuickFilter, IFilter } from 'src/app/models/quick-filter.model';

@Component({
  selector: 'app-quick-filter',
  templateUrl: './quick-filter.component.html',
  styleUrls: ['./quick-filter.component.scss']
})
export class QuickFilterComponent implements OnInit {


  @Input() quickFilters: IQuickFilter[];
  @Output() filterChangedEvent = new EventEmitter<IQuickFilter>();
  logVisible: boolean = false;
  public haveFilters: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  onChange(filter: IQuickFilter) {
    this.haveFilters = false;
    this.quickFilters.map(x => {
      x.filters.map(filter => {
        if (filter.checked) {
          this.haveFilters = true;
        }
      });
    });
    this.filterChangedEvent.emit(filter);
  }

  clearOnClick() {
    this.quickFilters.map(x => {
      x.filters.map(filter => {
        if (filter.checked) {
          filter.checked = false;
          this.filterChangedEvent.emit(x);
        }
      });
    });
    this.haveFilters = false;
  }

  openDialog(): void {
    this.logVisible = true;
  }
}
  

