
//  Description: Metabolite Confirmed Service
//  Author: Juan Carlos Arce
//  Creation date: 02/Nov/2022   
//  <history>
//     <update> H00 - RD-34
//              Description: Creation.
//     </update>
// </history>

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { IMetaboliteConfirmed, IMetaboliteConfirmedDTO } from '../../models/metabolite-confirmed.model';
import { ProbeProposedService } from '../probe-proposed/probe-proposed.service';
import { CompoundService } from '../../providers/compound.service';
import { ActiveIngredientService } from '../shared/active-ingredient-selector/active-ingredient.service';
import { settings } from 'src/settings/settings';
import { IResponse } from '../../models/response.model';
import { IIdentity } from 'src/app/models/identity.model';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';
import { UserService } from 'src/app/providers/user.service';

@Injectable({
  providedIn: 'root'
})
export class MetaboliteConfirmedService {

  headers = new HttpHeaders({
    'x-rapidapi-host': 'random-facts2.p.rapidapi.com',
    'x-rapidapi-key': 'your-api-key'
  });

  public listMetaboliteConfirmed: IMetaboliteConfirmed[] = [];
  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    private probeProposedService: ProbeProposedService,
    private AIService: ActiveIngredientService,
    private compoundService: CompoundService,
    private gridService: GridService,
    private userService: UserService) { }

  //#region API Calls

  //  Description: Get Metabolite Confirmed Data
  //  Author: Juan Carlos Arce
  //  Creation date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  getMetaboliteIdentificationProposed(materialId: number): Observable<IMetaboliteConfirmed[]> {
    return this.probeProposedService.getMetaboliteIdentificationProposed(materialId, "Yes");
  }



  getMetaboliteConfirmedRelevance(materialId: number): Observable<any> {

    let query = settings.ServiceUrl + 'api/metabolitecomfirmed/params?MaterialIdCore=' + materialId + "&xNumber=";
    let rowData;
    rowData = this.http.get(query).pipe(map(response => response));
    return rowData;
  }

  //  Description: Update Metabolite Confirmed Data
  //  Author: Juan Carlos Arce
  //  Creation date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-54 - 14/Nov/2022 -  Juan C. Arce 
  //              Description: Fix update call.
  //     </update>
  // </history>
  updateMetaboliteConfirmed(data: IMetaboliteConfirmed[]): Observable<any> {
    const transformedData = this.transformUpdateData(data);
    const query = settings.ServiceUrl + 'api/metabolitecomfirmed/';
    return this.http.put(query, transformedData);
  }

  //  Description: Create Metabolite Confirmed Data
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-54 - 14/Nov/2022 -  Juan C. Arce 
  //              Description: Fix create call.
  //     </update>
  // </history>
  saveMetaboliteConfirmed(data: IMetaboliteConfirmed[]): Observable<any> {
    const transformedData = this.transformUpdateData(data);
    const query = settings.ServiceUrl + 'api/metabolitecomfirmed';
    return this.http.post(query, transformedData);
  }

  //  Description: Get Metabolite Confirmed Columns Def
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getMetaboliteConfirmedColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/confirmed/MetaboliteConfirmedColumnsDef.json")
    //Ref. H01
    .pipe(map((colDef: any) => {
      return colDef.map( item => {
        item.children?.map(child => {
          if(child.filter == "ListboxFilter") child.filter = ListboxFilter;
          if(child.filter == "DropdownFilter") child.filter = DropdownFilter;
        })
        return item;
      })
    }));
  }

  //  Description: Get Metabolite Confirmed Detail Columns Def
  //  Author: 
  //  Date: 
  //  <history>
  //     <update> H00 -
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getConfirmedBioSystemLevelsColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/confirmed/ConfirmedBioSystemLevelsColumnsDef.json")
    //Ref.H01
    .pipe(map((colDef: any) => {
      return colDef.map( item => {
        if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
        if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
        return item;
      })
    }));
  }

  //  Description: Get Probe Proposed Detail Columns Def
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getDetailMetaboliteConfirmedColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/proposed/ProbeProposedDetailColumnsDef.json")
    //Ref.H01
    .pipe(map((colDef: any) => {
      return colDef.map( item => {
        if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
        if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
        return item;
      })
    }));
  }

  //#endregion


  //#region Methods

  //  Description: Transform Update Metabolite Confirmed Data
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-54 - 14/Nov/2022 -  Juan C. Arce 
  //              Description: update Transform Data.
  //     </update>
  //     <update> H02 - RD-87 - 06/Feb/2023 -  Yun Wei
  //              Description: Convert undefined to null
  //     </update>
  //     <update> H03 - RD-112 - 23/Mar/2023 -  Tomas Samuels
  //              Description: Add Confirmation Date
  //     </update>
  // </history>
  transformUpdateData(elements: any[]) {
    const newElements = [];
 
    elements.map(element => {
      const newElement: any = {
        x_number_metabolite: element.x_number != '' ? element.x_number : "",
        metabolite_identification_confirmed_pk: element.metabolite_identification_confirmed_pk != undefined ? element.metabolite_identification_confirmed_pk : -1,
        material_id_metabolite: element.material_id_metabolite,
        material_id_core: this.AIService.SelectedActiveIngredient.material_id as number,
        metaboliteRelevancebiosystemDetails:  element.metaboliteRelevancebiosystemDetails ? element.metaboliteRelevancebiosystemDetails : [],
        exact_mass: element.exact_mass != undefined ? element.exact_mass : '',
        structure_status_dropdown_field_value_pk: element.structure_status_dropdown_field_value_pk ? element.structure_status_dropdown_field_value_pk : null, //Ref.H02
        priority_dropdown_field_value_pk: element.priority_dropdown_field_value_pk ? element.priority_dropdown_field_value_pk : null, //Ref.H02
        labeled_ring: element.labeled_ring != undefined ? element.labeled_ring : '',
        metabolite_group: element.metabolite_group != undefined ? element.metabolite_group : '',
        comments: element.comments != undefined ? element.comments : '',
        created_by : element.metabolite_identification_confirmed_pk <= 0 ? this.userService.profile.displayName : element.created_by,
        created_date : element.metabolite_identification_confirmed_pk <= 0 ? new Date() : element.created_date,
        updated_by : this.userService.profile.displayName,
        updated_date : new Date(),
        confirmation_date: element.confirmation_date == undefined ? undefined : element.confirmation_date //Ref H03
      };

      newElements.push(newElement);

    });
    return newElements;
  }

  //  Description: Transform Data from API
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  transformAPIData(data: any): IMetaboliteConfirmed[] {
    return data.map(item => {
      return this.transformItem(item.value)
    });
  }

  //  Description: Transform Item from API Data
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  transformItem(item: any): IMetaboliteConfirmed {
    item.identity_exact_mass = '';
    item.confirmedBiosystem = [];
    item.proposedBiosystem = [];
    item.metaboliteRelevancesConfirmed = false;
    item.metaboliteRelevancesProposed = false;
    this.getIdentityData(item);
    return item;
  }

  //  Description: Format Date data
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  dateFormat(date: string) {
    return formatDate(date, "yyyy-MM-dd", this.locale)
  }

  //  Description: Transform DropDown Field Data as Multiselect
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  getDropdownFieldAsMultiselect(data: any[], dropDownField: number) {
    let values = [];
    data
      .filter(detail => detail.dropdown_field_pk == dropDownField)
      .map(element => {
        values.push(element.dropdown_field_value_pk);
      });
    return values;
  }

  //  Description: Transform Users Data as Multiselect
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  getUsersAsMultiselect(data: any[], type: string) {
    let values = [];
    data
      .filter(detail => detail.investigator_type == type)
      .map(element => {
        values.push(element.users_pk);
      });
    return values;
  }

  //  Description: Transform Children Data
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H02 - RD-131 - Karina Villalobos S.
  //              Description: Add new  comment  column  and Delete column  level IV and V.
  //     </update>
  // </history>
  public transformAPIDataBioSystem(data: any[]) {
    let values = [];
    data.map(element => {
      let row = {
        PK: element.metaboliteRelevanceBiosystemPk,
        levelI: null,
        levelII: null,
        levelIII: null,
        comment: '', //Ref H02
        relevance: null
      };
      if(element.metaboliteRelevanceBiosystemDetail!= undefined)
      {
      element.metaboliteRelevanceBiosystemDetail.map(({ listdropdownFieldValuePk, comment, dropdownFieldCode}) => {
        row.comment = comment ? comment : '';//Ref H02
        switch (dropdownFieldCode){
          case 'MERE': {
            row.relevance = listdropdownFieldValuePk;
          break; }
          case 'BMLI': {
            row.levelI = listdropdownFieldValuePk;
          break; }
          case 'BMLII': {
            row.levelII = listdropdownFieldValuePk;
          break; }
          case 'BMLIII': {
            row.levelIII = listdropdownFieldValuePk;
          break; }
        };
      });
      }
      values.push(row);
    });
    return values;
  }

  //  Description: Get Identity Data From Compound Endpoint
  //  Author: Juan Carlos Arce
  //  Date: 07/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-87 - 06/Feb/2023 -  Yun Wei
  //              Description: Change variables names in CORE identity data
  //     </update>
  // </history>
  getIdentityData(item: any) {
    if (item.x_number != undefined) {
      item.x_number = item.x_number.replace("X", "");
    }
    
    this.compoundService.GetIdentity(item.x_number).subscribe((response: IResponse) => {
      const identity = response.value as IIdentity
      item.identity_molecular_formula = identity.molecular_formula; //Ref.H01
      item.identity_molecular_weight = identity.molecule_weight; //Ref.H01
      item.identity_structure_imagen = identity.structure_image; //Ref.H01
      item.identity_smiles_code = identity.smile_code; //Ref.H01
      item.identity_synonyms = identity.synonyms; //Ref.H01
      this.gridService.updateTransaction([item]);
    });
  }

  deleteMetaboliteConfirmedData(data: any): Observable<any> {
    const query = settings.ServiceUrl + 'api/metabolitecomfirmed/' + data.metabolite_identification_confirmed_pk;
    return this.http.delete(query);
  }
  //#endregion


}
