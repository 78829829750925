import { RowDataTransaction } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InnerGridService {

  private _transaction: RowDataTransaction; // Make this private so we can expose it via a get/set
  private transactionChange$ = new Subject<RowDataTransaction>(); // This will be used to create our Observable
  public transaction$ = this.transactionChange$.asObservable(); // This is our Observable

  constructor() { }

  
  set Transaction(val: RowDataTransaction) {
    this._transaction = val; // Set the new value
    this.transactionChange$.next(val); // Trigger the subject, which triggers the Observable
   
  }

  get Transaction() {
    return this._transaction;
  }

  public addTransaction(data: any[]){
    this.Transaction = { addIndex: 0, add : data };
  }

  public updateTransaction(data: any[]){
    this.Transaction = { update : data };
  }

  public removeTransaction(data: any[]){
    this.Transaction = { remove : data };
  }
}
