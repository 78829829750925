import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ColDef, ColGroupDef, ICellRendererParams, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { ProbeProposedService } from '../../../probe-proposed/probe-proposed.service';
import { MetaboliteConfirmedService } from '../../../metabolite-confirmed/metabolite-confirmed.service';
import { ListBoxSelectionComponent } from '../list-box/list-box-selection.component';
import { TextAreaComponent } from '../text-area/text-area.component';
import { DropDownListComponent } from '../drop-down-list/drop-down-list.component';
import { ValueBuildCombinationsService } from '../../../value-build-combinations/value-build-combinations.service';
import { AccordionService } from './accordion.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExportExcel } from '../../../../providers/export-excel.service'
import { DropdownFieldService } from 'src/app/providers/dropdown-field.service';
import { DropDownListService } from '../drop-down-list/drop-down-list.service';


@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements ICellRendererAngularComp, OnDestroy {

  public columnConfirmedDefs: (ColDef | ColGroupDef)[] = [];
  public columnProposedDefs: (ColDef | ColGroupDef)[] = [];
  private confirmedGridApi: GridApi;
  private proposedGridApi: GridApi;
  public params: any;
  public value: Date;
  public context;
  public rowSelection = 'multiple';
  public selectedRows: any[];
  public listItems: Array<any>;
  public parent;
  public stringValue;
  public columnThatModifies;
  public confirmedRowData;
  public proposedRowData;
  public link;
  private confirmedModuleCode = 'CONF';
  private proposedModuleCode = 'PROP';

  private columnStateNameProposed = "proposed";
  private columnStateNameConfirmed = "confirmed";
  private columnDetailStateName = "metaboliterelevancebiosystem"

  public defaultColDef: ColDef = {
    flex: 1,
    editable: true,
    filter: 'agSetColumnFilter'
  };

  public confirmedActive: boolean = false;
  public proposedActive: boolean = false;
  public isExcelLoading: boolean = false;
  newRowIndex: number = -1;

  ConfirmedTransactionSubscription: Subscription;
  GetConfirmedCombinationsSubscription: Subscription;
  GetProposedCombinationsSubscription: Subscription;
  confirmedColDefSubscription: Subscription;
  proposedColDefSubscription: Subscription;
  subscriptions: Subscription[] = []


  public frameworkComponents: {
    listBoxselectionRenderer: typeof ListBoxSelectionComponent,
    dropdownRenderer: typeof DropDownListComponent,
    textAreaRenderer: typeof TextAreaComponent
  };

  constructor(private probeProposedService: ProbeProposedService,
    private metaboliteConfirmedService: MetaboliteConfirmedService,
    private combinationService: ValueBuildCombinationsService,
    private accordionservice: AccordionService,
    private exportExcel: ExportExcel,
    private dropdownFieldService: DropdownFieldService,
    private dropDownListService: DropDownListService) {

  }

  //  Description: Ag Init Accordion Component
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  agInit(params: any): void {
    this.frameworkComponents = {
      listBoxselectionRenderer: ListBoxSelectionComponent,
      dropdownRenderer: DropDownListComponent,
      textAreaRenderer: TextAreaComponent
    };
    this.context = params.context;
    this.params = params;
    this.listItems = params.value;
    this.parent = params.context.componentParent;
    this.stringValue = params.stringValue;
    this.columnThatModifies = params.colDef;
    this.link = params.link;
    if (params.data.proposedBiosystem == undefined) { params.data.proposedBiosystem = [] }
    if (params.data.confirmedBiosystem == undefined) { params.data.confirmedBiosystem = [] }


    this.combinationService.moduleCombinations = [];
    this.GetConfirmedCombinationsSubscription = this.combinationService.getCombinationsByModuleCode(this.confirmedModuleCode)
      .pipe(take(1))
      .subscribe(confirmedCombinations => {
        confirmedCombinations.map(element1 => {
          element1.moduleCode = this.confirmedModuleCode;
          this.combinationService.moduleCombinations.push(element1);
        });

        this.GetProposedCombinationsSubscription = this.combinationService.getCombinationsByModuleCode(this.proposedModuleCode)
          .pipe(take(1))
          .subscribe(proposedCombinations => {
            proposedCombinations.map(element2 => {
              element2.moduleCode = this.proposedModuleCode;
              this.combinationService.moduleCombinations.push(element2);
            });
          });
        this.subscriptions.push(this.GetProposedCombinationsSubscription);
      });

    this.subscriptions.push(this.GetConfirmedCombinationsSubscription);
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  //  Description: Refresh Accordion Component
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  //  Description: onConfirmedGridReady
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  onConfirmedGridReady(params: GridReadyEvent) {
    if (!this.confirmedGridApi) {
      this.confirmedGridApi = params.api;
      this.exportExcel.setDetailInformation(this.confirmedGridApi, null);
    }
  }

  //  Description: onProposedGridReady
  //  Author: Reinier Rodriguez V.
  //  Date: 18/May/2023
  //  <history>
  //     <update> H00 - RD-143
  //              Description: Creation.
  //     </update>
  // </history>
  onProposedGridReady(params: GridReadyEvent) {
    if (!this.proposedGridApi) {
      this.proposedGridApi = params.api;
      this.exportExcel.setDetailInformation(this.proposedGridApi, null);
    }
  }

  //  Description: Toogle Accordion Tab
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  toggleConfirmed() {
    this.confirmedActive = !this.confirmedActive;
    this.confirmedColDefSubscription = this.metaboliteConfirmedService.getConfirmedBioSystemLevelsColumsDefs()
      .pipe(take(1))
      .subscribe(DetailColDefs => {
        this.columnConfirmedDefs = DetailColDefs;
      });
    this.subscriptions.push(this.confirmedColDefSubscription);

    this.setRowHeight();
  }

  //  Description: Toogle Accordion Tab
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  toggleProposed() {
    this.proposedActive = !this.proposedActive;
    this.proposedColDefSubscription = this.probeProposedService.getDetailProbeProposedReadOnlyColumsDefs()
      .pipe(take(1))
      .subscribe(DetailColDefs => {
        this.columnProposedDefs = DetailColDefs;
      });
    this.subscriptions.push(this.proposedColDefSubscription);
    this.setRowHeight();
  }

  //  Description: On Tab Closed
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  onTabClose(event) {
    if (event.index == 0) this.toggleConfirmed;
    if (event.index == 1) this.toggleProposed;
  }

  //  Description: On Tab Open
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  onTabOpen(event) {
    if (event.index == 0) this.toggleConfirmed;
    if (event.index == 1) this.toggleProposed;
  }

  //  Description: Change Row Height depending on Accordion Status
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  setRowHeight() {
    const params = {
      node: this.params.node,
      confirmed: this.confirmedActive,
      proposed: this.proposedActive,
      api: this.params.api,
    };

    this.parent.resetRowHeights(params);
  }

  //  Description: On Add Click Event
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  public AddOnClick(event: Event): void {

    if (this.params.data.confirmedBiosystem == undefined) {
      this.params.data.confirmedBiosystem = []
    }
    let tempConfirmBiosystem = [...this.params.data.confirmedBiosystem];

    let tempValue = {};
    tempValue['pk'] = this.newRowIndex;
    tempValue['levelI'] = [];
    tempValue['levelII'] = [];
    tempValue['levelIII'] = [];
    tempValue['relevance'] = [];
    tempValue['haveChanges'] = true;
    this.newRowIndex--;

    tempConfirmBiosystem.unshift(tempValue);
    this.params.data.confirmedBiosystem = tempConfirmBiosystem;
    this.params.data.haveChanges = true;

  }


  //  Description: OnSelectionChange
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  onSelectionChanged(event) {
    const api = event.api;
    this.selectedRows = api.getSelectedRows();
  }

  //  Description: OnSelectionChange
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  onDelete(event) {
    let deletePKs = this.selectedRows.map(dat => dat.PK);
    this.params.data.confirmedBiosystem = this.params.data.confirmedBiosystem.filter(x => !deletePKs.includes(x.PK));
    this.params.data.haveChanges = true;
    this.selectedRows = [];
  }

  // <summary>
  //  Description: Export Excel.
  //  Author: Juan Carlos Arce
  //  Date: 25/Nov/2022
  // </summary>
  //  <history>
  //     <update> H00 - RD-143
  //              Description: Creation.
  //     </update>
  // </history>
  exportToExcelConfirmed(): void {
    this.isExcelLoading = true;
    this.exportExcel.GetInfoToAssembleExcel(this.columnDetailStateName, this.params.data.metabolite_identification_confirmed_pk, this.columnStateNameConfirmed).pipe(take(1)).subscribe(response => {
      this.exportExcel.exportTOExcel(this.columnDetailStateName, this.params.data.metabolite_identification_confirmed_pk, this.columnStateNameConfirmed, response);
      this.isExcelLoading = false;
    });
  }



}
