//  Description: Numeric component
//  Author: Juan Carlos Arce
//  Creation date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
// </history>

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-numeric',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss']
})
export class NumericComponent implements ICellRendererAngularComp{

  public params: any;
  public value;
  public listItems: Array<any>;
  public parent;
  public selectedText;
  public key;
  public stringValue;
  public columnThatModifies;
  public decimals;
  public readOnly: boolean = false;
  public isGroupRendered: boolean;

  constructor(private validatorService: ValidatorService) {}

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  //  Description: Numeric component init
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //      <update> H01 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
  //          Description: Set readonly with validator service
  //      </update>
  // </history>
  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.listItems = params.value;
    this.parent = params.context.componentParent;
    this.key = params.key;
    this.stringValue = params.stringValue;
    this.columnThatModifies = params.colDef;
    this.decimals = params.decimals;
    //Ref: H01
    this.readOnly = this.validatorService.isComponentReadOnly(params);
    this.isGroupRendered = params.node.group;
    if(this.value != undefined) this.selectedText = this.value;
  }

  
  //  Description: on Input Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  public onInput(event: any): void {
    this.value = event.value;
    this.parent.numericChanged(
      this.columnThatModifies,
      this.value,
      this.params.data,
      this.params.node.id
    );
  }

}
