import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ValueBuildsService } from 'src/app/components/value-builds/value-builds.service';
import { IDropDownField, IDropDownValue } from 'src/app/models/dropdown-field.model';
import { DropdownFieldService } from 'src/app/providers/dropdown-field.service';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-value-builds',
  templateUrl: './value-builds.component.html',
  styleUrls: ['./value-builds.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ValueBuildsComponent implements OnInit, OnDestroy {

  //#region Properties
  public DropdownFields: IDropDownField[] = [];
  public rowData: IDropDownValue[];
  public rowSelection = 'single';
  public columnSizeMode = 'NA';
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];

  public selectedRows: IDropDownValue | null;
  public selectedDropdownField: number;
  public deleteVisible: boolean = false;


  colDefSubscription: Subscription;
  SelectedDropdownFieldSubscription: Subscription;
  DropdownValueServiceSubscription: Subscription;
  SaveSubscription: Subscription;
  DeleteSubscription: Subscription;

  subscriptions: Subscription[] = [];
  public path: string;
  //#endregion

  // <sumary>
  // Description: Constructor
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Add ActionButton Service
  //     </update>
  // </history>
  constructor(private valueBuildservice: ValueBuildsService,
    private dropdownValueService: DropdownValueService,
    private confirmationService: ConfirmationService,
    private dropDownFieldService: DropdownFieldService,
    private messageService: MessageService,
    private gridService: GridService,
    //Ref. H01
    private actionButtonService: ActionButtonService,
    private route: ActivatedRoute) { }

  // <sumary>
  // Description: Component Init event
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Add ActionButtonService Edit
  //     </update>
  // </history>
  ngOnInit(): void {
    this.path = this.route.snapshot.url[0].path.toString();
    //Ref. H01
    this.actionButtonService.UseEditMode = false;
    this.actionButtonService.Editable = true;
    this.colDefSubscription = this.valueBuildservice.getColumsDefs()
      .pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefs = ColDefs;
      });
    this.subscriptions.push(this.colDefSubscription);

    this.SelectedDropdownFieldSubscription = this.dropDownFieldService.selectedDropdownField$.subscribe(selectedDropdownField => {
      this.selectedDropdownField = selectedDropdownField;
      this.getGridData();
    });
    this.subscriptions.push(this.SelectedDropdownFieldSubscription);

    this.resetSelectedRows();
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  // <sumary>
  // Description: Dropdown Field change value
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  // </history>
  public getGridData(): void {
    this.DropdownValueServiceSubscription = this.dropdownValueService.GetByParams([{ name: 'id', value: this.selectedDropdownField }])
      .pipe(take(1))
      .subscribe(Data => {
        this.rowData = Data;
      });
    this.subscriptions.push(this.DropdownValueServiceSubscription);

  }

  // <sumary>
  // Description: Add new dropdown field value
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  // </history>
  public AddOnClick(): void {
    const newRow: IDropDownValue = { dropdown_field_value_pk: 0, dropdown_field_pk: this.selectedDropdownField, dropdown_field_value: '', active: true, haveChanges: true };
    this.rowData.unshift(newRow);
    this.gridService.addTransaction([newRow]);
  }

  // <sumary>
  // Description: Save All Changes Button Event
  // Author: Yun Wei
  // Date: 07/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  public SaveOnClick(): void {
    let tempData = this.rowData.filter(element => element.haveChanges == true && element.dropdown_field_value.trim() != '');
    if (tempData.length > 0) {
      tempData.forEach(item => {
        item.active = item.active.toString() == 'true' ? true : false;
      });

      this.SaveSubscription = this.valueBuildservice.SaveData(tempData)
        .pipe(take(1))
        .subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Changes Saved' });
          this.getGridData();
        });
      this.subscriptions.push(this.SaveSubscription);
    }
  }

  // <sumary>
  // Description: Delete Btn Event
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-41 - Yun Wei - 07/Oct/2022
  //              Description: Implement delete mehotd
  //     </update>
  //     <update> H02 - RD-90 - 20/Feb/2023 - Karina Villalobo s
  //         Description: Delete multiple selected rows.
  //     </update>
  // </history>
  DeleteOnClick(event: Event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        //Ref.H01 H02
        let tempData = this.rowData.filter(element => element.selected == true);
        const last = tempData[tempData.length - 1]
        tempData.forEach(element => {
          this.DeleteSubscription = this.valueBuildservice.DeleteData(element)
            .pipe(take(1))
            .subscribe(() => {
              if (last.dropdown_field_value_pk == element.dropdown_field_value_pk) {
                this.resetSelectedRows();
                this.deleteVisible = false;
                this.getGridData();
              }
            });
        });
        this.subscriptions.push(this.DeleteSubscription);
      }
    });
  }


  //#region Methods

  // <sumary>
  // Description: Delete Btn Confirmation Message
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      },
      reject: () => {
        callback(false);
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }

  resetSelectedRows() {
    this.selectedRows = null;
  }
  //#endregion

  //#region Events

  // <sumary>
  // Description: Ag Grid Select Row
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  // </history>
  onSelectionChanged(event) {
    this.selectedRows = event as IDropDownValue;
    let tempData = this.rowData.filter(element => element.selected == true);
    if (tempData.length > 0)
      this.deleteVisible = true;
    else
      this.deleteVisible = false;
  }
  //#endregion
}
