
//  Description: Shared Module
//  Author: Juan Carlos Arce
//  Creation date: 09/Sep/2022    
//  <history>
//     <update> H00 - RD-23
//              Description: Creation.
//     </update>
// </history>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { SideMenuComponent } from './side-menu/side-menu.component';


import { SplitterModule } from 'primeng/splitter';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';



@NgModule({
  declarations: [
    SideMenuComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    UnauthorizedComponent
  ],
  imports: [
    CommonModule,
    SplitterModule,
    PanelMenuModule,
    MenubarModule,
    ButtonModule,
    DialogModule,
    TabViewModule
  ],
  exports:[
    SideMenuComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    UnauthorizedComponent
  ]
})
export class SharedModule { }
