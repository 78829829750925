
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { settings } from 'src/settings/settings';
import { map } from 'rxjs/operators';
import { IGlpStudySpecifics, IGlpStudySpecificsDetail } from 'src/app/models/glp-study-specifics.model';
import { IResponse } from 'src/app/models/response.model';
import { ActionButtonService } from '../../shared/action-button/action-button.service';


@Injectable({
  providedIn: 'root'
})
export class GlpStudySpecificsToxicologyService {

  private nonEditedDataValue? = undefined;

  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    private actionButtonService: ActionButtonService) {
  }

  getGLPStudySpecificsColumnsDef(studySection?: string, editable: boolean = false, dossierSection?: string): Observable<any> {
    let colDefLocation = `../../../assets/column_defs/glp_study_specifics_`  + studySection+`/${studySection}`;
    if (dossierSection != undefined) {
      colDefLocation = `../../../assets/column_defs/glp_study_specifics_`  + studySection +`/${studySection + dossierSection}`;
    }
    if (editable) colDefLocation += `Editable`;
    colDefLocation += `ColumnsDef.json`;
    return this.http.get(colDefLocation);
  }

  getGLPStudySpecificsDetailColumnsDef(studySection: string, editable: boolean = false, dossierSection?: string): Observable<any> {
    let colDefLocation = `../../../assets/column_defs/glp_study_specifics_`  + studySection+`/${studySection}`;
    if (dossierSection != undefined) {
      colDefLocation = `../../../assets/column_defs/glp_study_specifics_`  + studySection+`/${studySection + dossierSection}`;
    }
    colDefLocation += `Editable`;
    colDefLocation += `DetailColumnsDef.json`;
    return this.http.get(colDefLocation);
  }

  getGLPStudySpecificsDataByMaterialID(materialId: number, studySection: string): Observable<any> {
    if (!materialId) return;
    let query = settings.ServiceUrl + 'api/glpstudyspecifics/params?MaterialIdCore=' + materialId + '&Section=' + studySection;
    return this.http.get(query).pipe(map(response => {
      return this.transformAPIData(response, studySection);
    }));
  }

  deleteGLPStudySpecificsDataByID(Id: number): Observable<any> {

    let query = settings.ServiceUrl + 'api/glpstudyspecifics/' + Id;
    return this.http.delete(query);
  }

  //<summary>
  //  Description: Method to save StudySpecifis
  //  Author: Allam Barrera G && Reinier Rodriguez V
  //  Date: 14/Nov/2022
  //</summary>   
  //<history>
  //   <update> H00 - RD-42
  //            Description: Creation.
  //   </update>
  //</history>
  addGLPStudySpecifics(data: IGlpStudySpecifics[]): Observable<any> {
    const query = settings.ServiceUrl + 'api/glpstudyspecifics/add';
    return this.http.post(query, data);
  }

  //<summary>
  //  Description: Method to get transform data to an object
  //  Author: Allam Barrera G && Reinier Rodriguez V
  //  Date: 14/Nov/2022
  //</summary>
  //<history>
  //  <update> H00 - RD-50
  //      Description: Creation.
  //  </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Add Residues support.
  //     </update>
  //</history>
  transformAPIData({ value }: IResponse, section: string): IGlpStudySpecifics[] {
    //Ref. H02
    if (section == 'toxicology') {
      this.nonEditedDataValue = value;
      return this.actionButtonService.Editable ?
        this.transformEditableData(value) :
        this.transformReadOnlyData(value);
    }

    return value.map(item => {
      return this.transformItem(item);
    });
  }

  transformItem(item: any): IGlpStudySpecifics {
    item.pk = item.glp_study_specifics_pk;
    item.haveChanges = false;
    return item;
  }

  //  Description: Transform Read Only Data
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-78 - 23/Feb/2022 - George Ulecia
  //              Description: - Add toxicology list support and Method Refactor.
  //     </update>
  //     <update> H02 - RD-120 - 22/March/2023 - George Ulecia
  //              Description: Add metabolite relevance usage.
  //     </update>
  // </history>
  transformReadOnlyData(dataValue: any[]): IGlpStudySpecifics[] {
    let result: IGlpStudySpecifics[] = [];
    dataValue?.forEach(async item => {

      let newItem = {
        pk: item.glp_study_specifics_pk,
        expanded: false,
        haveChanges: false,
        dossier_point: item.dossier_point,
        sectionHTML: item.sectionHTML,
        sectionHTMLSummary: item.sectionHTML ? [item.sectionHTML ] : undefined,
        descriptor: item.descriptor,
        is_external: item.is_external,
        material_id_core: item.material_id_core,
        study_section: item.study_section,
        study_status_dropdown_field_value_pk: item.study_status_dropdown_field_value_pk,
        study_status: item.study_status,
        study_title: item.study_title,
        study_year: item.study_year,
        study_number: item.study_number
      };

      if (item.toxicology_specific_data?.length > 0) {

        item.toxicology_specific_data?.forEach((toxicology: any) => {
          let newToxicologyItem: IGlpStudySpecifics = {
            ...newItem,
            method: toxicology.method,
            doses: toxicology.doses,
            loael: toxicology.loael,
            noael: toxicology.noael,
            results: toxicology.results,
            route: toxicology.route,
            sex: toxicology.sex,
            species: toxicology.species,
            strain: toxicology.strain,
            study_type: toxicology.study_type,
            test: toxicology.test,
            test_object: toxicology.test_object,
            test_item: toxicology.test_item,
            metabolitesRelevanceList : toxicology.metabolitesRelevanceList ?? [], //REF H02

          };
          result.push(newToxicologyItem);
          newToxicologyItem = null;
        });
      } else {
        result.push(newItem);
      }
    });
    return result;
  }

  transformEditableData(dataValue: any[]): IGlpStudySpecifics[] {
    let result: IGlpStudySpecifics[] = [];
    dataValue?.forEach(item => {
      item.pk = item.glp_study_specifics_pk;
      item.expanded = false;
      item.haveChanges = false;
      item.readOnly = item.is_external == undefined ? false : item.is_external;
      item.sectionHTMLSummary = item.sectionHTML ? [ item.sectionHTML ] : undefined;
      item.children = [];
      if (item.toxicology_specific_data && item.toxicology_specific_data.length > 0) {
        item.toxicology_specific_data.map((toxicology: any) => {
          item.children.push(this.getToxicologyAsChildren(toxicology, item));
        });
      }
      
      let found = result.find(x => x.dossier_point == item.dossier_point && x.study_number == item.study_number);
      if(!found){
        result.push(item);
      }
      else{
        found.haveChanges = true;
        if(!found.children) found.children = [];
        if(item.children?.length > 0){
          item.children.forEach(child => {
            found.children.push(child);
          });
        }
        if (item.sectionHTMLSummary) {
          found.sectionHTMLSummary ? found.sectionHTMLSummary.push(item.sectionHTML) : found.sectionHTMLSummary = item.sectionHTMLSummary;
          found.sectionHTMLSummary = [...new Set(found.sectionHTMLSummary)];
        }
      }
    });
    return result;
  }

  transformSaveData(dataValue: any[]): any[] {
    let result: IGlpStudySpecifics[] = [];
    dataValue?.forEach(item => {
      item.listGLPStudySpecificsMetabolismDTO = [];
      item.listGLPStudySpecificsResiduesDTO = [];
      item.toxicology_specific_data = [];
      item.study_status = "";
      item.dossier_point = "";
      result = result.concat(this.assignChildrenObjects(item));
    });
    return result;
  }

  //  Description: Get Toxcicology Data as Children
  //  Author: George Ulecia
  //  Date: 22/March/2023
  //  <history>
  //     <update> H00 - RD-69
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-120
  //              Description: Add Metabolite Relevance Usage
  //     </update>
  // </history>
  getToxicologyAsChildren(toxicology: any, { readOnly, external_study_pk }): IGlpStudySpecificsDetail {
    let newChildren: IGlpStudySpecificsDetail = {
      ...toxicology,
      haveChanges: false,
      readOnly,
      external_study_pk,
      metabolitesRelevanceList: toxicology.metabolitesRelevanceList ?? []
    }
    return newChildren;
  }

  //  Description: Get Children Data as Object Method
  //  Author: Juan Carlos Arce
  //  Date: 09/Feb/2023
  //  <history>
  //     <update> H00 - RD-69
  //              Description: Creation.
  //     </update>
  // </history>

  assignChildrenObjects(item: any): any {

    let results: IGlpStudySpecifics[] = [];

    if (item.children?.length == 0) {
      results.push(item);
      return results;
    }
  
    item.children?.map((child: any) => {
      if(child.external_study_pk == item.external_study_pk){
        const newToxicology = {
          ...child,
          glpStudySpecificsPk : item.glp_study_specifics_pk
        };
        if(!item.toxicology_specific_data) item.toxicology_specific_data = [];
        item.toxicology_specific_data.push(newToxicology);
        
        results.push(item);
      } else {
        let foundToxicology = this.nonEditedDataValue.find(x => x.external_study_pk == child.external_study_pk);      
        foundToxicology.created_by =  item.created_by;
        foundToxicology.created_date = item.created_date;
        foundToxicology.updated_by = item.updated_by;
        foundToxicology.updated_date = item.updated_date;

        if(foundToxicology){
          const newToxicology = {
            ...child,
            glpStudySpecificsPk : foundToxicology.glp_study_specifics_pk
          };
          if(!foundToxicology.toxicology_specific_data) foundToxicology.toxicology_specific_data = [];
          foundToxicology.toxicology_specific_data.push(newToxicology);
          foundToxicology.toxicology_specific_data = foundToxicology.toxicology_specific_data.filter(x => x.metabolitesRelevanceList?.length > 0);
          results.push(foundToxicology);
        }
      }
    });
    return results;
  }

  saveGLPStudySpecifics(data: IGlpStudySpecifics[]) {
    const query = settings.ServiceUrl + 'api/glpstudyspecifics';
    return this.http.put(query, data);
  }

  saveGLPStudySpecificsData(data: IGlpStudySpecifics[]) {
    return this.saveGLPStudySpecifics(this.transformSaveData(data));
  }

}