import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public draggable: boolean = true;
  public workflowVisible: boolean = false;

  // <sumary>
  // Description:
  // Author: 
  // Date: 
  // </sumary>
  // <history>
  //     <update> H00 - RD-
  //              Description: Creation
  //     </update>
  // </history> 
  constructor() { }
  
  // <sumary>
  // Description:
  // Author: 
  // Date: 
  // </sumary>
  // <history>
  //     <update> H00 - RD-
  //              Description: Creation
  //     </update>
  // </history>
  public ngOnInit(): void {
     
  }

  public onClick(event): void {
    this.workflowVisible = true;
  }
}