import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { settings } from 'src/settings/settings';
import { IResponse } from '../models/response.model';
import { IUser } from '../models/user.model';
import { IProfile } from 'src/app/models/profile.model';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { Privileges, PrivilegeModule, PrivilegeType, Role } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  profile: IProfile = {};
  loading: boolean = true;
  private readonly _destroying$ = new Subject<void>();

  constructor(private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private http: HttpClient) {
  }

  initProfile()
  {
    this.loading = true;
    this.msalBroadcastService.inProgress$
    .pipe(
      filter(
        (status: InteractionStatus) => status === InteractionStatus.None
      ),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.getProfile();
    });
  }

  getProfile() {
    try {
      this.http.get('https://graph.microsoft.com/v1.0/me').subscribe({
        next: async (profile: IProfile) => {
          await this.GetUserRol(profile);
          this.checkAndSetActiveAccount();
        },
        error: (err) => {
          console.error(err);
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  
  // <sumary>
  // Description: Get All Users
  // Author: Juan Carlos Arce Loria
  // Date: 10/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-42
  //              Description: Creation
  //     </update>
  // </history>
  GetAll() : Observable<IUser[]>
	{
    const query = settings.ServiceUrl + 'api/users';
    return this.http.get(query)
      .pipe(map((data: any) => data.value));
  }

  // <sumary>
  // Description: Get User By ID
  // Author: Juan Carlos Arce Loria
  // Date: 10/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-42
  //              Description: Creation
  //     </update>
  // </history>
  GetByID(id: number) : Observable<IUser>
	{
    const query = settings.ServiceUrl + 'api/user/' + id;
    return this.http.get(query)
    .pipe(map((response: IResponse) => response.value as IUser));
  }

  GetByNickName(nickName: string) : Observable<any>
	{
    return this.http.get("https://graph.microsoft.com/v1.0/users?$filter=mailNickname eq '" + nickName + "'");
  }

    // <sumary>
  // Description: Get User By ID
  // Author: Juan Carlos Arce Loria
  // Date: 10/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-42
  //              Description: Creation
  //     </update>
  // </history>
  async GetUserRol(profile: IProfile)
	{
    const query = settings.ServiceUrl + 'api/user/params?Email=' + profile.mail;
    await this.http.get(query).toPromise()
    .then( (response: IResponse) => {
      profile.role = response?.statusCode == 200 ?  response.value : undefined;
      this.profile = profile;
      this.loading = false;
    });
  }

  isPrivilegeFull(privilegesModule: string, path: string, isConfirmed?: boolean){
    const roleName = this.profile.role.roleName;
    if(path == 'administrator') return Privileges[roleName][PrivilegeModule.Admin] == PrivilegeType.full;
    if(path == 'proposed'){
      return Privileges[roleName][PrivilegeModule.Proposed] == PrivilegeType.full ||
      (roleName == Role.SME && !isConfirmed);
    }
    return Privileges[roleName][privilegesModule]  == PrivilegeType.full;
  }
}
