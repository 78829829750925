import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { DropDownOptionService } from 'src/app/providers/dropdown-option.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements ICellRendererAngularComp, OnDestroy {

  public params: any;
  public value;
  public listItems: Array<any>;
  public parent;
  public field;
  public type;
  public readOnly: boolean = false;
  public isGroupRendered: boolean;
  public selectedText;
  public forceSelection = true;
  public selectedValue;
  filteredvalues: string[] = [];

  DropDownOptionServiceSubscription: Subscription
  subscriptions: Subscription[] = [];

  
//  Description: constructor
//  Author: Juan Carlos Arce
//  Date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
// </history>
  constructor(private dropDownOptionService: DropDownOptionService,
              private validatorService: ValidatorService) {}


//  Description: refresh
//  Author: Juan Carlos Arce
//  Date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
// </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }


//  Description: agInit
//  Author: Juan Carlos Arce
//  Date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
//     <update> H01 - RD-107 - 01/Mar/2023 - Juan C Arce
//              Description: Add ReadOnly and X Number logic.
//     </update>
//     <update> H02 - RD-99 - 13/Mar/2023 - George
//              Description: Modified Read Only validation.
//     </update>
//      <update> H03 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
//          Description: Set readonly with validator service
//      </update>
// </history>
  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.listItems = params.value;
    this.parent = params.context.componentParent;
    this.field = params.colDef.field;
    this.type = params.type;
    this.isGroupRendered = params.node.group;
    if(params.forceSelection != undefined) this.forceSelection = params.forceSelection;
    //Ref: H03
    this.readOnly = this.validatorService.isComponentReadOnly(params);

    if(this.value) this.selectedText = this.value;

    if (this.params.type == 'xnumber' && !this.value) this.readOnly = false;
    if (this.params.type == 'xnumber') this.value = this.value?.replace("X", "");
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

//  Description: onSelect
//  Author: Juan Carlos Arce
//  Date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
//     <update> H01 - RD-107 - 01/Mar/2023 - Juan C Arce
//              Description: Add data to params and use value
//     </update>
// </history>
  public onSelect(){
    this.selectedValue = this.value;
    this.searchChanged();
  }

//  Description: OnBlur
//  Author: Juan Carlos Arce
//  Date: 01/Mar/2023    
//  <history>
//     <update> H00 - RD-107
//              Description: Creation.
//     </update>
// </history>
  public onBlur(){
    setTimeout(() => {
      if(this.value != this.selectedValue)
        this.searchChanged()
    }, 500);
  }

//  Description: searchChanged
//  Author: Juan Carlos Arce
//  Date: 01/Mar/2023    
//  <history>
//     <update> H00 - RD-107
//              Description: Creation.
//     </update>
// </history>
  public searchChanged()
  {
    this.params.data[this.field] = this.value;
    this.params.data.haveChanges = true;
    const params = {
      data: this.params.data, //Ref. H01
      value: this.value, //Ref. H01
      field: this.field,
      node: this.params.node
    }
    this.parent.searchChanged(params);
  }

  // <sumary>
  // Description: Filter result
  // Author: Yun Wei
  // Date: 20/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-32
  //              Description: Creation
  //     </update>
  // </history>
  public onFilter(event) {
    this.DropDownOptionServiceSubscription = this.dropDownOptionService.GetList(this.params.type, event.query).pipe(take(1)).subscribe(data => {
      this.filteredvalues = data;
    });
    this.subscriptions.push(this.DropDownOptionServiceSubscription);
  }
}
