import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { Component, Input, OnInit } from '@angular/core';
import { IStandardSample } from '../../models/standard-sample.model';
import { CompoundService } from '../../providers/compound.service';
import { IResponse } from '../../models/response.model';
import { ExportExcel } from '../../providers/export-excel.service'
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-standard-samples',
  templateUrl: './standard-samples.component.html',
  styleUrls: ['./standard-samples.component.scss']
})

//  Description: Standard Samples Component
//  Author: Juan Carlos Arce
//  Creation date: 07/Nov/2022    
//  <history>
//     <update> H00 - RD-34
//              Description: Creation.
//     </update>
// </history>
export class StandardSamplesComponent implements OnInit {

  public rowData: IStandardSample[];
  public rowSelection = 'single';
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public gridApi: GridApi;


  @Input() materialId: string;

  //  Description: contructor
  //  Author: Juan Carlos Arce
  //  Creation date: 07/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  constructor(private compoundService: CompoundService, private exportExcel: ExportExcel) {
  }

  //  Description: NgOnInit
  //  Author: Juan Carlos Arce
  //  Creation date: 07/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-162 - Karina Villalobos S.http://localhost:4200/DegradationPathway
  //              Description: Column and the data Metabolite Number are added to be exported.
  //     </update>
  // </history>
  ngOnInit(): void {


    this.compoundService.GetStandardSample(Number(this.materialId)).pipe(take(1)).subscribe(data => {
      // Ref. H01
      data.map((element: any) => {
        let use: string = '';
        element.x_number = this.materialId;

        for (let index = 0; index < element.uses.length; index++) {
          use = use + element.uses[index].use;
          if (element.uses.length > index + 1) {
            use = use + ', '
          }
        }
        element.uses = use;

      });
      this.rowData = data;
    });
    this.compoundService.GetStandardSamplesColumsDefs().pipe(take(1)).subscribe(ColDefs => {
      this.columnDefs = ColDefs;
    });

  }

  // <summary>
  //  Description: Add onGridReady functionality to get and set gridApi information and use it in the export to excel process.
  //  Author: Reinier Rodriguez V.
  //  SDte: 18/May/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-143
  //              Description: Creation
  //     </update>
  // </history>
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.exportExcel.setDetailInformation(this.gridApi, null);
  }

  // <summary>
  //  Description: Add new call to export excel functionality.
  //  Author: Reinier Rodriguez V.
  //  SDte: 18/May/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-143
  //              Description: Creation
  //     </update>
  // </history>
  exportToExcel(): void {
    this.exportExcel.exportDetailToExcel();
  }
}
