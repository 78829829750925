import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlpStudyDataService } from "./glp-study-data.service";
import { ActiveIngredientService } from '../shared/active-ingredient-selector/active-ingredient.service';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';
import { ActionButtonService } from '../shared/action-button/action-button.service'

@Component({
  selector: 'app-glp-study-data',
  templateUrl: './glp-study-data.component.html',
  styleUrls: ['./glp-study-data.component.scss']
})

export class GLPStudyDataComponent implements OnInit {
  @Input() SectionVisible: boolean = false;
  @Input() material_id: any;
  @Input() source: string;
  @Input() params: any;
  @Output() displayChange = new EventEmitter();

  public rowData = [];
  public rowSelection = 'single';
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public glpResidueDefinitionColumnDef: (ColDef | ColGroupDef)[] = [];
  colDefSubscription: Subscription;
  public btnSaveClass: string = 'p-button-secondary p-button-outlined p-button-sm mr-2';
  public btnSaveIcon: string = 'pi pi-save';
  public DropdownFieldStudyType: any[] = [];
  public selectedDropdownFieldStudy;
  subscriptions: Subscription[] = [];
  public dossierSection: string;
  readOnlyColDefSubscription: Subscription;
  GetGLPStudySpecificsSubscription: Subscription;
  public studySection: any;
  public isDisabled: boolean;
  public isLoading: boolean;
  public metaboliteRelevances = [];
  public relevants = [];
  public selectedDropdownRelevance: number;
  public selectedDropdownRelevant: number;
  public rawData;
  public showSaveSummary: boolean = true;
  public readOnly: boolean;

  constructor(private glpStudyDataService: GlpStudyDataService,
              public AiService: ActiveIngredientService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private readonly dropDownService: DropdownValueService,
              private actionButtonService: ActionButtonService) {
                this.initializeRelevanceFilter();
                this.initializeRelevantFilter();
               }

  /// <summary>
  //  Description: Init
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  ngOnInit(): void {
    if(this.params)
      this.readOnly = this.params.readOnly;
    if(this.source == "residueDefinition"){
      this.getGlpResidueDefinitionColumnDef();
      this.showSaveSummary = false;
    }
    this.getStudyType();
  }

  /// <summary>
  //  Description: Selection Changed
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  selectionChanged({value}) {
    if(this.rowData){
      if(this.rowData.filter(element => element.haveChanges == true).length > 0){
        this.changeStudyConfirmation(event.target, (confirmed) => {
          if (confirmed) {
            this.studySection = value;
            this.changeStudyType(value);
          }
          else{
            this.selectedDropdownFieldStudy = this.studySection;
          }
        });
      }
      else{
        this.studySection = value;
        this.changeStudyType(value);
      }
    }
    else{
      this.studySection = value;
      this.changeStudyType(value);
    }
    
  }
  
  /// <summary>
  //  Description: On Change Study Type
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  changeStudyType(value): void{
    let residueDefinitionPk = null;
    if(this.params)
      residueDefinitionPk = this.params.data.residueDefinitionPk;

    if(value != 0){
      this.loadColumnDefs();
      this.getGLPStudyData(this.material_id, this.studySection, residueDefinitionPk);
    }
    else{
      this.columnDefs = [];
      this.rowData = [];
    }
  }

  /// <summary>
  //  Description: Get Glp Study Data
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  async getGLPStudyData(materialId: number, section: string, residueDefinitionPk?: number) {
    this.isLoading = true;
    this.GetGLPStudySpecificsSubscription = await this.glpStudyDataService?.getGLPStudyDataByMaterialID(materialId, section, residueDefinitionPk)
      .pipe(take(1))
      .subscribe((result: any[]) => {
        this.rawData = result;
        this.rowData = result;
        this.isDisabled = false;
        this.isLoading = false;
        this.getRelevanceList();
        this.getRelevantList();
        this.subscriptions.push(this.GetGLPStudySpecificsSubscription);
      });
  }

  /// <summary>
  //  Description: Load Column Defs
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  loadColumnDefs() {
    this.readOnlyColDefSubscription = this.glpStudyDataService.getGLPStudyDataColumnsDef(this.studySection).pipe(take(1)).subscribe(ColDefs => {
      if(this.source == "residueDefinition")
        this.addGlpResiduesDefinitionColumnDef(ColDefs);
      this.columnDefs = ColDefs;
      });
    this.subscriptions.push(this.readOnlyColDefSubscription);
  }

  /// <summary>
  //  Description: Get Study Type
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  getStudyType(): void {
    this.DropdownFieldStudyType = [
      {
        pk: "0",
        name: "Select Option"
      },
      {
        pk: "metabolism",
        name: "Metabolism"
      },
      {
        pk: "enviromentFate",
        name: "Environmental fate"
      },
      {
        pk: "residues",
        name: "Residues"
      },
      {
        pk: "1",
        name: "Toxicology Acute",
      },
      {
        pk: "2",
        name: "Toxicology Adme"
      },
      {
        pk: "3",
        name: "Toxicology Genotoxicology"
      },
      {
        pk: "4",
        name: "Toxicology Reproductivity"
      },
      {
        pk: "5",
        name: "Toxicology Short-Term"
      },
      {
        pk: "6",
        name: "Toxicology Metabolite"
      }
    ]
  }

  /// <summary>
  //  Description: On Close Pop Up
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  onClose() {
    this.displayChange.emit(false);
  }

  /// <summary>
  //  Description: On Destroy
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  ngOnDestroy() {
    this.displayChange.unsubscribe();
  }

  /// <summary>
  //  Description: save
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  onSaveSummary(){
    const tempData = this.rowData.filter(element => element.haveChanges == true);
    if(tempData.length > 0)
    {
      this.saveConfirmation(event.target, (confirmed) => {
        if (confirmed) {
         this.saveSummary(tempData);
        }
      });
    }
    else
    this.showNoChangesMessage();
   
  }

  /// <summary>
  //  Description: save
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  saveSummary(tempData): void{
      this.isDisabled = true;
      this.glpStudyDataService.saveGLPStudySpecifics(tempData,this.selectedDropdownFieldStudy,this.material_id).subscribe((response: any) => {
        if (response.value > 0) {
          this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Changes Saved' });
          this.getGLPStudyData(this.material_id, this.studySection, null);
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error saving changes' });
      });
  }

  /// <summary>
  //  Description: Confirmation
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  changeStudyConfirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      header: 'Possible loss of changes',
      message: 'If you change the study type, the unsaved data will be lost. Are you sure you want to change the study type?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
      },
      reject: () => {
        callback(false);
      }
    });
  }

  /// <summary>
  //  Description: Show no changes message
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  showNoChangesMessage() {
    this.messageService.add({ severity: 'warn', summary: 'No Changes', detail: 'There are no changes to save.' });
  }

  /// <summary>
  //  Description: Confirmation
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  saveConfirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Do you want to save the changes?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
      },
      reject: () => {
        callback(false);
      }
    });
  }

  /// <summary>
  //  Description: Get relevance list
  //  Author: Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  getRelevanceList(): void{
    this.initializeRelevanceFilter();
     this.dropDownService.GetAll().subscribe(relevances =>{
       if(this.rawData){
         for(let i = 0; i < this.rawData.length; i++){
           if(this.rawData[i].metabolitesRelevanceList)
           this.rawData[i].metabolitesRelevanceList.forEach(mrPk =>{
           let relevance = relevances.find(r => r.dropdown_field_value_pk == mrPk);
           if(!this.metaboliteRelevances.find(rc => rc.label == relevance.dropdown_field_value))
             this.metaboliteRelevances.push({pk: relevance.dropdown_field_value_pk, label:relevance.dropdown_field_value});
           });
         }
       }
     });
   }

  // <summary>
  //  Description: Get relevant list
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  getRelevantList(): void{
    this.initializeRelevantFilter();
    if(this.rawData){
      for(let i = 0; i < this.rawData.length; i++){

        let pk: number =  Number(this.rawData[i].relevant);
        let label: string = "No";

        if(pk == 1)
          label = "Yes";
          
        if(!this.relevants.find(rc => rc.pk == pk))
               this.relevants.push({pk: pk, label: label});
         }
    }
   }

  /// <summary>
  //  Description: On relevance changed
  //  Author: Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  onRelevanceOrRelevantChanged({value}): void{
    let temp = [];
    if(this.selectedDropdownRelevance == 0 && this.selectedDropdownRelevant == -1){
      temp = this.rawData;
    }
    else if(this.selectedDropdownRelevance > 0 && this.selectedDropdownRelevant == -1){
       temp = this.rawData.filter(r => r.metabolitesRelevanceList);
       temp = temp.filter(r => r.metabolitesRelevanceList.includes(this.selectedDropdownRelevance));
    }
    else if(this.selectedDropdownRelevant > -1 && this.selectedDropdownRelevance == 0){
      temp = this.rawData.filter(r => r.relevant == this.selectedDropdownRelevant);
    }else{
      temp = this.rawData.filter(r => r.metabolitesRelevanceList);
      temp = temp.filter(r => r.metabolitesRelevanceList.includes(this.selectedDropdownRelevance) && r.relevant == this.selectedDropdownRelevant);
    }
    this.rowData = [...temp];
  }

  /// <summary>
  //  Description: Initialize relevance filter
  //  Author: Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  initializeRelevanceFilter():void{
    this.metaboliteRelevances = [];
    this.metaboliteRelevances.unshift({pk: 0, label:"Select an option"});
    this.selectedDropdownRelevance = 0;
  }

  // <summary>
  //  Description: initialize Relevant Filter
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  initializeRelevantFilter():void{
    this.relevants = [];
    this.relevants.push({pk: -1, label:"Select an option"});
    this.selectedDropdownRelevant = -1;
  }

  // <summary>
  //  Description: get Glp Residue Definition ColumnDef
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  getGlpResidueDefinitionColumnDef(): void {
    this.readOnlyColDefSubscription = this.glpStudyDataService.getGlpResidueDefinitionColumnDef().pipe(take(1)).subscribe(ColDefs => {
        this.glpResidueDefinitionColumnDef = ColDefs;
      });
    this.subscriptions.push(this.readOnlyColDefSubscription);
  }

  // <summary>
  //  Description: add Glp Residues Definition Column Def
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  addGlpResiduesDefinitionColumnDef(colDef : ColDef[] ): ColDef[]{
    colDef.splice(0,1);
    this.glpResidueDefinitionColumnDef.forEach(c =>{
      colDef.unshift(c);
    });
    if(this.readOnly)
      {
        let useInDef = colDef.find(x => x.field == 'useInDefinition');
        if(useInDef){
          useInDef.cellRendererParams = {
            forcedReadOnly: true,
            readOnly: true
          }
        }
      }
    return colDef;
  }

  // <summary>
  //  Description: on Save Residues
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  onSaveResidues(){
    const tempData = this.rowData.filter(element => element.haveChanges == true);
    if(tempData.length > 0)
    {
      this.saveConfirmation(event.target, (confirmed) => {
        if (confirmed) {
          tempData.forEach(d =>{
            d.residueDefinitionPk = this.params.data.residueDefinitionPk;
          });
          this.saveResidues(tempData);
        }
      });
    }
    else
    this.showNoChangesMessage();
  }

  // <summary>
  //  Description: save Residues
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  saveResidues(tempData): void{
    this.isDisabled = true;
    this.glpStudyDataService.saveGlpResidues(tempData,this.selectedDropdownFieldStudy,this.material_id).subscribe((response: any) => {
      if (response.value > 0) {
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Changes Saved' });
        this.getGLPStudyData(this.material_id, this.studySection, this.params.data.residueDefinitionPk);
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error saving changes' });
    });
  }

  // <summary>
  //  Description: export excel
  //  Author: Reinier Rodriguez V
  //  Date: 23/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-155
  //              Description: Creation.
  // </history>
  exportToExcel():void{
    this.actionButtonService.ExportExcel = true;
  }
}
