import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessControlService } from './access-control.service';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { take } from 'rxjs/operators';
import { IAccessControl } from '../../models/access-control.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { IResponse } from '../../models/response.model';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/providers/user.service';
import { IProfile } from 'src/app/models/profile.model';

@Component({
  selector: 'app-access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AccessControlComponent implements OnInit, OnDestroy {

  colDefSubscription: Subscription;
  subscriptions: Subscription[] = [];
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public rowSelection = 'single';
  public detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];
  public rowData: any[];
  public deleteVisible: boolean = false;
  public rowHeight: number;
  public selectedRows: IAccessControl;
  public path: string;

  GetAccessControlSubscription: Subscription;
  CreateAccessControlSubscription: Subscription;
  DeleteAccessControlSubscription: Subscription;

  constructor(private accessControlService: AccessControlService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private gridService: GridService,
    private actionButtonService: ActionButtonService,
    private route: ActivatedRoute,
    private userService: UserService) { }

  // <summary>
  //     Description: NgOnInit
  //     Author: Reinier Rodriguez V.
  //     Date: 21/Apr/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-104
  //         Description: Creation.
  //     </update>
  // </history>
  ngOnInit(): void {
    this.path = this.route.snapshot.url[0].path.toString();
    this.actionButtonService.UseEditMode = false;
    this.actionButtonService.Editable = true;
    this.rowData = [];
    this.setColumnDefs();
    this.getGridData();
  }

  // <summary>
  //     Description: Save Method
  //     Author: Reinier Rodriguez V.
  //     Date: 21/Apr/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-104
  //         Description: Creation.
  //     </update>
//     <update> H01 - RD-139 - 15/May/2023 - Reinier Rodriguez V.
//              Description: Add new property ShowInProposed.
//     </update>
  // </history>
  public SaveOnClick(): void {
    let addElement = true;
    let Data:IAccessControl[] = [];

    this.rowData.forEach(item => {

      if(item.haveChanges){
        let tempData:IAccessControl ={
          UsersPk : 0,
          UserId : "",
          UserName : "",
          Email : "",
          Comment : "",
          UserRolePk : null,
          Active : true,
          ShowInProposed: "", //Ref. H01
          haveChanges: false,
          selected: false
        };

        tempData.UsersPk = Number(item.usersPk) > 0 ? Number(item.usersPk) : 0; 
        tempData.UserId = item.userId;
        tempData.UserName = item.userName  == undefined? "" : item.userName;
        tempData.Email = item.email  == undefined? "" : item.email;
        tempData.UserRolePk = Number(item.userRolePk) > 0 ? Number(item.userRolePk) : null ;
        tempData.Comment = item.comment == undefined? "" : item.comment;
        tempData.Active =  item.active + "" == "true" ? true : false;
        tempData.ShowInProposed =  item.showInProposed == undefined? "" : item.showInProposed; //Ref. H01

        if (this.rowData.filter(element => element.userId == item.userId).length < 2){
          Data.push(tempData);
        }
        else
        {
          this.rowData.filter(element => element.userId == item.userId).forEach(element => {
            if(element.userId == item.userId)
            {
              if(tempData.UsersPk > 0)
              {
                if(addElement)
                {
                  addElement = false;
                  Data.push(tempData);
                }
              }
            }
          });
        }

      }
    });

    this.CreateAccessControlSubscription = this.accessControlService.saveAccessControlData(Data)
      .pipe(take(1))
      .subscribe(data => {
        this.messageService.add({ severity: "success", summary: "Save", detail: "Success Service Message", });
        this.getGridData();
      });
    this.subscriptions.push(this.CreateAccessControlSubscription);
  }

  // <summary>
  //     Description: Get information to fill grid.
  //     Author: Reinier Rodriguez V.
  //     Date: 21/apr/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-104
  //         Description: Creation.
  //     </update>
  // </history>
  public getGridData(): void {
    this.GetAccessControlSubscription = this.accessControlService.getAccessControlData()
      .pipe(take(1))
      .subscribe((response: IResponse) => {
        this.rowData = response.value;
      });
    this.subscriptions.push(this.GetAccessControlSubscription);
  }

  // <summary>
  //     Description: Delete method
  //     Author: Reinier Rodriguez V.
  //     Date: 21/Apr/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-104
  //         Description: Creation.
  //     </update>
  // </history>
  deleteOnClick(event: Event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        //Ref. H01
        let tempData = this.rowData.filter(element => element.selected == true);
        tempData.forEach(element => {
            this.DeleteAccessControlSubscription = this.accessControlService.deleteAccessControlData(element.usersPk)
              .pipe(take(1))
              .subscribe(() => {
                  this.deleteVisible = false;
                  this.getGridData();
              });
            this.subscriptions.push(this.DeleteAccessControlSubscription);
        });
      }
    });
  }

  // <summary>
  //     Description: Add new row to the grid
  //     Author: Reinier Rodriguez V.
  //     Date: 21/Aor/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-104
  //         Description: Creation.
  //     </update>
//     <update> H01 - RD-139 - 15/May/2023 - Reinier Rodriguez V.
//              Description: Add new property ShowInProposed.
//     </update>
  // </history>
  public AddOnClick(): void {
    const newRow: any = {
      usersPk: 0, userId: '', userName: '', email: '', comments: '', userRolePk : 3, active: true, showInProposed: 'NA', haveChanges:false, selected:false //Ref. H01
    };

    this.rowData.unshift(newRow);
    this.gridService.addTransaction([newRow]);
  }

  // <summary>
  //  Description: ngOnDestroy implements
  //  Author: Reinier Rodriguez V.
  //  Creation date: 21/Apr/2023    
  // </summary>  
  //  <history>
  //     <update> H00 - RD-104
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  // <summary>
  //  Description: Confirmation message display
  //  Author: Reinier Rodriguez V.
  //  Creation date: 21/Apr/2023    
  // </summary>  
  //  <history>
  //     <update> H00 - RD-104
  //              Description: Creation
  //     </update>
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      },
      reject: () => {
        callback(false);
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }

  /// <summary>
  ///     Description: Drop Down Combination select Row method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  onSelectionChanged(event) {
    this.selectedRows = event as IAccessControl;
    let tempData = this.rowData.filter(element => element.selected == true);
    if (tempData.length > 0)
      this.deleteVisible = true;
    else
      this.deleteVisible = false;
  }

  /// <summary>
  ///     Description: method to set information updated on AGgrid
  ///     Author: Reinier Rodriguez V.
  ///     Date: 24/Apr/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-104
  ///         Description: Creation.
  ///     </update>
  /// </history>
  textBoxChanged(event): void {
    if(event.colDef.field == "userId"){
      this.userService.GetByNickName(event.data.userId).subscribe( response => {
        if(response.value.length > 0)
        {
          const profile: IProfile = response.value[0];
          event.data.userName = profile.displayName;
          event.data.email = profile.mail;
        }
        else
        {
          event.data.userName = '';
          event.data.email = '';
          this.messageService.add({ severity: 'warn', summary: 'Not Found', detail: `User Nickname ${event.data.userId} not found` });
        }    
        this.gridService.updateTransaction([event.data]);
      });
    }
  }

  /// <summary>
  ///     Description: Load AgGrid Columns 
  ///     Author: Reinier Rodriguez V.
  ///     Date: 24/Apr/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-104
  ///         Description: Creation.
  ///     </update>
  /// </history>
  setColumnDefs():void{
    this.colDefSubscription = this.accessControlService.getAccessControlColumnDefs()
    .pipe(take(1))
    .subscribe(ColDefs => {
      this.columnDefs = ColDefs;
    });
  this.subscriptions.push(this.colDefSubscription);
  }
}
