
//  Description: Multi Selection Component
//  Author: Juan Carlos Arce
//  Creation date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
// </history>
 
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { DropdownFieldService } from 'src/app/providers/dropdown-field.service';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';
import { UserService } from '../../../../providers/user.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-multi-selection',
  templateUrl: './multi-selection.component.html',
  styleUrls: ['./multi-selection.component.scss'],
})
export class MultiSelectionComponent implements ICellRendererAngularComp, OnDestroy {
  public params: any;
  public selectedValues: any[];
  public listItems: any[];
  public parent;
  public dropdownFieldID;
  public columnThatModifies;
  public readOnly: boolean = false;

  
  GetAllUserSubscription: Subscription;
  DropdownFieldServiceSubscription: Subscription;
  DropdownFieldValueServiceSubscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(private dropdownFieldService: DropdownFieldService,
              private dropdownValueService: DropdownValueService,
              private userService: UserService,
              private validatorService: ValidatorService) {}

  //  Description: Multi Selection Component Init
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  //  Description: Multi Selection Component Init
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //      <update> H01 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
  //          Description: Set readonly with validator service
  //      </update>
  // </history>
  agInit(params: any): void {
    this.params = params;
    this.selectedValues = params.value;
    this.parent = params.context.componentParent;
    this.dropdownFieldID = params.field;
    this.columnThatModifies = params.colDef;
    //Ref: H01
    this.readOnly = this.validatorService.isComponentReadOnly(params);

    if(params.type)
    {
      this.getUsers();
    }else
    {
      this.getListValues();
    }
  }
  
  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  
  //  Description: Get DropDown Values from service
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  getListValues(){
    if(!this.dropdownFieldID) return;
    this.DropdownFieldServiceSubscription = this.dropdownFieldService.GetByID(this.dropdownFieldID).pipe(take(1)).subscribe(field => {
      this.DropdownFieldValueServiceSubscription = this.dropdownValueService.GetByParams([{name: 'id', value: field.dropdown_field_pk.toString()}]).pipe(take(1)).subscribe(values => {
        this.listItems = values.filter(x => x.active).map( val => this.getListObject(val));
      });

      this.subscriptions.push(this.DropdownFieldValueServiceSubscription);
    });

    this.subscriptions.push(this.DropdownFieldServiceSubscription);
  }

  
  //  Description: Get User Values from Service
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  getUsers(){
    this.GetAllUserSubscription = this.userService.GetAll().pipe(take(1)).subscribe(users => {
      this.listItems = users.filter(x => x.active).map( val => this.getUserListObject(val));
      });
    this.subscriptions.push(this.GetAllUserSubscription);
  }

  //  Description: Get DropDown Values formated
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  getListObject(val){
    return {value: val.dropdown_field_value_pk, text: val.dropdown_field_value};
  }

  //  Description: Get User Values formated
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  getUserListObject(val){
    return {value: val.users_Pk, text: val.user_Name};
  }

  //  Description: On Change Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  public onChange(pValues: any): void {
    this.parent.multiSelectionChanged(
      this.columnThatModifies,
      pValues.value,
      this.params.data,
      this.params.node.id
    );
  }

  //  Description: On Panel Show Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  public onPanelShow(param): void {
    
    var items:any = document.getElementsByClassName('ag-row-no-focus');
    for (let i = 0; i < items.length; i++) {
        let element = items[i];
        element.style.zIndex = -9999;    
    }
  }
  
  //  Description: On Panel Hide Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  public onPanelHide(param): void {
    
    var items:any = document.getElementsByClassName('ag-row-no-focus');
    for (let i = 0; i < items.length; i++) {
        let element = items[i];
        element.style.zIndex = "auto";    
    }
  }
}
