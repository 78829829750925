import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


//  Description: Footer Component
//  Author: Juan Carlos Arce
//  Creation date: 09/Sep/2022
//  <history>
//     <update> H00 - RD-23
//              Description: Creation.
//     </update>
// </history>

export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
