import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ApplicationRef, DoBootstrap } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCommonModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from './shared/shared.module';
import { ComponentsModule } from './components/components.module';
import { MsalModule, MsalRedirectComponent, MsalInterceptor } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation  } from '@azure/msal-browser';

import { LicenseManager } from '@ag-grid-enterprise/core'
import { settings, Settings } from '../settings/settings';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

LicenseManager.setLicenseKey(settings.AGGridLicense);

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

const isIframe = window !== window.parent && !window.opener;

Settings.IdentifyEnvironment();


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
    ],
    imports: [
        MsalModule.forRoot( new PublicClientApplication({
            auth: {
                clientId: settings.clientID,
                authority: settings.authority,
                redirectUri: settings.redirectUri,
            },
            cache: {
              cacheLocation: BrowserCacheLocation.LocalStorage,
              storeAuthStateInCookie: isIE,
            },
          }),{
            interactionType: isIframe ? InteractionType.Popup : InteractionType.Redirect,
            authRequest: {
              scopes: settings.msalAngular.consentScopes
            }
        },{
          interactionType: isIframe ? InteractionType.Popup : InteractionType.Redirect,
          protectedResourceMap: new Map([
              [settings.key1, settings.value1],
              [settings.key2, settings.value2],
              [settings.key3, settings.value3]
          ]),
        }),
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatCommonModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatDialogModule,
        MatCardModule,
        MatGridListModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatSidenavModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatTabsModule,
        MatExpansionModule,
        DragDropModule,
        HttpClientModule,
        AgGridModule,
        SharedModule,
        ComponentsModule,
        DialogModule,
        ButtonModule
    ],
    providers: [{
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    }],
    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule implements DoBootstrap {

    // https://confluence.research.corteva.com/pages/viewpage.action?spaceKey=DA&title=MSAL+Recommended+Setup
  
    ngDoBootstrap(ref: ApplicationRef) {
  
      if (window !== window.parent && !window.opener) {
        // Page is requested from within an IFRAME/XFRAME.  Load a dummy app componenet
        // ref.bootstrap(MsalAppComponent);
        ref.bootstrap(AppComponent);
      } else {
        // Normal application load
        ref.bootstrap(AppComponent);
      }
    }
  }
