import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IGlpStudySpecificsSearchModels } from 'src/app/models/glp-study-specifics-search.model';
import { GlpStudySpecificsSearchService } from './glp-study-specifics-search.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-glp-study-specifics-search',
  templateUrl: './glp-study-specifics-search.component.html',
  styleUrls: ['./glp-study-specifics-search.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class GlpStudySpecificsSearchComponent implements OnInit, OnDestroy {
  
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];
  public rowData: IGlpStudySpecificsSearchModels[];
  public selectedRow: IGlpStudySpecificsSearchModels | null;
  public rowSelection = 'single';
  public studySection: any;

  GlpStudySpecificsSearchSubscription: Subscription;
  colDefSubscription: Subscription;
  detailColDefSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(private glpstudyspecificssearchservice: GlpStudySpecificsSearchService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.colDefSubscription = this.glpstudyspecificssearchservice.getGLPStudySpecificsSearchColumnsDef()
    .pipe(take(1))
    .subscribe(ColDefs => {
      this.columnDefs = ColDefs;
    });
    this.subscriptions.push(this.colDefSubscription);

    this.detailColDefSubscription = this.glpstudyspecificssearchservice.getGLPStudySpecificsSearchColumnsDef()
    .pipe(take(1))
    .subscribe(DetailColDefs => {
      this.detailColumnDefs = DetailColDefs ?? null;
    });
    this.subscriptions.push(this.detailColDefSubscription);

    this.getData();
  }
  
  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  getData(){
    this.GlpStudySpecificsSearchSubscription = this.glpstudyspecificssearchservice.getGLPStudySpecificsSearchData()
    .pipe(take(1))
    .subscribe(Data => {
      this.rowData = Data.value;
    });
    this.subscriptions.push(this.GlpStudySpecificsSearchSubscription);
  }
}
