import { Component, OnInit } from '@angular/core';
import { settings } from '../../../settings/settings';
import { UserService } from 'src/app/providers/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

//  Description: Header Component
//  Author: Juan Carlos Arce
//  Creation date: 09/Sep/2022    
//  <history>
//     <update> H00 - RD-23
//              Description: Creation.
//     </update>
// </history>

export class HeaderComponent implements OnInit {

  logVisible: boolean = false;
  settingContent: string = '';

  constructor(public userService: UserService) {
  }
  
  ngOnInit(): void {
  }

  openDialog(): void {
    this.logVisible = true;
    this.settingContent = '';
    this.settingContent += '<strong class="subtitle">Service</strong><br/>';
    this.settingContent += '<span class="tag">Environment:</span> ' + settings.environment + '<br/>';
    this.settingContent += '<span class="tag">Service Url:</span> ' + settings.ServiceUrl + '<br/><br/>';

    this.settingContent += '<strong class="subtitle">MSAL</strong><br/>';
    this.settingContent += '<span class="tag">Client ID:</span> ' + settings.clientID + '<br/>';
    this.settingContent += '<span class="tag">Redirect Uri:</span> ' + settings.redirectUri + '<br/>';
    this.settingContent += '<span class="tag">key 1:</span> ' + settings.key1 + '<br/>';
    this.settingContent += '<span class="tag">key 2:</span> ' + settings.key2 + '<br/>';
    this.settingContent += '<span class="tag">key 3:</span> ' + settings.key3 + '<br/>';
    this.settingContent += '<span class="tag">value 1:</span> ' + settings.value1 + '<br/>';
    this.settingContent += '<span class="tag">value 2:</span> ' + settings.value2 + '<br/>';
    this.settingContent += '<span class="tag">value 3:</span> ' + settings.value3 + '<br/>';
  }
}