import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDropDownFieldCombination } from '../../models/value-build-combinations-data.model';
import { settings } from 'src/settings/settings';
import { elementAt, map } from 'rxjs/operators';
import { IResponse } from '../../models/response.model';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';

@Injectable({
  providedIn: 'root'
})
export class ValueBuildCombinationsService {

  headers = new HttpHeaders({
    'x-rapidapi-host': 'random-facts2.p.rapidapi.com',
    'x-rapidapi-key': 'your-api-key'
  });
  public moduleCombinations : IDropDownFieldCombination[] = [];

  constructor(private http: HttpClient) { }

  /// <summary>
  ///     Description: Method to get data from Combination table
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  getValueBuildCombinationsData(): Observable<any> {
    const query = settings.ServiceUrl + 'api/combinations';
    return this.http.get(query);
  }

  //  Description: Get Combinations by Module ID
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>

  getCombinationsByModuleCode(moduleCode: string): Observable<IDropDownFieldCombination[]> {
    const query = settings.ServiceUrl + 'api/combination/'+ moduleCode;
    return this.http.get(query).pipe(map((data: any) => data.value as IDropDownFieldCombination[]));
  }

  /// <summary>
  ///     Description: Method to get colunm defs from Combination table
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  /// </history>
  getValueBuildCombinationsColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/combinations/ValueBuildCombinationsDef.json")
    //Ref. H01
    .pipe(map((colDef: any) => {
      return colDef.map( item => {
        if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
        if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
        return item;
      })
    }));
  }

  /// <summary>
  ///     Description: Method to save data from Combination table
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  createValueBuildCombinationsData(data: IDropDownFieldCombination[]) {
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = settings.ServiceUrl + 'api/combination';
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data));
  }

  /// <summary>
  ///     Description: Method to delete data from Combination table
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  deleteValueBuildCombinationsData(dataId: number) {
    const query = settings.ServiceUrl + 'api/combination/' + dataId;
    return this.http
      .delete(query)
      .pipe(map((data: any) => data));
  }
}
