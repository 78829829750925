import { Component, Input, OnInit, ViewEncapsulation, OnChanges, ViewChild } from '@angular/core';
import { Node, Layout, GraphComponent } from '@swimlane/ngx-graph';
import * as shape from 'd3-shape';
import { degradationNodesOnlyLayout } from 'src/app/models/degradation-nodes-settings.model';

@Component({
  selector: 'app-molecule-structure',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './molecule-structure.component.html',
  styleUrls: ['./molecule-structure.component.scss'],
})

// <sumary>
// Description: Molecule Structure Component
// Author: Yun Wei
// Date: 18/Nov/2022
// </sumary>
// <history>
//     <update> H00 - RD-44
//              Description: Creation
//     </update>
//     <update> H01 - RD-81
//              Description: Refactor to be used as external component.
//     </update>
// </history>
export class MoleculeStructureComponent implements OnInit {

  @Input() links = [];
  @Input() nodes = [];
  @Input() view: any[];
  @Input() draggingEnabled: boolean=true;
  @Input() panningEnabled	: boolean=true;
  @Input() autoCenter:boolean=true;

  public showSpinner: boolean = true;

  autoZoom = false;
  panOnZoom = true;
  enableZoom = true;
  layoutSettings = {
    orientation: 'TB',
  };

  height = 590;

  curveType: string = 'Bundle';
  curve: any = shape.curveLinear;

  nodesList = [];
  linkList = [];

  // layout: String | Layout = 'dagre';
  layout: Layout = new degradationNodesOnlyLayout();
  ///
  // <sumary>
  // Description: Molecule Structure Component
  // Author: Yun Wei
  // Date: 18/Nov/2022
  // </sumary>
  //  <history>
  //     <update> H00 - RD-44
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-81
  //              Description: Refactor to be used as external component.
  //     </update>
  // </history>
  ngOnInit() {
    this.setInterpolationType(this.curveType);
  }

  ngOnChanges() {
    this.showSpinner = true;
    this.hideSpinner();
  }

  // <sumary>
  // Description: Molecule Structure Component
  // Author: Yun Wei
  // Date: 18/Nov/2022
  // </sumary>
  //  <history>
  //     <update> H00 - RD-44
  //              Description: Creation
  //     </update>
  // </history>
  setInterpolationType(curveType) {
    this.curveType = curveType;
    if (curveType === 'Bundle') {
      this.curve = shape.curveBundle.beta(1);
    }
    if (curveType === 'Cardinal') {
      this.curve = shape.curveCardinal;
    }
    if (curveType === 'Catmull Rom') {
      this.curve = shape.curveCatmullRom;
    }
    if (curveType === 'Linear') {
      this.curve = shape.curveLinear;
    }
    if (curveType === 'Monotone X') {
      this.curve = shape.curveMonotoneX;
    }
    if (curveType === 'Monotone Y') {
      this.curve = shape.curveMonotoneY;
    }
    if (curveType === 'Natural') {
      this.curve = shape.curveNatural;
    }
    if (curveType === 'Step') {
      this.curve = shape.curveStep;
    }
    if (curveType === 'Step After') {
      this.curve = shape.curveStepAfter;
    }
    if (curveType === 'Step Before') {
      this.curve = shape.curveStepBefore;
    }
  }

  // <sumary>
  // Description: Molecule Structure Component
  // Author: Yun Wei
  // Date: 18/Nov/2022
  // </sumary>
  //  <history>
  //     <update> H00 - RD-44
  //              Description: Creation
  //     </update>
  // </history>
  onLegendLabelClick(entry) {}
  /// <summary>
  /// Description: Hide spinner
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  hideSpinner() {
    setTimeout(() => {
      this.showSpinner = false;
    }, 10000);
  }
  
  /// <summary>
  /// Description: Checks if the provided Image is and SVG or a Base64 Image, used in HTML component file, to draw images accordingly.
  /// Author: George Ulecia.
  /// Date: 10/May/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-132
  ///              Description: Creation
  ///     </update>
  /// </history>
  checkSvgOrImg(imageData:any){
    if(typeof imageData!="string"){
      return true;
    }
    return false;
  }

}
