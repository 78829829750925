
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { settings } from 'src/settings/settings';
import { map } from 'rxjs/operators';
import { IGlpStudySpecifics, IGlpStudySpecificsDetail } from 'src/app/models/glp-study-specifics.model';
import { IResponse } from 'src/app/models/response.model';
import { ActionButtonService } from '../shared/action-button/action-button.service'
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { UserService } from 'src/app/providers/user.service';

@Injectable({
  providedIn: 'root'
})
export class GlpStudySpecificsService {

  public title: string = 'Executive Summary';

  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    private actionButtonService: ActionButtonService,
    private userService: UserService) {
  }

  /// Description: Get Study Section
  /// Author: Karina Villalobs Salazar
  /// Date: 14/Mar/2023   
  /// <history>
  ///     <update> H00 - RD-102
  ///          Description: Creation
  ///     </update>
  /// </history>
  getStudySection(studyNumber: number): Observable<any> {
    let data = {
      title: this.title,
      id_study: studyNumber
    };
    const query = settings.ServiceUrl + 'api/GetStudySection';
    return this.http.put(query, data);
  }

  //  Description: Get GLP Study Specifics Columns Definitions Method
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getGLPStudySpecificsColumnsDef(studySection?: string, editable: boolean = false): Observable<any> {
    let colDefLocation = `../../../assets/column_defs/glp_study_specifics_` + studySection + `/${studySection}`;
    if (editable) colDefLocation += `Editable`;
    colDefLocation += `ColumnsDef.json`;
    return this.http.get(colDefLocation)
      //Ref. H01
      .pipe(map((colDef: any) => {
        return colDef.map(item => {
          if (item.filter == "ListboxFilter") item.filter = ListboxFilter;
          if (item.filter == "DropdownFilter") item.filter = DropdownFilter;
          return item;
        })
      }));
  }

  //  Description: Get GLP Study Specifics Detail Columns Definitions Method
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Add studySection parameter.
  //     </update>
  ///     <update> H02 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getGLPStudySpecificsDetailColumnsDef(studySection: string, editable: boolean = false): Observable<any> {
    //Ref. H01
    let colDefLocation = `../../../assets/column_defs/glp_study_specifics_` + studySection + `/${studySection}`;
    if (editable) colDefLocation += `Editable`;
    colDefLocation += `DetailColumnsDef.json`;
    return this.http.get(colDefLocation)
      //Ref. H02
      .pipe(map((colDef: any) => {
        return colDef.map(item => {
          if (item.filter == "ListboxFilter") item.filter = ListboxFilter;
          if (item.filter == "DropdownFilter") item.filter = DropdownFilter;

          return item;
        })
      }));
  }

  /// <summary>
  ///     Description: Method to get data from GLP Study Specifics by Material ID
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  /// </history>
  getGLPStudySpecificsDataByMaterialID(materialId: number, studySection: string): Observable<any> {
    if (!materialId) return;
    let query = settings.ServiceUrl + 'api/glpstudyspecifics/params?MaterialIdCore=' + materialId + '&Section=' + studySection;
    return this.http.get(query);
  }

  /// <summary>
  ///     Description: Method to delete data from GLP Study Specifics by ID
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  /// </history>
  deleteGLPStudySpecificsDataByID(Id: number): Observable<any> {
    let query = settings.ServiceUrl + 'api/glpstudyspecifics/' + Id;
    return this.http.delete(query);
  }

  //<summary>
  //  Description: Method to save StudySpecifis
  //  Author: Allam Barrera G && Reinier Rodriguez V
  //  Date: 14/Nov/2022
  //</summary>   
  //<history>
  //   <update> H00 - RD-42
  //            Description: Creation.
  //   </update>
  //</history>
  addGLPStudySpecifics(data: IGlpStudySpecifics[]): Observable<any> {
    const query = settings.ServiceUrl + 'api/glpstudyspecifics/add';
    return this.http.post(query, data);
  }

  //<summary>
  //  Description: Method to get transform data to an object
  //  Author: Allam Barrera G && Reinier Rodriguez V
  //  Date: 14/Nov/2022
  //</summary>
  //<history>
  //  <update> H00 - RD-50
  //      Description: Creation.
  //  </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Add Residues support.
  //     </update>
  //</history>
  transformAPIData(data: IResponse, section: string): IGlpStudySpecifics[] {
    if (data.statusCode != 200) return [];
    return this.actionButtonService.Editable ?
      this.transformEditableData(data.value) :
      this.transformReadOnlyData(data.value);
  }

  transformItem(item: any): IGlpStudySpecifics {
    item.pk = item.glp_study_specifics_pk;
    item.haveChanges = false;
    return item;
  }

  //  Description: Transform Read Only Data
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Add residues list support and Method Refactor.
  //     </update>
  //     <update> H02 - RD-100 - 09/March/2023 - George Ulecia
  //              Description: Add Enviroment Fate support.
  //     </update>
  //     <update> H03 - RD-120 - 22/March/2023 - George Ulecia
  //              Description: Add metabolite relevance usage.
  //     </update>
  //     <update> H04 - RD-146 - 25/May/2023 - Tomas Samuels
  //              Description: Add Level3
  //     </update>
  //     <update> H05 - RD-164 - 14/Jun/2023 - Karina Villalobos S.
  //              Description: Add Physical Chemical support.
  //     </update>
  //     <update> H06 - RD-185 - 04/Nov/2023 - Reinier Rodriguez V.
  //              Description: Add biosystem 4 and biosystem 5 and radioLabel
  //     </update>
  // </history>
  transformReadOnlyData(dataValue: any[]): IGlpStudySpecifics[] {
    let result: IGlpStudySpecifics[] = [];
    dataValue?.forEach(async item => {
      let newItem = {
        pk: item.glp_study_specifics_pk,
        expanded: false,
        haveChanges: false,
        dossier_point: item.dossier_point,
        sectionHTML: item.sectionHTML,
        sectionHTMLSummary: item.sectionHTML ? [item.sectionHTML] : undefined,
        descriptor: item.descriptor,
        is_external: item.is_external,
        material_id_core: item.material_id_core,
        study_section: item.study_section,
        study_status_dropdown_field_value_pk: item.study_status_dropdown_field_value_pk,
        study_title: item.study_title,
        study_year: item.study_year,
        study_number: item.study_number
      };

      if (item.listGLPStudySpecificsMetabolismDTO?.length > 0 || item.listGLPStudySpecificsResiduesDTO?.length > 0 || item.enviromentFate_specific_data?.length > 0 ||
        item.physChem_specific_data?.length > 0) {

        item.listGLPStudySpecificsMetabolismDTO?.forEach((metabolism: any) => {
          let newMetabolismItem = {
            ...newItem,
            biosystemLevel1DropdownFieldValuePk: metabolism.biosystemLevel1DropdownFieldValuePk,
            biosystemLevel1: metabolism.biosystemLevel1,
            biosystemLevel2DropdownFieldValuePk: metabolism.biosystemLevel2DropdownFieldValuePk,
            biosystemLevel2: metabolism.biosystemLevel2,
            biosystemLevel3DropdownFieldValuePk: metabolism.biosystemLevel3DropdownFieldValuePk, //ref:H04
            biosystemLevel3: metabolism.biosystemLevel3,//ref:H04
            biosystemLevel4DropdownFieldValuePk: metabolism.biosystemLevel4DropdownFieldValuePk, //ref. H06
            biosystemLevel4: metabolism.biosystemLevel4,//ref:H06
            biosystemLevel5DropdownFieldValuePk: metabolism.biosystemLevel5DropdownFieldValuePk, //ref. H06
            biosystemLevel5: metabolism.biosystemLevel5,//ref:H06
            radioLabel: metabolism.radioLabel, //ref, H06
            trrPercentage: metabolism.trrPercentage,
            trrPpm: metabolism.trrPpm,
            analyte: metabolism.analyte,
            analyteMaterialId: metabolism.analyteMaterialId,
            analyteMaterialName: metabolism.analyteMaterialName != '' ? metabolism.analyteMaterialName : metabolism.analyte,
            metabolitesRelevanceList: metabolism.metabolitesRelevanceList, //REF H03
            comments: metabolism.comments,
            
          };
          result.push(newMetabolismItem);
          newMetabolismItem = null;
        });

        item.listGLPStudySpecificsResiduesDTO?.forEach((residue: any) => {
          let newResidueItem = {
            ...newItem,
            biosystemLevel1DropdownFieldValuePk: residue.biosystemLevel1DropdownFieldValuePk,
            biosystemLevel1: residue.biosystemLevel1,
            biosystemLevel2DropdownFieldValuePk: residue.biosystemLevel2DropdownFieldValuePk,
            biosystemLevel2: residue.biosystemLevel2,
            biosystemLevel3DropdownFieldValuePk: residue.biosystemLevel3DropdownFieldValuePk, //ref:H04
            biosystemLevel3: residue.biosystemLevel3,//ref:H04
            biosystemLevel4DropdownFieldValuePk: residue.biosystemLevel4DropdownFieldValuePk, //ref. H06
            biosystemLevel4: residue.biosystemLevel4,//ref:H06
            biosystemLevel5DropdownFieldValuePk: residue.biosystemLevel5DropdownFieldValuePk, //ref. H06
            biosystemLevel5: residue.biosystemLevel5,//ref:H06
            analyte: residue.analyte,
            analyteMaterialId: residue.analyteMaterialId,
            analyteMaterialName: residue.analyteMaterialName,
            formulation: residue.formulation,
            samplingEvent: residue.samplingEvent,
            residueMin: residue.residueMin,
            residueMax: residue.residueMax,
            residueMedian: residue.residueMedian,
            sampleSize: residue.sampleSize,
            metabolitesRelevanceList: residue.metabolitesRelevanceList,//REF H03

          };
          result.push(newResidueItem);
          newResidueItem = null;
        });

        item.enviromentFate_specific_data?.forEach((enviromentFate: any) => {
          let newEnviromentFateItem = {
            ...newItem,
            biosystemLevel1DropdownFieldValuePk: enviromentFate.biosystemLevel1DropdownFieldValuePk,
            biosystemLevel1: enviromentFate.biosystemLevel1,
            biosystemLevel2DropdownFieldValuePk: enviromentFate.biosystemLevel2DropdownFieldValuePk,
            biosystemLevel2: enviromentFate.biosystemLevel2,
            biosystemLevel3DropdownFieldValuePk: enviromentFate.biosystemLevel3DropdownFieldValuePk, //ref:H04
            biosystemLevel3: enviromentFate.biosystemLevel3,//ref:H04
            biosystemLevel4DropdownFieldValuePk: enviromentFate.biosystemLevel4DropdownFieldValuePk, //ref. H06
            biosystemLevel4: enviromentFate.biosystemLevel4,//ref:H06
            biosystemLevel5DropdownFieldValuePk: enviromentFate.biosystemLevel5DropdownFieldValuePk, //ref. H06
            biosystemLevel5: enviromentFate.biosystemLevel5,//ref:H06
            analyte: enviromentFate.analyte,
            analyteMaterialId: enviromentFate.analyteMaterialId,
            analyteMaterialName: enviromentFate.analyteMaterialName != '' ? enviromentFate.analyteMaterialName : enviromentFate.compound,
            radioLabel: enviromentFate.radioLabel,
            arPercentage: enviromentFate.arPercentage,
            dat: enviromentFate.dat,
            metabolitesRelevanceList: enviromentFate.metabolitesRelevanceList,//REF H03
            comments: enviromentFate.comments,


          };
          result.push(newEnviromentFateItem);
          newEnviromentFateItem = null;
        });
        //Ref. H05
        item.physChem_specific_data?.forEach((physChem: any) => {
          let newPhysChemItem = {
            ...newItem,
            testphysChem: physChem.testphysChem,
            test_itemphysChem: physChem.test_itemphysChem,
            concentration: physChem.concentration,
            temperature: physChem.temperature,
            organic_solvents: physChem.organic_solvents,
            ph: physChem.ph,
            resultsphysChem: physChem.resultsphysChem,
            unit: physChem.unit,
            deviation: physChem.deviation,
            wavelength: physChem.wavelength

          };
          result.push(newPhysChemItem);
          newPhysChemItem = null;
        });

      } else {
        result.push(newItem);
      }
    });
    return result;
  }

  //  Description: Transform Editable Data
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Add residues list support
  //     </update>
  //     <update> H02 - RD-99
  //         Description: Check If Data is External To enable Read Only
  //     </update>
  //     <update> H03 - RD-100 - 09/March/2023 - George Ulecia
  //         Description: Add Enviroment Fate list support
  //     </update>
  // </history>
  transformEditableData(dataValue: any[]): IGlpStudySpecifics[] {
    let result: IGlpStudySpecifics[] = [];
    dataValue?.forEach(item => {
      item.pk = item.glp_study_specifics_pk;
      item.expanded = false;
      item.haveChanges = false;
      item.children = [];
      item.readOnly = item.is_external == undefined ? false : item.is_external;
      item.sectionHTMLSummary = item.sectionHTML ? [item.sectionHTML] : undefined;

      if (item.listGLPStudySpecificsMetabolismDTO && item.listGLPStudySpecificsMetabolismDTO.length > 0) {
        item.listGLPStudySpecificsMetabolismDTO.map((metabolism: any) => {
          item.children.push(this.getMetabolismAsChildren(metabolism));
        });
      }

      //Ref. H01
      if (item.listGLPStudySpecificsResiduesDTO && item.listGLPStudySpecificsResiduesDTO.length > 0) {
        item.listGLPStudySpecificsResiduesDTO.map((residues: any) => {
          item.children.push(this.getResiduesAsChildren(residues, item.readOnly));
        });
      }

      //Ref. H03
      if (item.enviromentFate_specific_data && item.enviromentFate_specific_data.length > 0) {
        item.enviromentFate_specific_data.map((enviromentFate: any) => {
          item.children.push(this.getenviromentFateAsChildren(enviromentFate));
        });
      }

      if (item.physChem_specific_data && item.physChem_specific_data.length > 0) {
        item.physChem_specific_data.map((physChem: any) => {
          item.children.push(this.getphysChemAsChildren(physChem, item));
        });
      }
      let found = result.find(x => x.dossier_point == item.dossier_point && x.study_number == item.study_number);
      if (!found) {
        result.push(item);
      }
      else {
        if (!found.children) found.children = [];
        if (item.children?.length > 0) {
          item.children.forEach(child => {
            found.children.push(child);
          });
        }
        if (item.sectionHTMLSummary) {
          found.sectionHTMLSummary ? found.sectionHTMLSummary.push(item.sectionHTML) : found.sectionHTMLSummary = item.sectionHTMLSummary;
          found.sectionHTMLSummary = [...new Set(found.sectionHTMLSummary)];
        }
      }
    });
    return result;
  }

  //  Description: Transform Save Data
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Add residues list save support
  //     </update>
  //     <update> H02 - RD-100 - 09/March/2023 - George Ulecia
  //              Description: Add Enviroment Fate support.
  //     </update>
  //     <update> H03 - RD-164 - 14/Jun/2023 - Karina Villalobos
  //              Description: Add physical Chemical support.
  //     </update>
  // </history>
  transformSaveData(dataValue: any[]): any[] {
    let result: IGlpStudySpecifics[] = [];

    dataValue?.forEach(item => {

      item.listGLPStudySpecificsMetabolismDTO = [];
      item.listGLPStudySpecificsResiduesDTO = [];
      item.enviromentFate_specific_data = [];
      item.physChem_specific_data = [];
      item.study_status = "";
      item.created_by = item.glp_study_specifics_pk == 0 || !item.created_by ? this.userService.profile.displayName : item.created_by;
      item.created_date = item.glp_study_specifics_pk == 0 || !item.created_date ? new Date() : item.created_date;
      item.updated_by = this.userService.profile.displayName;
      item.updated_date = new Date();
      if (item.children && item.children.length > 0) {
        item.children.map((child: any) => {
          if (item.study_section == 'metabolism') item.listGLPStudySpecificsMetabolismDTO.push(this.getChildrenAsObject(item, child));
          if (item.study_section == 'residues') item.listGLPStudySpecificsResiduesDTO.push(this.getChildrenAsObject(item, child));
          if (item.study_section == 'enviromentFate') item.enviromentFate_specific_data.push(this.getChildrenAsObject(item, child));
          if (item.study_section == 'physicalChemical') item.physChem_specific_data.push(this.getChildrenAsObject(item, child));  //Ref. H03
        });
      }
      result.push(item);
    });
    return result;
  }

  //  Description: Get Metabolism Data as Children Method
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-120 - 22/March/2023 - George Ulecia
  //              Description: Add metabolite relevance usage.
  //     </update>
  //     <update> H02 - RD-194 - 15/Feb/2024 - Reinier Rodriguez V.
  //              Description: 1) Add reference to verify if biosystem levels have information, if not load the list
  //                           2) When the value is equal to -1 the information for dropdownvalues is loaded automatically wih all possible records.
  //     </update>
  // </history>
  getMetabolismAsChildren(metabolism: any): IGlpStudySpecificsDetail {
    let newChildren: IGlpStudySpecificsDetail = {
      ...metabolism,
      pk: metabolism.glpStudySpecificsMetabolismPk,
      haveChanges: false,
      metabolitesRelevanceList: metabolism.metabolitesRelevanceList, //REF H01
      analyteMaterialName: metabolism.analyteMaterialName ? metabolism.analyteMaterialName : metabolism.analyte,
      biosystemLevel1DropdownFieldValuePk: metabolism.biosystemLevel1 == '' ? -1 : metabolism.biosystemLevel1DropdownFieldValuePk, //H02
      biosystemLevel2DropdownFieldValuePk: metabolism.biosystemLevel2 == '' ? -1 : metabolism.biosystemLevel2DropdownFieldValuePk, //H02
      biosystemLevel3DropdownFieldValuePk: metabolism.biosystemLevel3 == '' ? -1 : metabolism.biosystemLevel3DropdownFieldValuePk, //H02
      biosystemLevel4DropdownFieldValuePk: metabolism.biosystemLevel4 == '' ? -1 : metabolism.biosystemLevel4DropdownFieldValuePk, //H02
      biosystemLevel5DropdownFieldValuePk: metabolism.biosystemLevel5 == '' ? -1 : metabolism.biosystemLevel5DropdownFieldValuePk  //H02
    }
    
    return newChildren;
  }

  //  Description: Get Residue Data as Children Method
  //  Author: Juan Carlos Arce
  //  Date: 09/Feb/2023
  //  <history>
  //     <update> H00 - RD-69
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-120 - 22/March/2023 - George Ulecia
  //              Description: Add metabolite relevance usage.
  //     </update>
  //     <update> H02 - RD-194 - 15/Feb/2024 - Reinier Rodriguez V.
  //              Description: 1) Add reference to verify if biosystem levels have information, if not load the list
  //                           2) When the value is equal to -1 the information for dropdownvalues is loaded automatically wih all possible records.
  //     </update>
  // </history>
  getResiduesAsChildren(residues: any, readOnly: boolean): IGlpStudySpecificsDetail {
    let newChildren: IGlpStudySpecificsDetail = {
      ...residues,
      pk: residues.glpStudySpecificsResiduePk,
      haveChanges: false,
      readOnly: readOnly,
      metabolitesRelevanceList: residues.metabolitesRelevanceList,//REF H01
      biosystemLevel1DropdownFieldValuePk: residues.biosystemLevel1 == '' ? -1 : residues.biosystemLevel1DropdownFieldValuePk, //H02
      biosystemLevel2DropdownFieldValuePk: residues.biosystemLevel2 == '' ? -1 : residues.biosystemLevel2DropdownFieldValuePk, //H02
      biosystemLevel3DropdownFieldValuePk: residues.biosystemLevel3 == '' ? -1 : residues.biosystemLevel3DropdownFieldValuePk, //H02
      biosystemLevel4DropdownFieldValuePk: residues.biosystemLevel4 == '' ? -1 : residues.biosystemLevel4DropdownFieldValuePk, //H02
      biosystemLevel5DropdownFieldValuePk: residues.biosystemLevel5 == '' ? -1 : residues.biosystemLevel5DropdownFieldValuePk  //H02
    }
    return newChildren;
  }
  //  Description: Get Enviroment Fate Data as Children Method
  //  Author:  George Ulecia
  //  Date: 09/March/2023
  //  <history>
  //     <update> H01 - RD-100 
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-120 - 22/March/2023 - George Ulecia
  //              Description: Add metabolite relevance usage.
  //     </update>
  //     <update> H02 - RD-194 - 15/Feb/2024 - Reinier Rodriguez V.
  //              Description: 1) Add reference to verify if biosystem levels have information, if not load the list
  //                           2) When the value is equal to -1 the information for dropdownvalues is loaded automatically wih all possible records.
  //     </update>
  // </history>
  getenviromentFateAsChildren(enviromentFate: any): IGlpStudySpecificsDetail {
    let newChildren: IGlpStudySpecificsDetail = {
      ...enviromentFate,
      pk: enviromentFate.glpStudySpecificsEnviromentFatePk,
      haveChanges: false,
      metabolitesRelevanceList: enviromentFate.metabolitesRelevanceList,//REF H01
      analyteMaterialName: enviromentFate.analyteMaterialName ? enviromentFate.analyteMaterialName : enviromentFate.compound,
      biosystemLevel1DropdownFieldValuePk: enviromentFate.biosystemLevel1 == '' ? -1 : enviromentFate.biosystemLevel1DropdownFieldValuePk, //H02
      biosystemLevel2DropdownFieldValuePk: enviromentFate.biosystemLevel2 == '' ? -1 : enviromentFate.biosystemLevel2DropdownFieldValuePk, //H02
      biosystemLevel3DropdownFieldValuePk: enviromentFate.biosystemLevel3 == '' ? -1 : enviromentFate.biosystemLevel3DropdownFieldValuePk, //H02
      biosystemLevel4DropdownFieldValuePk: enviromentFate.biosystemLevel4 == '' ? -1 : enviromentFate.biosystemLevel4DropdownFieldValuePk, //H02
      biosystemLevel5DropdownFieldValuePk: enviromentFate.biosystemLevel5 == '' ? -1 : enviromentFate.biosystemLevel5DropdownFieldValuePk  //H02
    }
    return newChildren;
  }

  //  Description: Get PhysChem Data as Children Method
  //  Author:  Karina Villalobos S
  //  Date: 14/Jun/2023
  //  <history>
  //     <update> H01 - RD-164
  //              Description: Creation.
  //     </update>
  // </history>
  getphysChemAsChildren(physChem: any, item: any): IGlpStudySpecificsDetail {
    let newChildren: IGlpStudySpecificsDetail = {
      ...physChem,
      pk: physChem.glpStudySpecificsPhysicalChemicalPk,
      haveChanges: false,
      testphysChem: physChem.testphysChem,
      test_itemphysChem: physChem.test_itemphysChem,
      concentration: physChem.concentration,
      temperature: physChem.temperature,
      organic_solvents: physChem.organic_solvents,
      ph: physChem.ph,
      resultsphysChem: physChem.resultsphysChem,
      unit: physChem.unit,
      deviation: physChem.deviation,
      wavelength: physChem.wavelength,
      readOnly: item.is_external,
      forcedReadOnly: item.is_external
    }
    return newChildren;
  }

  //  Description: Get Children Data as Object Method
  //  Author: Juan Carlos Arce
  //  Date: 09/Feb/2023
  //  <history>
  //     <update> H00 - RD-69
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-100 - 09/March/2023 - George Ulecia
  //              Description: Add Enviroment Fate support.
  //     </update>
  //     <update> H02 - RD-120 - 22/March/2023 - George Ulecia
  //              Description: Removed SpecificData Pks so save/update works.
  //     </update>
  //     <update> H03 - RD-185 - 03/Nov/2023 - Reinier Rodriguez V.
  //              Description: Add new reference for biosystem lvl 4 and 5.
  //     </update>
  // </history>
  getChildrenAsObject(parent: any, child: any): any {
    let newMetabolism = {
      ...child,
      glpStudySpecificsPk: parent.pk,
      biosystemLevel1DropdownFieldValuePk: Number(child.biosystemLevel1DropdownFieldValuePk),
      biosystemLevel2DropdownFieldValuePk: Number(child.biosystemLevel2DropdownFieldValuePk),
      biosystemLevel3DropdownFieldValuePk: Number(child.biosystemLevel3DropdownFieldValuePk),
      biosystemLevel4DropdownFieldValuePk: Number(child.biosystemLevel4DropdownFieldValuePk), //Ref. H03
      biosystemLevel5DropdownFieldValuePk: Number(child.biosystemLevel5DropdownFieldValuePk) //Ref. H03
    }
    return newMetabolism;
  }

  saveGLPStudySpecifics(data: IGlpStudySpecifics[]) {
    const query = settings.ServiceUrl + 'api/glpstudyspecifics';
    return this.http.put(query, data);
  }

  saveGLPStudySpecificsData(data: IGlpStudySpecifics[]) {
    return this.saveGLPStudySpecifics(this.transformSaveData(data));
  }
}

