//  Description: Metabolite Confirmed Component
//  Author: Juan Carlos Arce
//  Creation date: 02/Nov/2022   
//  <history>
//     <update> H00 - RD-34
//              Description: Creation.
//     </update>
// </history>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IMetaboliteRelevanceBiosystems } from '../../models/probe-proposed.model';
import { ActiveIngredientService } from '../shared/active-ingredient-selector/active-ingredient.service';
import { IActiveIngredient } from '../../models/active-ingredient.model';
import { ParOptionListService } from '../../providers/par-option-list.service';
import { MetaboliteConfirmedService } from './metabolite-confirmed.service';
import { IMetaboliteConfirmed } from '../../models/metabolite-confirmed.model';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { AccordionService } from '../shared/grid-components/accordion/accordion.service';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ExportExcel } from 'src/app/providers/export-excel.service';
import { async } from '@angular/core/testing';
import { promise } from 'protractor';

@Component({
  selector: 'app-metabolite-confirmed',
  templateUrl: './metabolite-confirmed.component.html',
  styleUrls: ['./metabolite-confirmed.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class MetaboliteConfirmedComponent implements OnInit, OnDestroy {


  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentActiveIngredient: IActiveIngredient;

  public rowData: IMetaboliteConfirmed[];
  public rowSelection = 'single';
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];
  public projectStateOptions = [];
  public selectedRow: IMetaboliteConfirmed | null;
  public selectedDetailRows: IMetaboliteRelevanceBiosystems[];
  public deleteVisible: boolean = false;
  public module = "confirmed";
  public isExcelLoading: boolean = false;

  constructor(private metaboliteConfirmedService: MetaboliteConfirmedService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public AiService: ActiveIngredientService,
    private parService: ParOptionListService,
    private gridService: GridService,
    private accordionService: AccordionService,
    private actionButtonService: ActionButtonService, private exportExcel: ExportExcel) { }


  //  Description: Probe Proposed Component Init
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Validate if Active Ingredient is already selected
  //     </update>
  //     <update> H02 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Validate if Active Ingredient is already selected
  //     </update>
  // </history>
  ngOnInit(): void {
    this.actionButtonService.UseEditMode = false; //Ref. H01
    this.actionButtonService.Editable = true; //Ref. H01
    this.metaboliteConfirmedService.getMetaboliteConfirmedColumsDefs()
      .pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefs = ColDefs;
      });

    this.metaboliteConfirmedService.getDetailMetaboliteConfirmedColumsDefs()
      .pipe(take(1))
      .subscribe(DetailColDefs => {
        this.detailColumnDefs = DetailColDefs ?? null;
      });

    //Ref. H02
    if (this.AiService.SelectedActiveIngredient) {
      this.getMetaboliteConfirmed(this.AiService.SelectedActiveIngredient.material_id);
    }

    this.AiService.selectedActiveIngredient$.pipe(takeUntil(this.destroy$)).subscribe(async selectedActiveIngredient => {
      if (selectedActiveIngredient && selectedActiveIngredient != this.currentActiveIngredient) {
        this.currentActiveIngredient = selectedActiveIngredient;
        await this.getMetaboliteConfirmed(selectedActiveIngredient.material_id);
      }
    });

    this.selectedRow = null;
    this.selectedDetailRows = [];
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  //#region Methods

  //  Description: Delete Confirmation
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      },
      reject: () => {
        callback(false);
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }
  //#endregion

  //  Description: Search Cell Change Event
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-107 - 01/Mar/2023 - Juan C Arce
  //              Description: Add Data parameter and X Number logic.
  //     </update>
  // </history>
  onSearchCellChanged({ field, value, data }) {
    let row = this.rowData.find(x => x.pk == this.selectedRow.pk);
    if (field == "synthesis_request_id_par") {
      this.parService.GetSynthesisByIdentifier(value).pipe(take(1)).subscribe(synthesis => {
        row.synthesisPercComplete = synthesis.percentage_complete;
        row.synthesisRequestStatus = synthesis.status;
        row.synthesisNeedByDate = synthesis.need_by_date;
      });
    }

    if (field == "analytical_request_id_par") {
      this.parService.GetAnalysisByIdentifier(value).pipe(take(1)).subscribe(analysis => {
        row.analyticalPercComplete = analysis.percentage_complete;
        row.analyticalRequestStatus = analysis.status;
      });
    }

    //Ref. H01
    if (field == "x_number") this.getIdentityData(data);
  }

  //#region Events
  //  Description: On Selection Changed Event
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  onSelectionChanged(event) {
    this.selectedRow = event as IMetaboliteConfirmed;
    let tempData = this.rowData.filter(element => element.selected == true);
    if (tempData.length > 0)
      this.deleteVisible = true;
    else
      this.deleteVisible = false;
  }

  //  Description: On Detail Selection Changed Event
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  onDetailSelectionChanged(event) {
    this.selectedDetailRows = event as IMetaboliteRelevanceBiosystems[];
  }

  //  Description: On Save Clicked Event
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  onSaveClick(event) {
    this.saveMetaboliteConfirmed(this.rowData);
  }

  //  Description: On Delete Event
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  onDeleteProbePropose(event: Event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.deleteMetaboliteConfirmed();
      }
    });
  }

  //  Description: On Delete Detail Event
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  onDeleteDetail(event) {
    let deletePKs = event.detail.map(x => x.PK);
    event.parent.children = event.parent.children.filter(x => !deletePKs.includes(x.PK));
    this.accordionService.confirmedRemoveTransaction(event.detail);
  }


  //  Description: On Add Detail Event
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  onAddDetail(pk) {
    let row = this.rowData.find(x => x.pk == this.selectedRow.pk);
    if (!row.proposedBiosystem) row.proposedBiosystem = [];
    row.proposedBiosystem.unshift({
      pk: pk,
      levelI: "",
      levelII: "",
      levelIII: "",
      metaboliteRelevance: ""
    }
    );
    row.metaboliteRelevancesConfirmed = false;
    row.metaboliteRelevancesProposed = false;
  }


  //  Description: On Add Event
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  onAddMetaboliteConfirmed(event) {
    let newRow: IMetaboliteConfirmed = {
      pk: -1, x_number: '', x_number_metabolite: '', synonyms: [], molecular_weight: '', exactMass: '', molecularFormula: undefined,
      structure: undefined, smilesCode: '', structureStatus: undefined, priority: undefined, projectStage: [], proposedName: '',
      proposedMolecularWeight: '', proposedSmilesCode: '', proposedFormula: '', proposedExactMass: '', experimentalData: '',
      msInvestigator: [], pscInvestigator: [], comments: '', enteredBy: '', dateUpdated: undefined, synthesisRequestID: '',
      synthesisRequestStatus: '', synthesisPercComplete: 0, synthesisNeedByDate: undefined, analyticalRequestID: '',
      analyticalPercComplete: 0, analyticalRequestStatus: '', lotId: 0, sampleQTY: 0, recertificateDate: undefined, expirationDate: undefined,
      labeled_ring: '', metabolite_group: '', confirmedComment: '', children: [], proposedBiosystem: [],
      molecular_formula: '', smile_code: '', haveChanges: true, metaboliteRelevancesConfirmed: true, metaboliteRelevancesProposed: true,
      metaboliteRelevances: [], confirmation_date: undefined
    };

    newRow['material_id_core'] = this.AiService.SelectedActiveIngredient.material_id;
    newRow['metabolite_identification_confirmed_pk'] = -1;
    newRow['exact_mass'] = "";
    newRow['identity_exact_mass'] = '';
    newRow['readOnly'] = false;
    this.rowData.unshift(newRow);
    this.gridService.addTransaction([newRow]);
  }

  //  Description: On DropDown Selection Event
  //  Author: Juan Carlos Arce
  //  Date: 17/Nov/2022    
  //  <history>
  //     <update> H00 - RD-54
  //              Description: Creation.
  //     </update>
  // </history>
  onDropDownSelectionChanged(event) {
    this.getIdentityData(event.data);
  }
  //#endregion

  //#region ProbeProposed CRUD

  async getIdentityData(item: any) {
    let tempData = [...this.rowData];
    await this.metaboliteConfirmedService.getIdentityData(item);
    this.rowData = tempData;
  }

  //  Description: Get Metabolite Confirmed
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-54 - 17/Nov/2022 - Juan Carlos Arce
  //              Description: Modify Event to handle search with New Row XNumber Dropdown.
  //     </update>
  //     <update> H02 - RD-87 - 06/Feb/2023 - Yun Wei
  //              Description: a) Set ReadOnly true to Proposed records and readonly false to Confirmed records
  //                           b) Asign structure status pk and priority pk to local variables
  //     </update>
  //     <update> H03 - RD-112 - 23/Mar/2023 - Tomas Samuels
  //              Description: Add Confirmation Date
  //     </update>
  //     <update> H03 - RD-112 - 23/Mar/2023 - Tomas Samuels
  //              Description: Add Confirmation Date
  //     </update>
  //     <update> H04 - RD-131 - Karina Villalobos S.
  //              Description: Get confirmed Bio System.
  //     </update>
  // </history>
  async getMetaboliteConfirmed(materialId: any) {
    this.metaboliteConfirmedService.getMetaboliteIdentificationProposed(materialId)
      .pipe(take(1))
      .subscribe(proposedList => {
        proposedList.map((element: any) => {
          element.identity_exact_mass = '';
          element.proposed_comments = element.comments;
          element.comments = '';
          element.metabolite_identification_confirmed_pk = -1;
          element.confirmedBiosystem = [];
          element.proposedBiosystem = [];
          element.x_number = element.x_number;
          element.children.forEach(child => {
            element.confirmedBiosystem.push({ ...child, readOnly: false });
            element.proposedBiosystem.push({ ...child, readOnly: true });
          });
          element.readOnly = true; //Ref.H02.a
          delete element.children;
        });

        this.rowData = proposedList.filter(x => x.x_number != '');

        this.metaboliteConfirmedService.getMetaboliteConfirmedRelevance(materialId)
          .pipe(take(1))
          .subscribe(response => {
            let confirmedList = response.value;

            confirmedList.map(element => {
              element.x_number = element?.x_number_metabolite;
              element.identity_exact_mass = element.exact_mass;
              element.structure_status = element.structure_status_dropdown_field_value_pk; //Ref.H02.b
              element.priority = element.priority_dropdown_field_value_pk; //Ref.H02.b
              element.readOnly = false; //Ref.H02.a
              element.confirmation_date = element.confirmation_date == undefined ? undefined : new Date(element.confirmation_date); //Ref H03
              element.confirmedBiosystem = this.metaboliteConfirmedService.transformAPIDataBioSystem(element.metaboliteRelevanceBiosystem);//Ref H04
              delete element.x_number_metabolite;
              delete element.structure_status_dropdown_field_value_pk;
              delete element.priority_dropdown_field_value_pk;
              delete element.exact_mass;
            });

            this.rowData.map((proposed: any) => {
              let confirm = confirmedList.filter(item => 'X' + proposed.x_number == item.x_number || proposed.x_number == item.x_number)[0];
              if (confirm) {
                proposed.x_number = confirm.x_number;
                proposed.metabolite_identification_confirmed_pk = confirm.metabolite_identification_confirmed_pk;
                proposed.identity_exact_mass = confirm.identity_exact_mass;
                proposed.structure_status = confirm.structure_status;
                proposed.priority = confirm.priority;
                proposed.labeled_ring = confirm.labeled_ring;
                proposed.metabolite_group = confirm.metabolite_group;
                proposed.comments = confirm.comments;
                proposed.metaboliteRelevances = confirm.metaboliteRelevances;
                proposed.confirmedBiosystem = confirm.confirmedBiosystem;//Ref H04
                proposed.confirmation_date = confirm.confirmation_date; //Ref H03
                let index = confirmedList.indexOf(confirm);
                confirmedList.splice(index, 1);
              }
            });

            this.rowData = this.rowData.concat(confirmedList);
            this.rowData.forEach(item => {
              this.getIdentityData(item);
            });
          });
      });
  }

  //  Description: update Metabolite Confirmed
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-54 - 14/Nov/2022 -  Juan C. Arce 
  //              Description: Fix update and create implementation.
  //     </update>
  //     <update> H02 - RD-87 - 06/Feb/2023 -  Yun Wei
  //              Description: Asign local object variable to API object variable
  //     </update>
  //     <update> H03 - RD-131 - Karina Villalobos S.
  //              Description: Add confirmed Bio System.
  //     </update>
  //     <update> H04 - RD-187 - 12/Nov/2023 - Reinier Stiven Rodriguez Valverde.
  //              Description: Add string format to a variable.
  //     </update>
  // </history>
  saveMetaboliteConfirmed(data: any[]) {
    //Ref. H01
    const tempData = data.filter(element => element.haveChanges == true);
    if (tempData.length > 0) {

      tempData.forEach(confirmedItem => {

        confirmedItem.exact_mass = confirmedItem.identity_exact_mass == '' ? 0.0000 : confirmedItem.identity_exact_mass; //Ref.H02, H04
        confirmedItem.structure_status_dropdown_field_value_pk = Number(confirmedItem.structure_status); //Ref.H02
        confirmedItem.priority_dropdown_field_value_pk = Number(confirmedItem.priority); //Ref.H02
        delete confirmedItem.identity_exact_mass;
        delete confirmedItem.structure_status;
        delete confirmedItem.priority;
        confirmedItem.metaboliteRelevancebiosystemDetails = [];
        if (confirmedItem.confirmedBiosystem) {
          confirmedItem.confirmedBiosystem?.map(relevanceItem => {
            relevanceItem.levelI = relevanceItem.levelI ? relevanceItem.levelI : [];
            relevanceItem.levelII = relevanceItem.levelII ? relevanceItem.levelII : [];
            relevanceItem.levelIII = relevanceItem.levelIII ? relevanceItem.levelIII : [];
            relevanceItem.comment = relevanceItem.comment ? relevanceItem.comment : "";
            relevanceItem.relevance = relevanceItem.relevance ? relevanceItem.relevance : [];
            relevanceItem.OECDRelevancy = [];
            this.removeBiosystemProperties(relevanceItem);
            confirmedItem.metaboliteRelevancebiosystemDetails.unshift(relevanceItem);
          });
        }
      });

      this.metaboliteConfirmedService.saveMetaboliteConfirmed(tempData).pipe(take(1)).subscribe(response => {
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'New rows created' });
        if (response.value > 0) {
          this.getMetaboliteConfirmed(this.AiService.SelectedActiveIngredient.material_id);
        }
      }, error => {
        if (error.error?.Message.includes('The x_number_metabolite inserted already exists')) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'The selected X Number already exists' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating new rows.' });
        }
      });
    }
  }

  removeBiosystemProperties(child: any) {
    delete child.PK;
    delete child.haveChanges;
  }

  //  Description: Delete Metabolite Confirmed
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-90 - 20/Feb/2023 - Karina Villalobo s
  //         Description: Delete multiple selected rows.
  //     </update>
  // </history>
  deleteMetaboliteConfirmed() {
    //Ref. H01
    let tempData = this.rowData.filter(element => element.selected == true);
    const last = tempData[tempData.length - 1];
    tempData.forEach(element => {
      this.metaboliteConfirmedService.deleteMetaboliteConfirmedData(element)
        .pipe(take(1))
        .subscribe(() => {
          const index: number = this.rowData.indexOf(this.selectedRow);
          let tempData = [...this.rowData];
          tempData.splice(index, 1);
          this.rowData = tempData;
          this.selectedRow = null;
        });
    });
  }
  //#endregion

  // <summary>
  //  Description: Export excel.
  //  Author: Karina Villalobos S. && Reinier Rodriguez V.
  //  Date: 25/Aug/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-181
  //              Description: Creation.
  //     </update>
  // </history>
  exportToExcel(event) {
    if (this.AiService.SelectedActiveIngredient != undefined) {
      this.isExcelLoading = true;
      this.exportExcel.GetInfoToAssembleExcel(this.module,
        this.AiService.SelectedActiveIngredient.material_id, '').
        pipe(take(1)).subscribe(data => {
          (async () => {
            let info = JSON.parse(JSON.stringify(data.value));
            info.forEach((elem) => {
              elem.structure = "";
            });
            await data.value.forEach((element, index) => {
              if (element.structure != undefined) {
                this.exportExcel.processImage(element.structure).then((base64) => {
                  element.structure = base64;
                  if ((index + 1) == data.value.length) {
                    this.exportExcel.exportTOExcelDataImage(this.module, this.AiService.SelectedActiveIngredient.material_id, data.value, info);
                    this.isExcelLoading = false;
                  }
                });
              }
            });
          })();
        });
    }
  }
}