
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-group-textbox',
  templateUrl: './group-textbox.component.html',
  styleUrls: ['./group-textbox.component.scss']
})
export class GroupTextboxComponent implements ICellRendererAngularComp {

  public params: any;
  public text: string;
  public value;
  public readOnly: boolean = false;
  public field: any;
  public style: string;
  public data;

  constructor(private validatorService: ValidatorService) { }

  /// <summary>
  ///     Description: Group Text Box Refresh Method
  ///     Author: Juan Carlos Arce
  ///     Date: 09/Feb/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-69
  ///         Description: Creation.
  ///     </update>
  /// </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  /// <summary>
  ///     Description: Group Text Box AG Init Method
  ///     Author: Juan Carlos Arce
  ///     Date: 09/Feb/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-69
  ///         Description: Creation.
  ///     </update>
  //      <update> H01 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
  //          Description: Set readonly with validator service
  //      </update>
  /// </history>
  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.style = params.style;
    this.field = params.node.field;
    this.data = params.context.data;
    //Ref: H01
    this.readOnly = this.validatorService.isComponentReadOnly(params);
    this.text = !isNaN(Number(params.value)) ? params.value > 0 ? params.value : '' : params.value;

  }

  /// <summary>
  ///     Description: Group Text Box AG On input Method
  ///     Author: Juan Carlos Arce
  ///     Date: 09/Feb/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-69
  ///         Description: Creation.
  ///     </update>
  /// </history>
  onInput(value){
    let updateRows = [];
    this.params.node.allLeafChildren.map(x => updateRows.push(x.data));
    let tempList = this.data.children.filter(x => updateRows.includes(x));
    tempList.map(x=> x[this.field] = value);
    this.value = value;
    this.params.value = value;
    this.data.haveChanges = true;
  }

}

