import { HttpClient } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { GridService } from '../components/shared/grid-components/grid/grid.service';
import { ColumnApi, GridApi, GridOptions } from '@ag-grid-community/core';
import { DropdownFieldService } from '../providers/dropdown-field.service'
import { DropDownListService } from '../components/shared/grid-components/drop-down-list/drop-down-list.service'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';
import { settings } from '../../settings/settings';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';



@Injectable({
  providedIn: 'root'
})
export class ExportExcel {


  constructor(private http: HttpClient,
    private gridService: GridService) { }


  public structure = 'structure';
  public Nameproposed = "proposed";
  private gridApi: GridApi;
  private detailColumnApi: ColumnApi;
  private detailGridApi: GridApi;
  public gridOptions: GridOptions = {};
  public groupDefaultExpanded = 0;
  public detailCellRendererParams: any | null;
  public pinnedTopRowData;
  public listBoxComponentList: any[] = [];
  public data = [{}];

  // <summary>
  //  Description: Set information.
  //  Author: Reinier Rodriguez V.
  //  Date: 02/May/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-133
  //              Description: Creation.
  //     </update>
  // </history>
  setInformation(gridApi: GridApi, gridOptions: GridOptions): void {
    this.gridApi = gridApi;
    this.gridOptions = gridOptions;
  }

  // <summary>
  //  Description: Set information.
  //  Author: Reinier Rodriguez V.
  //  Date: 02/May/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-133
  //              Description: Creation.
  //     </update>
  // </history>
  setDetailInformation(gridApi: GridApi, gridOptions: GridOptions): void {
    this.detailGridApi = gridApi;
    this.gridOptions = gridOptions;
  }


  // <summary>
  //  Description: Export to excel method.
  //  Author: Reinier Rodriguez V.
  //  Date: 02/May/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-133
  //              Description: Creation.
  //     </update>
  // </history>
  exportToExcel(gridApi: GridApi, gridOptions: GridOptions, dropdownFieldService?: DropdownFieldService, dropDownListService?: DropDownListService): void {

    const time = new Date().getTime();
    if (gridApi) {
      gridOptions.defaultExcelExportParams = {
        headerRowHeight: 40,
        columnWidth: 200,
        rowHeight: 200,
        fileName: `Export_${time}`,
        allColumns: true,
        addImageToCell: (rowIndex, col, value) => {
          if ((col.getColId() === 'identity_structure_imagen' || col.getColId() === 'molecular_structure') && value.includes('base64')) {
            return {
              image: {
                id: `Structure Imagen${rowIndex}`,
                base64: value,
                imageType: 'png'
              }
            };
          }
          return;
        }
      };

      gridApi.exportDataAsExcel({
        processCellCallback(params: any) {
          if (params.column.getColDef().cellRenderer == "listBoxselectionRenderer") {

            let Result = '';
            let duplicate: any = [];

            if (params.value != undefined) {
              params.value.map((val, index) => {

                let criteria: any;

                if (params.column.getColDef().cellRendererParams.code)
                  criteria = { name: 'code', value: params.column.getColDef().cellRendererParams.code };

                if (params.column.getColDef().cellRendererParams.type && criteria == undefined)
                  criteria = { name: 'code', value: params.column.getColDef().cellRendererParams.type };

                if (params.column.getColDef().cellRendererParams.source && criteria == undefined)
                  if (params.column.getColDef().cellRendererParams.source != 'dropdownfield')
                    criteria = { name: 'code', value: params.column.getColDef().cellRendererParams.source };
                  else
                    criteria = { name: 'code', value: undefined };


                dropdownFieldService.dropDownListData.forEach(item => {

                  if (item.metID[0].name == criteria.name && item.metID[0].value == criteria.value) {

                    let rowResult: any;

                    if (criteria.value != 'MS' || criteria.value != 'PSC' || criteria.value != 'compound') rowResult = item.list.filter(x => x.dropdown_field_value_pk == val)[0];
                    if (criteria.value == 'MS' || criteria.value == 'PSC') rowResult = item.list.filter(x => x.usersPk == val)[0];
                    if (criteria.value == 'compound') rowResult = item.list.filter(x => x.value == val)[0];


                    if (rowResult) {
                      if (item.list[0].dropdown_field_value_pk) {
                        if (duplicate.length > 0) {
                          if (!duplicate.find(x => x == rowResult.dropdown_field_value)) {
                            Result = `${Result}${rowResult.dropdown_field_value}`;
                            duplicate.push(rowResult.dropdown_field_value);
                            if (index < params.value.length - 1) { Result = `${Result}, `; }
                          }
                        } else {
                          Result = `${Result}${rowResult.dropdown_field_value}`;
                          duplicate.push(rowResult.dropdown_field_value);
                          if (index < params.value.length - 1) { Result = `${Result}, `; }
                        }
                      }
                      if (criteria.value == 'MS' || criteria.value == 'PSC') {
                        Result = `${Result}${rowResult.userName}`;
                        if (index < params.value.length - 1) { Result = `${Result}, `; }
                      }
                      if (criteria.value == 'compound') {
                        if (!duplicate.find(x => x == rowResult.text)) {
                          Result = `${Result}${rowResult.text}`;
                          duplicate.push(rowResult.text);
                          if (index < params.value.length - 1) {
                            Result = `${Result}, `;
                          }
                        }
                      }
                    }
                  }
                });

                if (criteria.value == undefined) {

                  dropdownFieldService.dropDownListData.forEach(item => {

                    let dropDownItem: any = [];

                    if (item.metID[0].value == 'compound') {
                      dropDownItem = item.list.filter(x => x.value == val);
                    } else {
                      dropDownItem = item.list.filter(x => x.dropdown_field_value_pk == val);
                    }

                    if (dropDownItem.length > 0) {

                      if (duplicate.length > 0) {
                        if (item.metID[0].value == 'compound') {
                          if (!duplicate.find(x => x == dropDownItem[0].text)) {
                            Result = `${Result}${dropDownItem[0].text}`;
                            duplicate.push(dropDownItem[0].text);
                            if (index < params.value.length - 1) {
                              Result = `${Result}, `;
                            }
                          }
                        } else {
                          if (!duplicate.find(x => x == dropDownItem[0].dropdown_field_value)) {
                            Result = `${Result}${dropDownItem[0].dropdown_field_value}`;
                            duplicate.push(dropDownItem[0].dropdown_field_value);
                            if (index < params.value.length - 1) {
                              Result = `${Result}, `;
                            }
                          }
                        }


                      } else {
                        if (item.metID[0].value == 'compound') {
                          Result = `${Result}${dropDownItem[0].text}`;
                          duplicate.push(dropDownItem[0].text);
                          if (index < params.value.length - 1) {
                            Result = `${Result}, `;
                          }
                        } else {
                          Result = `${Result}${dropDownItem[0].dropdown_field_value}`;
                          duplicate.push(dropDownItem[0].dropdown_field_value);
                          if (index < params.value.length - 1) {
                            Result = `${Result}, `;
                          }
                        }

                      }
                    }
                  });
                }
              })
            }
            return Result;
          }
          if (params.column.getColDef().cellRenderer == "dropdownRenderer") {

            let result: any = [];

            if (params.column.getColDef().cellRendererParams.code) {
              result = dropDownListService.dropDownListData.filter(x => x.key.code == params.column.getColDef().cellRendererParams.code);
              if (result.length > 0) result = result[0].list.filter(x => x.value == params.value);
            } else {
              dropDownListService.dropDownListData.forEach(item => {
                if (item.list.filter(x => x.value == params.value))
                  result = item.list.filter(x => x.value == params.value);
              });
            }

            if (params.column.getColDef().cellRendererParams.list) {
              result = params.column.getColDef().cellRendererParams.list.filter(x => x.value == params.value + '');
            }

            return result.length > 0 ? result[0].text : params.value;
          }
          return params.value;
        }
      });
    }
  }


  exportToExcelInfo(gridApi: GridApi): void {

    const time = new Date().getTime();

    if (gridApi) {
      gridApi.exportDataAsExcel({
        processCellCallback(params: any) {
          if (params.column.getColDef().cellRenderer == "listBoxselectionRenderer") {

            let Result = '';

            if (params.value != undefined) {
              params.value.map((val, index) => {
                if (params.column.getColDef().refData != undefined) {
                  if (params.column.getColDef().refData.find(x => x.value == val)) {
                    const text = params.column.getColDef().refData.find(x => x.value == val).text != undefined ? params.column.getColDef().refData.find(x => x.value == val).text : '';
                    Result = `${Result}${text}`;
                    if (index < params.value.length - 1)
                      Result = `${Result}, `;
                  }
                }
              })
            }
            return Result;
          }
          if (params.column.getColDef().cellRenderer == "dropdownRenderer" && params.column.getColDef().refData != undefined && params.column.getColDef().refData.find(x => x.value == params.value + '')) {
            return params.column.getColDef().refData.find(x => x.value == params.value + '').text;
          }
          return params.value;
        }
      });
    }
  }
  // <summary>
  //  Description: Export to excel method.
  //  Author: Reinier Rodriguez V.
  //  Date: 02/May/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-133
  //              Description: Creation.
  //     </update>
  // </history>
  exportDetailToExcel(dropdownFieldService?: DropdownFieldService, dropDownListService?: DropDownListService): void {

    if (this.detailGridApi) {

      this.detailGridApi.exportDataAsExcel({
        processCellCallback(params: any) {
          params.columnWidth = 200;
          if (params.column.getColDef().field == 'molecular_structure' || params.column.getColDef().field == 'identity_structure_imagen') { params.value = ''; }

          if (params.column.getColDef().cellRenderer == "listBoxselectionRenderer") {

            let Result = '';
            let duplicate: any = [];

            if (params.value != undefined) {
              params.value.map((val, index) => {

                let criteria: any;
                let list: any;

                if (params.column.getColDef().cellRendererParams.code) {
                  criteria = { name: 'code', value: params.column.getColDef().cellRendererParams.code };
                  list = dropdownFieldService.dropDownListData.filter(x => x.metID[0].value == params.column.getColDef().cellRendererParams.code);
                }
                else {
                  criteria = { name: 'code', value: params.column.getColDef().cellRendererParams.type };
                  list = dropdownFieldService.dropDownListData.filter(x => x.service == params.column.getColDef().cellRendererParams.module);

                }

                if (list.length <= 0) list = dropdownFieldService.dropDownListData;

                list.forEach(item => {

                  if (item.metID[0].name == criteria.name && item.metID[0].value == criteria.value) {

                    let rowResult: any;

                    if (item.list[0].dropdown_field_value_pk) rowResult = item.list.filter(x => x.dropdown_field_value_pk == val)[0];
                    if (criteria.value == 'MS' || criteria.value == 'PSC') rowResult = item.list.filter(x => x.usersPk == val)[0];

                    if (rowResult) {

                      if (item.list[0].dropdown_field_value_pk) {
                        if (duplicate.length > 0) {
                          if (!duplicate.find(x => x == rowResult.dropdown_field_value)) {
                            Result = `${Result}${rowResult.dropdown_field_value}`;
                            duplicate.push(rowResult.dropdown_field_value);
                            if (index < params.value.length - 1) { Result = `${Result}, `; }
                          }
                        } else {
                          Result = `${Result}${rowResult.dropdown_field_value}`;
                          duplicate.push(rowResult.dropdown_field_value);
                          if (index < params.value.length - 1) { Result = `${Result}, `; }
                        }
                      }
                      if (criteria.value == 'MS' || criteria.value == 'PSC') {
                        Result = `${Result}${rowResult.userName}`;
                        if (index < params.value.length - 1) { Result = `${Result}, `; }
                      }
                    }
                  }
                })

                if (criteria.value == undefined) {

                  dropdownFieldService.dropDownListData.forEach(item => {

                    const dropDownItem = item.list.filter(x => x.dropdown_field_value_pk == val);

                    if (duplicate.length > 0) {
                      if (!duplicate.find(x => x == dropDownItem[0].dropdown_field_value)) {
                        Result = `${Result}${dropDownItem[0].dropdown_field_value}`;
                        duplicate.push(dropDownItem[0].dropdown_field_value);
                        if (index < params.value.length - 1) {
                          Result = `${Result}, `;
                        }
                      }
                    } else {
                      Result = `${Result}${dropDownItem[0].dropdown_field_value}`;
                      duplicate.push(dropDownItem[0].dropdown_field_value);
                      if (index < params.value.length - 1) {
                        Result = `${Result}, `;
                      }
                    }

                  });
                }

              })
            }
            return Result;
          }
          if (params.column.getColDef().cellRenderer == "dropdownRenderer") {

            let result: any = [];

            dropDownListService.dropDownListData.forEach(item => {
              result = item.list.filter(x => x.value == params.value);
            });

            if (params.column.getColDef().cellRendererParams.list) {
              result = params.column.getColDef().cellRendererParams.list.filter(x => x.value == params.value + '');
            }

            return result.length > 0 ? result[0].text : params.value;
          }
          return params.value;
        }
      });
    }
  }




  // <summary>
  //  Description: Get to Data for Module.
  //  Author: Karina Villalobos S. && Reinier Rodriguez V.
  //  Date: 25/Aug/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-173
  //              Description: Creation.
  //     </update>
  // </history>
  GetInfoToAssembleExcel(NameModule: string, materialId: number, type: string): Observable<any> {
    const Data: any = {
      materialId: materialId,
      type: type,
      nameModule: NameModule
    }
    const query = settings.ServiceUrl + 'api/GetInfoToAssembleExcel';
    return this.http.put(query, Data);
  }


  // <summary>
  //  Description: Save excel document and image.
  //  Author: Karina Villalobos S. && Reinier Rodriguez V.
  //  Date: 30/Aug/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-181
  //              Description: Creation.
  //     </update>
  // </history>
  exportTOExcelDataImage(NameModule: string, materialId: number, dataList: any, dataInfo: any) {

    let workbook = new Excel.Workbook();
    let keys;
    let data;    
    data = dataList;
    keys = Object.keys(data[0]);
    let worksheet = workbook.addWorksheet('Sheet' + NameModule, {
      properties: {
        defaultRowHeight: 100,
      }
    });
    const columns = [];
    for (var key of keys) {
      columns.push({ header: key[0].toString().toUpperCase() + key.slice(1), key: key, width: 60 });
    };
    worksheet.columns = columns;

    for (let index = 0; index < dataInfo.length; index++) {
      worksheet.addRow(dataInfo[index]);
    }
    worksheet.properties.defaultRowHeight = 100;

    let cellnumber = 1;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      cellnumber = cellnumber + 1;

      if (NameModule.includes(this.Nameproposed)) {
        const StructureInfo = keys.find(x => x.includes('proposed Structure'));
        if (StructureInfo != undefined) {
          if (element[StructureInfo] && element[StructureInfo].includes('data:image/png;base64')) {
            var imageId = workbook.addImage({
              base64: element[StructureInfo],
              extension: 'png',
            });
            worksheet.addImage(imageId, 'G' + cellnumber + ':G' + cellnumber);
          }
          else {
            var imageId = workbook.addImage({
              base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAACaCAIAAAAvnwihAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAHmSURBVHhe7dQxAQAADMOg+TfdycgDIrgBRAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQERLYH+uZzb+KUUnUAAAAASUVORK5CYII=',
              extension: 'png',
            });
            worksheet.addImage(imageId, 'G' + cellnumber + ':G' + cellnumber);
          }
        }
      }
      else {
        const StructureInfo = keys.find(x => x == this.structure);
        if (StructureInfo) {
          if (element.structure && element.structure.includes('data:image/png;base64')) {
            var imageId = workbook.addImage({
              base64: element.structure,
              extension: 'png',
            });
            worksheet.addImage(imageId, 'G' + cellnumber + ':G' + cellnumber)
          }
          else {
            var imageId = workbook.addImage({
              base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAACaCAIAAAAvnwihAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAHmSURBVHhe7dQxAQAADMOg+TfdycgDIrgBRAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQEZAQERLYH+uZzb+KUUnUAAAAASUVORK5CYII=',
              extension: 'png',
            });
            worksheet.addImage(imageId, 'G' + cellnumber + ':G' + cellnumber);
          }
        }
      }
      var row = worksheet.getRow(cellnumber);
      row.height = 160;
    }
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, NameModule + '_export_' + materialId.toString() + '_' + new Date().getTime());
    });
  }


  // <summary>
  //  Description: Save excel document.
  //  Author: Karina Villalobos S. && Reinier Rodriguez V.
  //  Date: 30/Aug/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-173
  //              Description: Creation.
  //     </update>
  // </history>
  exportTOExcel(NameModule: string, materialId: number, type: string, dataList?: any) {
    this.data = dataList.value;

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.save(excelBuffer, NameModule, materialId);
  }

  // <summary>
  //  Description: Save excel document.
  //  Author: Karina Villalobos S. && Reinier Rodriguez V.
  //  Date: 25/Aug/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-173
  //              Description: Creation.
  //     </update>
  // </history>
  save(buffer: any, fileName: string, materialId: number) {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + materialId.toString() + '_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  // <summary>
  //  Description: Receives a string (svg xml) makes a promise to convert it to base 64 and return the base 64 string.
  //  Author: Karina Villalobos S. && Reinier Rodriguez V.
  //  Date: 30/Aug/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-181
  //              Description: Creation.
  //     </update>
  // </history>
  processImage(svgXml) {
    return new Promise((resolve, reject) => {
      this.convertSvgToImg(svgXml, (error, base64Data) => {
        if (error) {
          console.error(error);
          reject(error)
        } else {
          resolve(base64Data)
        }
      })
    });
  }

  // <summary>
  //  Description: Convertir de svg a base64.
  //  Author: Karina Villalobos S. && Reinier Rodriguez V.
  //  Date: 30/Aug/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-181
  //              Description: Creation.
  //     </update>
  // </history>
  convertSvgToImg(svgXml: string, callback) {
    const img = new Image();
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgXml, 'image/svg+xml');
    const svgElement = doc.getElementsByTagName('svg')[0];
    const rect = doc.getElementsByTagName('rect')[0];
    const rectHeight = rect ? rect.getAttribute('height') : '0';
    const rectWidth = rect ? rect.getAttribute('width') : '0';

    const width = Number(rectWidth);
    const height = Number(rectHeight);

    if (!svgElement) return;
    const svgString = new XMLSerializer().serializeToString(svgElement);
    img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, width, height);
      const base64Data = canvas.toDataURL('image/png').replace(/^data:image\/svg\+xml;base64,/, 'data:image\/png;base64,');
      callback(null, base64Data);
    };
  }
}
