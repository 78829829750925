import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MetaboliteSummaryService } from './metabolite-summary.service';
import { IMetaboliteSummary, IMetaboliteSummaryNote } from '../../models/metabolite-summary.model';
import { ActiveIngredientService } from '../shared/active-ingredient-selector/active-ingredient.service';
import { ValueBuildCombinationsService } from '../value-build-combinations/value-build-combinations.service';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IActiveIngredient } from '../../models/active-ingredient.model';
import { ExportExcel } from 'src/app/providers/export-excel.service';

@Component({
  selector: 'app-metabolite-summary',
  templateUrl: './metabolite-summary.component.html',
  styleUrls: ['./metabolite-summary.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class MetaboliteSummaryComponent implements OnInit, OnDestroy, AfterViewInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentActiveIngredient: IActiveIngredient;

  public rowData: IMetaboliteSummary[];
  public rowSelection = 'single';
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];
  public projectStateOptions = [];
  public selectedRow: IMetaboliteSummary | null;
  public selectedDetailRows: any[];
  public deleteVisible: boolean = false;
  public metaboliteSummaryNotes: IMetaboliteSummaryNote;

  public notes = '';
  private moduleCode = 'METSUM';
  public isExcelLoading: boolean = false;

  public SectionVisible: boolean = false;
  public material_id: any;
  public iconClass: string;
  public module = "summary";
  private columnDetailStateName = "metaboliterelevancebiosystem"

  constructor(private metaboliteSummaryService: MetaboliteSummaryService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private combinationService: ValueBuildCombinationsService,
    public AiService: ActiveIngredientService,
    private gridService: GridService,
    private actionButtonService: ActionButtonService, private exportExcel: ExportExcel) {
    this.iconClass = '';
  }


  /// <summary>
  //  Description: Metabolite Summary Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  ngOnInit(): void {
    this.actionButtonService.UseEditMode = false;
    this.actionButtonService.Editable = true;
    this.metaboliteSummaryService.getMetaboliteSummaryColumsDefs().pipe(take(1)).subscribe(ColDefs => {
      this.columnDefs = ColDefs;
    });

    this.metaboliteSummaryService.getDetailMetaboliteSummaryColumsDefs().pipe(take(1)).subscribe(DetailColDefs => {
      this.detailColumnDefs = DetailColDefs ?? null;
    });

    if (this.AiService.SelectedActiveIngredient) {
      this.getMetaboliteSummary(this.AiService.SelectedActiveIngredient.material_id);
    }

    this.AiService.selectedActiveIngredient$.pipe(takeUntil(this.destroy$)).subscribe(async selectedActiveIngredient => {
      if (selectedActiveIngredient && selectedActiveIngredient != this.currentActiveIngredient) {
        this.currentActiveIngredient = selectedActiveIngredient;
        await this.getMetaboliteSummary(selectedActiveIngredient.material_id);
      }
    });

    this.combinationService.moduleCombinations = [];

    this.combinationService.getCombinationsByModuleCode(this.moduleCode).pipe(take(1)).subscribe(proposedCombinations => {
      proposedCombinations.map(element => {
        element.moduleCode = this.moduleCode;
        this.combinationService.moduleCombinations.push(element);
      });
    });

    this.selectedRow = null;
    this.selectedDetailRows = [];
  }

  /// <summary>
  //  Description: Metabolite Summary Component OnDestroy
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /// <summary>
  //  Description: Metabolite Summary After Init
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  ngAfterViewInit(): void {
    if (this.AiService.SelectedActiveIngredient) {
      const temp = this.AiService.SelectedActiveIngredient;
      setTimeout(() => {
        this.AiService.SelectedActiveIngredient = temp;
      }, 750);
    }
  }

  onExportDetail(params) {
    let row = params.parent;
    if (row.metaboliteSummaryPk != 0) {
      this.exportExcel.GetInfoToAssembleExcel(this.columnDetailStateName, row.metaboliteSummaryPk, this.module).pipe(take(1)).subscribe(response => {
        this.exportExcel.exportTOExcel(this.columnDetailStateName, row.metaboliteSummaryPk, this.module, response);

      });
    }
  }

  //#region Methods

  /// <summary>
  //  Description: Delete Confirmation
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      },
      reject: () => {
        callback(false);
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }
  //#endregion

  //#region Events
  /// <summary>
  //  Description: Selection Change Event
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  onSelectionChanged(event) {
    this.selectedRow = event as IMetaboliteSummary;
    let tempData = this.rowData.filter(element => element.selected == true);
    if (tempData.length > 0)
      this.deleteVisible = true;
    else
      this.deleteVisible = false;
  }


  /// <summary>
  //  Description: Detail Selection Cell Change Event
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  onDetailSelectionChanged(event) {
    this.selectedDetailRows = event as any[];
  }


  /// <summary>
  //  Description: Save Click Event
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  onSaveClick(event) {
    this.saveMetaboliteSummary(this.rowData);
  }


  /// <summary>
  //  Description: Delete Event
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  onDelete(event: Event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.deleteMetaboliteSummary();
      }
    });
  }


  /// <summary>
  //  Description: Delete detail Event
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  onDeleteDetail(event) {
    let deletePKs = event.detail.map(x => x.PK);
    event.parent.children = event.parent.children.filter(x => !deletePKs.includes(x.PK));
    event.parent.haveChanges = true;
    this.gridService.removeDetailTransaction(event.detail);
  }



  /// <summary>
  //  Description: Add Detail Event
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  onAddDetail(params) {
    let row = params.parent;
    if (!row.children) row.children = [];
    const newChildren = {
      PK: params.index,
      levelI: "",
      levelII: "",
      levelIII: "",
      levelIV: "",
      levelV: "",
      relevance: ""
    };
    row.children.unshift(newChildren);
    this.gridService.addDetailTransaction([newChildren]);
  }


  /// <summary>
  //  Description: Text Change Event
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  onTextChange(event) {
    this.metaboliteSummaryNotes.haveChanges = true;
  }



  /// <summary>
  //  Description: Add Event
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  onAdd(event) {
    let newRow: IMetaboliteSummary = {
      metaboliteSummaryPk: 0,
      materialIdCore: this.AiService.SelectedActiveIngredient.material_id,
      compoundName: '',
      relevanceDropdownFieldValuePk: null,
      ranking: -1,
      decisionNotes: '',
      conmpoungFoundIn: 'Expand',
      children: [],
      haveChanges: true
    };
    this.rowData.unshift(newRow);
    this.gridService.addTransaction([newRow]);
  }
  //#endregion

  //#region Metabolite Summary CRUD

  /// <summary>
  //  Description: Get metabolite Summary
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  async getMetaboliteSummary(materialId: number) {
    await this.metaboliteSummaryService.getMetaboliteSummary(materialId)
      .pipe(take(1)).subscribe(Data => {
        this.rowData = Data;
      });

    await this.metaboliteSummaryService.getMetaboliteSummaryNote(materialId)
      .pipe(take(1)).subscribe(Data => {
        this.metaboliteSummaryNotes = Data.value;
        this.metaboliteSummaryNotes.haveChanges = false;
        this.notes = this.metaboliteSummaryNotes.note;
      });
  }

  /// <summary>
  //  Description: Update metabolite Summary
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  saveMetaboliteSummary(data: IMetaboliteSummary[]) {
    const tempData = data.filter(x => x.haveChanges == true);
    if (tempData.length > 0) {
      tempData.map(summary => {
        summary.ranking = Number(summary.ranking);
        summary.metaboliteRelevanceBiosystemDetails = [];

        if (summary.children)
          summary.children?.map(child => {
            child.relevance = child.relevance ? child.relevance : [];
            child.levelI = child.levelI ? child.levelI : [];
            child.levelII = child.levelII ? child.levelII : [];
            child.levelIII = child.levelIII ? child.levelIII : [];
            child.levelIV = child.levelIV ? child.levelIV : [];
            child.levelV = child.levelV ? child.levelV : [];
            child.OECDRelevancy = child.OECDRelevancy ? [Number(child.OECDRelevancy)] : [];
            summary.metaboliteRelevanceBiosystemDetails.unshift(child);
          });
      });


      this.metaboliteSummaryService.saveMetaboliteSummaryData(tempData).pipe(take(1)).subscribe(response => {
        if (response.statusCode == 200) {
          this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Changes Saved' });
          this.getMetaboliteSummary(this.AiService.SelectedActiveIngredient.material_id);
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error saving changes' });
      });
    }
    if (this.metaboliteSummaryNotes.haveChanges) {
      this.metaboliteSummaryNotes.note = this.notes;
      this.metaboliteSummaryNotes.materialIdCore = this.AiService.SelectedActiveIngredient.material_id;
      this.metaboliteSummaryService.saveMetaboliteSummaryNoteData(this.metaboliteSummaryNotes).pipe(take(1)).subscribe(response => {
        if (response.statusCode == 200) {
          this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Notes Changes Saved' });
          this.getMetaboliteSummary(this.AiService.SelectedActiveIngredient.material_id);
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error saving changes' });
      });
    }
  }

  /// <summary>
  //  Description: Delete metabolite Summary
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  // </history>
  deleteMetaboliteSummary() {
    let tempData = this.rowData.filter(element => element.selected == true);
    tempData.forEach(element => {
      this.metaboliteSummaryService.deleteMetaboliteSummaryData(element).pipe(take(1)).subscribe(response => {
        const index: number = this.rowData.indexOf(this.selectedRow);
        let tempData = [...this.rowData];
        tempData.splice(index, 1);
        this.rowData = tempData;
        this.selectedRow = null;
      });
    });
  }
  //#endregion

  /// <summary>
  //  Description: On Click GLP Summary
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  onGLPClick(event): void {
    if (this.currentActiveIngredient != undefined) this.SectionVisible = true;
  }

  /// <summary>
  //  Description: Close Pop Up
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  onDialogClose(event) {
    this.SectionVisible = event;
  }

  exportToExcel(event) {
    if (this.AiService.SelectedActiveIngredient != undefined) {
      this.isExcelLoading = true;
      this.exportExcel.GetInfoToAssembleExcel(this.module, this.AiService.SelectedActiveIngredient.material_id, '').pipe(take(1)).subscribe(response => {
        this.exportExcel.exportTOExcel(this.module, this.AiService.SelectedActiveIngredient.material_id, '', response);
        this.isExcelLoading = false;
      });
    }
  }
}

