import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'tooltip-component',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})

export class TooltipComponent implements ITooltipAngularComp {
  /// <summary>
  ///     Description: Tooltip HTML
  ///     Author: George Ulecia
  ///     Date: 22/March/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-120
  ///         Description: Creation.
  ///     </update>
  /// </history>
  private params!: { tooltipData: string } & ITooltipParams;
  public data!: any;
  public isDossierTooltip: boolean = false;

  agInit(params: { tooltipData: string } & ITooltipParams): void {

    this.params = params;
    this.data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;
    if (this.params.tooltipData.includes("descriptor")) {

      if ( this.data.identity_synonyms != '' && this.data.identity_synonyms != undefined ) this.data.descriptor = this.data.identity_synonyms;

      this.isDossierTooltip = true;
    }
  }
}
