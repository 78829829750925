import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ConfirmationService } from 'primeng/api';
import { ValidatorService } from 'src/app/providers/validator.service';
import { UserService } from 'src/app/providers/user.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements ICellRendererAngularComp {

  public params: any;
  public parent;
  public checked: boolean = false;
  public readOnly: boolean = false;
  public isGroupRendered: boolean = false;
  public requireConfirmation: boolean = false;
  public disabled: boolean = false;
  public readOnlyValue: string;
  public isSelectedField: boolean = false;
  shouldEnableSignOff: boolean;
  public isVisitor:boolean=false;

  constructor(private confirmationService: ConfirmationService,
              private validatorService: ValidatorService, private userService: UserService) { }

  /// <summary>
  ///     Description: Check Box Refresh Method
  ///     Author: Juan Carlos Arce
  ///     Date: 04/Jan/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-55
  ///         Description: Creation.
  ///     </update>
  /// </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  /// <summary>
  ///     Description: Check Box AG Init Method
  ///     Author: Juan Carlos Arce
  ///     Date: 04/Jan/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-55
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-99
  ///         Description: Hide the Check-Box if its for data which is readonly(external)
  ///     </update>
  ///     <update> H02 - RD-119
  ///         Description: ReadOnly Value assigned
  ///     </update>
  /// </history>
  agInit(params: any): void {
    this.isVisitor = this.userService.profile.role.roleName.toLowerCase() =="visitor";
    this.shouldEnableSignOff = params.module == "residue";
    this.params = params;
    this.checked = params.value;
    this.parent = params.context.componentParent;
    this.isGroupRendered = params.node.group ?? false;
    this.requireConfirmation = params.requireConfirmation ?? false;
    this.readOnly = this.shouldEnableSignOff ==true ? false :this.validatorService.isComponentReadOnly(params);
    this.isSelectedField = params.colDef.field == 'selected';
    this.validateCanChange();
    this.readOnlyValue = this.checked ? "Yes" : "No";//ref: H02
  }

  onClick(event)
  {
    if(this.disabled) return;
    if(this.checked && this.requireConfirmation)
    {
      this.checked = false;
      this.confirmation(event.target, (confirmed) => {
        if (confirmed) {
          this.checked = true;
          this.change(this.checked);
        }
      });

    }else
    {
      this.change(this.checked);
    }
  }

  change(checked: boolean){
    this.validateCanChange();
    this.params.data[this.params.colDef.field] = checked;
    this.params.data.haveChanges = true;
    this.parent.checkBoxChanged(this.params);
  }

  validateCanChange(){
    if(this.requireConfirmation){
      this.disabled = this.shouldEnableSignOff == true ? false :this.validatorService.isCheckBoxComponentDisabled(this.checked);

    }

  }

  
  
  /// <summary>
  //  Description: SignOff Confirmation
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: `${this.params.header} 
                ${this.params.message}`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
      },
      reject: () => {
        callback(false);
      }
    });
  }

}
