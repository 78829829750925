import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { DropdownFieldService } from 'src/app/providers/dropdown-field.service';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ValidatorService } from 'src/app/providers/validator.service';
import { IDropDownField } from 'src/app/models/dropdown-field.model';
import { ValueBuildCombinationsService } from 'src/app/components/value-build-combinations/value-build-combinations.service';

@Component({
  selector: 'app-group-dropdown',
  templateUrl: './group-dropdown.component.html',
  styleUrls: ['./group-dropdown.component.scss']
})
export class GroupDropdownComponent implements ICellRendererAngularComp, OnDestroy {
  public params: any;
  public listItems: any[];
  public selectedValue: any;
  public parent: any;
  public dropdownFieldCode;
  public style = 'large';
  public data;
  public field;
  public module;
  public link;
  public readOnlyValue;

  public placeholder: string = 'Select Item';
  public readOnly: boolean = false;

  subscriptions: Subscription[] = []

  constructor(private dropdownFieldService: DropdownFieldService,
              private dropdownValueService: DropdownValueService,
              private validatorService: ValidatorService,
              private combinationService: ValueBuildCombinationsService) { }

  //  Description: Class Refresh
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  //  Description: Class Init
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  async agInit(params: any): Promise<void> {
    this.params = params;
    this.selectedValue = Number(params.value);
    this.readOnly = this.validatorService.isComponentReadOnly(params);

    this.parent = params.context.componentParent;
    this.data = params.context.data;
    this.field = params.node.field;
    this.module = params.module;
    this.link = params.link;
    this.dropdownFieldCode = params.code;
    
    this.combinationService.moduleCombinations = [];

    
    if(params.module=="GLPRES" ){
      this.readOnly = params.context.data.readOnly;
      if(this.readOnly==true){
        let rowIndex = params.rowIndex;
        let key = "biosystemLevel"+(rowIndex+1);
        this.readOnlyValue= params?.context?.data?.children[rowIndex][key];
        return;
      }
      
    }

    const combinations = await this.combinationService.getCombinationsByModuleCode(this.module).pipe(take(1)).toPromise();
    combinations.map(element => {
      element.moduleCode = this.module;
      this.combinationService.moduleCombinations.push(element);
    });

    if (this.module && this.dropdownFieldCode) this.getDropDownCombinationByField();
  }
  
  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  
  //  Description: Get DropDown Combinations
  //  Author: Juan Carlos Arce
  //  Creation date: 05/May/2023    
  //  <history>
  //     <update> H00 - RD-141
  //              Description: Creation.
  //     </update>
  // </history>
  async getDropDownCombinationByField() {
    try {
      let items = [];

      //Get Dropdown Field
      let sourceField: IDropDownField;
      await this.dropdownFieldService.GetByParams([{ name: 'code', value: this.dropdownFieldCode }]).toPromise().then(f => sourceField = f[0]);

      //Fill combinations list
      let sourceCombinations = [];
      this.combinationService.moduleCombinations.filter(x => x.source_dropdown_field_pk == sourceField.dropdown_field_pk && x.moduleCode == this.module)
        .map(source => source.source_dropdown_field_value_pk.map(value => sourceCombinations.push(value)));
      sourceCombinations = [...new Set(sourceCombinations)];

      if (this.link) {

        let relatedCombinations = this.combinationService.moduleCombinations.filter(x => x.destination_dropdown_field_pk == sourceField.dropdown_field_pk && x.moduleCode == this.module)

        let validCombinations = []
        relatedCombinations.map(x => {
          if (x.source_dropdown_field_value_pk.some(y => y == this.params.node.allLeafChildren[0].data[this.link])) { // Verify it works
            validCombinations.push(x);
          }
        });

         let validValues = [];
         validCombinations.map(val => val.destination_dropdown_field_value_pk.map(v => validValues.push(v)));
         sourceCombinations = validValues;
       }
      const values = await this.dropdownValueService.GetByBulkIDs(sourceCombinations).toPromise();
      if (values) values.map(val => items.push(this.getDropDownFieldValueAsObject(val)));
      
      this.listItems = items.sort((a,b) => a.text > b.text ? 1 : -1);

      if (this.selectedValue) this.updateSelection(this.selectedValue)
    } catch (err) {
      console.warn(err);
    }
  }

  //  Description: Get Dropdownfield object
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  getDropDownFieldValueAsObject(val) {
    return { value: val.dropdown_field_value_pk, text: val.dropdown_field_value };
  }

  
  //  Description: On Change Method
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  public onChange(event): void {
    this.updateSelection(this.selectedValue, true);
  }

  
  //  Description: Change Parent Method
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  public updateSelection(value: any, isEvent: boolean = false) {
    if(isEvent){
      let updateRows = [];
      this.params.node.allLeafChildren.map(x => updateRows.push(x.data));
      let tempList = this.data.children.filter(x => updateRows.includes(x));
      tempList.map(x=> x[this.field] = value);
      this.params.value = value;
    }

    const params = {
      data: this.data,
      value,
      isEvent,
      node: this.params.node,
    };
    this.parent.groupDropdownChanged(
      params
    );
  }
}

