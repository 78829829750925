// NOTE: This allows settings to be compiled with the code as opposed to
// needing to execute an http request to get settings, this means that settings
// can also be used in app startup for things such as MSAL, datadog, and app
// insights. The ID in the settings file HAS to line up with what is being
// searched for in the predeploy script and also HAS to be the first value in
// the list of vars

import { StaticProvider } from '@angular/core';
// import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { ResidueSettings, RESIDUE_SETTINGS } from './residue-settings';
import { Configuration } from 'msal';
import { AuthOptions, CacheOptions } from 'msal/lib-commonjs/Configuration';
import { settingsDv } from './settings.dv';
import { settingsQa } from './settings.qa';
import { settingsTr } from './settings.tr';
import { settingsPr } from './settings.pr';

export const settings = {
  beginToken: '81162727-7245-4058-88b0-38db22f8737f',
  name: 'local',
  baseUrl: 'https://localhost:7071/api/',
  authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
  clientID: '4beb9ce6-beae-4dde-9288-51d5754486e7',
  redirectUri: 'http://localhost:4200/',
  key1: 'https://graph.microsoft.com/v1.0/me',
  key2: 'http://localhost:7071/',
  key3: 'https://graph.microsoft.com/v1.0/users',
  value1: ['user.read'],
  value2: ['https://agcompany.onmicrosoft.com/residuedefinition/dev/user_impersonation/AccessResidueDefinitionDev'],
  value3: ['user.read.all'],



  msalAngular: {
    consentScopes: [
      'https://analysis.windows.net/powerbi/api/Report.Read.All',
      'https://agcompany.onmicrosoft.com/residuedefinition/dev/user_impersonation/AccessResidueDefinitionDev',
      'User.Read',
      'User.ReadBasic.All',
      'profile',
      'openid'
    ],
    unprotectedResources: ['version.json']
  },

  SUPPORT_BOT_SETTINGS: {
    botAlias: "$LATEST",
    botName: "TesterBOT",
    supportName: "DSI Support",
    roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
    roleSessionName: "DvSession"
  },

  powerBiConsentScope: 'https://analysis.windows.net/powerbi/api/Report.Read.All',
  powerBiReportEmbedUrl: 'https://app.powerbi.com/reportEmbed',
  powerBiReportId: '',
  powerBiGroupId: '',
  netDocsScope: 'api://43aeb1a0-45c5-4687-8c0c-b35db02570f0/nd_access',
  netDocsUrl: 'https://8be6w9m8ja.execute-api.us-east-1.amazonaws.com/dv/v1/lambda/v1',
  netDocsCabinet: 'NG-7M3XK9TA',
  appAdmin: 'Jennifer Jones',
  functionScope: 'https://agcompany.onmicrosoft.com/residuedefinition/dev/user_impersonation/AccessResidueDefinitionDev',

  environment: 'local',
  ServiceUrl: 'http://localhost:7071/',
  AGGridLicense: 'CompanyName=SHI International Corp._on_behalf_of_Pioneer Hi-Bred Intl Inc. (US, Johnston, IA 50131),LicensedGroup=DSI,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=25,LicensedProductionInstancesCount=0,AssetReference=AG-036752,SupportServicesEnd=2_September_2024_[v2]_MTcyNTIzMTYwMDAwMA==3211b93f03e1e90c9f013132ac4acca8',

  endToken: '4daa9ca3-3ea3-4873-82c5-168cfc5864ae'
};

export class Settings {

  public static IdentifyEnvironment(): void
	{
		switch(settings.environment) {
			case 'dv': this.ApplySettingsProperties(settings, settingsDv); break;
			case 'qa': this.ApplySettingsProperties(settings, settingsQa); break;
			case 'tr': this.ApplySettingsProperties(settings, settingsTr); break;
			case 'pr': this.ApplySettingsProperties(settings, settingsPr); break;
		}
	}

	private static ApplySettingsProperties(main: any, env: any): void
	{
		main.ServiceUrl = env.ServiceUrl;
		main.redirectUri = env.redirectUri;
		main.key2 = env.key2;
	}

  public static initializeProviders(): StaticProvider[] {

    const authOptions: AuthOptions = {
      authority: settings.authority,
      clientId: settings.clientID,
      redirectUri: settings.redirectUri
    }

    const cachOptions: CacheOptions = {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false
    }

    const msalSettings: Configuration = {
      auth: authOptions,
      cache: cachOptions
    };

    const residueSettings: ResidueSettings = {
      baseUrl: settings.baseUrl,
      environment: settings.name,
      powerBiConsentScope: settings.powerBiConsentScope,
      powerBiReportEmbedUrl: settings.powerBiReportEmbedUrl,
      powerBiReportId: settings.powerBiReportId,
      powerBiGroupId: settings.powerBiGroupId,
      netDocsScope: settings.netDocsScope,
      netDocsUrl: settings.netDocsUrl,
      netDocsCabinet: settings.netDocsCabinet,
      appAdmin: settings.appAdmin,
      functionScope: settings.functionScope
    };

    return [
      // { provide: MSAL_CONFIG, useValue: msalSettings },
      { provide: RESIDUE_SETTINGS, useValue: residueSettings },
      // { provide: MSAL_CONFIG_ANGULAR, useValue: settings.msalAngular }
    ];
  }
}
