import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';

import { MenuItem, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { MenuService } from 'src/app/providers/menu.service';
// import { MsalBroadcastService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
//import { InteractionStatus } from '@azure/msal-browser';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  providers: [MessageService],
})

//  Description: Side Menu Component
//  Author: Juan Carlos Arce
//  Creation date: 09/Sep/2022    
//  <history>
//     <update> H00 - RD-23
//              Description: Creation.
//     </update>
// </history>

export class SideMenuComponent implements OnInit {

  Items: MenuItem[] = [];
  hidden = false;
  iconCollapse = 'pi pi-angle-left';
  iconExpande = 'pi pi-angle-right';
  icon = this.iconCollapse;

  @Output() MenuHideEvent = new EventEmitter<any>();


  constructor(private menuService: MenuService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.getMenu();
  }

  getMenu() {
    try {
      this.menuService.getMenu().subscribe({
        next: (menu: any) => {
          this.Items = menu;
          this.setClickEventListener();
        },
        error: (error: any) => {
          console.error(error);
          this.messageService.add({
            severity: 'error',
            summary: error.error.Value.Title,
            detail: error.error.Value.Detail,
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  //  Description: Toogle Menu Hide/Visible
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  toggleMenu(){
    this.hidden = !this.hidden;
    this.icon = this.hidden ? this.iconExpande : this.iconCollapse;
    this.MenuHideEvent.emit(this.hidden);
  }

  /// Description: Listens to clicks to menu events
  /// Author: Yun Wei (Copy from BaMs - Laureano Bonila Pretiz)
  /// Date: 03/Jan/2023
  /// <history>
  ///    <update> H00 - RD-59
  ///             Description: Creation
  ///    </update>
  /// </history>  
  setClickEventListener()
  {
    const menuItems = document.querySelectorAll('p-panelmenu');
    menuItems.forEach(el => el.addEventListener('click', event => {
      this.removeActiveClassFromAllElements();
      this.addActiveClassToTarget(event);
    }))
  }

  /// Description: Removes selected class to all elements
  /// Author: Yun Wei (Copy from BaMs - Laureano Bonila Pretiz)
  /// Date: 03/Jan/2023
  /// <history>
  ///    <update> H00 - RD-59
  ///             Description: Creation
  ///    </update>
  /// </history>    
  removeActiveClassFromAllElements()
  {
    const activeItems = document.querySelectorAll('.p-menuitem-link-active');
    activeItems.forEach(ai => {
      ai.classList.remove('.p-menuitem-link-active')
    });
  }

  /// Description: Adds selected class to specified target
  /// Author: Yun Wei (Copy from BaMs - Laureano Bonila Pretiz)
  /// Date: 03/Jan/2023
  /// <history>
  ///    <update> H00 - RD-59
  ///             Description: Creation
  ///    </update>
  /// </history>    
  addActiveClassToTarget(event)
  {
    var target = event.target as HTMLElement
      if (target instanceof HTMLSpanElement)
        target.parentElement.classList.add('.p-menuitem-link-active');
      else
      target.classList.add('.p-menuitem-link-active');
  }
}
