import { RowDataTransaction } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccordionService {

  //Proposed
  private _proposedTransaction: RowDataTransaction; // Make this private so we can expose it via a get/set
  private proposedTransactionChange$ = new Subject<RowDataTransaction>(); // This will be used to create our Observable
  public proposedTransaction$ = this.proposedTransactionChange$.asObservable(); // This is our Observable

  //Confirmed
  private _confirmedTransaction: RowDataTransaction; // Make this private so we can expose it via a get/set
  private confirmedTransactionChange$ = new Subject<RowDataTransaction>(); // This will be used to create our Observable
  public confirmedTransaction$ = this.confirmedTransactionChange$.asObservable(); // This is our Observable

  constructor() { }

  
  set ProposedTransaction(val: RowDataTransaction) {
    this._proposedTransaction = val; // Set the new value
    this.proposedTransactionChange$.next(val); // Trigger the subject, which triggers the Observable
   
  }

  get ProposedTransaction() {
    return this._proposedTransaction;
  }

  public proposedAddTransaction(data: any[]){
    this.ProposedTransaction = { addIndex: 0, add : data };
  }

  public proposedUpdateTransaction(data: any[]){
    this.ProposedTransaction = { update : data };
  }

  public proposedRemoveTransaction(data: any[]){
    this.ProposedTransaction = { remove : data };
  }

  set ConfirmedTransaction(val: RowDataTransaction) {
    this._confirmedTransaction = val; // Set the new value
    this.confirmedTransactionChange$.next(val); // Trigger the subject, which triggers the Observable
   
  }

  get ConfirmedTransaction() {
    return this._confirmedTransaction;
  }
  

  public confirmedAddTransaction(data: any[]){
    this.ConfirmedTransaction = { addIndex: 0, add : data };
  }

  public confirmedUpdateTransaction(data: any[]){
    this.ConfirmedTransaction = { update : data  };
  }

  public confirmedRemoveTransaction(data: any[]){
    this.ConfirmedTransaction = { remove : data };
  }
}
