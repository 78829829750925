import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DropdownValueService } from '../../../../providers/dropdown-value.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss']
})
export class TextboxComponent implements ICellRendererAngularComp, OnDestroy {

  public params: any;
  public text: string;
  public value;
  public readOnly: boolean = false;
  public field: any;
  public style: string;
  public isGroupRendered: boolean;
  public parent: any;

  DropDownValueServiceSubscription: Subscription

  constructor(public dropdownValueService: DropdownValueService,
              private validatorService: ValidatorService) { }

  /// <summary>
  ///     Description: Text Box Refresh Method
  ///     Author: Juan Carlos Arce
  ///     Date: 04/Jan/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-55
  ///         Description: Creation.
  ///     </update>
  /// </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  /// <summary>
  ///     Description: Text Box AG Init Method
  ///     Author: Juan Carlos Arce
  ///     Date: 04/Jan/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-55
  ///         Description: Creation.
  ///     </update>
  //     <update> H01 - RD-99
  //              Description: Read-Only option will only display the text without the option to edit it.
  //     </update>
  //      <update> H02 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
  //          Description: Set readonly with validator service
  //      </update>
  /// </history>
  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.style = params.style;
    this.field = params.colDef.field;
    this.text = this.value;
    this.parent = params.context.componentParent;
    this.isGroupRendered = params.node.group;
    //Ref: H02
    this.readOnly = this.validatorService.isComponentReadOnly(params);

    if(params.type == 'DropDownValue' && this.value)
    {
      this.GetDDValue();
    }
    if(params.type == 'DateYear' && this.value)
    {
      this.text = this.value;
    }
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.DropDownValueServiceSubscription?.unsubscribe();
  }

  /// <summary>
  ///     Description: Text Box Get DropDownValue
  ///     Author: Juan Carlos Arce
  ///     Date: 04/Jan/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-55
  ///         Description: Creation.
  ///     </update>
  /// </history>
  GetDDValue(){
    this.DropDownValueServiceSubscription = this.dropdownValueService.GetByID(this.value).pipe(take(1)).subscribe(response => {
      this.text = response.dropdown_field_value;
    });
  }

  onInput(value){
    this.params.data[this.field] = value;
    this.params.data.haveChanges = true;
  }
  onBlur(){
    this.parent.textBoxChanged(this.params);
  }
  
}
