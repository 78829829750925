
import { Component, OnDestroy } from '@angular/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { DropdownValueService } from '../../../../../providers/dropdown-value.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { UserService } from '../../../../../providers/user.service';
import { DropdownFieldService } from '../../../../../providers/dropdown-field.service';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilter implements IFilterAngularComp, OnDestroy  {

  params!: IFilterParams;
  validFilters = [];
  rowModel: any;
  rowNodes: any = null;
  source: string;
  selectAll: boolean;
  field: string;
  
  DropdownFieldValueServiceSubscription: Subscription;
  DropdownFieldServiceSubscription: Subscription;
  GetAllUserSubscription: Subscription;

  constructor(private dropdownValueService: DropdownValueService,
              private dropdownFieldService: DropdownFieldService,
              private userService: UserService) { }
  
  //  Description: DropDown Filter AgInit
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  agInit(params): void {
    this.params = params;
    this.source = params.source;
    this.rowModel = params.rowModel as any;
    this.rowNodes = this.rowModel.rowsToDisplay;
    this.field = params.colDef.field;
    this.selectAll = true;
  }

  
  //  Description: DropDown Filter NgOn Destroy
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.DropdownFieldValueServiceSubscription?.unsubscribe();
    this.GetAllUserSubscription?.unsubscribe();
    this.DropdownFieldServiceSubscription?.unsubscribe();
  }

  //  Description: DropDown Filter Get Filter Values
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getFilterValues(){
    this.validFilters = [];
    this.rowNodes.map(rowNode => {
      if(rowNode.data[this.field])
        this.getValidValue(rowNode.data[this.field]);
    });
  }

  //  Description: DropDown Filter Get Valid Value
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getValidValue(value: any)
  {
    if(this.source == 'dropdownvalue') this.getDropDownFieldValue(value);
    if(this.source == 'list') this.getListValue(value);
    if(this.source == 'dropdownfield') this.getDropdownfield(value);
  }

  //  Description: DropDown Filter Get DropDownField Value
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getDropDownFieldValue(value: number ) {
    this.DropdownFieldValueServiceSubscription = this.dropdownValueService.GetByID(value).pipe(take(1)).subscribe(val => {
      if(!this.validFilters.find(x=> x.value == val.dropdown_field_value_pk))
        this.validFilters.push(this.getDropDownFieldValueAsObject(val));
      this.validFilters = this.validFilters.sort((a,b) => a.title > b.title ? 1 : -1);
    });
  }

  //  Description: DropDown Filter Get DropdownField Values as Object
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getDropDownFieldValueAsObject(val) {
    return { value: val.dropdown_field_value_pk, title: val.dropdown_field_value, checked: true };
  }

  //  Description: DropDown Filter Get List Value
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getListValue(value: number ) {
    if(!this.validFilters.find(x=> x.value == value))
      this.validFilters.push(this.getListAsObject(value));
    this.validFilters = this.validFilters.sort((a,b) => a.title > b.title ? 1 : -1);
  }

  //  Description: DropDown Filter Get List as Object
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getListAsObject(val) {
    return { value: val, title: val? 'Active': 'Disabled', checked: true };
  }

  //  Description: DropDown Filter Get DropdownField
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getDropdownfield(value: number){
    this.DropdownFieldServiceSubscription = this.dropdownFieldService.GetByID(value).pipe(take(1)).subscribe(val => {
      if(!this.validFilters.find(x=> x.value == val.dropdown_field_pk))
        this.validFilters.push(this.getDropDownFieldAsObject(val));
      this.validFilters = this.validFilters.sort((a,b) => a.title > b.title ? 1 : -1);
    });
  }

  //  Description: DropDown Filter Get DropdownField as Object
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getDropDownFieldAsObject(val) {
    return { value: val.dropdown_field_pk, title: val.dropdown_field_name, checked: true };
  }

  //  Description: DropDown Filter IsFilterActive
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  isFilterActive(): boolean {
    return this.validFilters.filter(x => x.checked == false).length > 0;
  }

  //  Description: DropDown Filter DoesFilter Pass
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let filterPass = false;
      if(this.validFilters.filter(x => x.value == params.data[this.field] && x.checked == true).length > 0 )
        filterPass = true;
    return filterPass;

  }

  
  //  Description: DropDown Filter On New Rows Loaded
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  onNewRowsLoaded(): void
  {
    this.validFilters.map(x => x.checked = true);
    this.selectAll = true;
  }
  
  //  Description: DropDown Filter AfterGuiAttached
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  afterGuiAttached(params?: IAfterGuiAttachedParams): void
  {
    this.getFilterValues();
  }

  //  Description: DropDown Filter getModel
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getModel() {}

  //  Description: DropDown Filter SetModel
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  setModel(model: any) {}

  //  Description: DropDown Filter On Selection Changed
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  onSelectionChanged(item) {
    if(this.validFilters.filter(x => x.checked == true).length < this.validFilters.length ) this.selectAll = false;
    if(this.validFilters.filter(x => x.checked == true).length == this.validFilters.length ) this.selectAll = true;
    this.params.filterChangedCallback();
  }

  //  Description: DropDown Filter Select All Check
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  selectAllCheck()
  {
    this.validFilters.forEach(filter => filter.checked = this.selectAll);
    this.params.filterChangedCallback();
  }

}

