import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DegradationPathwayService } from './degradation-pathway.service';
import { ActiveIngredientService } from '../shared/active-ingredient-selector/active-ingredient.service';
import { IActiveIngredient } from '../../models/active-ingredient.model';
import { IDegradationPathway } from 'src/app/models/degradation-pathway-data.model';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { IDiagram, IDiagramDetail, IFilterData, IFilterDataDetail, IMetaboliteData } from 'src/app/models/degradationPathwayDetail.model';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { take, takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-degradation-pathway',
  templateUrl: './degradation-pathway.component.html',
  styleUrls: ['./degradation-pathway.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class DegradationPathwayComponent implements OnInit, OnDestroy {
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentActiveIngredient: IActiveIngredient;

  public btnDialogClass: string = 'p-button-secondary p-button-outlined p-button-sm mr-2';
  public btnDialogIcon: string = 'pi pi-search-plus';
  public btnDownloadIcon: string = 'pi pi-download';

  public rowData: IDegradationPathway[];
  public rowSelection = 'single';
  public selectedRow: IDegradationPathway | null;
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public materialId: number;
  public deleteVisible: boolean = false;
  public diagramData: IDiagram;
  public visorControls = [];
  public imageLoaded: boolean;

  private filterData;
  private showHideData: IMetaboliteData[];
  moleculesNames = new Map<string, string>();
  hiddenLeft = false;
  hiddenRight = false;
  iconCollapse = 'pi pi-angle-left';
  iconExpande = 'pi pi-angle-right';
  iconLeftPanel = this.iconCollapse;
  iconRightPanel = this.iconExpande;
  panelLeftColspan = 5;
  panelCenterColspan = 10;
  panelRightColspan = 4;

  @ViewChild('parentPanel') parentPanel: ElementRef<HTMLElement>;
  @ViewChild('dialogPanel') dialogPanel: ElementRef<HTMLElement>;

  
  parentWidth: any = 800;

  height = 590;
  nodesList = [];
  linkList = [];
  view: any[];
  popUpView: any[];
  popUpViewAutoCenter:any;

  public SectionVisible: boolean = false;
  showSpinner: boolean;
  panOffsetY: any;

  ///
  //  Description: 
  //  Author: 
  //  Creation date: 
  //  <history>
  //     <update> H00 - RD-
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-125
  //              Add AI Data
  //     </update>
  // </history>
  constructor(private degradationPathwayService: DegradationPathwayService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public AiService: ActiveIngredientService,
    private gridService: GridService,
    private actionButtonService: ActionButtonService,
    private sanitizer: DomSanitizer) { 
      this.nodesList = [];
      this.linkList = [];
      this.showHideData = [];
      this.diagramData = {detail:[], aiStructure:{value: '', visible: true}, aiMolecularWeight:{value: '', visible: true}, aiBiosystems:{value: '', visible: true}, aiExactMass:{value: '', visible: true}}; //Ref: H01
      this.imageLoaded = false;
    }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Karina Villalobos S
  //  Creation date: 27/Oct/2022    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-43
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Dropdown Refactor
  //     </update>
  //     <update> H02 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Use actionButtonService
  //     </update>
  //     <update> H03 - RD-75
  //         Description: Get filter and visor ShowHide data.
  //     </update>
  //     <update> H04 - RD-88 -22/Feb/2023 - Juan Carlos Arce
  //              Description: Implement Subscriptions
  //     </update>
  // </history>
  ngOnInit(): void {
    this.actionButtonService.UseEditMode = false; //Ref. H02
    this.actionButtonService.Editable = true; //Ref. H02
    this.degradationPathwayService.getDegradationPathwayColumsDefs()
      .pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefs = ColDefs;
      });
    
    if (this.AiService.SelectedActiveIngredient) {
      this.getGridData(this.AiService.SelectedActiveIngredient.material_id);
    }

    this.AiService.selectedActiveIngredient$.pipe(takeUntil(this.destroy$)).subscribe(async selectedActiveIngredient => {
      if (selectedActiveIngredient && selectedActiveIngredient != this.currentActiveIngredient){
        this.currentActiveIngredient = selectedActiveIngredient;
        await this.getGridData(selectedActiveIngredient.material_id);
      }
    });

    this.view = [this.parentWidth, this.height];

    this.setVisorControls();
  }
   
  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  //  Description: Load module data
  //  Author: Karina Villalobos S
  //  Creation date: 27/Oct/2022 
  //  <history>
  //     <update> H00 - RD-43
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-74 - 16/Jan/2023 - George & Tomas
  //              Description: Added links and nodes list population.
  //     </update>
  //     <update> H02 - RD-81 - 02/Feb/2023 - George
  //              Description: Auto center diagram after it has been loaded.
  //     </update>
  // </history>
  async getGridData(materialId: number) {
      //ref: H02
      await this.degradationPathwayService.getDegradationPathwayData(materialId)
        .pipe(take(1))
        .subscribe(data => {
          this.rowData = data;
          this.getFilterAndVisorShowHideData(materialId);
        });
  }

  openPopup(): void{
    this.showSpinner = true;
    this.SectionVisible = true;
    this.showSpinner = false;
    setTimeout(() => {
      this.popUpView = [this.dialogPanel?.nativeElement.clientWidth, 2000];
    }, 100)
    this.popUpViewAutoCenter = true;
  }

   downloadImage() {
    this.dialogPanel?.nativeElement.scrollIntoView();
    const graphElement = document.getElementById('ngx-graph');
    html2canvas(graphElement,{
      scrollY:-200,
      width:this.dialogPanel?.nativeElement.clientWidth,
      height:2200,
      scale:window.devicePixelRatio*3,
         useCORS:true,
         foreignObjectRendering:true,
         allowTaint: true
    }).then(canvas=>{
      const dataUrl = canvas.toDataURL();
      const link = document.createElement('a');
      let fileName = this.AiService.SelectedActiveIngredient.compound_name+"_Degradation_Pathway_Graph.png";
      link.download = fileName;
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    
    
  }

  //  Description: Populates Nodes List Including the AI
  //  Author: George Ulecia
  //  Creation date: 13/Jan/2023
  //  <history>
  //     <update> H00 - RD-74
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-95
  //              Description: Get Data from filters and show hide data.
  //     </update>
  //     <update> H02 - RD-125
  //              Add AI Data
  //     </update>
  // </history>
  populateNodeList() {
    let nodeIdCount = 1;
    //ref H01
    this.diagramData.detail.forEach(element => {
      let node = {
        id: `${nodeIdCount++}`,
        label: element.precursor,
        opacity: 1,
        height: this.structureCheckboxCheked() ? 400 : 50,
        molecularWeight: this.diagramData.aiMolecularWeight,
        exactMass: this.diagramData.aiExactMass,
        biosystems: this.diagramData.aiBiosystems,
        structure: this.diagramData.aiStructure,
        metabolite: element.metabolite
      };
      if (this.checkNodeInList(this.nodesList, node) == undefined && node.label == this.AiService.SelectedActiveIngredient.compound_name) {
        this.nodesList.push(node);
        this.moleculesNames.set(node.label, node.id);
      } else { nodeIdCount--; }
      if(element.metabolite.value != "")
      //ref H01
      node = {
        id: `${nodeIdCount++}`,
        label: element.metabolite.value,
        opacity: element.status == '' ? 0.3 : 1,
        height: this.getDiagramElementHeight(element.status), //Ref: H02
        molecularWeight: element.molecularWeight, //Ref: H02
        exactMass: element.exactMass, //Ref: H02
        biosystems: element.biosystems, //Ref: H02
        structure: element.structure,
        metabolite: element.metabolite
      };
      if (this.checkNodeInList(this.nodesList, node) == undefined) {
        this.nodesList.push(node);
        this.moleculesNames.set(node.label, node.id);
      } else { nodeIdCount--; }
    });
    let tempNodeList = this.nodesList;
    this.nodesList = [...tempNodeList];
  }

  //  Description: Checks for a node(object) in the NodeList
  //  Author: George Ulecia
  //  Creation date: 13/Jan/2023
  //  <history>
  //     <update> H00 - RD-74
  //              Description: Creation.
  //     </update>
  // </history>
  checkNodeInList(list, node) {
    return list.find(object => { return object.label == node.label });
  }

  //  Description: Populates Links List based on source and target IDs.
  //  Author: George Ulecia
  //  Creation date: 13/Jan/2023
  //  <history>
  //     <update> H00 - RD-74
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-95
  //              Description: Get Data from filters and show hide data.
  //     </update>
  // </history>
  populateLinkList() {
    let linkIdCounter = 1;
    //ref H01
    if(this.nodesList.length > 1)
      this.diagramData.detail.forEach(element =>
        {
          let sourceId = this.moleculesNames.get(element.precursor)
          let targetId = this.moleculesNames.get(element.metabolite.value);
          let link = {
            id: String.fromCharCode('A'.charCodeAt(0)+linkIdCounter++),
            source: sourceId,
            target: targetId,
            label: ''
          };
          this.linkList.push(link);
        });
        let tempList = this.linkList;
        this.linkList = [...tempList];
  }

  //#region Methods
  /// <summary>
  //  Description: Delete Confirmation
  //  Author: Karina Villalobos S
  //  Creation date: 27/Oct/2022  
  /// </summary>    
  //  <history>
  //     <update> H00 - RD-43
  //              Description: Creation.
  //     </update>
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      },
      reject: () => {
        callback(false);
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }

  /// <summary>
  //    Description: Add Method
  //    Author: Karina Villalobos S
  //    Creation date: 27/Oct/2022   
  /// </summary> 
  //  <history>
  //     <update> H00 - RD-43
  //              Description: Creation.
  //     </update>
  // </history>
  onAddDegradationPathway(event) {
    const newRow: IDegradationPathway = {
      biosystems: 'Expand',
      degradation_pathway_pk: 0,
      haveChanges: true,
      metabolite: '', precursor: '',
      material_id_core: this.AiService.SelectedActiveIngredient.material_id,
      diagram: "",
      created_by: "",
      updated_by: '',
      created_date: null,
      updated_date: null
    };

    this.rowData.push(newRow);
    this.gridService.addTransaction([newRow]);
  }

  /// <summary>
  ///     Description: Degradation Pathway select Row method
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  /// </history>
  onSelectionChanged(event) {
    this.selectedRow = event as IDegradationPathway;
    let tempData = this.rowData.filter(element => element.selected == true);
    if (tempData.length > 0)
      this.deleteVisible = true;
    else
      this.deleteVisible = false;
  }

  /// <summary>
  ///     Description: Degradation Pathway save method
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  /// </history>
  onSaveClick(event) {
    this.saveDegradationPathway(this.rowData);
  }

  /// <summary>
  ///     Description: Degradation Pathway update method
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  saveDegradationPathway(data: IDegradationPathway[]) {
    let tempData = data.filter(element => element.haveChanges == true);

    this.degradationPathwayService.saveDegradationPathway(tempData, this.AiService.SelectedActiveIngredient.material_id)
      .pipe(take(1))
      .subscribe(response => {
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Changes Saved' });
        this.getGridData(this.AiService.SelectedActiveIngredient.material_id);
      });
  }

  /// <summary>
  ///     Description: Degradation Pathway delete method
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  /// </history>
  onDeleteDegradationPathway(event: Event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.deleteDegradationPathway();
      }
    });
  }

  /// <summary>
  ///     Description: Degradation Pathway delete method
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-90 - 20/Feb/2023 - Karina Villalobo s
  ///         Description: Delete multiple selected rows.
  ///     </update>
  /// </history>
  deleteDegradationPathway() {
    //Ref. H01  
    let tempData = this.rowData.filter(element => element.selected == true);
    const last = tempData[tempData.length - 1];
    tempData.forEach(element => {
      this.degradationPathwayService.deleteDegradationPathwayData(element).pipe(take(1)).subscribe(response => {
        if (element.degradation_pathway_pk == last.degradation_pathway_pk) {
          this.selectedRow = null;
          this.getGridData(this.AiService.SelectedActiveIngredient.material_id);
        }
      });
    });
  }

  /// <summary>
  ///     Description: Updates Colspan Size
  ///     Author: Tomas Samuels
  ///     Date: 13/Jan/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-74
  ///         Description: Update.
  ///     </update>
  /// </history>
  toggleLeftPanel() {
    this.hiddenLeft = !this.hiddenLeft;
    this.iconLeftPanel = this.hiddenLeft ? this.iconExpande : this.iconCollapse;

    if (this.hiddenLeft) {
      this.panelCenterColspan = this.panelCenterColspan + 5;
    }
    if (!this.hiddenLeft) {
      this.panelLeftColspan = 5;
      this.panelCenterColspan = this.panelCenterColspan - 5;
    }
    setTimeout(() => {
      this.updateDimension();
    }, 0)
  }

  /// <summary>
  ///     Description: Updates Colspan Size
  ///     Author: Tomas Samuels
  ///     Date: 13/Jan/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-74
  ///         Description: Update.
  ///     </update>
  /// </history>
  toggleRightPanel() {
    this.hiddenRight = !this.hiddenRight;
    this.iconRightPanel = this.hiddenRight ? this.iconCollapse : this.iconExpande;

    if (this.hiddenRight) {
      this.panelCenterColspan = this.panelCenterColspan + 4;
    }
    if (!this.hiddenRight) {
      this.panelRightColspan = 4;
      this.panelCenterColspan = this.panelCenterColspan - 4;
    }
    setTimeout(() => {
      this.updateDimension();
    }, 0)
  }

  /// <summary>
  ///     Description: Updates Structure Diagram Width Dimension
  ///     Author: George Ulecia
  ///     Date: 13/Jan/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-74
  ///         Description: Create.
  ///     </update>
  /// </history>
  updateDimension() {
    this.view = [this.parentPanel?.nativeElement.clientWidth, this.parentPanel?.nativeElement.clientHeight];
  }

  /// <summary>
  /// Description: Get filter and visor show hide data
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  //     <update> H01 - RD-125
  //              Add AI Data
  //     </update>
  /// </history>
  getFilterAndVisorShowHideData(materialId: number) {
    this.degradationPathwayService.getFilterAndVisorShowHideData(materialId).pipe(take(1)).subscribe(degradationPatway => {
      this.filterData = degradationPatway.filterList;
        this.showHideData = degradationPatway.visorHideShowDataList;
        this.getAiBiosystem(this.filterData.filter(f => f.metaboliteName == this.AiService.SelectedActiveIngredient.compound_name)); //Ref: H01
        setTimeout(()=>{
          this.refreshDiagram();
        },500);
      });
  }

  /// <summary>
  /// Description: Refresh diagram
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  refreshDiagram(): void{
    this.hideOrShowData();
    this.cleanDiagram();
    this.populateNodeList();
    this.populateLinkList();
    setTimeout(()=>{
      this.updateDimension(); 
    },500)
  }

  /// <summary>
  /// Description: Get filtered data
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  getFilteredData(filteredData: IFilterData): void{
   this.initialiteDiagramData(filteredData);
   this.setDiagramData(filteredData);
  }

  /// <summary>
  /// Description: Initialite diagram data
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  initialiteDiagramData(filteredData: IFilterData): void{
    this.diagramData.detail.length = 0;
    if(filteredData.onlyStatusSelected)
      this.fillDiagramDataGrayedOut();
    else
      this.fillDiagramData(filteredData.detail);
  }

  /// <summary>
  /// Description: Set diagram data
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  setDiagramData(filteredData: IFilterData): void{
    this.getAiData();
    this.diagramData.detail.forEach(d =>{
      d.biosystems.value = this.setSpanForEachBiosystem(filteredData.detail,d.metabolite.value);
      d.status = filteredData.detail.some(f => f.metaboliteName == d.metabolite.value) ? filteredData.detail.find(f => f.metaboliteName == d.metabolite.value).status : '';
      this.getDataFromShowAndHide(d);
    });
  }

  /// <summary>
  /// Description: Get Ai structure
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  //     <update> H01 - RD-125
  //              Add AI Data
  //     </update>
  /// </history>
  getAiData(): void{
    
    this.degradationPathwayService.getStructureImage(this.AiService.SelectedActiveIngredient.material_id.toString()).pipe(take(1)).subscribe(result => {
      let structure={data:null};
        this.processImage(result.structure_image).then((base64Data)=>{
          structure.data=base64Data;
        });
      this.diagramData.aiStructure.value = result.structure_image == undefined ? "" : structure;
      this.diagramData.aiMolecularWeight.value = `Molecular Weight = ${result.molecule_weight}`; //Ref: H01
      this.diagramData.aiExactMass.value = `Exact Mass = ${result.extract_mass}`; //Ref: H01
    });
  }

  /// <summary>
  /// Description: Get AI Biosystem
  /// Author: Tomas Samuels Brenes.
  /// Date: 11/Abr/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-125
  ///              Description: Creation
  ///     </update>
  /// </history>
  getAiBiosystem(aiMetabolite): void{
    this.diagramData.aiBiosystems.value = this.setSpanForEachBiosystem(aiMetabolite,this.AiService.SelectedActiveIngredient.compound_name);
    
  }

  /// <summary>
  /// Description: Get data from show and hide
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-111
  ///              Description: Show Proposed Image
  ///     </update>
  /// </history>
  getDataFromShowAndHide(diagramDetail: IDiagramDetail): void{
    this.showHideData.forEach(data =>{
      if(diagramDetail.metabolite.value == data.metaboliteName){
        diagramDetail.exactMass.value = diagramDetail.status == '' ? '' : 'Exact Mass = ' + data.exactMass;
        diagramDetail.structure.value = data.structure //ref: H01
        this.getMetaboliteStructureAndMolecularWeight(diagramDetail, data);
      }
    });
  }

  /// <summary>
  /// Description: Get metabolite structure and molecular weight
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-111
  ///              Description: Show Proposed Image
  ///     </update>
  /// </history>
  getMetaboliteStructureAndMolecularWeight(diagramDetail: IDiagramDetail, showHideData: IMetaboliteData): void{
    this.degradationPathwayService.getStructureImage(showHideData.metaboliteName).pipe(take(1)).subscribe(result => {
      //ref: H01
      if(showHideData.structure == undefined || showHideData.structure == 'new'){
        let structure={data:null};
        this.processImage(result.structure_image).then((base64Data)=>{
          structure.data=base64Data;
        });
        diagramDetail.structure.value = result.structure_image == undefined ? "" : structure;

      }
      let mw = result.molecule_weight ?? '';
      mw = mw == 0 ? showHideData.molecularWeight : mw;
      diagramDetail.molecularWeight.value = diagramDetail.status == '' ? '' : 'Molecular Weight = ' + mw;
    });
  }

   processImage(svgXml){
    return new Promise ((resolve,reject)=>{
      this.convertSvgToImg(svgXml,(error,base64Data)=>{
        if(error){
          console.error(error);
          reject(error)
        }else{
          resolve(base64Data)
        }
      })
    });
    
  }

  /// <summary>
  /// Description: Set span for each biosystem
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-145
  ///              Description: Set Glp Prefix
  ///     </update>
  /// </history>
  setSpanForEachBiosystem(filteredData: IFilterDataDetail[], metaboliteName: string): SafeHtml{
    let result: string = '';
    filteredData.filter(f => f.metaboliteName == metaboliteName).forEach(d => {
      if(d.levelI.length > 0){
        result += this.setGlpPrefix(d); //ref: H01
        result += this.setBiosystemColorAndText(d);
      }
    });
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

  /// <summary>
  /// Description: Set Glp Prefix
  /// Author: Tomas Samuels Brenes.
  /// Date: 11/May/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-145
  ///              Description: Creation
  ///     </update>
  ///     <update> H00 - RD-132
  ///              Description: Removed Span Tag as it was caussing errors when diagram was being downloaded.
  ///     </update>
  /// </history>
  setGlpPrefix(filterDataDetail:IFilterDataDetail): string{
    let result = '';
    if(filterDataDetail.levelI.length > 0 && (filterDataDetail.status == 'GLP Study' || filterDataDetail.status == '') )
      result = 'GLP: '
    return result;
  }

  /// <summary>
  /// Description: Clean diagram
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  cleanDiagram(): void{
    this.nodesList.length = 0;
    this.linkList.length = 0;
  }

  /// <summary>
  /// Description: Get color by relevance
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-117
  ///              Description: Relevance Multiselection
  ///     </update>
  /// </history>
  getColorByRelevance(relevanceList: string[]): string{
    let color: string = '';
    let hasMajor: boolean = false;
    let hasMinor: boolean = false;

    relevanceList.forEach(relevance => {
     if(relevance == "Major"){
      hasMajor = true;
     }else if(relevance == "Minor"){
      hasMinor = true;
     }
    });

    if(hasMajor){
      color = 'red';
    }else if(hasMinor){
      color = 'green';
    }else{
      color = 'black';
    }
    
    return color;
  }

  /// <summary>
  /// Description: Set visor controls
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  setVisorControls(): void{
    this.visorControls.push({title: 'Structure', checked: true, id: 'structure'});
    this.visorControls.push({title: 'Metabolite Name', checked: true, id: 'metaboliteName'});
    this.visorControls.push({title: 'Exact Mass', checked: true, id: 'exactMass'});
    this.visorControls.push({title: 'Biosystem', checked: true, id: 'biosystem'});
    this.visorControls.push({title: 'Molecular Weight', checked: true, id: 'molecularWeight'});
  }

  /// <summary>
  /// Description: Check or uncheck visor element
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  checkOrUncheckVisorElement(event: any) {
    this.visorControls.find(c => c.id == event.target.id).checked = event.target.checked;
  }

  /// <summary>
  /// Description: Hide or show data
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-125
  ///              Description: Get IA Data
  ///     </update>
  /// </history>
  hideOrShowData(): void{
    this.visorControls.forEach(c =>{
      this.diagramData.detail.forEach(d =>{
        switch(c.id){
          case 'metaboliteName':
            d.metabolite.visible = c.checked;
          break;
          case 'molecularWeight':
            d.molecularWeight.visible = c.checked;
            this.diagramData.aiMolecularWeight.visible = c.checked; //Ref: H01
          break;
          case 'exactMass':
            d.exactMass.visible = c.checked;
            this.diagramData.aiExactMass.visible = c.checked; //Ref: H01
          break;
          case 'structure':
            d.structure.visible = c.checked;
            this.diagramData.aiStructure.visible = c.checked;
          break;
          case 'biosystem':
            d.biosystems.visible = c.checked;
            this.diagramData.aiBiosystems.visible = c.checked; //Ref: H01
          break;
        }
      });
    });
  }

  /// <summary>
  /// Description: Fill diagram data grayed out
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  fillDiagramDataGrayedOut():void{
    if(this.rowData)
        this.rowData.forEach(r =>{
          this.diagramData.detail.push({precursor: r.precursor, status: '', height: 0,
          metabolite: { value: r.metabolite, visible: true}, biosystems: {value: '', visible: true},
          molecularWeight: {value: '', visible: true}, structure: {value: '', visible: true},
          exactMass: {value: '', visible: true}});
        });
  }

  /// <summary>
  /// Description: Fill diagram data
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  fillDiagramData(filteredDataDetail: IFilterDataDetail[]):void{
    if(this.rowData)
      filteredDataDetail.forEach(f =>{
        this.rowData.forEach(r =>{
          if(f.metaboliteName == r.metabolite)
            this.diagramData.detail.push({precursor: r.precursor, status: '', height: 0,
            metabolite: { value: r.metabolite, visible: true}, biosystems: {value: '', visible: true},
            molecularWeight: {value: '', visible: true},  structure: {value: '', visible: true},
            exactMass: {value: '', visible: true}});
        });
      });
      if(this.diagramData.detail.length == 0 && this.rowData.length > 0)
         this.diagramData.detail.push({precursor: this.AiService.SelectedActiveIngredient.compound_name, status: '', height: 0,
            metabolite: { value: "", visible: true}, biosystems: {value: '', visible: true},
            molecularWeight: {value: '', visible: true},  structure: {value: '', visible: true},
            exactMass: {value: '', visible: true}});
  }

  /// <summary>
  /// Description: Convert svg to img
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  convertSvgToImg(svgXml: string,callback) {
    const img = new Image();
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgXml, 'image/svg+xml');
    const svgElement = doc.getElementsByTagName('svg')[0];
    const rect = doc.getElementsByTagName('rect')[0];
    const rectHeight = rect ? rect.getAttribute('height') : '0';
    const rectWidth = rect ? rect.getAttribute('width') : '0';
    
    const width = Number(rectWidth);
    const height = Number(rectHeight);

    if(!svgElement) return;
    const svgString = new XMLSerializer().serializeToString(svgElement);
    img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, width, height);
      const base64Data = canvas.toDataURL('image/png').replace(/^data:image\/svg\+xml;base64,/, 'data:image\/png;base64,');      
      callback(null,base64Data);
      };
  } 

  /// <summary>
  /// Description: Get diagram element height
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  getDiagramElementHeight(status: string): number{
    let height: number = 125;
    if(this.structureCheckboxCheked())
      height = 400;
    if(status == '')
      height = 275;
    return height;
  }

  /// <summary>
  /// Description: Structure checkbox cheked
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  structureCheckboxCheked(): boolean{
    return this.visorControls.find(v => v.id = 'structure').checked;
  }

  /// <summary>
  /// Description: Set Biosystem Color And Text
  /// Author: Tomas Samuels Brenes.
  /// Date: 11/May/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-145
  ///              Description: Creation
  ///     </update>
  /// </history>
  setBiosystemColorAndText(filterDataDetail:IFilterDataDetail): string{
    const levelList: string[] = ['levelI', 'levelII', 'levelIII', 'levelIV', 'levelV'];
    let result = "<span style=\"color: " + this.getColorByRelevance(filterDataDetail.relevance) + "\">";
      for(let i = 0; i < levelList.length; i++){
        filterDataDetail[levelList[i]] =  filterDataDetail[levelList[i]].filter((elem: string) => {return elem.trim().length > 0;});
        filterDataDetail[levelList[i]].forEach(l =>{
          if(filterDataDetail[levelList[i]].indexOf(l) == filterDataDetail[levelList[i]].length -1 )
            result += l;
          else
            result += l + ', ';
        });
        if(filterDataDetail[levelList[i+1]]){
          if(filterDataDetail[levelList[i+1]].filter((elem: string) => {return elem.trim().length > 0;}).length > 0)
          result += '/ ';
        }
      }
      result += "</span> <br>";
      return result;
  }

}
