import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { settings } from 'src/settings/settings';
import { IDropDownField, IListOption } from '../models/dropdown-field.model';
import { IParam } from '../models/param.model';
import { IResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class DropdownFieldService {

  private _selectedDropdownField: number;
  private selectedDropdownFieldChange$ = new Subject<number>();
  public selectedDropdownField$ = this.selectedDropdownFieldChange$.asObservable();

  public dropDownListData: IListOption[] = [];
  public dropDownList: any[] = [];
  public result: any;
  public code: string;

  constructor(private http: HttpClient) { }

  // <sumary>
  // Description: Get All Dropdown Fields
  // Author: Yun Wei
  // Date: 06/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  GetAll(): Observable<any> {
    const query = settings.ServiceUrl + 'api/dropdownfields';
    return this.http.get(query).pipe(map((response: IResponse) => response.value as IDropDownField));;
  }

  // <summary>
  // Description: Method to set information in the principal variable called dropDownListData that has all elements required from each field that matches the params criteria.
  // Author: Reinier Rodriguez V
  // Date: 08/Jun/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  // </history>
  setDropDownList(params: any){
    this.dropDownList = params;
  }


  // <summary>
  // Description: get getDropDownList
  // Author: Reinier Rodriguez V
  // Date: 08/Jun/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  // </history> 
  getDropDownList(): any{
    return this.dropDownList;
  }

  // <sumary>
  // Description: Get Dropdown Field By ID
  // Author: Yun Wei
  // Date: 06/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  GetByID(id: number): Observable<IDropDownField> {
    const query = settings.ServiceUrl + 'api/dropdownfield/' + id;
    return this.http.get(query).pipe(map((response: IResponse) => response.value as IDropDownField));
  }

  // <sumary>
  // Description: Get Dropdown Fields By Parameters
  // Author: Yun Wei
  // Date: 06/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  GetByParams(params: IParam[]): Observable<IDropDownField[]> {
    var urlParams: string = '';

    params.forEach(param => {
      if (urlParams != '') urlParams += '&';
      urlParams += param.name + '=' + param.value;
    });

    const query = settings.ServiceUrl + 'api/dropdownfields/params/?' + urlParams;
    return this.http.get(query).pipe(map((response: IResponse) => response.value as IDropDownField[]));
  }

  //  Description: Set SelectedDropDownField
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  set SelectedDropdownField(val: number) {
    this._selectedDropdownField = val;
    this.selectedDropdownFieldChange$.next(val);
  }


  //  Description: Get SelectedDropDownField
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  get SelectedDropdownField() {
    return this._selectedDropdownField;
  }

  /// <summary>
  /// Description: Method to validate if element is already included in the result list
  /// Author: Reinier Rodriguez V
  /// Date: 08/Jun/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  /// </history>
  validateIfDropDownItemExist(params: any, component: string): boolean {
    this.result = [];
    let validateResult: boolean = false;

    params.forEach(param => {
      this.dropDownListData.forEach(element => {
        if (element.metID[0].name == param.name && element.metID[0].value == param.value && element.service == component) {
          this.result.push(element); //Set variable to get only the list needed.
          validateResult = true;
        }
      });
    });
    return validateResult;
  }
  /// <summary>
  /// Description: Method to validate if element is already included in the result list
  /// Author: Reinier Rodriguez V
  /// Date: 08/Jun/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  /// </history>
  validateIfSourceFieldExist(sourcePk: number, component: string): boolean {
    this.result = [];
    let validateResult: boolean = false;

    this.dropDownListData.forEach(element => {
      if (element.key.dropdown_field_pk == sourcePk + '' && element.service == component) {
        this.result.push(element); //Set variable to get only the list needed.
        validateResult = true;
      }
    });

    return validateResult;
  }

  // <summary>
  // Description: Method to get list of required information based on params criteria.
  // Author: Reinier Rodriguez V
  // Date: 08/Jun/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  // </history>
  getDropDownListDataLocal(params: any, component: string) {
    if (!this.validateIfDropDownItemExist(params, component)) {
      this.result = [];
    }
    return this.result;
  }

  // <summary>
  // Description: Method to get list of required information based on params criteria.
  // Author: Reinier Rodriguez V
  // Date: 08/Jun/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  // </history>
  getDropDownListSourceField(params: any, component: string) {
    if (!this.validateIfSourceFieldExist(params, component)) {
      this.result = [];
    }
    return this.result;
  }

  // <summary>
  // Description: Method to set information in the principal variable called dropDownListData that has all elements required from each field that matches the params criteria.
  // Author: Reinier Rodriguez V
  // Date: 08/Jun/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  // </history>
  setDropDownData(params: any, metID: any, result: any, component: string) {
    if (!this.validateIfDropDownItemExist(metID, component))
      this.dropDownListData.push({ key: params, metID: metID, list: result, service: component });
  }
}