import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { settings } from 'src/settings/settings';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/models/response.model';
import { take } from 'rxjs/operators';
import { IListOption } from 'src/app/models/dropdown-option.model';
import { ActiveIngredientService } from '../../../shared/active-ingredient-selector/active-ingredient.service';

@Injectable({
  providedIn: 'root'
})
export class DropDownListService {

  headers = new HttpHeaders({
    'x-rapidapi-host': 'random-facts2.p.rapidapi.com',
    'x-rapidapi-key': 'your-api-key'
  });

  public rankingList: any[] = [];
  public selectedRankings: number[] = [];
  public dropDownListData: IListOption[] = [];
  public result: any[] = [];

  constructor(private http: HttpClient, public AiService: ActiveIngredientService) {
  }

  /// <summary>
  /// Description: Init Ranking List
  /// Author: Juan C Arce
  /// Date: 10/Mar/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-93
  //              Description: Creation
  //     </update>
  /// </history>
  initRankingList(rankingRangeMin: number, rankingRangeMax: number) {
    if (this.rankingList.length == 0) {
      this.rankingList.push({ text: 'Select an option', value: '-1' });
      for (var i = rankingRangeMin; i <= rankingRangeMax; i++) {
        this.rankingList.push({ text: String(i), value: String(i) });
      }
    }
    return this.rankingList;
  }

  /// <summary>
  /// Description: Method to validate if element is already included in the result list
  /// Author: Reinier Rodriguez V
  /// Date: 08/Jun/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  /// </history>
  validateIfDropDownItemExist(params: any): boolean {
    this.result = [];
    let Validateresult: boolean = false;

    this.dropDownListData.forEach(element => { // First for each to loop the main object that contains a list with key (params) and result (values for the dropdown)
      if (element.key.source == params.source && element.key.code == params.code && element.key.materialId == params.materialId) {
        element.list.forEach(item => {  //Drop down module only recognize elements added one by one in the list and not a list inside of a list.
          this.result.push(item); //Set variable to get only the list needed.
        });
        Validateresult = true;
      }
    });
    return Validateresult;
  }

  // <summary>
  // Description: Method to get list of required information based on params criteria.
  // Author: Reinier Rodriguez V
  // Date: 08/Jun/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  // </history>
  getDropDownListDataLocal(params: any) {
    if (!this.validateIfDropDownItemExist(params)) {
      this.result = [];
    }
    return this.result;
  }

  // <summary>
  // Description: Method to set information in the principal variable called dropDownListData that has all elements required from each field that matches the params criteria.
  // Author: Reinier Rodriguez V
  // Date: 08/Jun/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  // </history>
  setDropDownData(params: any, result: any) {
    if (!this.validateIfDropDownItemExist(params))
      this.dropDownListData.push({ key: params, list: result });
  }

  /// <summary>
  /// Description: 
  /// Author: George, Tomas, Yun Wei
  /// Date: 18/Jan/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Creation
  //     </update>
  /// </history>
  getDropDownListData(data: any): Observable<any> {
  
      if (this.AiService.SelectedActiveIngredient) {
        data.materialId = this.AiService.SelectedActiveIngredient.material_id;
      }
    
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = settings.ServiceUrl + 'api/dropdownlist/params';
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.value));
  }

  /// <summary>
  /// Description: Get Available Rankings
  /// Author: Juan C Arce
  /// Date: 10/Mar/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-93
  //              Description: Creation
  //     </update>
  /// </history>
  getAvaliableRankings(selectedValue: number) {
    return this.rankingList.filter(x => !this.selectedRankings.includes(x.value) || x.value == selectedValue);
  }

  /// <summary>
  /// Description: Sete Selected Rankings
  /// Author: Juan C Arce
  /// Date: 10/Mar/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-93
  //              Description: Creation
  //     </update>
  /// </history>
  setSelectedRankings(newValue: number, oldValue: number) {
    this.excludeFromSelectedRanking(oldValue);
    this.includeToSelectedRanking(newValue);
  }

  /// <summary>
  /// Description: Exclude From Rankings
  /// Author: Juan C Arce
  /// Date: 10/Mar/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-93
  //              Description: Creation
  //     </update>
  /// </history>
  excludeFromSelectedRanking(value: number) {
    if (value > 0) this.selectedRankings.splice(this.selectedRankings.findIndex(x => x == value), 1);
  }

  /// <summary>
  /// Description: Inlcude To Rankings
  /// Author: Juan C Arce
  /// Date: 10/Mar/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-93
  //              Description: Creation
  //     </update>
  /// </history>
  includeToSelectedRanking(value: number) {
    if (value > 0 && this.selectedRankings.findIndex(x => x == value) < 0) {
      this.selectedRankings.push(value)
    }
  }
}