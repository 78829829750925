
//  Description: Metabolite Confirmed Service
//  Author: Juan Carlos Arce
//  Creation date: 02/Nov/2022   
//  <history>
//     <update> H00 - RD-34
//              Description: Creation.
//     </update>
// </history>

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IAccessControl } from '../../models/access-control.model';
import { settings } from 'src/settings/settings';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';
import { IProfile } from 'src/app/models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  headers = new HttpHeaders({
    'x-rapidapi-host': 'random-facts2.p.rapidapi.com',
    'x-rapidapi-key': 'your-api-key'
  });

  profile: IProfile = {};

  public listMetaboliteConfirmed: IAccessControl[] = [];
  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient) { }


  //  Description: Get Metabolite Confirmed Columns Def
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getAccessControlColumnDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/access_control/AccessControlColumnsDef.json")
    //Ref. H01
    .pipe(map((colDef: any) => {
      return colDef.map( item => {
        item.children?.map(child => {
          if(child.filter == "ListboxFilter") child.filter = ListboxFilter;
          if(child.filter == "DropdownFilter") child.filter = DropdownFilter;
        })
        return item;
      })
    }));
  }

  //  Description: Get Metabolite Confirmed Columns Def
  //  Author: Juan Carlos Arce
  //  Date: 02/Nov/2022   
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getAccessControlData(): Observable<any> {
    const query = settings.ServiceUrl + 'api/users';
    return this.http.get(query);
  }

  /// <summary>
  ///     Description: Method to save data from Combination table
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  saveAccessControlData(data: IAccessControl[]) {
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = settings.ServiceUrl + 'api/users/add';
    return this.http
      .post(query, body, { headers })
      .pipe(map((data: any) => data));
  }

  /// <summary>
  ///     Description: Method to delete data from Combination table
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  deleteAccessControlData(dataId: number) {
    const query = settings.ServiceUrl + 'api/users_delete/' + dataId;
    return this.http
      .delete(query)
      .pipe(map((data: any) => data));
  }
}
