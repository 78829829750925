import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProbeProposedComponent } from './components/probe-proposed/probe-proposed.component';
import { ValueBuildsComponent } from './components/value-builds/value-builds.component';
import { ValueBuildCombinationsComponent } from './components/value-build-combinations/value-build-combinations.component';
import { MetaboliteConfirmedComponent } from './components/metabolite-confirmed/metabolite-confirmed.component';
import { DegradationPathwayComponent } from './components/degradation-pathway/degradation-pathway.component';
import { GlpStudySpecificsComponent } from './components/glp-study-specifics/glp-study-specifics.component';
import { GlpStudySpecificsSearchComponent } from './components/glp-study-specifics/glp-study-specifics-search/glp-study-specifics-search.component';
import { GlpStudySpecificsToxicologyComponent } from './components/glp-study-specifics/glp-study-specifics-toxicology/glp-study-specifics-toxicology.component';
import { GlpStudySpecificsResiduesComponent } from './components/glp-study-specifics/glp-study-specifics-residues/glp-study-specifics-residues.component';
import { MetaboliteSummaryComponent } from './components/metabolite-summary/metabolite-summary.component';
import { StructureImageComponent } from './components/structure-image/structure-image.component';
//import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from '@azure/msal-browser';
import { ResidueDefinitionComponent } from './components/residue-definition/residue-definition.component';
import { AccessControlComponent } from './components/access-control/access-control.component'
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'probeProposed', component: ProbeProposedComponent},
  { path: 'confirmed', component: MetaboliteConfirmedComponent},
  { path: 'administrator/valueBuilds', component: ValueBuildsComponent},
  { path: 'administrator/valueBuildCombinations', component: ValueBuildCombinationsComponent},
  { path: 'DegradationPathway', component: DegradationPathwayComponent},
  { path: 'physicalChemical', component: GlpStudySpecificsComponent},
  { path: 'analyticalMethods', component: GlpStudySpecificsComponent},
  { path: 'toxicology', component: GlpStudySpecificsToxicologyComponent},
  { path: 'metabolism', component: GlpStudySpecificsComponent},
  { path: 'residues', component: GlpStudySpecificsResiduesComponent},
  { path: 'enviromentFate', component: GlpStudySpecificsComponent},
  { path: 'allSections', component: GlpStudySpecificsSearchComponent},
  { path: 'metaboliteSummary', component: MetaboliteSummaryComponent},
  { path: 'residueDefinition', component: ResidueDefinitionComponent},
  { path: 'administrator/accessControl', component: AccessControlComponent},
  { path: 'postlogin', redirectTo: '/home' },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {


 }
