import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {
  @Input() notes: string;
  @Input() readOnly: boolean;
  @Output() notesOutput = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    if(this.notes == "new")
      this.notes = "";
  }

  onTextChange(event){
    if(event.htmlValue == "" || event.htmlValue == null)
      event.htmlValue = "new";
    this.notesOutput.emit(event.htmlValue);
  }

}
