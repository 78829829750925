import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { ActiveIngredientService } from '../../active-ingredient-selector/active-ingredient.service';
import { DropDownListService } from './drop-down-list.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ValidatorService } from 'src/app/providers/validator.service';
import { IDropDownField } from 'src/app/models/dropdown-field.model';
import { DropdownFieldService } from 'src/app/providers/dropdown-field.service';
import { ValueBuildCombinationsService } from 'src/app/components/value-build-combinations/value-build-combinations.service';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';

@Component({
  selector: 'app-drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.scss']
})
export class DropDownListComponent implements ICellRendererAngularComp, OnDestroy {
  public params: any;
  public listItems: Array<any>;
  public selectedValue: any;
  selectedText: string;
  public parent: any;
  public readOnly: boolean = false;
  public style = 'large';
  public placeholder: string = 'Select Item';
  public isGroupRendered: boolean;
  public dropdownFieldCode;
  public module;
  public link;
  public showClear: boolean = false;
  public colDef: any;

  GetDropDownListSubscription: Subscription;

  constructor(private dropDownListService: DropDownListService,
    private AiService: ActiveIngredientService,
    private validatorService: ValidatorService,
    private dropdownFieldService: DropdownFieldService,
    private combinationService: ValueBuildCombinationsService,
    private dropdownValueService: DropdownValueService) { }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  /// <summary>
  ///     Description: Method of initializing the values ​​of the Drop Down
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-43 Karina Villalobos S.
  ///         Description: Method of initializing the values ​​of the Drop Down Metabolite and Precursor.
  ///     </update>
  ///     <update> H02 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  ///              Description: Dropdown Refactor
  ///     </update>
  ///     <update> H03 - RD-87 - 06/Feb/2023 - Yun Wei
  ///              Description: Validate Column Def Readonly and Row Readonly
  ///     </update>
  //      <update> H04 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
  //          Description: Set readonly with validator service
  //      </update>
  /// </history>
  async agInit(params: any): Promise<void> {
    this.params = params;
    this.selectedValue = params.value != undefined ? String(params.value) : undefined;
    this.selectedText = this.selectedValue;
    this.parent = params.context.componentParent;
    this.colDef = params.colDef;
    this.listItems = params.list;
    this.style = params.style ?? this.style;
    this.showClear = params.showClear ?? this.showClear;
    this.placeholder = params.placeholder ?? this.placeholder;
    this.isGroupRendered = params.node.group;
    //Ref H04
    this.readOnly = this.validatorService.isComponentReadOnly(params);
    this.dropdownFieldCode = params.code;
    this.link = params.link;
    this.module = params.module;
    if(params.module=="GLPRES" ){
      this.readOnly = params.context.data.readOnly;
      if(this.readOnly==true){
        this.selectedText= params?.data?.validation_data;
        return;
      }
    }
    if (!this.listItems) {
      //Ref: H02
      if (this.params.source == 'xnumber') {
        if (this.selectedValue == 'Select') {
          this.readOnly = false;
        }
        this.selectedValue = this.selectedValue.replace("X", "");
      }
      if (this.params.source == 'ranking') {
        this.initRankingList();
      }
      else {
        if (this.params.source == 'dropdowncombinations') {
          this.combinationService.moduleCombinations = [];

          const combinations = await this.combinationService.getCombinationsByModuleCode(this.module).pipe(take(1)).toPromise();
          combinations.map(element => {
            element.moduleCode = this.module;
            this.combinationService.moduleCombinations.push(element);
          });

          this.getDropDownListCombination();
        }
        else {
          this.getDropDownListData();
        }
      }
    } else {
      this.selectedText = this.listItems.find(x => x.value == this.selectedValue)?.text;
      this.colDef.refData = this.listItems;
    }
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    if (this.params.source == 'ranking') this.dropDownListService.excludeFromSelectedRanking(this.selectedValue);
    this.GetDropDownListSubscription?.unsubscribe();
  }

  initRankingList() {
    this.listItems = this.dropDownListService.initRankingList(this.params.rankingRangeMin, this.params.rankingRangeMax);
    this.dropDownListService.includeToSelectedRanking(this.selectedValue);
  }

  getRankingDropDownData() {
    this.listItems = this.dropDownListService.getAvaliableRankings(this.selectedValue);
    this.colDef.refData = this.listItems;
  }

  /// <summary>
  /// Description: Get data drom params
  /// Author: Yun, Tomas & George
  /// Date: 18/Jan/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-82
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-148 - 08/Jun/2023 - Reinier Rodriguez V.
  //              Description: Modify method to only call server when the value is not loaded once the information is retreaved is got from a local variable.
  //     </update>
  /// </history>
  getDropDownListData() {
    const params = {
      source: this.params.source,
      code: this.params.code,
      fields: this.params.fields,
      materialId: this.AiService.SelectedActiveIngredient ? this.AiService.SelectedActiveIngredient.material_id : 0,
      valueDefinition: this.params.valueDefinition
    }

    const list = this.dropDownListService.getDropDownListDataLocal(params);

    if (list.length > 0) //Ref. H01
    {
      this.listItems = list as unknown as Array<any>;
      this.setDropDownDataOnRendering();
    }
    else {
      this.GetDropDownListSubscription = this.dropDownListService.getDropDownListData(params)
        .pipe(take(1))
        .subscribe(data => {
          this.listItems = data;
          if(this.listItems.length > 0)
          this.dropDownListService.setDropDownData(params, data);
          this.setDropDownDataOnRendering();

        });
    }
  }

  // <summary>
  // Description: Moved code from previous method to set selected values in the dropdown fields
  // Author: Reinier Rodriguez V.
  // Date: 08/Jun/2023
  // </summary>
  // <history>
  //     <update> H00 - RD-148
  //              Description: Creation
  //     </update>
  /// </history>
  setDropDownDataOnRendering() {
    let isGroupHeaderSelection = false;
    if (this.showClear) this.listItems.unshift({ text: this.placeholder, value: null });
    if (!Number.isNaN(this.selectedValue) && Number(this.selectedValue) < 0) {
      if (!this.showClear) this.listItems.unshift({ text: this.placeholder, value: this.selectedValue });
      isGroupHeaderSelection = true;
    }

    if (!isGroupHeaderSelection) {

      const selectedOption = this.listItems.find(x => x.value == this.selectedValue);

      if (selectedOption != undefined) {
        this.selectedText = selectedOption.text;
        if (this.params.destination)
          if (this.selectedValue != 0)
            this.changeParent(selectedOption);//case when the item was found send code 
      }
      else
        if (this.selectedValue != '' && this.selectedValue != undefined && this.selectedValue != 'undefined' && this.selectedValue != '0' && this.selectedValue != '-1') {
          if (this.params.source == 'xnumber' && this.selectedValue != 'undefined') {
            this.selectedText = 'X' + this.selectedValue;
            this.listItems.unshift({ text: 'X' + this.selectedValue, value: this.selectedValue, code: '' });
          }
          else
            this.listItems.unshift({ text: this.selectedValue, value: this.selectedValue, code: '' });
        }
    }
  }

  //  Description: Get DropDown Combinations
  //  Author: Juan Carlos Arce
  //  Creation date: 05/May/2023    
  //  <history>
  //     <update> H00 - RD-141
  //              Description: Creation.
  //     </update>
  // </history>
  async getDropDownListCombination() {
    try {

      let items = [];
      let sourceField: any;
      let metID: any = [{ name: 'code', value: this.dropdownFieldCode }];
      let values: any;


      const list = this.dropdownFieldService.getDropDownListDataLocal(metID, 'dropDownComponent');

      if (list.length > 0) {
        sourceField = list;
      } else {
        await this.dropdownFieldService.GetByParams(metID).toPromise().then(f => sourceField = f[0]);

        if(this.params.isLastCombined == "true"){
          let combinations = []
          this.combinationService.moduleCombinations.filter(x=> x.destination_dropdown_field_pk == sourceField.dropdown_field_pk && x.moduleCode == this.module)
          .map(source => source.destination_dropdown_field_value_pk.map(value=> combinations.push(value)));

          values = await this.dropdownValueService.GetByBulkIDs(combinations).toPromise();
          this.dropdownFieldService.setDropDownData(sourceField, metID, values, 'dropDownComponent');
          if (values) values.map(val => items.push(this.getDropDownFieldValueAsObject(val)));
          this.listItems = items.sort((a, b) => a.text > b.text ? 1 : -1);
          if (this.selectedValue) this.changeParent(this.selectedValue)
          return;
        }
      }

      //Fill combinations list
      let sourceCombinations = [];
      this.combinationService.moduleCombinations.filter(x => x.source_dropdown_field_pk == sourceField.dropdown_field_pk && x.moduleCode == this.module)
        .map(source => source.source_dropdown_field_value_pk.map(value => sourceCombinations.push(value)));
      sourceCombinations = [...new Set(sourceCombinations)];

      if (this.link) {

        let relatedCombinations = this.combinationService.moduleCombinations.filter(x => x.destination_dropdown_field_pk == sourceField.dropdown_field_pk
          && x.moduleCode == this.module)

        let validCombinations = []
        relatedCombinations.map(x => {
          if (x.source_dropdown_field_value_pk.some(y => y == this.params.node.allLeafChildren[0].data[this.link])) { // Verify it works
            validCombinations.push(x);
          }
        });

        let validValues = [];
        validCombinations.map(val => val.destination_dropdown_field_value_pk.map(v => validValues.push(v)));
        sourceCombinations = validValues;
      }

      if (list.length > 0) {
        values = list[0].list;
      } else {
        values = await this.dropdownValueService.GetByBulkIDs(sourceCombinations).toPromise();
        this.dropdownFieldService.setDropDownData(sourceField, metID, values, 'dropDownComponent');
      }

      if (values) values.map(val => items.push(this.getDropDownFieldValueAsObject(val)));

      this.listItems = items.sort((a, b) => a.text > b.text ? 1 : -1);
      if (this.selectedValue) this.changeParent(this.selectedValue)
    } catch (err) {
      console.warn(err);
    }
  }


  //  Description: Get DropDown Filed Value as Object
  //  Author: Juan Carlos Arce
  //  Creation date: 05/May/2023    
  //  <history>
  //     <update> H00 - RD-141
  //              Description: Creation.
  //     </update>
  // </history>
  getDropDownFieldValueAsObject(val) {
    return { value: val.dropdown_field_value_pk.toString(), text: val.dropdown_field_value };
  }

  /// <summary>
  ///     Description: Get Drop Down on Change method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  //     <update> H01 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Dropdown Refactor
  //     </update>
  /// </history>
  public onChange(event: any): void {
    if (!event.value) {
      this.changeParent(null, true);
      return;
    }
    let selectedOption = this.listItems.find(x => x.value == event.value);
    if (this.params.source == 'ranking') {
      this.dropDownListService.setSelectedRankings(event.value, this.params.data.ranking);
    }
    selectedOption.value = String(selectedOption.value);
    this.changeParent(selectedOption, true);
  }

  public onShow(event: any) {
    if (this.params.source == 'ranking') {
      this.getRankingDropDownData();
    }
  }

  /// <summary>
  ///     Description: Get Drop Down on Change parent method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  //     <update> H01 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Dropdown Refactor
  //     </update>
  /// </history>
  public changeParent(selectedOption: any, isEvent: boolean = false) {
    if (isEvent) {
      this.params.data[this.params.colDef.field] = selectedOption ? selectedOption.value : selectedOption;
      this.params.data.haveChanges = true;
    }
    const linkParams = { columns: [this.params.destination], rowNodes: [this.params.node] };
    const params = {
      data: this.params.data,
      link: this.link,
      value: selectedOption ? selectedOption.value : selectedOption,
      selectedItem: selectedOption,
      linkParams: linkParams,
      isEvent
    };
    this.parent.dropdownChanged(params);
  }
}
