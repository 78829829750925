import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { DropDownListService } from '../drop-down-list/drop-down-list.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-text-dropdown-list',
  templateUrl: './text-dropdown-list.component.html',
  styleUrls: ['./text-dropdown-list.component.scss']
})
export class TextDropdownListComponent implements ICellRendererAngularComp, OnDestroy {
  public params: any;
  public listItems: Array<any>;
  public selectedValue: any;
  public columnThatModifies: any;
  public parent: any;

  public readOnly: boolean = false;
  public style = 'large';
  public placeholder: string = 'Select Item';
  public isGroupRendered: boolean;

  DropDownOptionServiceSubscription: Subscription

  constructor(private dropDownListService: DropDownListService,
              private validatorService: ValidatorService) { }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  /// <summary>
  ///     Description: Method of initializing the values ​​of the Drop Down
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-43 Karina Villalobos S.
  ///         Description: Method of initializing the values ​​of the Drop Down Metabolite and Precursor.
  ///     </update>
  //     <update> H02 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Dropdown Refactor
  //     </update>
  //      <update> H03 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
  //          Description: Set readonly with validator service
  //      </update>
  /// </history>
  agInit(params: any): void {
    this.params = params;
    this.selectedValue = String(params.value);
    this.parent = params.context.componentParent;
    this.columnThatModifies = params.colDef;
    this.listItems = params.list;
    this.style = params.style ?? this.style;
    //Ref: H03
    this.readOnly = this.validatorService.isComponentReadOnly(params);
    this.placeholder = params.placeholder ?? this.placeholder;
    this.isGroupRendered = params.node.group;

    if (!this.listItems) {
      //Ref: H02
      if (this.params.source == 'xnumber') {
        if (this.selectedValue == 'Select') {
          this.readOnly = false;
        }
        this.selectedValue = this.selectedValue.replace("X", "");
      }
      
      this.getDropDownListData();
    }
  }
  
  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.DropDownOptionServiceSubscription?.unsubscribe();
  }

  /// <summary>
  /// Description: Get data drom params
  /// Author: Yun, Tomas & George
  /// Date: 18/Jan/2023
  /// </summary>
  /// <history>
  //     <update> H00 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Creation
  //     </update>
  /// </history>
  getDropDownListData() {
    const params = {
      source: this.params.source,
      code: this.params.code,
      fields: this.params.fields,
      materialId: this.params.context.materialId,
      valueDefinition: this.params.valueDefinition
    }
    
  this.DropDownOptionServiceSubscription = this.dropDownListService.getDropDownListData(params)
      .pipe(take(1))
      .subscribe(data => {
        this.listItems = data;
        const selectedOption = this.listItems.find(x => x.value == this.selectedValue);
        if (this.params.destination) {
          if (this.selectedValue != 0) {
            if (selectedOption != undefined)
              this.changeParent(selectedOption);//case when the item was found send code 
          }
        }
      });
  }

  /// <summary>
  ///     Description: Get Drop Down on Change method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  //     <update> H01 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Dropdown Refactor
  //     </update>
  /// </history>
  public onChange(event: any): void {
    let selectedOption = this.listItems.find(x => x.value == event.value);
    this.changeParent(selectedOption);
  }

  /// <summary>
  ///     Description: Get Drop Down on Change parent method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  //     <update> H01 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Dropdown Refactor
  //     </update>
  /// </history>
  public changeParent(selectedOption: any) {
    this.params.data[this.params.colDef.field] = selectedOption.value;
    const linkParams = { columns: [this.params.destination], rowNodes: [this.params.node] };
    const params = {
      data: this.params.data,
      link: this.params.link,
      value: selectedOption.value,
      selectedItem: selectedOption,
      linkParams: linkParams
    };
    this.parent.dropdownChanged(params);
  }
}
