import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

//  Description: Layout Component
//  Author: Juan Carlos Arce
//  Creation date: 09/Sep/2022    
//  <history>
//     <update> H00 - RD-23
//              Description: Creation.
//     </update>
// </history>

export class LayoutComponent implements OnInit {

  panelsize = [20,80];
  minPanelsize = [1,50];
  isIframe = false;
  loginDisplay = false;

  constructor(private authService: MsalService) { }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
  }

  
  //  Description: Hide Menu Event
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  menuHiddeChanged(hidden)
  {
    if(hidden) {
      this.panelsize = [1,99];
      this.minPanelsize = [1,99];
    }
    if(!hidden) {
      const prevVal = JSON.parse(localStorage.getItem('splSize'));
      this.panelsize = prevVal ? prevVal : [20,80];
      this.minPanelsize = [1,50];
    }
  }

  login() {
    this.authService.loginRedirect();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

}
