export const settingsTr = {
  beginToken: '81162727-7245-4058-88b0-38db22f8737f',
  name: 'qa',
  baseUrl: 'https://dsi-se-residuedef-func-stage.azurewebsites.net/api/',
  authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
  clientID: 'dfd9be2e-1608-498e-9610-09e62fdfdeb2',
  redirectUri: 'https://dsi-se-residuedef-app-stage.azurewebsites.net/',
  key2: 'https://dsi-se-residuedef-func-stage.azurewebsites.net/api/',

  msalAngular: {
    consentScopes: [
      'https://analysis.windows.net/powerbi/api/Report.Read.All',
      'https://agcompany.onmicrosoft.com/residuedefinition/stage/user_impersonation/AccessResidueDefinitionQa',
      'User.Read', 'User.ReadBasic.All', 'profile', 'openid'],
    unprotectedResources: ['version.json']
  },

  SUPPORT_BOT_SETTINGS: {
    botAlias: "$LATEST",
    botName: "RasResDefBot",
    supportName: "DSI Support",
    roleArn: "arn:aws:iam::078183859623:role/dsi_qa-lexrole-sts",
    roleSessionName: "QASession"
  },
  powerBiConsentScope: 'https://analysis.windows.net/powerbi/api/Report.Read.All',
  powerBiReportEmbedUrl: 'https://app.powerbi.com/reportEmbed',
  powerBiReportId: '',
  powerBiGroupId: '',
  netDocsScope: 'api://43aeb1a0-45c5-4687-8c0c-b35db02570f0/nd_access',
  netDocsUrl: 'https://8be6w9m8ja.execute-api.us-east-1.amazonaws.com/dv/v1/lambda/v1',
  netDocsCabinet: 'NG-7M3XK9TA',
  appAdmin: 'Jennifer Jones',
  functionScope: 'https://agcompany.onmicrosoft.com/residuedefinition/stage/user_impersonation/AccessResidueDefinitionQa',
  ServiceUrl: 'https://dsi-se-residuedef-func-stage.azurewebsites.net/',
  endToken: '4daa9ca3-3ea3-4873-82c5-168cfc5864ae'
};
