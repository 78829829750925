
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, Input, OnInit,Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-structure-image',
  templateUrl: './structure-image.component.html',
  styleUrls: ['./structure-image.component.scss'],
})

export class StructureImageComponent implements ICellRendererAngularComp {

  @Input() SVG: string;


  public innerHTML: SafeHtml;

  //  Description: Constructor
  //  Author: Karina Villalobos Salazar
  //  Date: 16/Nov/2022    
  //  <history>
  //     <update> H00 - RD-60
  //              Description: Creation.
  //     </update>
  // </history>

  constructor(private sanitizer: DomSanitizer) {

  }



  //  Description: Regresh method
  //  Author: Karina Villalobos Salazar
  //  Date: 16/Nov/2022    
  //  <history>
  //     <update> H00 - RD-60
  //              Description: Creation.
  //     </update>
  // </history>


  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  //  Description: agInit
  //  Author: Karina Villalobos Salazar
  //  Date: 16/Nov/2022    
  //  <history>
  //     <update> H00 - RD-60
  //              Description: Creation.
  //     </update>
  // </history>
  agInit(params: any): void { }

  //  Description: ngOnInit
  //  Author: Karina Villalobos Salazar
  //  Date: 16/Nov/2022    
  //  <history>
  //     <update> H00 - RD-60
  //              Description: Creation.
  //     </update>
  // </history>
  ngOnInit(): void {
    if (this.SVG != undefined) { this.innerHTML = this.SVG }
  }
}
