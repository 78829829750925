import { IStatusPanelAngularComp } from '@ag-grid-community/angular';
import { IStatusPanelParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserService } from 'src/app/providers/user.service';
import { PrivilegeModule, PrivilegeType } from 'src/app/shared/constants';
import { ExportExcel } from '../../../../providers/export-excel.service';
import { DropdownFieldService } from 'src/app/providers/dropdown-field.service';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';
import { take } from 'rxjs/operators';
import { DropDownListService } from '../drop-down-list/drop-down-list.service';

@Component({
  selector: 'app-status-panel',
  templateUrl: './status-panel.component.html',
  styleUrls: ['./status-panel.component.scss']
})
export class StatusPanelComponent implements IStatusPanelAngularComp {
  public parent;
  public selectionCount: number = 0;
  public isPrivilegeFull: boolean = true;

  //  Description: constructor
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userService: UserService,
    public exportExcel: ExportExcel,
    private dropdownFieldService: DropdownFieldService,
    private dropDownListService: DropDownListService,
    private dropdownValueService: DropdownValueService) {
  }


  //  Description: Component init method
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  async agInit(params: any): Promise<void> {
    this.parent = params.context.componentParent;
    let selectedRow = undefined;

    await new Promise(resolve => setTimeout(() => {
      selectedRow = params.context.componentParent.selectedRow;
      const isConfirmed = selectedRow ? selectedRow.isConfirmed : false;
      this.isPrivilegeFull = this.userService.isPrivilegeFull(PrivilegeModule.InnerGrid, params.context.componentParent.path, isConfirmed);
    }, 500));
  }


  //  Description: Delete Button Event
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  onDeleteClick(event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.parent.DetailDeleteClicked();
      }
    });
  }


  //  Description: Add Button Event
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  onAddClick() {
    this.parent.AddDetailClicked();
  }


  //  Description: Set Selection Count Method
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  setSelectionCount(selectionCount: number) {
    this.selectionCount = selectionCount;
  }

  //  Description: Delete Event Confirmation
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      },
      reject: () => {
        callback(false);
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }

  // <summary>
  //  Description: Export to excel method.
  //  Author: Reinier Rodriguez V.
  //  Date: 02/May/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-133
  //              Description: Creation.
  //     </update>
  // </history>
  public exportToExcel(event: Event): void {
    this.parent.exportToExcel();
  }
 


    // this.dropdownFieldService.GetByParams([{ name: 'code', value: 'BMLI' }]).pipe(take(1)).subscribe(fields => fields.map(field => {
    //   this.dropdownValueService.GetByParams([{ name: 'id', value: field.dropdown_field_pk.toString() }]).pipe(take(1)).
    //     subscribe(values => {
    //       this.dropdownFieldService.setDropDownData(field, [{ name: 'code', value: 'BMLI' }], values, 'listBoxComponent');

    //       this.dropdownFieldService.GetByParams([{ name: 'code', value: 'BMLII' }]).pipe(take(1)).subscribe(fields => fields.map(field => {
    //         this.dropdownValueService.GetByParams([{ name: 'id', value: field.dropdown_field_pk.toString() }]).pipe(take(1)).
    //           subscribe(values => {
    //             this.dropdownFieldService.setDropDownData(field, [{ name: 'code', value: 'BMLII' }], values, 'listBoxComponent');

    //             this.dropdownFieldService.GetByParams([{ name: 'code', value: 'BMLIII' }]).pipe(take(1)).subscribe(fields => fields.map(field => {
    //               this.dropdownValueService.GetByParams([{ name: 'id', value: field.dropdown_field_pk.toString() }]).pipe(take(1)).
    //                 subscribe(values => {
    //                   this.dropdownFieldService.setDropDownData(field, [{ name: 'code', value: 'BMLIII' }], values, 'listBoxComponent');

    //                   this.dropdownFieldService.GetByParams([{ name: 'code', value: 'BMLIV' }]).pipe(take(1)).subscribe(fields => fields.map(field => {
    //                     this.dropdownValueService.GetByParams([{ name: 'id', value: field.dropdown_field_pk.toString() }]).pipe(take(1)).
    //                       subscribe(values => {
    //                         this.dropdownFieldService.setDropDownData(field, [{ name: 'code', value: 'BMLIV' }], values, 'listBoxComponent');

    //                         this.dropdownFieldService.GetByParams([{ name: 'code', value: 'BMLV' }]).pipe(take(1)).subscribe(fields => fields.map(field => {
    //                           this.dropdownValueService.GetByParams([{ name: 'id', value: field.dropdown_field_pk.toString() }]).pipe(take(1)).
    //                             subscribe(values => {
    //                               this.dropdownFieldService.setDropDownData(field, [{ name: 'code', value: 'BMLV' }], values, 'listBoxComponent');
    //                               this.exportExcel.exportDetailToExcel(this.dropdownFieldService, this.dropDownListService);
    //                             });
    //                         }));
    //                       });
    //                   }));
    //                 });
    //             }));
    //           });
    //       }));
    //     });
    // }));
  
}