import { InjectionToken } from '@angular/core';

/*
 * Injection token for encompass settings
 */
export const RESIDUE_SETTINGS = new InjectionToken<string>('RESIDUE_SETTINGS');

export interface ResidueSettings {
  aiKey?: string;
  baseUrl?: string;
  environment?: string;
  powerBiConsentScope: string;
  powerBiReportEmbedUrl: string;
  powerBiGroupId: string;
  powerBiReportId: string;
  netDocsScope: string;
  netDocsUrl: string;
  netDocsCabinet: string;
  appAdmin: string;
  functionScope: string;
}