import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, IDetailCellRendererParams, GridApi, CellValueChangedEvent, GridOptions } from '@ag-grid-community/core';
import { MultiSelectionComponent } from '../multi-selection/multi-selection.component';
import { NumericComponent } from '../numeric/numeric.component';
import { SearchComponent } from '../search/search.component';
import { DropDownListComponent } from '../drop-down-list/drop-down-list.component';
import { PopupButtonComponent } from '../popup-button/popup-button.component';
import { StatusPanelComponent } from '../status-panel/status-panel.component';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { AccordionComponent } from '../accordion/accordion.component';
import { CheckboxComponent } from '../checkbox/checkbox.component'
import { TooltipComponent } from '../tooltip/tooltip.component';
import { TextboxComponent } from '../textbox/textbox.component';
import { GroupCellComponent } from '../group-cell/group-cell.component';
import { InnerGridComponent } from '../inner-grid/inner-grid.component';
import { TextDropdownListComponent } from '../text-dropdown-list/text-dropdown-list.component';
import { TextAreaComponent } from '../text-area/text-area.component';
import { GridService } from './grid.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GlpStudySpecificsService } from "../../../glp-study-specifics/glp-study-specifics.service";
import { ListBoxSelectionComponent } from '../list-box/list-box-selection.component';
import { ButtonComponent } from '../button/button.component';
import { IQuickFilter } from 'src/app/models/quick-filter.model';
import { HttpClient } from '@angular/common/http';
import { IProfile } from 'src/app/models/profile.model';
import { ExportExcel } from '../../../../providers/export-excel.service';
import { ActionButtonService } from '../../action-button/action-button.service'
import { DropdownValueService } from '../../../../providers/dropdown-value.service';
import { ProbeProposedService } from '../../../probe-proposed/probe-proposed.service';
import { DropdownFieldService } from '../../../../providers/dropdown-field.service'
import { DropDownListService } from '../drop-down-list/drop-down-list.service'


@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(OverlayPanel) private overlayPanel: OverlayPanel;

  //#region Inputs
  //  Description: Grid Component Inputs
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-120 - 22/March/2023 George Ulecia
  //              Description: Add Tooltip usage to component.
  //     </update>
  // </history>
  @Input() rowData: any[];
  @Input() popUpRowData: any[];
  @Input() rowSelection: 'single' | 'multiple' = 'single';
  @Input() columnDefs: (ColDef | ColGroupDef)[] = [];
  @Input() detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];
  @Input() columnSizeMode: string = 'autoSize';
  @Input() groupDisplayType: string = null;
  @Input() autoGroupColumnDef: any = null;
  @Input() defaultColDef: ColDef = {
    flex: 1,
    editable: false,
    filter: 'agSetColumnFilter',
    tooltipComponent: TooltipComponent,
    sortable: true,
 
  };
  //Ref H01
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 30000;
  @Input() rowHeight: number;
  @Input() profile: number;
  @Input() columnStateName: string;
  @Input() path: string = '';
  //#endregion

  //#region Outputs
  //  Description: Grid Component Outputs
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-54 - 17/Nov/2022 - Juan C. Arce
  //              Description: Add dropDownSelectionChangedEvent Output.
  //     </update>
  // </history>
  @Output() selectionChangedEvent = new EventEmitter<any[]>();
  @Output() AddDetailEvent = new EventEmitter<any>();

  @Output() ExportDetailEvent = new EventEmitter<any>();
  @Output() detailSelectionChangedEvent = new EventEmitter<any[]>();
  @Output() DeleteDetailEvent = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();
  @Output() detailCellValueChangedEvent = new EventEmitter<any[]>();
  @Output() searchCellChanged = new EventEmitter<any>();
  @Output() dropDownSelectionChangedEvent = new EventEmitter<any>(); //Ref. H01
  @Output() addInnerGridEvent = new EventEmitter<any>();
  @Output() deleteInnerGridEvent = new EventEmitter<any>();
  @Output() fileChange = new EventEmitter<string>();
  @Output() notesChange = new EventEmitter<string>();
  @Output() textBoxChangedEvent = new EventEmitter<any>();
  //#endregion

  //#region Properties
  //  Description: Grid Component Properties
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  private columnApi: ColumnApi;
  private gridApi: GridApi;
  private detailColumnApi: ColumnApi;
  private detailGridApi: GridApi;
  public gridOptions: GridOptions = {};
  public groupDefaultExpanded = 0;
  public detailCellRendererParams: any | null;
  public pinnedTopRowData;
  public innerHTMLs: SafeHtml[] = [];
  public frameworkComponents: {
    multiselectionRenderer: typeof MultiSelectionComponent,
    listBoxselectionRenderer: typeof ListBoxSelectionComponent,
    dropdownRenderer: typeof DropDownListComponent,
    numericRender: typeof NumericComponent,
    searchRenderer: typeof SearchComponent,
    popupRenderer: typeof PopupButtonComponent,
    datePickerRenderer: typeof DatePickerComponent,
    accordionRenderer: typeof AccordionComponent,
    checkBoxRenderer: typeof CheckboxComponent,
    textBoxRenderer: typeof TextboxComponent,
    emptyGroupCellRenderer: typeof GroupCellComponent,
    InnerGridRenderer: typeof InnerGridComponent,
    textDropdownListRenderer: typeof TextDropdownListComponent,
    textAreaRenderer: typeof TextAreaComponent,
    buttonRenderer: typeof ButtonComponent  
  };
  public detailFrameworkComponents: {
    [p: string]: any;
  } = {
      dropdownRenderer: DropDownListComponent,
      listBoxselectionRenderer: ListBoxSelectionComponent,
      textAreaRenderer: TextAreaComponent,
    };
  public context;
  public inputRow = {};
  public modifiedData: any[] = [];
  public statusBar;
  public statusBarDetailComponent;
  public selectedRow: any;
  public SVGstatus: number = 0;
  public SVG: string = "";
  public defaultHeight = 100;
  public xnumberMaterialId: number = 0;
  public SectionVisible: boolean = false;
  public popupInputParam: any;
  public popupComponent: string = '';
  public popupReadOnly: boolean = false;
  public buttonCliked: boolean = false;
  public buttonInputParams: any;
  public draggable: boolean = true;
  profile_user: string[] = [];

  actionButtonSubscription: Subscription;
  actionButtonSubscriptionRowColor: Subscription;
  GridServiceSubscription: Subscription;
  GridDetailServiceSubscription: Subscription;
  QuickFilterSubscription: Subscription;
  RedrawRowSubscription: Subscription;
  subscriptions: Subscription[] = [];
  //#endregion

  constructor(private gridService: GridService,
    private glpStudySpecificsService: GlpStudySpecificsService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private exportExcel: ExportExcel,
    private dropdownValueService: DropdownValueService,
    private actionButtonService: ActionButtonService,
    private probeProposedService: ProbeProposedService,
    private dropdownFieldService: DropdownFieldService,
    private dropDownListService: DropDownListService) {
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: StatusPanelComponent,
          key: 'statusPanel',
        },
      ],
    };

    this.gridOptions.statusBar = {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' }
      ]
    };
  }

  //#region Events
  //  Description: Grid Component OnInit Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-57 - 25/Nov/2022 Juan C. Arce.
  //              Description: Add defaultHeight instead of only rowHeight.
  //     </update>
  //     <update> H02 - RD-57 - 25/Nov/2022 Juan C. Arce.
  //              Description: Add AccordionRenderer.
  //     </update>
  //     <update> H03 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Set Material Id in Context
  //     </update>
  // </history>
  ngOnInit(): void {
    if (this.rowHeight > 0) this.defaultHeight = this.rowHeight; //Ref. H01

    this.pinnedTopRowData = [this.inputRow];
    //Ref: RD-83
    this.context = { componentParent: this };
    this.frameworkComponents = {
      numericRender: NumericComponent,
      multiselectionRenderer: MultiSelectionComponent,
      listBoxselectionRenderer: ListBoxSelectionComponent,
      dropdownRenderer: DropDownListComponent,
      searchRenderer: SearchComponent,
      popupRenderer: PopupButtonComponent,
      datePickerRenderer: DatePickerComponent,
      accordionRenderer: AccordionComponent, //Ref. H02
      checkBoxRenderer: CheckboxComponent,
      textBoxRenderer: TextboxComponent,
      emptyGroupCellRenderer: GroupCellComponent,
      InnerGridRenderer: InnerGridComponent,
      textDropdownListRenderer: TextDropdownListComponent,
      textAreaRenderer: TextAreaComponent,
      buttonRenderer: ButtonComponent

    };

    if (!this.detailCellRendererParams) this.setDetailCellRendererParams();

    this.GridServiceSubscription = this.gridService.transaction$.subscribe(transaction => {
      this.gridApi.applyTransaction(transaction);
    });
    this.subscriptions.push(this.GridServiceSubscription);

    this.RedrawRowSubscription = this.gridService.redrawRows$.subscribe(redraw => {
      this.gridApi.redrawRows();
    });
    this.subscriptions.push(this.RedrawRowSubscription);

    this.GridDetailServiceSubscription = this.gridService.detailTransaction$.subscribe(detailTransaction => {
      this.detailGridApi.applyTransaction(detailTransaction);
    });
    this.subscriptions.push(this.GridDetailServiceSubscription);

    this.QuickFilterSubscription = this.gridService.quickFilter$.subscribe((quickFilter: IQuickFilter) => {
      this.applyQuickFilter(quickFilter);
    });
    this.subscriptions.push(this.QuickFilterSubscription);
    this.SetSelectGrid();

    this.actionButtonSubscription = this.actionButtonService.exportExcel$.subscribe(value => {
      if (value) this.exportExcelProcess(this.gridApi);
    });
    this.subscriptions.push(this.actionButtonSubscription);

    this.actionButtonSubscriptionRowColor = this.probeProposedService.rowColor$.subscribe(value => {
      this.ConfirmedProposedProcess(value);
    });
    this.subscriptions.push(this.actionButtonSubscriptionRowColor);

    this.actionButtonSubscriptionRowColor = this.actionButtonService.rowColor$.subscribe(value => {
      this.ConfirmedProposedProcessButton(value);
    });
    this.subscriptions.push(this.actionButtonSubscriptionRowColor);
  }

  exportExcelProcess(gridApi: GridApi) {
    this.exportExcel.exportToExcel(gridApi, this.gridOptions, this.dropdownFieldService, this.dropDownListService);
  }

  /// <summary>
  //  Description: Set the color of the row that has x number and that is confirmed
  //  Author: Karina Villalobos Salazar
  //  Creation date: 24/May/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-152
  //              Description: Creation
  //     </update>
  // </history>
  ConfirmedProposedProcess(value: Boolean) {
    this.gridOptions.getRowStyle = (params) => {
      if (params.data.x_number != '' && params.data.confirmed_dropdown_field_value_pk === this.dropdownValueService.confirmedYesDDV.dropdown_field_value_pk)
        return { 'background-color': 'rgb(227 229 230)' }
      return { 'background-color': 'white' }
    }
  }

  ConfirmedProposedProcessButton(value: Boolean) {
    this.gridOptions.getRowStyle = (params) => {
      if (params.data.x_number != '' && params.data.confirmed_dropdown_field_value_pk === this.dropdownValueService.confirmedYesDDV.dropdown_field_value_pk)
        return { 'background-color': 'rgb(227 229 230)' }
      return { 'background-color': 'white' }
    }
  }

  /// <summary>
  //  Description: Set the selected column of the Grid 
  //  Author: Karina Villalobos Salazar
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-90
  //              Description: Creation
  //     </update>
  // </history>
  SetSelectGrid(): void {
    if (this.rowData != undefined) {
      this.rowData.forEach(item => {
        item.selected = false;
      });
    }
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-128
  //             Description: Added Column Group State management.
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.gridService.setColumnGroupState(this.columnStateName, this.columnApi.getColumnGroupState());
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  //  Description: Grid Component OnChange Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailColumnDefs) {
      this.setColumnSize(this.detailColumnApi, this.detailGridApi);
      this.statusBarDetailComponent?.setSelectionCount(this.detailGridApi.getSelectedRows().length);
    }
    this.setColumnSize(this.columnApi, this.gridApi);
  }

  //  Description: On Grid Ready Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-128
  //              Description: Added Column Group State management.
  //     </update>
  // </history>
  onGridReady(params: GridReadyEvent) {
    this.columnApi = params.columnApi;
    this.gridApi = params.api;
    this.setColumnSize(this.columnApi, this.gridApi);
    //#H01
    setTimeout(() => {
      let groupState = this.gridService.getColumnGroupState(this.columnStateName);
      if (!groupState) {
        groupState = this.columnApi.getColumnGroupState();
        this.gridService.setColumnGroupState(this.columnStateName, groupState);
      }
      this.columnApi.setColumnGroupState(this.gridService.getColumnGroupState(this.columnStateName));
      setTimeout(() => {
        this.columnApi.autoSizeAllColumns();
        this.columnApi.getAllDisplayedVirtualColumns();
      }, 1000);
    }, 1543);
  }


  //  Description: On Grid Column Group Opened Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onColumnGroupOpened(event) {
    this.setColumnSize(this.columnApi, this.gridApi);
  }

  //  Description: On Grid Selection changed Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onSelectionChanged() {
    this.selectedRow = this.gridApi.getSelectedRows()[0];
    this.selectionChangedEvent.emit(this.selectedRow);
  }

  //  Description: On Grid Cell Value Changed
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onCellValueChanged(event: CellValueChangedEvent) {
    if (event.data) event.data.haveChanges = true;
  }

  //#endregion

  //#region Methods

  //  Description: Set Detail Grid Options
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  setDetailCellRendererParams() {
    if (!this.detailColumnDefs) {
      this.detailCellRendererParams = null;
      return;
    }

    this.detailCellRendererParams = {
      detailGridOptions: {
        rowSelection: 'multiple',
        rowHeight: 125,
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        columnDefs: this.detailColumnDefs,
        defaultColDef: {
          flex: 1,
          editable: true,
          filter: 'agSetColumnFilter'
        },
        onGridReady: (event) => {
          this.detailGridApi = event.api;
          this.exportExcel.setDetailInformation(this.detailGridApi, null);
          this.detailColumnApi = event.columnApi;
          this.setColumnSize(this.detailColumnApi, this.detailGridApi);
          this.statusBarDetailComponent = this.detailGridApi.getStatusPanel('statusPanel') as any;
          this.detailCellRendererParams.detailGridOptions.columnDefs = this.detailColumnDefs;

        },
        onSelectionChanged: (event) => {
          this.detailSelectionChangedEvent.emit(this.detailGridApi.getSelectedRows());
          this.statusBarDetailComponent.setSelectionCount(this.detailGridApi.getSelectedRows()?.length);
        },
        onCellValueChanged: (event) => {
          event.data.haveChanges = true;
        },
        masterDetail: true,
        detailRowAutoHeight: true,
        statusBar: this.statusBar,
        context: { componentParent: this },
        components: this.detailFrameworkComponents
      },
      getDetailRowData: (params) => {
        const node = this.gridApi.getRowNode(params.node.parent.id);
        node.setSelected(true, true);
        params.successCallback(params.data.children);
      }
    } as IDetailCellRendererParams;
  }

  //  Description: Set Columns Size
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  setColumnSize(columnApi: ColumnApi, gridApi: GridApi) {
    if (this.columnSizeMode === 'autoSize') {
      this.autoSizeAllColumns(columnApi);
    }
    else if (this.columnSizeMode === 'fit') {
      this.fitColumns(gridApi);
    }
  }

  //  Description: AutoSize All Grid Columns
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  autoSizeAllColumns(columnApi: ColumnApi) {
    columnApi?.autoSizeColumns(columnApi?.getColumns()?.map(col => col.getId()), false);
  }

  //  Description: Fit Grid Columns
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  fitColumns(gridApi: GridApi) {
    gridApi?.sizeColumnsToFit();
  }

  //  Description: Apply quick Filters
  //  Author: Juan Carlos Arce
  //  Creation date: 20/Apr/2023    
  //  <history>
  //     <update> H00 - RD-118
  //              Description: Creation.
  //     </update>
  // </history>
  applyQuickFilter(params: IQuickFilter) {
    const field = params.field;
    const filterInstance = this.gridApi.getFilterInstance(field);
    const checked = params.filters.filter(x => x.checked);
    filterInstance.setModel({ values: checked.length > 0 ? checked.map(x => x.value) : null });
    this.gridApi.onFilterChanged();
  }

  getSummaryHeader(index: number) {
    return `Summary ${index + 1}`;
  }

  //#endregion

  //#region Components Events

  //  Description: Multiselection Component Changed Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  multiSelectionChanged({ field }: any, value: any[], data: any, node: any) {
    data[field] = value;
    data.haveChanges = true;
  }

  //  Description: Numeric Component Changed Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  numericChanged({ field }: any, value: any[], data: any, node: any) {
    data[field] = value;
    data.haveChanges = true;
    if (this.selectedRow) this.selectedRow.haveChanges = true;
  }

  //  Description: Search Component Changed Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  searchChanged(params) {
    if (this.selectedRow) this.selectedRow.haveChanges = true;
    this.searchCellChanged.emit(params);
  }

  //  Description: DropDown Component Change Event
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-54 - 17/Nov/2022 - Juan C. Arce
  //              Description: XNumber handeling.
  //     </update>
  //     <update> H02 - RD-82 - 18/Jan/2023 - Yun, George & Tomas
  //              Description: Change params value to params.selectedItem.code
  //     </update>
  // </history>
  dropdownChanged(params: any) {

    if (this.selectedRow && params.isEvent) this.selectedRow.haveChanges = true;
    if (params.link == 'listBox') {
      const instances = this.gridApi.getCellRendererInstances(params.linkParams) as any[];
      instances.forEach((instance) => {
        instance.setListValues(params.selectedItem.code);
      });
    }
    if (params.link == 'x_number') {
      this.dropDownSelectionChangedEvent.emit(params);
    }
  }

  //  Description: Group DropDown Component Change Event
  //  Author: Juan Carlos Arce
  //  Creation date: 05/May/2023    
  //  <history>
  //     <update> H00 - RD-141
  //              Description: Creation.
  //     </update>
  // </history>
  groupDropdownChanged(params: any) {
    params.data.haveChanges = true;
    if (this.selectedRow) this.selectedRow.haveChanges = true;
    this.gridApi.setRowNodeExpanded(params.node, false);

    setTimeout(() => {
      this.gridApi.setRowNodeExpanded(params.node, true);
    }, 100);
  }

  //  Description: ListBox Component Change Event
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  lisboxChanged(params: any) {
    if (params.isEvent) {
      params.data.haveChanges = true;
      if (this.selectedRow) this.selectedRow.haveChanges = true;
    }
    if (params.link) {
      const instances = params.api?.getCellRendererInstances(params.linkParams) as any[];
      instances?.forEach((instance) => {
        instance.getDropDownCombinationByField(params.value);
      });
    }
  }

  datePickerChanged({ field }: any, value: any, data: any, node: any, view: string) {
    if (view == 'year')
      data[field] = value?.getFullYear();
    else
      data[field] = value;
    data.haveChanges = true;
  }

  checkBoxChanged(params: any) {
    if (this.selectedRow) this.selectedRow.haveChanges = true;
    if (params.colDef.field == 'selected')
      this.selectionChangedEvent.emit(this.selectedRow);
    if (params.requireConfirmation)
      this.gridApi.redrawRows(params.node);
  }

  textBoxChanged(params: any) {
    if (this.selectedRow) this.selectedRow.haveChanges = true;
    this.textBoxChangedEvent.emit(params);
  }

  //  Description: Accordion Toogle Event
  //  Author: Juan Carlos Arce
  //  Creation date: 25/Nov/2022    
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  resetRowHeights(params: any) {
    let val = this.defaultHeight;
    const height = 300;
    if (params.confirmed) val += height;
    if (params.proposed) val += height;
    params.node.setRowHeight(val);
    params.api.onRowHeightChanged();
  }

  //  Description: Add Embeded Row Event
  //  Author: Juan Carlos Arce
  //  Creation date: 25/Nov/2022
  //  <history>
  //     <update> H00 - RD-57
  //              Description: Creation.
  //     </update>
  // </history>
  AddOnInnerGrid(params: any) {
    this.addInnerGridEvent.emit(params);
  }

  DeleteOnInnerGrid(params: any) {
    this.deleteInnerGridEvent.emit(params);
  }

  groupCellToogle(params: any) {
    params.data.expanded = params.value;
    //Makes the InnerGrid Visible
    const instances = params.api.getCellRendererInstances(params.linkParams) as any[];
    instances.forEach((instance) => {
      instance.setVisible(params.value);
    });


    // Sets the Column Width 
    let canCollapse = true;
    if (!params.value) {
      canCollapse = this.rowData.filter(x => x.expanded == true).length == 0;
    }
    let col = params.columnApi.getColumn(params.destination);
    const width = !params.value && canCollapse ? 50 : params.innerGridWidth;
    col.minWidth = width;
    col.maxWidth = width;
    params.columnApi.setColumnWidth(params.destination, width);

    //Set the Row Height
    if (!this.selectedRow) this.selectedRow = params.node.data;
    const height = params.value ? 410 : this.defaultHeight;
    params.node.setRowHeight(height);
    params.api.onRowHeightChanged();
  }



  //  Description: Add Clicked Event
  //  Author: Juan Carlos Arce
  //  Creation date: 14/Oct/2022
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  AddDetailClicked() {
    const AddRowIndex = -Math.abs(this.detailGridApi.getDisplayedRowCount() + 1);
    this.AddDetailEvent.emit({ index: AddRowIndex, parent: this.gridApi.getSelectedRows()[0] });
  }


  exportToExcel() {

    this.ExportDetailEvent.emit({parent: this.gridApi.getSelectedRows()[0] });
  }

  //  Description: Delete Clicked Event
  //  Author: Juan Carlos Arce
  //  Creation date: 14/Oct/2022
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  DetailDeleteClicked() {
    const detailSelectedRows = this.detailGridApi.getSelectedRows();
    this.DeleteDetailEvent.emit({ detail: detailSelectedRows, parent: this.gridApi.getSelectedRows()[0] });
  }

  //  Description: on Pop Up Show Standard Samples
  //  Author: Team Tucanes
  //  Date: 08/Feb/2023
  //  <history>
  //     <update> H01 - RD-52 - 08/Feb/2023 - Reinier Rodriguez V
  //              Description: Refactor onPopupShow method to simplify logic
  //     </update>
  //     <update> H02 - RD-102 - 08/Mar/2023 - Karina Villalobos S
  //              Description: Refactor onPopupShow method to simplify logic
  //     </update>
  //     <update> H03 - RD-161 - 09/Jun/2023 - Karina Villalobos S
  //              Description: The number cover was removed and a string was validated in each component and then those that occupied int were validated as in standard_samples and radiolabeled_samples
  //     </update>
  // </history>
  onPopUpShow(params: any) {
    this.innerHTMLs = [];
    this.popupComponent = params.field;
    this.popupReadOnly = params.readOnly;
    this.draggable = params.draggable;

    if (this.popupComponent != "sectionHTML")
      this.popupInputParam = isNaN(params.data)? params.data: Number(params.data.toString().replace("X", ""));//Ref H03

    if (this.popupComponent == "sectionHTML") {//Ref H02
      this.SectionVisible = params.data.length > 0;
      if (this.SectionVisible) {
        params.data.forEach(item => {
          this.glpStudySpecificsService.getStudySection(Number(item)).subscribe((result: any) => {
            if (result.value != "")
              this.innerHTMLs.push(this.sanitizer.bypassSecurityTrustHtml(result.value));
          });
        });
      }
    }
    else {
      this.overlayPanel.toggle(params.event);
    }
  }

  /// <summary>
  /// Description: Get File
  /// Author: Tomas Samuels Brenes.
  /// Date: 13/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-111
  ///              Description: Creation
  ///     </update>
  /// </history>
  getFile(file: string): void {
    if (this.selectedRow) {
      this.selectedRow.haveChanges = true;
      this.selectedRow.fileChanges = true;
      this.selectedRow.file = file;
      this.fileChange.emit(file);
    }
  }

  // <summary>
  //  Description: get Notes
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-119
  //              Description: Creation.
  //     </update>
  // </history>
  getNotes(notes: string): void {
    if (this.selectedRow) {
      this.selectedRow.haveChanges = true;
      this.selectedRow.notesChanges = true;
      this.selectedRow.notes = notes;
      this.notesChange.emit(notes);
    }
  }

  // <summary>
  //  Description: on button Cliked
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-119
  //              Description: Creation.
  //     </update>
  // </history>
  onbuttonCliked(params) {
    this.buttonInputParams = params;
    this.buttonCliked = true;
  }

  // <summary>
  //  Description: display Change
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-119
  //              Description: Creation.
  //     </update>
  // </history>
  displayChange(event) {
    this.buttonCliked = event;
  }
  //#endregion
}
