
//  Description: Probe / Proposed Service
//  Author: Juan Carlos Arce
//  Date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
// </history>

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IProbeProposed } from '../../models/probe-proposed.model';
import { settings } from 'src/settings/settings';
import { map, take } from 'rxjs/operators';
import { ParOptionListService } from '../../providers/par-option-list.service';
import { formatDate } from '@angular/common';
import { IResponse } from '../../models/response.model';
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';

@Injectable({
  providedIn: 'root'
})
export class ProbeProposedService {

  confirmedYes: any = null;
  private _rowColor: boolean;
  private rowColorChange$ = new Subject<boolean>();
  public rowColor$ = this.rowColorChange$.asObservable();
  headers = new HttpHeaders({
    'x-rapidapi-host': 'random-facts2.p.rapidapi.com',
    'x-rapidapi-key': 'your-api-key'
  });

  constructor(@Inject(LOCALE_ID) public locale: string,
              private http: HttpClient,
              private parService: ParOptionListService,
              private gridService: GridService,
              private dropdownValueService: DropdownValueService){

    }

    get getRowColor() {
      return this._rowColor;
    }
    set RowColor(val: boolean) {
      this._rowColor = val;
      this.rowColorChange$.next(val);
    }
  //#region API Calls

  //  Description: Get Metabolite Identification Proposed Data
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  getMetaboliteIdentificationProposed(materialId: number, status?: string): Observable<IProbeProposed[]> {
    let query = settings.ServiceUrl + 'api/metabolitesproposed/params?MaterialIdCore=' + materialId;
    if (status) query += '&Status=' + status;
    return this.http.get(query).pipe(map(response => this.transformAPIData(response)));
  }

  //  Description:
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  getDropDownMetaboliteIdentificationProposed(): Observable<any> {
    const query = settings.ServiceUrl + 'api/degradation/proposedconfirmed';
    return this.http.get(query);
  }

  //  Description: Update Metabolite Identification Proposed Data
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  updateMetaboliteIdentificationProposed(data: IProbeProposed): Observable<any> {
    const query = settings.ServiceUrl + 'api/metaboliteproposed' + data.pk;
    return this.http.put(query, data);
  }

  //  Description: Create Metabolite Identification Proposed Data
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  saveMetaboliteIdentificationProposed(data: IProbeProposed[]): Observable<any> {
    const query = settings.ServiceUrl + 'api/metaboliteproposed';
    return this.http.post(query, data);
  }

  //  Description: Delete Metabolite Identification Proposed Data
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  deleteMetaboliteIdentificationProposed(id: number): Observable<any> {
    const query = settings.ServiceUrl + 'api/metaboliteproposed/' + id;
    return this.http.delete(query);
  }

  //  Description: Get Probe Proposed Columns Def
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  getProbeProposedColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/proposed/ProbeProposedColumnsDef.json")
      .pipe(map((colDef: any) => {
        return colDef.map(item => {
          item.children?.map(child => {
            if (child.filter == "ListboxFilter") child.filter = ListboxFilter;
            if (child.filter == "DropdownFilter") child.filter = DropdownFilter;
          })
          return item;
        })
      }));
  }

  //  Description: Get Probe Proposed Detail Columns Def
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getDetailProbeProposedColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/proposed/ProbeProposedDetailColumnsDef.json")
      //Ref. H01      
      .pipe(map((colDef: any) => {
        return colDef.map(item => {
          if (item.filter == "ListboxFilter") item.filter = ListboxFilter;
          if (item.filter == "DropdownFilter") item.filter = DropdownFilter;
          return item;
        })
      }));
  }

  //  Description: Get Metabolite Confirmed Detail Columns Def
  //  Author: 
  //  Date: 
  //  <history>
  //     <update> H00 -
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getDetailProbeProposedReadOnlyColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/proposed/ProbeProposedDetailReadOnlyColumnsDef.json")
      //Ref. H01
      .pipe(map((colDef: any) => {
        return colDef.map(item => {
          if (item.filter == "ListboxFilter") item.filter = ListboxFilter;
          if (item.filter == "DropdownFilter") item.filter = DropdownFilter;
          return item;
        })
      }));
  }

  //#endregion


  //#region Methods

  //  Description: Update Probe Proposed Data
  //  Author: Juan Carlos Arce
  //  Date: 10/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-82
  //              Description: Added data transformation to send it to the API.
  //     </update>
  // </history>
  saveProbeProposedData(data: IProbeProposed[]) {
    return this.saveMetaboliteIdentificationProposed(data);
  }

  //  Description: Delete Probe Proposed Data
  //  Author: Juan Carlos Arce
  //  Date: 10/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  deleteProbeProposedData(data: IProbeProposed) {
    return this.deleteMetaboliteIdentificationProposed(data.pk)
  }

  //  Description: Transform Data from API
  //  Author: Juan Carlos Arce
  //  Date: 10/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  transformAPIData(data: IResponse): IProbeProposed[] {
    return data.value.map(item => {
      return this.transformItem(item)
    });
  }

  //  Description: Transform Item from API Data
  //  Author: Juan Carlos Arce
  //  Date: 10/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  transformItem(item: any): IProbeProposed {
    this.getParData(item);
    item.pk = item.metabolite_identification_proposed_pk;
    item.projectStage = this.getDropdownFieldAsMultiselect(item.metaboliteIdentificationProposedDetails, 1);
    item.experimentalData = this.getDropdownFieldAsMultiselect(item.metaboliteIdentificationProposedDetails, 3);
    item.msInvestigator = this.getUsersAsMultiselect(item.metaboliteIdentificationProposedInvestigators, 'MS');
    item.pscInvestigator = this.getUsersAsMultiselect(item.metaboliteIdentificationProposedInvestigators, 'PSC');
    item.metaboliteRelevanceBiosystems = 'Expand';
    item.updated_date = this.dateFormat(item.updated_date.toString());
    item.children = this.transformAPIDataBioSystem(item.metaboliteRelevanceBiosystem);
    item.haveChanges = false;
    item.molecular_structure = item.molecular_structure ?? 'new';
    item.observation_date = item.observation_date == undefined ? undefined : new Date(item.observation_date);
    return item;
  }

  //  Description: Format Date data
  //  Author: Juan Carlos Arce
  //  Date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  dateFormat(date: string) {
    return formatDate(date, "yyyy-MM-dd", this.locale)
  }

  //  Description: Transform DropDown Field Data as Multiselect
  //  Author: Juan Carlos Arce
  //  Date: 10/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  getDropdownFieldAsMultiselect(data: any[], dropDownField: number) {
    let values = [];
    data
      .filter(detail => detail.dropdown_field_pk == dropDownField)
      .map(element => {
        values.push(element.dropdown_field_value_pk);
      });

    return values;
  }

  //  Description: Transform Users Data as Multiselect
  //  Author: Juan Carlos Arce
  //  Date: 10/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  getUsersAsMultiselect(data: any[], type: string) {
    let values = [];
    data
      .filter(detail => detail.investigator_type == type)
      .map(element => {
        values.push(element.users_pk);
      });
    return values;
  }

  //  Description: Transform Children Data
  //  Author: Juan Carlos Arce
  //  Date: 10/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  public transformAPIDataBioSystem(data: any[]) {
    let values = [];
    data.map(element => {
      let row = {
        PK: element.metaboliteRelevanceBiosystemPk,
        levelI: null,
        levelII: null,
        levelIII: null,
        comment: '', 
        relevance: null  ,
        haveChanges: false,
      };
      if(element.metaboliteRelevanceBiosystemDetail.length > 0){
        element.metaboliteRelevanceBiosystemDetail.map(({ listdropdownFieldValuePk, dropdownFieldCode, comment }) => {
          row.comment = comment ? comment : '';
          switch (dropdownFieldCode){
            case 'MERE': {
              row.relevance = listdropdownFieldValuePk;
            break; }
            case 'BMLI': {
              row.levelI = listdropdownFieldValuePk;
            break; }
            case 'BMLII': {
              row.levelII = listdropdownFieldValuePk;
            break; }
            case 'BMLIII': {
              row.levelIII = listdropdownFieldValuePk;
            break; }
          };
        });
        values.push(row);
      }
    });
    return values;
  }


  //  Description:
  //  Author:
  //  Date:
  //  <history>
  //     <update> H00 - RD-
  //              Description: Creation.
  //     </update>
  // </history>
  getParData(item: any) {
    item.isConfirmed = false;
    if (item.analytical_request_id_par) {
      this.parService.GetAnalysisByIdentifier(item.analytical_request_id_par).pipe(take(1)).subscribe(analysis => {
        item.analyticalPercComplete = analysis.percentage_complete;
        item.analyticalRequestStatus = analysis.status;
      });
    }

    if (item.synthesis_request_id_par) {
      this.parService.GetSynthesisByIdentifier(item.synthesis_request_id_par).pipe(take(1)).subscribe((synthesis) => {
        item.synthesisPercComplete = synthesis.percentage_complete;
        item.synthesisRequestStatus = synthesis.status;
        if (synthesis.need_by_date) item.synthesisNeedByDate = this.dateFormat(synthesis.need_by_date.toString());
        item.x_number = synthesis.x_number.replace('X', '');
        if(item.x_number != '' && item.confirmed_dropdown_field_value_pk === this.dropdownValueService.confirmedYesDDV?.dropdown_field_value_pk){ //CONFSTY
          item.isConfirmed = true;
          item.children?.map(child => child.isConfirmed = true);
        }
        this.gridService.setRedrawRows(true);
    });
    }
  }

  //#endregion


}
