import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements ICellRendererAngularComp {
  public params: any;
  public parent;
  public columnThatModifies;
  public visible: boolean = true;
  public readOnly: boolean = false;

  constructor(private validatorService: ValidatorService) { }

  agInit(params: any): void {
    this.params = params;
    this.visible = params.data.showButton;
    this.parent = params.context.componentParent;
    this.columnThatModifies = params.colDef;
    this.readOnly = this.validatorService.isComponentReadOnly(params);
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onClick(): void {
    let params = this.params;
    params.readOnly = this.readOnly;
    this.parent.onbuttonCliked(params);
  }

}
