
//  Description: Components Module
//  Author: Juan Carlos Arce
//  Creation date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
//     <update> H01 - RD-50 - Allam Barrera && Reinier Rodriguez V
//              Description: Add new Calendar component to imports.
//     </update>
// </history>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { AgGridModule } from "@ag-grid-community/angular";
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';


import { ProbeProposedComponent } from './probe-proposed/probe-proposed.component';
import { DegradationPathwayComponent } from './degradation-pathway/degradation-pathway.component';
import { ValueBuildCombinationsComponent } from './value-build-combinations/value-build-combinations.component';
import { ActiveIngredientSelectorComponent } from './shared/active-ingredient-selector/active-ingredient-selector.component';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from "primeng/toast";
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenubarModule } from 'primeng/menubar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { ToolbarModule } from 'primeng/toolbar';
import { BlockUIModule } from 'primeng/blockui';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar'; // Ref. H01
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { CardModule } from 'primeng/card';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';



import { GridComponent } from './shared/grid-components/grid/grid.component';
import { MultiSelectionComponent } from './shared/grid-components/multi-selection/multi-selection.component';
import { ListBoxSelectionComponent } from './shared/grid-components/list-box/list-box-selection.component';
import { StructureImageComponent } from './structure-image/structure-image.component';
import { NumericComponent } from './shared/grid-components/numeric/numeric.component';
import { SearchComponent } from './shared/grid-components/search/search.component';
import { DeleteButtonComponent } from './shared/grid-components/delete-button/delete-button.component';
import { ValueBuildsComponent } from './value-builds/value-builds.component';
import { DropDownListComponent } from './shared/grid-components/drop-down-list/drop-down-list.component';
import { StatusPanelComponent } from './shared/grid-components/status-panel/status-panel.component';
import { ActionButtonComponent } from './shared/action-button/action-button.component';
import { HeaderComponent } from './shared/header/header.component';
import { DataFieldSelectorComponent } from './shared/data-field-selector/data-field-selector.component';
import { GLPStudyDataComponent } from './glp-study-data/glp-study-data.component';
import { MetaboliteConfirmedComponent } from './metabolite-confirmed/metabolite-confirmed.component';
import { PopupButtonComponent } from './shared/grid-components/popup-button/popup-button.component';
import { StandardSamplesComponent } from './standard-samples/standard-samples.component';
import { GlpStudySpecificsComponent } from './glp-study-specifics/glp-study-specifics.component';
import { DatePickerComponent } from './shared/grid-components/date-picker/date-picker.component';
import { GlpStudySpecificsSearchComponent } from './glp-study-specifics/glp-study-specifics-search/glp-study-specifics-search.component';
import { MoleculeStructureComponent } from './molecule-structure/molecule-structure.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { AccordionComponent } from './shared/grid-components/accordion/accordion.component';
import { CheckboxComponent } from './shared/grid-components/checkbox/checkbox.component';
import { TextboxComponent } from './shared/grid-components/textbox/textbox.component';
import { GroupCellComponent } from './shared/grid-components/group-cell/group-cell.component';
import { InnerGridComponent } from './shared/grid-components/inner-grid/inner-grid.component';
import { GroupDropdownComponent } from './shared/grid-components/group-dropdown/group-dropdown.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { TextDropdownListComponent } from './shared/grid-components/text-dropdown-list/text-dropdown-list.component';
import { TextAreaComponent } from './shared/grid-components/text-area/text-area.component';
import { RadiolabeledSamplesComponent } from './radiolabeled-samples/radiolabeled-samples.component';
import { GroupTextboxComponent } from './shared/grid-components/group-textbox/group-textbox.component';
import { DegradationPathwayFilterComponent } from './degradation-pathway-filter/degradation-pathway-filter.component';
import { GlpStudySpecificsToxicologyComponent } from './glp-study-specifics/glp-study-specifics-toxicology/glp-study-specifics-toxicology.component';
import { GlpStudySpecificsResiduesComponent } from './glp-study-specifics/glp-study-specifics-residues/glp-study-specifics-residues.component';

import { ListboxFilter } from './shared/grid-components/list-box/listbox-filter/listbox-filter.component';
import { DropdownFilter } from './shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { MetaboliteSummaryComponent } from './metabolite-summary/metabolite-summary.component';
import { TooltipComponent } from './shared/grid-components/tooltip/tooltip.component';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ResidueDefinitionComponent } from './residue-definition/residue-definition.component';
import { DividerModule } from 'primeng/divider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TextEditorComponent } from './shared/text-editor/text-editor.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingComponent } from './shared/loading/loading.component';
import { ButtonComponent } from './shared/grid-components/button/button.component';
import { QuickFilterComponent } from './shared/grid-components/quick-filter/quick-filter.component';
import { UploaderComponent } from './shared/uploader/uploader.component';
import { AccessControlComponent } from './access-control/access-control.component';


ModuleRegistry.registerModules([
  ClientSideRowModelModule, ColumnsToolPanelModule, MasterDetailModule,
  RangeSelectionModule, MenuModule, StatusBarModule, SetFilterModule,
  ExcelExportModule,
]);

@NgModule({
  declarations: [
    ProbeProposedComponent,
    DegradationPathwayComponent,
    ValueBuildCombinationsComponent,
    ActiveIngredientSelectorComponent,
    GridComponent,
    MultiSelectionComponent,
    ListBoxSelectionComponent,
    StructureImageComponent,
    NumericComponent,
    SearchComponent,
    DeleteButtonComponent,
    ValueBuildsComponent,
    PopupButtonComponent,
    StatusPanelComponent,
    DropDownListComponent,
    ActionButtonComponent,
    HeaderComponent,
    DataFieldSelectorComponent,
    GLPStudyDataComponent,
    MetaboliteConfirmedComponent,
    StandardSamplesComponent,
    GlpStudySpecificsComponent,
    DatePickerComponent,
    GlpStudySpecificsSearchComponent,
    MoleculeStructureComponent,
    AccordionComponent,
    CheckboxComponent,
    TextboxComponent,
    GroupCellComponent,
    InnerGridComponent,
    GroupDropdownComponent,
    TextDropdownListComponent,
    TextAreaComponent,
    RadiolabeledSamplesComponent,
    GroupTextboxComponent,
    DegradationPathwayFilterComponent,
    GlpStudySpecificsToxicologyComponent,
    GlpStudySpecificsResiduesComponent,
    ListboxFilter,
    DropdownFilter,
    MetaboliteSummaryComponent,
    UploaderComponent,
    TooltipComponent,
    ResidueDefinitionComponent,
    ButtonComponent,
    LoadingComponent,
    TextEditorComponent,
    AccessControlComponent,
    QuickFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule,
    AgGridModule,
    ListboxModule,
    EditorModule,
    ButtonModule,
    RippleModule,
    ConfirmPopupModule,
    ToastModule,
    MultiSelectModule,
    InputNumberModule,
    MenubarModule,
    ScrollPanelModule,
    DropdownModule,
    AccordionModule,
    ToolbarModule,
    BlockUIModule,
    OverlayPanelModule,
    CalendarModule, //Ref. H01
    CheckboxModule,
    InputTextModule,
    NgxGraphModule,
    MatGridListModule,
    InputTextareaModule,
    InputTextareaModule,
    BrowserModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    SplitButtonModule,
    ProgressSpinnerModule,
    CardModule,
    ToggleButtonModule,
    FieldsetModule,
    TabViewModule
  ],
  exports: [
    ProbeProposedComponent,
    DegradationPathwayComponent,
    ValueBuildCombinationsComponent,
    MoleculeStructureComponent,
    UploaderComponent,
    TextEditorComponent,
    LoadingComponent,
    ListboxFilter,
    QuickFilterComponent
  ]
})
export class ComponentsModule { }
