
//  Description: Probe / Proposed Component
//  Author: Juan Carlos Arce
//  Creation date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
// </history>

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ColDef, ColGroupDef, ColumnApi } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProbeProposedService } from './probe-proposed.service';
import { IMetaboliteRelevanceBiosystems, IProbeProposed } from '../../models/probe-proposed.model';
import { ActiveIngredientService } from '../shared/active-ingredient-selector/active-ingredient.service';
import { ParOptionListService } from 'src/app/providers/par-option-list.service';
import { ValueBuildCombinationsService } from '../value-build-combinations/value-build-combinations.service';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IActiveIngredient } from '../../models/active-ingredient.model';
import { DropDownListService } from '../shared/grid-components/drop-down-list/drop-down-list.service';
import { UserService } from 'src/app/providers/user.service';
import { DropdownValueService } from 'src/app/providers/dropdown-value.service';
import { ExportExcel } from 'src/app/providers/export-excel.service';
@Component({
  selector: 'app-probe-proposed',
  templateUrl: './probe-proposed.component.html',
  styleUrls: ['./probe-proposed.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ProbeProposedComponent implements OnInit, OnDestroy, AfterViewInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentActiveIngredient: IActiveIngredient;

  public rowData: IProbeProposed[];
  public rowDataFiltered: any[];
  public rowSelection = 'single';
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];
  public projectStateOptions = [];
  public selectedRow: IProbeProposed | null;
  public selectedDetailRows: IMetaboliteRelevanceBiosystems[];
  public deleteVisible: boolean = false;
  public isExcelLoading: boolean = false;
  public IsProposed: boolean = true;
  public data: IProbeProposed[];
  private moduleCode = 'PROP';

  constructor(private probeProposedService: ProbeProposedService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public AiService: ActiveIngredientService,
    private parService: ParOptionListService,
    private combinationService: ValueBuildCombinationsService,
    private gridService: GridService,
    private actionButtonService: ActionButtonService,
    private dropdownValueService: DropdownValueService,
    private userService: UserService, private exportExcel: ExportExcel) { }
    public module = "proposed";
  private columnDetailStateName = "metaboliterelevancebiosystem"

  //  Description: Probe Proposed Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-42 - 14/Oct/2022 - Juan Carlos Arce
  //              Description: Implement Get Probe Proposed API Call.
  //     </update>
  //     <update> H02 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Use actionButtonService
  //     </update>
  // </history>
  ngOnInit(): void {

    this.actionButtonService.UseEditMode = false; //Ref. H02
    this.actionButtonService.Editable = true; //Ref. H02
    this.probeProposedService.getProbeProposedColumsDefs().pipe(take(1)).subscribe(ColDefs => {
      this.columnDefs = ColDefs;
    });

    this.probeProposedService.getDetailProbeProposedColumsDefs().pipe(take(1)).subscribe(DetailColDefs => {
      this.detailColumnDefs = DetailColDefs ?? null;
    });


    if (this.AiService.SelectedActiveIngredient) {
      this.getProbeProposed(this.AiService.SelectedActiveIngredient.material_id);
    }

    this.AiService.selectedActiveIngredient$.pipe(takeUntil(this.destroy$)).subscribe(async selectedActiveIngredient => {
      if (selectedActiveIngredient && selectedActiveIngredient != this.currentActiveIngredient) {
        this.currentActiveIngredient = selectedActiveIngredient;
        await this.getProbeProposed(selectedActiveIngredient.material_id);
      }
    });

    this.combinationService.moduleCombinations = [];
    this.combinationService.getCombinationsByModuleCode(this.moduleCode).pipe(take(1)).subscribe(proposedCombinations => {
      proposedCombinations?.map(element => {
        element.moduleCode = this.moduleCode;
        this.combinationService.moduleCombinations.push(element);
      });
    });
    this.selectedRow = null;
    this.selectedDetailRows = [];
  }

  /// <summary>
  //    Description: Probe Proposed Component Init
  //    Author: Juan Carlos Arce
  //    Date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /// <summary>
  //    Description: Probe Proposed Component After Init
  //    Author: Juan Carlos Arce
  //    Date: 23/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngAfterViewInit(): void {
    //Ref. H03
    if (this.AiService.SelectedActiveIngredient) {
      const temp = this.AiService.SelectedActiveIngredient;
      setTimeout(() => {
        this.AiService.SelectedActiveIngredient = temp;
      }, 750);
    }
  }

  //#region Methods

  //  Description: Delete Confirmation
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      },
      reject: () => {
        callback(false);
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }
  //#endregion

  //#region Events

  //  Description: Seach Cell Change Event
  //  Author: Juan Carlos Arce
  //  Date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-107 - 01/Mar/2023 - Juan C Arce
  //              Description: Update paramters to use Destructuration and change SelectedValue to Value
  //     </update>
  // </history>
  onSearchCellChanged({ field, value, node }) {
    let row = node.data;
    if (field == "synthesis_request_id_par") {
      this.parService.GetSynthesisByIdentifier(value).pipe(take(1)).subscribe(synthesis => {
        row.synthesisPercComplete = synthesis.percentage_complete;
        row.synthesisRequestStatus = synthesis.status;
        if (synthesis.need_by_date) row.synthesisNeedByDate = this.probeProposedService.dateFormat(synthesis.need_by_date.toString());
        row.x_number = synthesis.x_number;
        this.gridService.updateTransaction([row]);
      });
    }

    if (field == "analytical_request_id_par") {
      this.parService.GetAnalysisByIdentifier(value).pipe(take(1)).subscribe(analysis => {
        row.analyticalPercComplete = analysis.percentage_complete;
        row.analyticalRequestStatus = analysis.status;
        this.gridService.updateTransaction(this.rowData);
      });

    }
  }
  //  Description: On Selection Changed Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onSelectionChanged(event) {
    this.selectedRow = event as IProbeProposed;
    let tempData = this.rowData.filter(element => element.selected == true);
    if (tempData.length > 0)
      this.deleteVisible = true;
    else
      this.deleteVisible = false;
  }

  //  Description: On Detail Selection Changed Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onDetailSelectionChanged(event) {
    this.selectedDetailRows = event as IMetaboliteRelevanceBiosystems[];
  }

  //  Description: On Save Clicked Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onSaveClick(event) {
    this.saveProbeProposed(this.rowData);
  }

  //  Description: On Delete Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onDeleteProbePropose(event: Event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.deleteProbeProposed();
      }
    });
  }

  //  Description: On Delete Detail Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onDeleteDetail(event) {
    let deletePKs = event.detail.map(x => x.PK);
    event.parent.children = event.parent.children.filter(x => !deletePKs.includes(x.PK));
    event.parent.haveChanges = true;
    this.gridService.removeDetailTransaction(event.detail);
  }


  //  Description: On Add Detail Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onAddDetail(params) {
    let row = params.parent;
    if (!row.children) row.children = [];
    const newChildren = {
      PK: params.index,
      levelI: "",
      levelII: "",
      levelIII: "",
      comment: "",
      metaboliteRelevance: ""
    };
    row.children.unshift(newChildren);
    this.gridService.addDetailTransaction([newChildren]);
  }

  onExportDetail(params) {
    let row = params.parent;

    this.exportExcel.GetInfoToAssembleExcel(this.columnDetailStateName, row.metabolite_identification_proposed_pk, this.module).pipe(take(1)).subscribe(response => {
      this.exportExcel.exportTOExcel(this.columnDetailStateName, row.metabolite_identification_proposed_pk, this.module, response);

    });
  }


  //  Description: On Add Event
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  onAddProbePropose(event) {
    let newRow: IProbeProposed = {
      pk: -1, projectStage: [], proposedName: '', molecularWeight: '', proposedSmilesCode: '', proposedFormula: '', exact_mass: '',
      experimentalData: '', msInvestigator: [], pscInvestigator: [], synthesisRequestID: '', synthesisRequestStatus: '',
      synthesisPercComplete: 0, synthesisNeedByDate: undefined, analyticalRequestID: '', analyticalPercComplete: 0,
      analyticalRequestStatus: '', x_number: '', confirmedStatus: null, comments: '', enteredBy: '', dateUpdated: undefined,
      children: [], MetaboliteRelevanceBiosystems: [], haveChanges: true, confirmed_dropdown_field_value_pk: undefined, molecular_structure: 'new',
      observation_date: undefined
    };

    newRow['material_id_core'] = this.AiService.SelectedActiveIngredient.material_id;
    newRow['metaboliteRelevanceBiosystems'] = 'Expand';
    newRow['confirmed_dropdown_field_value_pk'] = null;

    this.rowData.unshift(newRow);
    this.gridService.addTransaction([newRow]);
  }
  //#endregion

  //#region ProbeProposed CRUD

  //  Description: Get Probe Proposed
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  async getProbeProposed(materialId: number) {
    await this.probeProposedService.getMetaboliteIdentificationProposed(materialId)
      .pipe(take(1)).subscribe(Data => {
        this.SetDataGridData(Data);
      });
  }

  //  Description: update Probe Proposed
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-113 - 20/Mar/2023 - Reinier Rodriguez V.
  //              Description: Fix condition to show/hide metabolites in confirmed section
  //     </update>
  // </history>
  saveProbeProposed(data: any[]) {
    const tempData = data.filter(element => element.haveChanges == true);
    if (tempData.length > 0) {
      tempData.forEach(proposedItem => {
        proposedItem.molecularWeight = proposedItem.molecularWeight == undefined ? undefined : String(proposedItem.molecularWeight);
        proposedItem.metaboliteIdentificationProposedDetails = [];
        proposedItem.metaboliteIdentificationProposedInvestigators = [];
        proposedItem.metaboliteRelevanceBiosystemDetails = [];

        if (proposedItem.projectStage)
          proposedItem.projectStage?.map(ps => {
            proposedItem.metaboliteIdentificationProposedDetails.unshift({ dropdown_field_pk: 1, dropdown_field_value_pk: ps });
          });

        if (proposedItem.experimentalData)
          proposedItem.experimentalData?.map(ed => {
            proposedItem.metaboliteIdentificationProposedDetails.unshift({ dropdown_field_pk: 3, dropdown_field_value_pk: ed });
          });

        if (proposedItem.msInvestigator)
          proposedItem.msInvestigator?.map(user => {
            proposedItem.metaboliteIdentificationProposedInvestigators.unshift({ users_pk: user, investigator_type: 'MS' });
          });

        if (proposedItem.pscInvestigator)
          proposedItem.pscInvestigator?.map(user => {
            proposedItem.metaboliteIdentificationProposedInvestigators.unshift({ users_pk: user, investigator_type: 'PSC' });
          });

        if (proposedItem.children)
          proposedItem.children?.map(child => {
            child.levelI = child.levelI ? child.levelI : [];
            child.levelII = child.levelII ? child.levelII : [];
            child.levelIII = child.levelIII ? child.levelIII : [];
            child.relevance = child.relevance ? child.relevance : [];
            child.comment = child.comment ? child.comment : "";
            child.OECDRelevancy = [];
            this.removeBiosystemProperties(child);
            proposedItem.metaboliteRelevanceBiosystemDetails.unshift(child);
          });

        proposedItem.x_number = proposedItem.x_number ? proposedItem.x_number : null;
        proposedItem.metabolite_identification_proposed_pk = proposedItem.pk;
        proposedItem.confirmed_dropdown_field_value_pk = proposedItem.confirmed_dropdown_field_value_pk ? Number(proposedItem.confirmed_dropdown_field_value_pk) : null;
        proposedItem.created_by = proposedItem.metabolite_identification_proposed_pk <= 0 ? this.userService.profile.displayName : proposedItem.created_by;
        proposedItem.created_date = proposedItem.metabolite_identification_proposed_pk <= 0 ? new Date() : proposedItem.created_date;
        proposedItem.updated_by = this.userService.profile.displayName;
        proposedItem.updated_date = new Date();

        this.removeProposedProperties(proposedItem);
      });

      this.probeProposedService.saveProbeProposedData(tempData).pipe(take(1)).subscribe(response => {
        if (response.value > 0) {
          this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Changes Saved' });
          this.getProbeProposed(this.AiService.SelectedActiveIngredient.material_id);
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error saving changes' });
      }, () => {
        this.actionButtonService.ResetHideButton = true;
      });
    }
  }

  removeBiosystemProperties(child: any) {
    delete child.PK;
    delete child.haveChanges;
  }

  removeProposedProperties(proposedItem: any) {
    delete proposedItem.pk;
    delete proposedItem.children;
    delete proposedItem.xNumber;
    delete proposedItem.projectStage;
    delete proposedItem.experimentalData;
    delete proposedItem.msInvestigator;
    delete proposedItem.pscInvestigator;
    delete proposedItem.metaboliteRelevances;
    delete proposedItem.MetaboliteRelevanceBiosystems;
    delete proposedItem.haveChanges;
    delete proposedItem.file;
    delete proposedItem.fileChanges;
  }

  //  Description: Delete Probe Proposed
  //  Author: Juan Carlos Arce
  //  Date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  ///     <update> H01 - RD-90 - 20/Feb/2023 - Karina Villalobo s
  ///         Description: Delete multiple selected rows.
  ///     </update>
  // </history>
  deleteProbeProposed() {
    //Ref. H01
    let tempData = this.rowData.filter(element => element.selected == true);
    tempData.forEach(element => {
      this.probeProposedService.deleteProbeProposedData(element).pipe(take(1)).subscribe(response => {
        const index: number = this.rowData.indexOf(this.selectedRow);
        let tempData = [...this.rowData];
        tempData.splice(index, 1);
        this.SetDataGridData(tempData);
        this.selectedRow = null;
      }, error => {

      }, () => {
        this.actionButtonService.ResetHideButton = true;
      });
    });
  }

  /// <summary>
  /// Description: Update proposed structure
  /// Author: Tomas Samuels Brenes.
  /// Date: 13/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-111
  ///              Description: Creation
  ///     </update>
  /// </history>
  updateProposedStructure(): void {
    this.rowData.forEach(r => {
      if (r.fileChanges) {
        r.molecular_structure = r.file;
        this.gridService.updateTransaction([r]);
      }
    });
  }
  //#endregion

  //  Description: On ShowHide Event
  //  Author: Reinier Rodriguez V
  //  Date: 05/Mar/2023
  //  <history>
  //     <update> H00 - RD-89
  //              Description: Creation.
  //     </update>
  // </history>
  onShowHideEventClicked(showHidden: boolean) {
    this.rowData = showHidden ? this.rowDataFiltered : this.data;
  }

  //  Description: Set Datagrid data.
  //  Author: Reinier Rodriguez V
  //  Date: 05/Mar/2023
  //  <history>
  //     <update> H00 - RD-89
  //              Description: Creation.
  //     </update>
  // </history>
  SetDataGridData(data: any) {
    this.data = data;
    this.rowDataFiltered = data.filter(x => x.x_number == '' || x.confirmed_dropdown_field_value_pk != this.dropdownValueService.confirmedYesDDV.dropdown_field_value_pk);
    this.rowData = this.data;
    this.probeProposedService.RowColor = true;
  }

  exportToExcel(event) {
   
    if (this.AiService.SelectedActiveIngredient != undefined) { 
      this.isExcelLoading = true;
      this.exportExcel.GetInfoToAssembleExcel(this.module, this.AiService.SelectedActiveIngredient.material_id, '').pipe(take(1)).subscribe(response => {
        let info =JSON.parse(JSON.stringify( response.value)); 
        info.forEach((element, index) => {
          element["proposed Structure"] = "";
        });
        this.exportExcel.exportTOExcelDataImage(this.module, this.AiService.SelectedActiveIngredient.material_id, response.value,info);
        this.isExcelLoading = false;
      });
    }
  }



}
