import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ColDef, ColGroupDef, ICellRendererParams, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { ListBoxSelectionComponent } from '../list-box/list-box-selection.component';
import { DropDownListComponent } from '../drop-down-list/drop-down-list.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { SearchComponent } from '../search/search.component';
import { NumericComponent } from '../numeric/numeric.component';
import { GroupDropdownComponent } from '../group-dropdown/group-dropdown.component';
import { GroupTextboxComponent } from '../group-textbox/group-textbox.component';
import { TextboxComponent } from '../textbox/textbox.component';
import { TextAreaComponent } from '../text-area/text-area.component';
import { GlpStudySpecificsService } from '../../../glp-study-specifics/glp-study-specifics.service';
import { GlpStudySpecificsToxicologyService } from '../../../glp-study-specifics/glp-study-specifics-toxicology/glp-study-specifics-toxicology.service';
import { GlpStudySpecificsResiduesService } from '../../../glp-study-specifics/glp-study-specifics-residues/glp-study-specifics-residues.service';

import { InnerGridService } from './inner-grid.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-inner-grid',
  templateUrl: './inner-grid.component.html',
  styleUrls: ['./inner-grid.component.scss']
})
export class InnerGridComponent implements ICellRendererAngularComp, OnDestroy  {

  public visible: boolean = false;
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public params: any;
  public value: Date;
  public context;
  public groupDisplayType = 'custom';
  public section;
  public dossierSection;
  public autoGroupColumnDef =
  {
    headerValueGetter: params => `${params.colDef.headerName}`,
    cellRendererParams: {
        suppressCount: true,
        checkbox: true,
    }};

  public parent;
  public columnThatModifies;
  public rowData;
  public selectedNode;
  public editable = true;
  private gridApi: GridApi;
  public defaultColDef: ColDef = {
    flex: 1,
    editable: true,
    filter: 'agSetColumnFilter'
  };
  
  public frameworkComponents: {
    listBoxselectionRenderer: typeof ListBoxSelectionComponent,
    dropdownRenderer: typeof DropDownListComponent,
    numericRender: typeof NumericComponent,
    checkBoxRenderer: typeof CheckboxComponent,
    searchRenderer: typeof SearchComponent,
    groupDropdownRenderer: typeof GroupDropdownComponent,
    groupTextboxRenderer : typeof GroupTextboxComponent,
    textBoxRenderer: typeof TextboxComponent,
    textAreaRenderer: typeof TextAreaComponent
  };
  
  DetailColumnsDefSubscription: Subscription;
  TransactionSubscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(private glpStudySpecificsService: GlpStudySpecificsService,
              private innerGridService: InnerGridService,
              private glpStudySpecificsToxicologyService: GlpStudySpecificsToxicologyService,
              private glpStudySpecificsResiduesService: GlpStudySpecificsResiduesService) { }

  //  Description: Class Init
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-78
  //              Description: Added conditional for Toxicology as it depends on another service.
  //     </update>
  // </history>
  agInit(params: any): void {
    this.frameworkComponents = {
      listBoxselectionRenderer: ListBoxSelectionComponent,
      dropdownRenderer: DropDownListComponent,
      checkBoxRenderer: CheckboxComponent,
      numericRender: NumericComponent,
      searchRenderer: SearchComponent,
      groupDropdownRenderer: GroupDropdownComponent,
      groupTextboxRenderer : GroupTextboxComponent,
      textBoxRenderer: TextboxComponent,
      textAreaRenderer: TextAreaComponent
    };
    this.params = params;
    this.parent = params.context.componentParent;
    this.columnThatModifies = params.colDef;
    this.rowData = this.params.data.children;
    this.visible = params.data.expanded;
    this.section = params.section;
    this.dossierSection = params.dossierSection;
    this.context = { componentParent: this.parent, data: this.params.data};
    this.editable = params.data.is_external == undefined ? true : !params.data.is_external;
    //REF H01
    if(this.section == "toxicology"){
      this.DetailColumnsDefSubscription = this.glpStudySpecificsToxicologyService.getGLPStudySpecificsDetailColumnsDef(this.section, this.editable,this.dossierSection)
      .pipe(take(1))
      .subscribe(DetailColDefs => {
        this.columnDefs = DetailColDefs;
      });      
    }
    if(this.section == "residues"){
      this.DetailColumnsDefSubscription = this.glpStudySpecificsResiduesService.getGLPStudySpecificsDetailColumnsDef(this.section, this.editable,this.dossierSection)
      .pipe(take(1))
      .subscribe(DetailColDefs => {
        this.columnDefs = DetailColDefs;
      });      
    }
    else{
      this.DetailColumnsDefSubscription = this.glpStudySpecificsService.getGLPStudySpecificsDetailColumnsDef(this.section, this.editable)
      .pipe(take(1))
      .subscribe(DetailColDefs => {
        this.columnDefs = DetailColDefs;
      });
    }
    
    this.subscriptions.push(this.DetailColumnsDefSubscription);

  }
  
  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  //  Description: Class Refresh
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
   }

  //  Description: On Add Click Event
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-78
  //              Description: Added conditional for Toxicology as its disabled until saved functionality is added.
  //     </update>
  // </history>
  public AddOnClick(event: Event): void {
      const params = {
      index: -Math.abs(this.gridApi.getDisplayedRowCount() + 1),
      parentNode: this.params.node,
      selectedNode: this.selectedNode?.selected ? this.selectedNode : undefined
    };
    this.parent.AddOnInnerGrid(params);
  }

  public IsButtonVisible(){
    if(this.params.node.data.is_external){
      if( this.section == "toxicology" ||  this.section == "residues" || this.section == "physicalChemical")
      return false
    };
    return true;
  }

  public DeleteOnClick(event: Event): void{
    //H01
    //TODO Remove When Save and Delete are worked for tox.
    const params = {
      parentNode: this.params.node,
      selectedNode: this.selectedNode
    };
    this.parent.DeleteOnInnerGrid(params);
  }

  //  Description: On Row Selected Event
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  onRowSelected(event) {
    if(event.node.selected) this.selectedNode = event.node;
  }
  //  Description: Set Visible Event
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  setVisible(visible: boolean)
  {
    this.visible = visible;
  }

  numericChanged(params)
  {
    this.parent.numericChanged(
      params
    );
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.TransactionSubscription = this.innerGridService.transaction$.subscribe( transaction => {
      this.gridApi.applyTransaction(transaction);
    });
    this.subscriptions.push(this.TransactionSubscription);
  }
}

