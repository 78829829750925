import { Component, Input,  Output, EventEmitter } from '@angular/core';
import { IFilterData, IFilter, IFilterDataDetail } from 'src/app/models/degradationPathwayDetail.model';

@Component({
  selector: 'app-degradation-pathway-filter',
  templateUrl: './degradation-pathway-filter.component.html',
  styleUrls: ['./degradation-pathway-filter.component.scss']
})
export class DegradationPathwayFilterComponent {
  @Input() filterData: IFilterDataDetail[];
  @Output() filterDataOutput = new EventEmitter<IFilterData>();

  public filterList: IFilter[];
  public emptyMessage: string;

  private result: IFilterData;

  constructor() {
    this.filterList = [];
    this.emptyMessage = "-";
  }

  ngOnChanges() {
    this.filterList.length = 0;
    if(this.filterData)
      this.getDataFromTheInput();
  }

  /// <summary>
  /// Description: Get data from the Input
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  getDataFromTheInput(): void {
    this.populateFilterList();
    this.showAllFilters();
    this.filter();
  }
  
  /// <summary>
  /// Description: Populate filter list
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  populateFilterList(): void{
    this.filterList = [...this.filterList,{ name: "Status", dataList: [], selectedValues: [], originName: 'status', height: '65px', filtered: false}];
    this.filterList = [...this.filterList,{ name: "Metabolite Relevance", dataList: [], selectedValues: [], originName: 'relevance', height: '65px', filtered: false}];
    this.filterList = [...this.filterList,{ name: "Biosystem Level I", dataList: [], selectedValues: [], originName: 'levelI', height: '65px', filtered: false}];
    this.filterList = [...this.filterList,{ name: "Biosystem Level II", dataList: [], selectedValues: [], originName: 'levelII', height: '65px', filtered: false}];
    this.filterList = [...this.filterList,{ name: "Biosystem Level III", dataList: [], selectedValues: [], originName: 'levelIII', height: '65px', filtered: false}];
    this.filterList = [...this.filterList,{ name: "Biosystem Level IV", dataList: [], selectedValues: [], originName: 'levelIV', height: '65px', filtered: false}];
    this.filterList = [...this.filterList,{ name: "Biosystem Level V", dataList: [], selectedValues: [], originName: 'levelV', height: '65px', filtered: false}];
  }
  
  /// <summary>
  /// Description: Show all filters
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  showAllFilters(): void{
    this.filterData.forEach((f: IFilterDataDetail) =>{
      this.addElementToShow(f);
    });
  }
  
  /// <summary>
  /// Description: On click filter
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  onClickFilter(event): void{
  const origin = event.option.origin;
  this.clearFilterDataList(origin);
  this.filter();
  }
  
  /// <summary>
  /// Description: Filter
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  filter(): void{
    this.filterData.filter(d =>this.itemIsIncludedInSelectedList(d)).forEach(f =>{
      this.addElementToShow(f);
      });
      this.removeNotUsedSelectedValues();
      if(this.filterList.find(f => f.originName == 'status').dataList.length == 0)
        this.showAllFilters();
      this.result = {onlyStatusSelected: this.getIfOnlyStatusIsSelected(), detail: this.filterData.filter(f =>this.itemIsIncludedInSelectedList(f) || this.filterList.find(f => f.originName == 'status').dataList.length == 0)};
      this.filterDataOutput.emit(this.result);
  }
  
  /// <summary>
  /// Description: Add element to show
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-117
  ///              Description: Relevance Multiselection
  ///     </update>
  /// </history>
  addElementToShow(f: IFilterDataDetail): void{
    this.addUniqueValuesInListboxElement(f.status, 'status');
    f.levelI.forEach( l =>{
      this.addUniqueValuesInListboxElement(l, 'levelI');
    });
    f.levelII.forEach( l =>{
      this.addUniqueValuesInListboxElement(l, 'levelII');
    });
    f.levelIII.forEach( l =>{
    this.addUniqueValuesInListboxElement(l, 'levelIII');
    });
    f.levelIV.forEach( l =>{
    this.addUniqueValuesInListboxElement(l, 'levelIV');
    });
    f.levelV.forEach( l =>{
    this.addUniqueValuesInListboxElement(l, 'levelV');
    });
    f.relevance.forEach( l =>{//ref H01
      this.addUniqueValuesInListboxElement(l, 'relevance');
    });

    this.iterateListboxLists();
    this.orderListAlphabetically();
  }

  /// <summary>
  /// Description: Add unique values in listbox element
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  addUniqueValuesInListboxElement(value: string, origin: string) : void{
    if(value === undefined)
      value = "";
    if (value.trim() !== "" && !this.filterList.find(f => f.originName == origin).dataList.some(s => s.value === value))
      this.filterList.find(f => f.originName == origin).dataList = [...this.filterList.find(f => f.originName == origin).dataList, { value: value, origin: origin}];
  }
  
  /// <summary>
  /// Description: Clear filter dataList
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  clearFilterDataList(excepList: string): void{
    for (let i = 0; i < this.filterList.length; i++) {
      if (this.filterList[i] && this.filterList[i].dataList && this.filterList[i].originName) {
        if(this.filterList[i].originName != excepList){
          this.filterList[i].dataList.length = 0;
          this.filterList[i].dataList = [...this.filterList[i].dataList];
        }
      }
    }
  }
  
  /// <summary>
  /// Description: Item is included in selected list
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-117
  ///              Description: Relevance Multiselection
  ///     </update>
  /// </history>
  itemIsIncludedInSelectedList(f: IFilterDataDetail): boolean{
    const selectedStatus: string[] = this.filterList.find(f => f.originName == 'status').selectedValues;
    const selectedRelevance: string[] = this.filterList.find(f => f.originName == 'relevance').selectedValues;
    const selectedLevelI: string[] = this.filterList.find(f => f.originName == 'levelI').selectedValues;
    const selectedLevelII: string[] = this.filterList.find(f => f.originName == 'levelII').selectedValues;
    const selectedLevelIII: string[] = this.filterList.find(f => f.originName == 'levelIII').selectedValues;
    const selectedLevelIV: string[] = this.filterList.find(f => f.originName == 'levelIV').selectedValues;
    const selectedLevelV: string[] = this.filterList.find(f => f.originName == 'levelV').selectedValues;

    return (selectedStatus.includes(f.status) || selectedStatus.length == 0) &&
    (this.includesSomeBiosystem(selectedRelevance,f.relevance) || selectedRelevance.length == 0) && //Ref H01
    (this.includesSomeBiosystem(selectedLevelI,f.levelI) || selectedLevelI.length == 0) &&
    (this.includesSomeBiosystem(selectedLevelII,f.levelII) || selectedLevelII.length == 0) &&
    (this.includesSomeBiosystem(selectedLevelIII,f.levelIII) || selectedLevelIII.length == 0) &&
    (this.includesSomeBiosystem(selectedLevelIV,f.levelIV) || selectedLevelIV.length == 0) &&
    (this.includesSomeBiosystem(selectedLevelV,f.levelV) || selectedLevelV.length == 0)
  }

  /// <summary>
  /// Description: order ListAlphabetically
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-145 - 12/May/2023 - Tomás Samuels
  ///              Description: Status specific order
  ///     </update>
  /// </history>
  orderListAlphabetically(): void{
    this.filterList.find(f => f.originName == 'status').dataList.sort((a,b) => {
      const order = ['Proposed Structure', 'Confirmed Structure', 'GLP Study'];
      return order.indexOf(a.value) - order.indexOf(b.value);
    });//Ref: H01
    this.filterList.find(f => f.originName == 'relevance').dataList.sort((a,b) => a.value > b.value ? 1 : -1);
    this.filterList.find(f => f.originName == 'levelI').dataList.sort((a,b) => a.value > b.value ? 1 : -1);
    this.filterList.find(f => f.originName == 'levelII').dataList.sort((a,b) => a.value > b.value ? 1 : -1);
    this.filterList.find(f => f.originName == 'levelIII').dataList.sort((a,b) => a.value > b.value ? 1 : -1);
    this.filterList.find(f => f.originName == 'levelIV').dataList.sort((a,b) => a.value > b.value ? 1 : -1);
    this.filterList.find(f => f.originName == 'levelV').dataList.sort((a,b) => a.value > b.value ? 1 : -1);
  }

  /// <summary>
  /// Description: Set listbox height
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  setListboxHeight(i: number): void{
    let listHeight = (this.filterList[i].dataList.length * 22) + 30;
    if(this.filterList[i].dataList.length == 0)
      listHeight = 65;
    if(this.filterList[i].dataList.length > 4)
      listHeight = 105;
    this.filterList[i].height = listHeight + 'px';
  }

  /// <summary>
  /// Description: Show or hide filter icon
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  showOrHideFilterIcon(i: number): void{
    this.filterList[i].filtered = this.filterList[i].selectedValues.length > 0;
  }

  /// <summary>
  /// Description: Iterate listbox lists
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  iterateListboxLists(): void{
    for (let i = 0; i < this.filterList.length; i++) {
     this.setListboxHeight(i);
     this.showOrHideFilterIcon(i);
    }
  }

  /// <summary>
  /// Description: Remove not used selected values
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  removeNotUsedSelectedValues(): void{
    for (let i = 0; i < this.filterList.length; i++) {
    const selectedValues = [...this.filterList[i].selectedValues];
    selectedValues.forEach(s => {
      if(!this.filterList[i].dataList.some(d => d.value === s)){
        const index = this.filterList[i].selectedValues.indexOf(s);
        if(index > -1) {
          this.filterList[i].selectedValues.splice(index, 1);
        }
      }
    });
  }
  }

  /// <summary>
  /// Description: Check if includes some biosystem
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  includesSomeBiosystem(selectedValues: string[], biosystemValues: string[]) {
    return biosystemValues.some((item) => selectedValues.includes(item));
  }

  /// <summary>
  /// Description: Get if only status is selected
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  ///     <update> H01 - RD-117
  ///              Description: Relevance Multiselection
  ///     </update>
  /// </history>
  getIfOnlyStatusIsSelected(): boolean{
    let statusSelected: boolean = false;
    let somethingElseSelected: boolean = false;
    let onlyStatusSelected: boolean = true;
    for (let i = 0; i < this.filterList.length; i++) {
      if(this.filterList[i].originName == 'status')
        statusSelected = this.filterList[i].selectedValues.length > 0;
      else
        if(this.filterList[i].selectedValues.length > 0)
          somethingElseSelected = true;
    }
    if((statusSelected && somethingElseSelected) || somethingElseSelected || !statusSelected) //Ref H01
      onlyStatusSelected = false;
    return onlyStatusSelected;
  }
}
