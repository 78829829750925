//  Description: Menu Service
//  Author: Juan Carlos Arce
//  Creation date: 09/Sep/2022    
//  <history>
//     <update> H00 - RD-23
//              Description: Creation.
//     </update>
// </history>
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuItem } from 'primeng/api';

@Injectable({
	providedIn: 'root'
})

export class MenuService {

	private items: MenuItem[];
	constructor(private http: HttpClient) { }

	//TODO
	getMenu(): Observable<any> {
		// const query = environment.GAP_Api_URL + "api/menu";    				
		// return this.http.get(query)
		// .pipe(map((data: any) => data.Value));

		return of([
			{ label: 'Home', routerLink: ['/home'] },
			{
				label: 'Administrator',
				items: [
					{ label: 'Value Builds', routerLink: ['/administrator/valueBuilds'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Value Builds' },
					{ label: 'Value Build Combinations', routerLink: ['/administrator/valueBuildCombinations'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Value Build Combinations' },
					{ label: 'Access Control', routerLink: ['/administrator/accessControl'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Access Control' }
				]
			},
			{
				label: 'Metabolite Identification',
				items: [
					{ label: 'Proposed Structure', routerLink: ['/probeProposed'], showDDL: true, showActionButtons: true, headerText: 'Metabolite Identification / Proposed' },
					{ label: 'Confirmed Structure', routerLink: ['/confirmed'], showDDL: true, showActionButtons: true, headerText: 'Metabolite Identification / Confirmed' },
				]
			},
			{ label: 'Degradation Pathway', routerLink: ['/DegradationPathway'], showDDL: true, showActionButtons: true, headerText: 'Degradation Pathway' },
			{
				label: 'GLP Study Specifics', 
				items: 
				[
					{ label: 'Physical & Chemical', routerLink: ['/physicalChemical'], showDDL: true, showActionButtons: true, headerText: 'GLP Study Specifics / Physical & Chemical' },
					{ label: 'Analytical Methods', routerLink: ['/analyticalMethods'], showDDL: true, showActionButtons: true, headerText: 'GLP Study Specifics / Analytical Methods' },
				    { label: 'Toxicology', routerLink: ['/toxicology'], showDDL: true, showActionButtons: true, headerText: 'GLP Study Specifics / Toxicology' },
					{ label: 'Metabolism', routerLink: ['/metabolism'], showDDL: true, showActionButtons: true, headerText: 'GLP Study Specifics / Metabolism' },
					{ label: 'Residues', routerLink: ['/residues'], showDDL: true, showActionButtons: true, headerText: 'GLP Study Specifics / Residues' },
					{ label: 'Environmental Fate', routerLink: ['/enviromentFate'], showDDL: true, showActionButtons: true, headerText: 'GLP Study Specifics / Environmental Fate' }
					
				]
			},
			{ label: 'Metabolite Summary', routerLink: ['/metaboliteSummary'], showDDL: true, showActionButtons: true, headerText: 'Metabolite Summary' },
			{ label: 'Residue Definition', routerLink: ['/residueDefinition'], showDDL: true, showActionButtons: true, headerText: 'Residue Definition' },
		]);
	}
}
