import { Component, OnDestroy } from '@angular/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { DropdownValueService } from '../../../../../providers/dropdown-value.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserService } from '../../../../../providers/user.service';
import { DropDownListService } from '../../drop-down-list/drop-down-list.service';

@Component({
  selector: 'app-listbox-filter',
  templateUrl: './listbox-filter.component.html',
  styleUrls: ['./listbox-filter.component.scss']
})
export class ListboxFilter implements IFilterAngularComp, OnDestroy  {

  params!: IFilterParams;
  validFilters = [];
  rowModel: any;
  rowNodes: any = null;
  source: string;
  selectAll: boolean;
  field: string;
  model: any;
  
  DropdownFieldValueServiceSubscription: Subscription;
  GetAllUserSubscription: Subscription;

  constructor(private dropDownListService: DropDownListService,
              private userService: UserService) { }

  //  Description: DropDown Filter AgInit
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  agInit(params): void {
    this.params = params;
    this.source = params.source;
    this.rowModel = params.rowModel as any;
    this.rowNodes = this.rowModel.rowsToDisplay;
    this.field = params.colDef.field;
    this.selectAll = true;
    this.getFilterValues();
  }

  
  //  Description: DropDown Filter Ng OnDestroy
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.DropdownFieldValueServiceSubscription?.unsubscribe();
    this.GetAllUserSubscription?.unsubscribe();
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void{
    this.getFilterValues();
  }
  //  Description: DropDown Filter GetFilterValues
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getFilterValues(){
    this.validFilters = [];
    if(this.source == 'dropdownvalue') this.getDropDownFieldValue();
    
    if(this.source == 'user'){
      this.rowNodes.map(rowNode => {
        rowNode.data[this.field]?.map(val => {
          this.getUsers(val);
        });
      });
    }
  }

  //  Description: DropDown Filter Get Dropdown Field Value
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getDropDownFieldValue() {
    this.dropDownListService.getDropDownListData(this.params.colDef.cellRendererParams).pipe(take(1)).subscribe(filters => {
      filters.map(val => {
        if(!this.validFilters.find(x=> x.value == val.value))
          this.validFilters.push(this.getDropDownFieldValueAsObject(val));
      });
      this.validFilters = this.validFilters.sort((a,b) => a.text > b.text ? 1 : -1);
      if(this.model)
      {
        this.validateModel();
      }
    });
  }

  //  Description: DropDown Filter get Users
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getUsers(value: number ) {
    this.GetAllUserSubscription = this.userService.GetByID(value).pipe(take(1)).subscribe(user => {
      if(!this.validFilters.find(x=> x.value == user.usersPk))
        this.validFilters.push(this.getUserListObject(user));
      this.validFilters = this.validFilters.sort((a,b) => a.text > b.text ? 1 : -1);
    });
  }

  //  Description: DropDown Filter Get User List Object
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getUserListObject(val) {
    return { value: val.usersPk, text: val.userName, checked: true };
  }

  //  Description: DropDown Filter Get DropdownField Value as Object
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getDropDownFieldValueAsObject(val) {
    return { value: val.value, text: val.text, checked: true };
  }

  //  Description: DropDown Filter Is Filter Active
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  isFilterActive(): boolean {
    return this.validFilters.filter(x => x.checked == false).length > 0;
  }

  //  Description: DropDown Filter Does Filter Pass
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let filterPass = false;
    params.data[this.field]?.forEach(val => {
      if(this.validFilters.filter(x => x.value == val && x.checked == true).length > 0 )
        filterPass = true;
    });
    return filterPass;

  }

  
  //  Description: DropDown Filter On New Rows Loaded
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  onNewRowsLoaded(): void
  {
    this.validFilters.map(x => x.checked = true);
    this.selectAll = true;
  }

  //  Description: DropDown Filter Get Model
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  getModel() {
  }

  //  Description: DropDown Filter Set Model
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  setModel(model: any) {
    this.model = model;
    if(this.validFilters.length > 0) this.validateModel();
  }

  validateModel(){
    if(this.model.values){
      this.validFilters.map( x => x.checked = false);
      this.validFilters.map( x => {
        if(this.model.values.includes(x.value)) x.checked = true; 
      });
    }else
    {
      this.validFilters.map(x=> x.checked = true);
    }
    this.onSelectionChanged();
  }

  //  Description: DropDown Filter On Selection Changed
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  onSelectionChanged() {
    if(this.validFilters.filter(x => x.checked == true).length < this.validFilters.length ) this.selectAll = false;
    if(this.validFilters.filter(x => x.checked == true).length == this.validFilters.length ) this.selectAll = true;
    this.params.filterChangedCallback();
  }

  //  Description: DropDown Filter Select All Check
  //  Author: Juan Carlos Arce
  //  Creation date: 27/Feb/2022    
  //  <history>
  //     <update> H00 - RD-76
  //              Description: Creation.
  //     </update>
  // </history>
  selectAllCheck()
  {
    this.validFilters.forEach(filter => filter.checked = this.selectAll);
    this.params.filterChangedCallback();
  }

}
