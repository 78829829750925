import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { settings } from 'src/settings/settings';
import { IDropDownValue } from '../models/dropdown-field.model';
import { IParam } from '../models/param.model';
import { IResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class DropdownValueService {

  public confirmedYesDDV: IDropDownValue;
  confirmedYesCode = 'CONFSTY';
  constructor(private http: HttpClient) {
    this.GetByParams([{ name: 'code', value: this.confirmedYesCode }]).toPromise().then(val => {
      this.confirmedYesDDV = val;
    });
  }


  // <sumary>
  // Description: Get All Dropdown Values
  // Author: Yun Wei
  // Date: 06/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  GetAll(): Observable<IDropDownValue[]> {
    const query = settings.ServiceUrl + 'api/dropdownvalues';
    return this.http.get(query)
      .pipe(map((response: IResponse) => response.value as IDropDownValue[]));
  }

  // <sumary>
  // Description: Get Dropdown Value By ID
  // Author: Yun Wei
  // Date: 06/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  GetByID(id: number): Observable<IDropDownValue> {
    const query = settings.ServiceUrl + 'api/dropdownvalue/' + id;
    return this.http.get(query)
      .pipe(map((response: IResponse) => response.value as IDropDownValue));
  }


  //  Description: Get DropDownValues by ID List
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  GetByBulkIDs(ids: number[]): Observable<IDropDownValue[]> {
    const query = settings.ServiceUrl + 'api/dropdownvalues/list?list=' + ids.toString();
    const body = JSON.stringify(ids);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.http.post(query, body, { headers })
      .pipe(map((response: IResponse) => response.value as IDropDownValue[]));
  }

  // <sumary>
  // Description: Get Dropdown Values By Parameters
  // Author: Yun Wei
  // Date: 06/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  GetByParams(params: IParam[]): Observable<any> {
    var urlParams: string = '';
    params.forEach(param => {
      if (urlParams != '') urlParams += '&';
      urlParams += param.name + '=' + param.value;
    });

    const query = settings.ServiceUrl + 'api/dropdownvalues/params/?' + urlParams;
    return this.http.get(query)
      .pipe(map((response: IResponse) => response.value));
  }
}
