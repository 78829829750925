import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActiveIngredientService } from '../shared/active-ingredient-selector/active-ingredient.service';
import { GlpStudySpecificsService } from "./glp-study-specifics.service";
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IGlpStudySpecifics, IGlpStudySpecificsDetail } from 'src/app/models/glp-study-specifics.model';
import { ActivatedRoute } from '@angular/router';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { IResponse } from '../../models/response.model';
import { InnerGridService } from '../shared/grid-components/inner-grid/inner-grid.service';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IActiveIngredient } from 'src/app/models/active-ingredient.model';


@Component({
  selector: 'app-glp-study-specifics',
  templateUrl: './glp-study-specifics.component.html',
  styleUrls: ['./glp-study-specifics.component.scss'],
  providers: [ConfirmationService, MessageService]

})


export class GlpStudySpecificsComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentActiveIngredient: IActiveIngredient;

  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public rowData: IGlpStudySpecifics[];
  private glpData: IResponse;
  public selectedRow: IGlpStudySpecifics | null;
  public selectedNode;
  public rowSelection = 'single';
  public studySection: any;
  public rowHeight: number = 150;
  public innerGridRowIndex = 0;
  public deleteVisible: boolean = false;

  constructor(private glpstudyspecificsservice: GlpStudySpecificsService,
    private messageService: MessageService,
    public AiService: ActiveIngredientService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private actionButtonService: ActionButtonService,
    private gridService: GridService,
    private innerGridService: InnerGridService) { }

  /// <summary>
  ///     Description: NgInit
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  ///              Description: Use actionButtonService
  ///     </update>
  ///     <update> H02 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  ///              Description: Validate if Active Ingredient is already selected
  ///     </update>
  ///     <update> H03 - RD-88 -22/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Subscriptions
  ///     </update>
  /// </history>
  ngOnInit(): void {
    this.actionButtonService.Editable = false; //Ref. H01
    this.actionButtonService.UseEditMode = true; //Ref. H01
    this.studySection = this.route.snapshot.url[0].path.toString();
    this.loadReadOnlyColumnDefs();

    //Ref. H02
    if (this.AiService.SelectedActiveIngredient) {
      this.getGLPStudySpecifics(this.AiService.SelectedActiveIngredient.material_id, this.studySection);
    }
    //Ref. H03
    this.AiService.selectedActiveIngredient$.pipe(takeUntil(this.destroy$)).subscribe(async selectedActiveIngredient => {
      if (selectedActiveIngredient && selectedActiveIngredient != this.currentActiveIngredient) {
        this.currentActiveIngredient = selectedActiveIngredient;
        await this.getGLPStudySpecifics(selectedActiveIngredient.material_id, this.studySection);
      }
    });
    this.selectedRow = null;
    //Ref. H03
    this.actionButtonService.editable$.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) this.reload(false);
        if (!value) this.reload(true);
      });
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.innerGridRowIndex = 0;
  }

  //  Description: On Selection Changed Event
  //  Author: Juan Carlos Arce
  //  Creation date: 02/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  // </history>
  onSelectionChanged(event) {
    this.selectedRow = event as IGlpStudySpecifics;
    let tempData = this.rowData.filter(element => element.selected == true);
    if (tempData.length > 0)
      this.deleteVisible = true;
    else
      this.deleteVisible = false;
  }

  //  Description: Load Read Only Columns Definition Method
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  loadReadOnlyColumnDefs() {
    this.glpstudyspecificsservice.getGLPStudySpecificsColumnsDef(this.studySection)
      .pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefs = ColDefs;
      });
  }

  //  Description: Load Editable Columns Definition Method
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  loadEditableColumnDefs() {
    this.glpstudyspecificsservice.getGLPStudySpecificsColumnsDef(this.studySection, true)
      .pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefs = ColDefs;
      });
  }

  reload(readyOnlyMode: boolean) {
    if (this.AiService.SelectedActiveIngredient) {
      this.rowData = undefined;
      if (this.glpData) {
        this.rowData = this.glpstudyspecificsservice?.transformAPIData(this.glpData, this.studySection);
        if (readyOnlyMode) this.loadReadOnlyColumnDefs();
        if (!readyOnlyMode) this.loadEditableColumnDefs();
      } else {
        this.getGLPStudySpecifics(this.AiService.SelectedActiveIngredient?.material_id, this.studySection).then(() => {
          if (readyOnlyMode) this.loadReadOnlyColumnDefs();
          if (!readyOnlyMode) this.loadEditableColumnDefs();
        });
      }
    }
  }

  /// <summary>
  ///     Description: Confirmation windonw
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  /// </history>
  deleteConfirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
      },
      reject: () => {
        callback(false);
      }
    });
  }

  cancelConfirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      header: 'Are you sure?',
      message: 'Are you sure you want to cancel the changes?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
      },
      reject: () => {
        callback(false);
      }
    });
  }

  /// <summary>
  ///     Description: Get GLPStudies
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  /// </history>
  async getGLPStudySpecifics(materialId: number, section: string) {
    if (!materialId) return;
    await this.glpstudyspecificsservice?.getGLPStudySpecificsDataByMaterialID(materialId, section)
      .pipe(take(1))
      .subscribe((result: IResponse) => {
        this.glpData = result;
        this.rowData = this.glpstudyspecificsservice?.transformAPIData(this.glpData, section);
      });
  }

  //  Description: On Add Inner Grid Event
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Add 3th level support and fix expand after update.
  //     </update>
  //     <update> H02 - RD-185 - 03/Nov/2023 - Reinier Rodriguez V.
  //              Description: - A) Add new section biosystem lvl 4 and 5.
  //                             B) Change parameteres to indicate last group row.
  //     </update>
  // </history>
  onAddInnerGrid(event) {
    const selectedNode = event.selectedNode;
    const data = event.parentNode.data;
    if (!data.children) data.children = [];
    let newChildren;
    if (selectedNode == undefined || selectedNode?.level == 0)
      newChildren = this.newChild();

    if (selectedNode?.level == 1)
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk);

    if (selectedNode?.level == 2 && selectedNode?.group == false)
      newChildren = this.newChild(selectedNode.data.biosystemLevel1DropdownFieldValuePk, selectedNode.data.biosystemLevel2DropdownFieldValuePk);

    if (selectedNode?.level == 2 && selectedNode?.group == true)
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel2DropdownFieldValuePk);

    //Ref. H01, H02
    if (selectedNode?.level == 3 && selectedNode?.group == true) {
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel2DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel3DropdownFieldValuePk);
    }
    //Ref. H02
    if (selectedNode?.level == 4 && selectedNode?.group == true) {
      newChildren = this.newChild(selectedNode.allLeafChildren[0].data.biosystemLevel1DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel2DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel3DropdownFieldValuePk, selectedNode.allLeafChildren[0].data.biosystemLevel4DropdownFieldValuePk);
    }    
    //Ref. H02
    if (selectedNode?.level == 5 && selectedNode?.group == false) {
      newChildren = this.newChild(selectedNode.data.biosystemLevel1DropdownFieldValuePk, selectedNode.data.biosystemLevel2DropdownFieldValuePk, selectedNode.data.biosystemLevel3DropdownFieldValuePk, selectedNode.data.biosystemLevel4DropdownFieldValuePk, selectedNode.data.biosystemLevel5DropdownFieldValuePk);
    }

    data.children.unshift(newChildren);
    data.haveChanges = true;

    this.innerGridService.addTransaction([newChildren]);

    //Ref. H01
    if (selectedNode?.level > 0) {
      this.innerGridService.updateTransaction(this.selectedRow.children);
      selectedNode.parent?.setExpanded(true);
      selectedNode.parent?.parent?.setExpanded(true);
      selectedNode.allLeafChildren?.forEach(node => {
        node.setExpanded(true);
      })
    }
  }

  onDeleteInnerGrid(event) {
    const selectedNode = event.selectedNode;
    const data = event.parentNode.data;
    let deleteRows = [];
    if (selectedNode.group) {
      event.selectedNode.allLeafChildren.map(x => {
        deleteRows.push(x.data);
      });
    }
    if (!selectedNode.group) deleteRows.push(selectedNode.data);
    data.children = data.children.filter(x => !deleteRows.includes(x));
    this.innerGridService.removeTransaction(deleteRows);
    data.haveChanges = true;
  }

  /// <summary>
  ///     Description: Add GLPSpecifics to gridview (Memory)
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-55 - Juan Arce - 16/Jan/2023
  ///         Description: Update method to call newMainRow.
  ///     </update>
  /// </history>
  onAddGLPStudySpecifics(event) {
    const newRow = this.newMainRow();
    if (this.rowData == undefined) this.rowData = [];
    this.rowData.unshift(newRow);
    this.gridService.addTransaction([newRow]);
  }


  //  Description: New Main Row Method
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  newMainRow(): IGlpStudySpecifics {
    return {
      material_id_core: this.AiService.SelectedActiveIngredient?.material_id,
      study_number: "",
      study_section: "",
      study_year: null,
      study_title: "",
      dossier_point_dropdown_field_value_pk: 0,
      pk: -Math.abs(this.rowData.length + 1),
      glp_study_specifics_pk: 0,
      children: [],
      listGLPStudySpecificsMetabolismDTO: [],
      listGLPStudySpecificsResiduesDTO: [],
      physChem_specific_data: [],
      enviromentFate_specific_data: [],
      study_status_dropdown_field_value_pk: 0,
      haveChanges: true,
      is_external: false,
      readOnly: false
    };
  }


  //  Description: New Level 0 Method
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Method Rename and refactor to handle childs from one method.
  //     </update>
  //     <update> H02 - RD-146 - 25/May/2023 - Tomas Samuels
  //              Description: Add Level3
  //     </update>
  //     <update> H03 - RD-185 - 03/Nov/2023 - Reinier Rodriguez V.
  //              Description: Add biosystem 4 and 5.
  //     </update>
  // </history>
  newChild(level1?: any, level2?: any, level3?: any, level4?: any, level5?: any, formulation?: string): IGlpStudySpecificsDetail { //ref: H02
    this.innerGridRowIndex++;
    return {
      pk: -Math.abs(this.innerGridRowIndex),
      biosystemLevel1DropdownFieldValuePk: level1 ? level1 : -Math.abs(this.innerGridRowIndex),
      biosystemLevel2DropdownFieldValuePk: level2 ? level2 : -Math.abs(this.innerGridRowIndex),
      biosystemLevel3DropdownFieldValuePk: level3 ? level3 : -Math.abs(this.innerGridRowIndex),//Ref: H02
      biosystemLevel4DropdownFieldValuePk: level4 ? level4 : -Math.abs(this.innerGridRowIndex),//Ref: H03
      biosystemLevel5DropdownFieldValuePk: level5 ? level5 : -Math.abs(this.innerGridRowIndex),//Ref: H03
      radioLabel : "",
      analyteMaterialId: undefined,
      analyteMaterialName: undefined,
      relevant: false,
      haveChanges: true,
      comments: "",


      //Metabolite
      glpStudySpecificsMetabolismPk: 0,
      trrPercentage: undefined,
      trrPpm: undefined,
      metabolitesRelevanceList: [],

      //Residues
      glpStudySpecificsResiduePk: 0,
      formulation: formulation ? formulation : String(-Math.abs(this.innerGridRowIndex)),
      samplingEvent: undefined,
      residueMin: 0,
      residueMax: 0,
      residueMedian: 0,
      sampleSize: 0,
      
      // physChem
      testphysChem: "",
      test_itemphysChem: "",
      concentration: "",
      temperature: "",
      organic_solvents: "",
      ph: "",
      resultsphysChem: "",
      unit: "",
      deviation: "",
      wavelength: ""
    };
  }

  /// <summary>
  ///     Description: method to confirm detele command
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  /// </history>
  onDeleteGLPStudySpecifics(event) {
    this.deleteConfirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.deleteGLPStudySpecifics();
      }
    });
  }


  /// <summary>
  ///     Description: Delete method
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-90 - 20/Feb/2023 - Karina Villalobo s
  ///         Description: Delete multiple selected rows.
  ///     </update>
  /// </history>
  deleteGLPStudySpecifics() {

    const colsDef = this.glpstudyspecificsservice.getGLPStudySpecificsColumnsDef(this.studySection, true).toPromise();
    colsDef.then(ColDefs => {
      this.columnDefs = ColDefs;
    });
    //Ref. H01
    let tempData = this.rowData.filter(element => element.selected == true);
    const last = tempData[tempData.length - 1];
    tempData.forEach(element => {

      if (element.pk < 0) {
        this.rowData = this.rowData.filter(x => x.pk != element.pk);
        const index: number = this.rowData.indexOf(element);
        let tmpData = [...this.rowData];
        tmpData.splice(index, 1);
        this.rowData = tmpData;
      }
      else {
        this.rowData = this.rowData.filter(x => x.glp_study_specifics_pk != element.glp_study_specifics_pk);

        this.glpstudyspecificsservice.deleteGLPStudySpecificsDataByID(element.glp_study_specifics_pk).pipe(take(1)).subscribe(response => {
          if (element.glp_study_specifics_pk == last.glp_study_specifics_pk) {
            this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
            this.selectedRow = null;
            this.getGLPStudySpecifics(this.AiService.SelectedActiveIngredient.material_id, this.studySection);
            this.endEditMode();
          }
        });
      }
    });

  }

  //  Description: On Cancel Button Event
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  onCancelClicked(event) {
    this.cancelConfirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.endEditMode();
      }
    });
  }

  /// <summary>
  ///     Description: Button action
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  /// </history>
  onSaveClick(event) {
    this.processDatarow(this.rowData)
  }

  processDatarow(data: IGlpStudySpecifics[]) {
    var materialID = this.AiService.SelectedActiveIngredient?.material_id;
    if (!materialID) {
      this.showNoChangesMessage();
      return;
    }
    const [tempDataSave, baseData] = data.reduce(
      ([tempDataSave, baseData], element) =>
        element.haveChanges ? [[...tempDataSave, element], baseData] : [tempDataSave, [...baseData, element]],
      [[], []]
    );
    tempDataSave.forEach(element => {
      element.dossier_point_dropdown_field_value_pk = Number(element.dossier_point_dropdown_field_value_pk);
      element.study_status_dropdown_field_value_pk = Number(element.study_status_dropdown_field_value_pk);
      element.material_id_core = materialID;
      element.study_section = this.studySection;
    });
    if (this.isStudyNumberAnnexPointAvailable(tempDataSave, baseData)) {
      if (tempDataSave.length > 0) {      
        this.saveGLPStudySpecifics(tempDataSave);        
      }

      if (tempDataSave.length == 0) {
        this.showNoChangesMessage();
      }
    };
  }

  showNoChangesMessage() {
    this.messageService.add({ severity: 'warn', summary: 'No Changes', detail: 'There are no changes to save.' });
  }

  //  Description: update Probe Proposed
  //  Author: Juan Carlos Arce
  //  Creation date: 14/Oct/2022    
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  saveGLPStudySpecifics(data: IGlpStudySpecifics[]) {
    this.glpstudyspecificsservice.saveGLPStudySpecificsData(data).pipe(take(1)).subscribe((response: IResponse) => {
      if (response.statusCode == undefined && response.message.includes('Error')) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while saving changes' });
        return;
      }
      this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Changes Saved' });
      this.getGLPStudySpecifics(this.AiService.SelectedActiveIngredient.material_id, this.studySection);
      this.endEditMode();
    });
  }

  endEditMode() {
    if (this.actionButtonService.UseEditMode) this.actionButtonService.Editable = false;
    this.innerGridRowIndex = 0;
  }
  isStudyNumberAnnexPointAvailable(tempDataSave: IGlpStudySpecifics[], baseData: IGlpStudySpecifics[]) {
    const duplicatesExist = tempDataSave.some(item => {
      return baseData.some(row => {
        return row.dossier_point_dropdown_field_value_pk == item.dossier_point_dropdown_field_value_pk && row.study_number == item.study_number;
      });
    });
    if (duplicatesExist) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Data duplicity detected.' });
    }
    return !duplicatesExist;
  }
}


