import { Inject, Component, OnInit } from '@angular/core';
import { ResidueSettings, RESIDUE_SETTINGS } from 'src/settings/residue-settings';
import { PrimeNGConfig } from 'primeng/api';
import { SettingsService } from './providers/settings.service';
import { UserService } from './providers/user.service';

export interface IAppComponent {
    username: string;
    year: number;
    version: string;
    isSelected(name: string): boolean;
    onRouterOutletActivate(component: any): void;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements IAppComponent, OnInit {
    public username: string;
    public year: number = new Date().getFullYear();
    public version: string;
    public environment: string;
    private _currentTab: string;

  //  Description: 
  //  Author: 
  //  Creation date: 
  //  <history>
  //     <update> H00 - RD-
  //              Description: Creation.
  //     </update>
  // </history>
    constructor(@Inject(RESIDUE_SETTINGS) private residueSettings: ResidueSettings,
                private primengConfig: PrimeNGConfig,
                public userService: UserService) {
        this.environment = this.residueSettings.environment;
    }

  //  Description: 
  //  Author: 
  //  Creation date: 
  //  <history>
  //     <update> H00 - RD-
  //              Description: Creation.
  //     </update>
  // </history>
    ngOnInit(): void {
      this.userService.initProfile();
      this.primengConfig.ripple = true;
    }

  //  Description: 
  //  Author: 
  //  Creation date: 
  //  <history>
  //     <update> H00 - RD-
  //              Description: Creation.
  //     </update>
  // </history>
    isSelected(name: string): boolean {
        return name === this._currentTab;
    }

  //  Description: 
  //  Author: 
  //  Creation date: 
  //  <history>
  //     <update> H00 - RD-
  //              Description: Creation.
  //     </update>
  // </history>
    onRouterOutletActivate(component: any): void {
        if (component == null)
            return;
        this._currentTab = component.tabName;
    }
}
