import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @Input() uploadedImg: string;
  @Input() readOnly: boolean = false;
  @Output() fileOutput = new EventEmitter<string>();

  public preview: string;
  public uploadButtonText: string;
  private selectedFile: File;

  constructor() { 
    this.preview = '';
    this.uploadButtonText = 'Upload';
  }

  ngOnInit(): void {
    if(this.uploadedImg != 'new'){
      this.preview = this.uploadedImg;
      this.uploadButtonText = 'Change';
    }
  }

  /// <summary>
  /// Description: Upload File
  /// Author: Tomas Samuels Brenes.
  /// Date: 13/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-111
  ///              Description: Creation
  ///     </update>
  /// </history>
  uploadFile(event: any): void {
    this.selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.fileOutput.emit(e.target.result);
      this.preview = e.target.result;
    };
    this.uploadButtonText = 'Change';
    reader.readAsDataURL(this.selectedFile);
  }

  /// <summary>
  /// Description: Delete File
  /// Author: Tomas Samuels Brenes.
  /// Date: 13/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-111
  ///              Description: Creation
  ///     </update>
  /// </history>
  deleteFile(): void {
    this.fileOutput.emit('new');
    this.preview = '';
    this.uploadButtonText = 'Upload';
  }
}
