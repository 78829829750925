//  Description: Constants Class
//  Author: Juan Carlos Arce
//  Creation date: 09/Sep/2022    
//  <history>
//     <update> H00 - RD-23
//              Description: Creation.
//     </update>
// </history>

export abstract class Constants  {
    public static messages = {
        rolesSystems: 'Get Roles Systems error',
        rolesUsers: 'Get User Systems error',
        profile: 'Get Profile error',
    };
    
    static CLIENT_ID: string = 'a379deae-82da-41cb-bc17-6bb30fae52a3';
    static TENANT_ID: string ='3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4';
    static GRAPH_ENDPOINT: string = 'https://graph.microsoft.com/v1.0';
};


export const Role = Object.freeze({
    Administrator: 'Administrator',
    SME: 'SME',
    Visitor: 'Visitor'
});

export const PrivilegeType = Object.freeze({
    full: 'Full',
    readOnly: 'ReadOnly',
});

export const PrivilegeModule = Object.freeze({
    Admin: 'Admin',
    RevertSignedOff: 'RevertSignedOff',
    Components: 'Components',
    Buttons: 'Buttons',
    InnerGrid: 'InnerGrid',
    Proposed: 'Proposed',
});

export const Privileges = Object.freeze({
    Administrator: {
        [PrivilegeModule.Admin]: PrivilegeType.full,
        [PrivilegeModule.RevertSignedOff]: PrivilegeType.full,
        [PrivilegeModule.Components]: PrivilegeType.full,
        [PrivilegeModule.Buttons]: PrivilegeType.full,
        [PrivilegeModule.InnerGrid]: PrivilegeType.full,
        [PrivilegeModule.Proposed]: PrivilegeType.full,
    },
    SME: {
        [PrivilegeModule.Admin]: PrivilegeType.readOnly,
        [PrivilegeModule.RevertSignedOff]: PrivilegeType.readOnly,
        [PrivilegeModule.Components]: PrivilegeType.full,
        [PrivilegeModule.Buttons]: PrivilegeType.full,
        [PrivilegeModule.InnerGrid]: PrivilegeType.full,
        [PrivilegeModule.Proposed]: PrivilegeType.readOnly,
    },
    Visitor: {
        [PrivilegeModule.Admin]: PrivilegeType.readOnly,
        [PrivilegeModule.RevertSignedOff]: PrivilegeType.readOnly,
        [PrivilegeModule.Components]: PrivilegeType.readOnly,
        [PrivilegeModule.Buttons]: PrivilegeType.readOnly,
        [PrivilegeModule.InnerGrid]: PrivilegeType.readOnly,
        [PrivilegeModule.Proposed]: PrivilegeType.readOnly,
    },
});

export const rowSelection = Object.freeze({
    single: 'single',
    multiple: 'multiple',
});

export const metaboliteField = Object.freeze({
    synthesisRequestIdPar: 'synthesis_request_id_par',
    analyticalRequestIdPar: 'analytical_request_id_par',
    xNumber: 'x_number',
});