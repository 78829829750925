import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { PrivilegeModule, PrivilegeType, Role } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor(private userService: UserService) { }

  
  /// <summary>
  ///     Description: Check if the component is readonly
  ///     Author: Tomas Samuels, Yun Wei, George Ulecia
  ///     Date: 29/Mar/2023
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-126
  ///         Description: Creation.
  ///     </update>
  /// </history>
  isComponentReadOnly(params: any) : boolean{
    const dataReadOnly = params.data ? params.data.readOnly ?? false : params.context.data.children[0].readOnly ?? false;
    const columDefReadOnly = params.readOnly ?? false;
    const forceReadOnly = params.forcedReadOnly;
    const isExternal = params.data ? params.data.is_external ?? false : params.context.data.children[0].is_external ?? false;
    const signedOff = params.data ? params.data.signOff ?? false : params.context.data.signOff ?? false;
    const isConfirmed = params.data ? params.data.isConfirmed ?? false : params.context.data.isConfirmed ?? false;
    const path = params.context.componentParent.path;
    const role = this.userService.profile.role.roleName;
    const isPrivilegeFUll = this.userService.isPrivilegeFull(PrivilegeModule.Components, path, isConfirmed);
    return (!isPrivilegeFUll || (signedOff && role == Role.SME) || (forceReadOnly || isExternal || (columDefReadOnly && dataReadOnly)));
  }

  isCheckBoxComponentDisabled(checked: boolean): boolean{
    const role = this.userService.profile.role.roleName;
    if(role != Role.Administrator && checked)
      return true;
    return  false;
  }
}
