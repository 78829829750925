
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { settings } from 'src/settings/settings';
import { ActionButtonService } from '../shared/action-button/action-button.service'
import { GridService } from '../shared/grid-components/grid/grid.service';
import { IGlpStudyData } from 'src/app/models/glp-study-data.model';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';

@Injectable({
  providedIn: 'root'
})
export class GlpStudyDataService {



  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    private actionButtonService: ActionButtonService, private gridService: GridService) {
  }

  /// <summary>
  //  Description: Filter and map rowData
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  filterAndMapRowData(rowData, dossierPoint: string) {
    return rowData.filter(row => row.dossierPoint.includes(dossierPoint));
  }

  /// <summary>
  //  Description: Get GLPStudy data by materialId
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  getGLPStudyDataByMaterialID(materialId: number, studySection: string, residueDefinitionPk?: number): Observable<any> {
    if (!materialId) return;
    let Section: any;
    if (studySection == "1" || studySection == "2" || studySection == "3" || studySection == "4" || studySection == "5" || studySection == "6") {
      Section = "toxicology";
    }
    else {
      Section = studySection;
    }
    let query = settings.ServiceUrl + `api/glpstudyspecifics/GetSummaryByParams?MaterialIdCore=`+materialId+`&Section=`+Section+`&residueDefinitionPk=`+residueDefinitionPk;
    return this.http.get(query).pipe(map((response: any) => {

      switch(studySection){
        case "1":
          return response.value.glpSumaryToxicologyList = this.filterAndMapRowData(response.value.glpSumaryToxicologyList, '5.2.');
        case "2":
          return response.value.glpSumaryToxicologyList = this.filterAndMapRowData(response.value.glpSumaryToxicologyList, '5.1.');
        case "3":
          return response.value.glpSumaryToxicologyList = this.filterAndMapRowData(response.value.glpSumaryToxicologyList, '5.4.');
        case "4":
          return response.value.glpSumaryToxicologyList = this.filterAndMapRowData(response.value.glpSumaryToxicologyList, '5.6.');
        case "5":
          return response.value.glpSumaryToxicologyList = this.filterAndMapRowData(response.value.glpSumaryToxicologyList, '5.3.');
        case "6":
          return response.value.glpSumaryToxicologyList = this.filterAndMapRowData(response.value.glpSumaryToxicologyList, '5.8');
        case "metabolism":
          return response.value.glpSumaryMetabolismList;
        case "residues":
          return response.value.glpSumaryResiduesList;
        case "enviromentFate":
          return response.value.glpSumaryEnviromentFateList
      }
    }));
  };

  /// <summary>
  //  Description: Get GLPStudy data columnsDef
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  getGLPStudyDataColumnsDef(studySection: string): Observable<any> {
    if (studySection == "1") {
      let colDefLocation = `../../../assets/column_defs/glp_study_data/` + "toxicology" + "Acute";
      colDefLocation += `ColumnsDef.json`;
      return this.http.get(colDefLocation).pipe(map((colDef: any) => {
        return colDef.map( item => {
          if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
          if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
          return item;
        })
      }));
    }
    else {
      if (studySection == "2") {
        let colDefLocation = `../../../assets/column_defs/glp_study_data/` + "toxicology" + "Adme";
        colDefLocation += `ColumnsDef.json`;
        return this.http.get(colDefLocation).pipe(map((colDef: any) => {
          return colDef.map( item => {
            if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
            if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
            return item;
          })
        }));
      }
      else {
        if (studySection == "3") {
          let colDefLocation = `../../../assets/column_defs/glp_study_data/` + "toxicology" + "Genotoxicology";
          colDefLocation += `ColumnsDef.json`;
          return this.http.get(colDefLocation).pipe(map((colDef: any) => {
            return colDef.map( item => {
              if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
              if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
              return item;
            })
          }));
        }
        else {
          if (studySection == "4") {
            let colDefLocation = `../../../assets/column_defs/glp_study_data/` + "toxicology" + "Reproductivity";
            colDefLocation += `ColumnsDef.json`;
            return this.http.get(colDefLocation).pipe(map((colDef: any) => {
              return colDef.map( item => {
                if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
                if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
                return item;
              })
            }));
          }
          else {
            if (studySection == "5") {
              let colDefLocation = `../../../assets/column_defs/glp_study_data/` + "toxicology" + "ShortTerm";
              colDefLocation += `ColumnsDef.json`;
              return this.http.get(colDefLocation).pipe(map((colDef: any) => {
                return colDef.map( item => {
                  if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
                  if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
                  return item;
                })
              }));
            }
            else {
              if (studySection == "6") {
                let colDefLocation = `../../../assets/column_defs/glp_study_data/` + "toxicology" + "Metabolite";
                colDefLocation += `ColumnsDef.json`;
                return this.http.get(colDefLocation).pipe(map((colDef: any) => {
                  return colDef.map( item => {
                    if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
                    if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
                    return item;
                  })
                }));
              }
              else {
                let colDefLocation = `../../../assets/column_defs/glp_study_data/` + studySection;
                colDefLocation += `ColumnsDef.json`;
                return this.http.get(colDefLocation).pipe(map((colDef: any) => {
                  return colDef.map( item => {
                    if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
                    if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
                    return item;
                  })
                }));
              }
            }
          }
        }
      }
    }
  }

  /// <summary>
  //  Description: Save GLPStudy specifics
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  saveGLPStudySpecifics(dataList: any,studySection: string,materialId:number) {
    const query = settings.ServiceUrl + 'api/GLPStudySpecificsSummarySave';
  let data:IGlpStudyData = {materialIdCore: 0, glpSumaryMetabolismList: [], glpSumaryResiduesList: [], glpSumaryEnviromentFateList: [], glpSumaryToxicologyList:[]}
  data.materialIdCore = materialId;
    switch(studySection){
      case "metabolism":
        data.glpSumaryMetabolismList = dataList;
        break;
      case "residues":
        data.glpSumaryResiduesList = dataList;
        break;
      case "enviromentFate":
        data.glpSumaryEnviromentFateList = dataList;
        break;
      default:
        data.glpSumaryToxicologyList = dataList;
        break;
    }
    return this.http.put(query, data);
  }

  // <summary>
  //  Description: get Glp Residue Definition Column Def
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  getGlpResidueDefinitionColumnDef(): Observable<any> {
    let colDefLocation = "../../../assets/column_defs/glp_study_data/glpResidueDefinition.json";
    return this.http.get(colDefLocation).pipe(map((colDef: any) => {return colDef}));
  }

  // <summary>
  //  Description: save Glp Residues
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  saveGlpResidues(dataList: any,studySection: string,materialId:number) {
    const query = settings.ServiceUrl + 'api/GlpResidueDefinitionSelectedStudySave';
  let data:IGlpStudyData = {materialIdCore: 0, glpSumaryMetabolismList: [], glpSumaryResiduesList: [], glpSumaryEnviromentFateList: [], glpSumaryToxicologyList:[]}
  data.materialIdCore = materialId;
    switch(studySection){
      case "metabolism":
        data.glpSumaryMetabolismList = dataList;
        break;
      case "residues":
        data.glpSumaryResiduesList = dataList;
        break;
      case "enviromentFate":
        data.glpSumaryEnviromentFateList = dataList;
        break;
      default:
        data.glpSumaryToxicologyList = dataList;
        break;
    }
    return this.http.put(query, data);
  }

}

