
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActiveIngredientService } from '../shared/active-ingredient-selector/active-ingredient.service';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IActiveIngredient } from '../../models/active-ingredient.model';
import { ResidueDefinitionService } from './residue-definition.service';
import { IResidueDefinition, IResidueDefinitionDetail } from 'src/app/models/residue-definition.model';
import { IQuickFilter } from 'src/app/models/quick-filter.model';
import { QuickFilterService } from '../shared/grid-components/quick-filter/quick-filter.service';
import { UserService } from 'src/app/providers/user.service';

@Component({
  selector: 'app-residue-definition',
  templateUrl: './residue-definition.component.html',
  styleUrls: ['./residue-definition.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class ResidueDefinitionComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentActiveIngredient: IActiveIngredient;

  public rowData: IResidueDefinition[];
  public rowSelection = 'single';
  public columnDefs: (ColDef | ColGroupDef)[] | any = [];
  public projectStateOptions = [];
  public selectedRow: IResidueDefinition | null;
  public deleteVisible: boolean = false;
  public quickFilters: IQuickFilter[] = [];
  public signedOffSelectedList: IResidueDefinition[] = [];
  public SectionVisible: boolean = false;
  public btnDialogClass: string =
    'p-button-secondary p-button-outlined p-button-sm mr-2';
  public btnDownloadIcon: string = 'pi pi-send';
  public reason: string = '';

  constructor(
    private residueDefinitionService: ResidueDefinitionService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public AiService: ActiveIngredientService,
    private gridService: GridService,
    private actionButtonService: ActionButtonService,
    private userService: UserService,
    private quickFilterService: QuickFilterService
  ) {}

  /// <summary>
  //  Description: Residue Definition Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  ngOnInit(): void {
    this.actionButtonService.UseEditMode = false;
    this.actionButtonService.Editable = true;
    this.columnDefs = [];
    this.residueDefinitionService
      .getColumsDefs()
      .pipe(take(1))
      .subscribe((ColDefs) => {
        this.columnDefs = ColDefs;
      });

    //Ref. H02
    if (this.AiService.SelectedActiveIngredient) {
      this.quickFilters = [];
      this.rowData = [];
      this.getResidueDefinition(
        this.AiService.SelectedActiveIngredient.material_id
      );
    }
    //Ref. H03
    this.AiService.selectedActiveIngredient$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (selectedActiveIngredient) => {
        this.quickFilters = [];
        this.rowData = [];
        if (
          selectedActiveIngredient &&
          selectedActiveIngredient != this.currentActiveIngredient
        ) {
          this.currentActiveIngredient = selectedActiveIngredient;
          await this.getResidueDefinition(
            this.AiService.SelectedActiveIngredient.material_id
          );
        }
      });

    this.selectedRow = null;
  }

  /// <summary>
  //  Description: Residue Definition Component OnDestroy
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.quickFilters = [];
    this.columnDefs = [];
    this.rowData = [];
  }

  /// <summary>
  //  Description: Residue Definition Component After Init
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  ngAfterViewInit(): void {
    if (this.AiService.SelectedActiveIngredient) {
      const temp = this.AiService.SelectedActiveIngredient;
      setTimeout(() => {
        this.AiService.SelectedActiveIngredient = temp;
      }, 750);
    }
  }

  initQuickFilters() {
    let columns = this.columnDefs.filter((x) => x.colId != undefined);
    this.quickFilterService
      .getQuickFilters(columns)
      .then((response) => (this.quickFilters = response));
  }

  //#region Methods

  /// <summary>
  //  Description: Delete Confirmation
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'Delete action completed',
        });
      },
      reject: () => {
        callback(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Canceled',
          detail: 'Delete action canceled',
        });
      },
    });
  };
  //#endregion

  //#region Events
  /// <summary>
  //  Description: Selection Changed
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  onSelectionChanged(event) {
    this.selectedRow = event as IResidueDefinition;
    let tempData = this.rowData.filter((element) => element.selected == true);
    if (tempData.length > 0) this.deleteVisible = true;
    else this.deleteVisible = false;
    this.signedOffSelectedList = [];
    this.checkResidueSignedCount();
  }

  /// <summary>
  //  Description: Save Click Event
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  onSaveClick(event) {
    this.saveResidueDefinition(this.rowData);
  }

  /// <summary>
  //  Description: Delete Event
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  onDelete(event: Event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        this.deleteResidueDefinition();
      }
    });
  }

  /// <summary>
  //  Description: Add Event
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  onAdd(event) {
    let newRow: IResidueDefinition = {
      residueDefinitionPk: 0,
      materialIdCore: this.AiService.SelectedActiveIngredient.material_id,
      haveChanges: true,
      decisionMaking: 'new',
      showButton: false,
    };
    this.rowData.unshift(newRow);
    this.gridService.addTransaction([newRow]);
  }

  sendUnlockRequest() {
    this.signedOffSelectedList.map((residue) => {
      residue.conventionalDropdownFieldValuePk = Number(
        residue.conventionalDropdownFieldValuePk
      );
      residue.statusDropdownFieldValuePk = Number(
        residue.statusDropdownFieldValuePk
      );
      residue.residueDefinitionDetail = [];
      this.addResidueDefinitionDetails(residue);
      residue.createdBy =
        residue.residueDefinitionPk == 0
          ? this.userService.profile.displayName
          : residue.createdBy;
      residue.createdDate =
        residue.residueDefinitionPk == 0 ? new Date() : residue.createdDate;
      residue.updatedBy = this.userService.profile.displayName;
      residue.updatedDate = new Date();
      residue.signOffBy = residue.signOff
        ? this.userService.profile.displayName
        : null;
      residue.signOffDate = residue.signOff ? new Date() : null;
      residue.decisionMaking =
        residue.decisionMaking == 'new' ? null : residue.decisionMaking;
      residue.approvedBy = residue.approvalDate
        ? this.userService.profile.displayName
        : null;
    });
    this.residueDefinitionService
      .sendRequestUnlock(this.signedOffSelectedList, this.reason)
      .pipe(take(1))
      .subscribe(
        (response) => {
          if (response.statusCode == 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Confirmed',
              detail: 'Unlock Request sent succesfully.',
            });
            this.getResidueDefinition(
              this.AiService.SelectedActiveIngredient.material_id
            );
          }
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error sending unlock request',
          });

        }
      );
      this.SectionVisible = false;
      this.reason="";
  }

  onRequestUnlock(event) {
    this.SectionVisible = true;
  }

  checkResidueSignedCount() {
    this.rowData.forEach((row) => {
      if (row.selected == true && row.signOff == true) {
        this.signedOffSelectedList.push(row);
      }
    });
  }

  onQuickFilterChanged(params: IQuickFilter) {
    this.gridService.QuickFIlter = params;
  }
  //#endregion

  //#region CRUD

  /// <summary>
  //  Description: Get Residue Definition
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  getResidueDefinition(materialId: number) {
    this.initQuickFilters();
    this.residueDefinitionService
      .getResidueDefinition(materialId)
      .pipe(take(1))
      .subscribe((Data) => {
        this.rowData = Data;
      });
  }

  /// <summary>
  //  Description: Update Residue Definition
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  saveResidueDefinition(data: IResidueDefinition[]) {
    const tempData = data.filter((x) => x.haveChanges == true);
    if (tempData.length > 0) {
      tempData.map((residue) => {
        residue.conventionalDropdownFieldValuePk = Number(
          residue.conventionalDropdownFieldValuePk
        );
        residue.statusDropdownFieldValuePk = Number(
          residue.statusDropdownFieldValuePk
        );
        residue.residueDefinitionDetail = [];
        this.addResidueDefinitionDetails(residue);
        residue.createdBy =
          residue.residueDefinitionPk == 0
            ? this.userService.profile.displayName
            : residue.createdBy;
        residue.createdDate =
          residue.residueDefinitionPk == 0 ? new Date() : residue.createdDate;
        residue.updatedBy = this.userService.profile.displayName;
        residue.updatedDate = new Date();
        residue.signOffBy = residue.signOff
          ? this.userService.profile.displayName
          : null;
        residue.signOffDate = residue.signOff ? new Date() : null;
        residue.decisionMaking =
          residue.decisionMaking == 'new' ? null : residue.decisionMaking;
        residue.approvedBy = residue.approvalDate
          ? this.userService.profile.displayName
          : null;
      });

      this.residueDefinitionService
        .saveResidueDefinitionData(tempData)
        .pipe(take(1))
        .subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Changes Saved',
              });
              this.getResidueDefinition(
                this.AiService.SelectedActiveIngredient.material_id
              );
            }
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error saving changes',
            });
          }
        );
    }
  }

  addResidueDefinitionDetails(residue: IResidueDefinition) {
    residue.compartment?.forEach((value) => {
      residue.residueDefinitionDetail.push(
        this.getElementAsDetail(value, 'compartment')
      );
    });
    residue.crop?.forEach((value) => {
      residue.residueDefinitionDetail.push(
        this.getElementAsDetail(value, 'crop')
      );
    });
    residue.geography?.forEach((value) => {
      residue.residueDefinitionDetail.push(
        this.getElementAsDetail(value, 'geography')
      );
    });
    residue.purpose?.forEach((value) => {
      residue.residueDefinitionDetail.push(
        this.getElementAsDetail(value, 'purpose')
      );
    });
    residue.compound?.forEach((value) => {
      residue.residueDefinitionDetail.push(
        this.getElementAsDetail(value, 'compound', true)
      );
    });
  }
  getElementAsDetail(
    value: number,
    columnName: string,
    useColumnValue: boolean = false
  ): IResidueDefinitionDetail {
    let detail: IResidueDefinitionDetail = {};
    detail.columnName = columnName;
    if (useColumnValue) detail.columnValue = value.toString();
    else detail.dropdownFieldValuePk = value;
    return detail;
  }

  /// <summary>
  //  Description: Delete Residue Definition
  //  Author: Juan Carlos Arce
  //  Creation date: 12/Abr/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-114
  //              Description: Creation.
  // </history>
  deleteResidueDefinition() {
    let tempData = this.rowData.filter((element) => element.selected == true);
    tempData.forEach((element) => {
      this.residueDefinitionService
        .deleteResidueDefinitionData(element)
        .pipe(take(1))
        .subscribe(() => {
          const index: number = this.rowData.indexOf(this.selectedRow);
          let tempData = [...this.rowData];
          tempData.splice(index, 1);
          this.rowData = tempData;
          this.selectedRow = null;
        });
    });
  }

  // <summary>
  //  Description: updat eNotes
  //  Author: Tomas Samuels
  //  Creation date: 04/May/2023
  /// </summary>
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  updateNotes(): void {
    this.rowData.forEach((r) => {
      if (r.notesChanges) {
        r.decisionMaking = r.notes;
        this.gridService.updateTransaction([r]);
      }
    });
  }

  //#endregion
}


