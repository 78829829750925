import { Component, OnInit } from '@angular/core';
import { IDropDownField } from 'src/app/models/dropdown-field.model';
import { DropdownFieldService } from 'src/app/providers/dropdown-field.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-data-field-selector',
  templateUrl: './data-field-selector.component.html',
  styleUrls: ['./data-field-selector.component.scss']
})
export class DataFieldSelectorComponent implements OnInit {
  
  public DropdownFields: IDropDownField[] = [];
  public selectedDropdownField: number;

  DropdownFieldSubscription: Subscription;

  constructor(private dropdownFieldService: DropdownFieldService) { }

  //  Description: On Init Event
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  ngOnInit(): void {
   this.DropdownFieldSubscription = this.dropdownFieldService.GetAll()
    .pipe(take(1))
    .subscribe(data => {
      this.DropdownFields  = data;
      this.selectedDropdownField = this.DropdownFields[0].dropdown_field_pk;
      this.setSelectedDropdownField(this.selectedDropdownField);
    });
  }
  
  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.DropdownFieldSubscription?.unsubscribe();
  }

  //  Description: Selection Change Event
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  selectionChanged({value}) {
    this.setSelectedDropdownField(value)
  }

  //  Description: Selected Dropdown Method
  //  Author: Juan Carlos Arce
  //  Creation date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  setSelectedDropdownField(id: number)
  {
    this.dropdownFieldService.SelectedDropdownField = id;
  }

}
