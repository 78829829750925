import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { settings } from 'src/settings/settings';
import { IParAnalytical } from '../models/par.analytical.model';
import { IResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ParOptionListService {

  constructor(private http: HttpClient) { }

  // <sumary>
  // Description: Get Synthesis list
  // Author: Yun Wei
  // Date: 20/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-32
  //              Description: Creation
  //     </update>
  // </history>
  GetSynthesisList(keyword?: string) : Observable<string[]>
	{
    const query = settings.ServiceUrl + 'api/par/fieldoptions?field=synthesis&keyword=' + keyword;
    return this.http.get(query)
      .pipe(map((response: IResponse) => response.value));
  }

  // <sumary>
  // Description: Get Analysis list
  // Author: Yun Wei
  // Date: 20/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-32
  //              Description: Creation
  //     </update>
  // </history>
  GetAnalysisList(keyword?: string) : Observable<string[]>
	{
    const query = settings.ServiceUrl + 'api/par/fieldoptions?field=analysis&keyword=' + keyword;
    return this.http.get(query)
      .pipe(map((response: IResponse) => response.value));
  }

  GetAnalysisByIdentifier(identifier: string): Observable<IParAnalytical>
  {
    const query = settings.ServiceUrl + 'api/par/analytical/' + identifier;
    return this.http.get(query)
    .pipe(map((response: IResponse) => response.value as IParAnalytical));
  }

  GetSynthesisByIdentifier(identifier: string): Observable<any>
  {
    const query = settings.ServiceUrl + 'api/par/synthesisrequest/' + identifier;
    return this.http.get(query)
    .pipe(map((response: IResponse) => response.value));
  }
}
