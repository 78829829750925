

//  Description: Metabolite Summary Service
//  Author: Juan Carlos Arce
//  Creation date: 09/Mar/2023    
//  <history>
//     <update> H00 - RD-93
//              Description: Creation.
//     </update>
// </history>

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { IMetaboliteSummary, IMetaboliteSummaryNote } from '../../models/metabolite-summary.model';
import { settings } from 'src/settings/settings';
import { map } from 'rxjs/operators';
import { IResponse } from '../../models/response.model';
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';
import { IGlpStudySpecifics, IGlpStudySpecificsDetail } from 'src/app/models/glp-study-specifics.model';

@Injectable({
  providedIn: 'root'
})
export class MetaboliteSummaryService {
  
  headers = new HttpHeaders({
    'x-rapidapi-host': 'random-facts2.p.rapidapi.com',
    'x-rapidapi-key': 'your-api-key'
  });

  constructor(@Inject(LOCALE_ID) public locale: string,
              private http: HttpClient) { }

  //#region API Calls

  //  Description: Get Metabolite Summary Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  getMetaboliteSummary(materialId: number) : Observable<IMetaboliteSummary[]>
	{
    let query = settings.ServiceUrl + 'api/metabolitesummary/params?MaterialIdCore=' + materialId;
    return this.http.get(query).pipe(map(response => this.transformAPIData(response)));
  }

  /// <summary>
  //  Description: Get GLPStudy data columsDefs
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  getGLPStudyDataColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/metabolite_summary/GLPStudyDataColumnsDef.json");
  }

  //  Description: Get Metabolite Summary Note Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  getMetaboliteSummaryNote(materialId: number) : Observable<any>
	{
    let query = settings.ServiceUrl + 'api/metabolitesummarynote/params?MaterialIdCore=' + materialId;
    return this.http.get(query);
  }

  //  Description: Update Metabolite Summary Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  updateMetaboliteSummary(data: IMetaboliteSummary) : Observable<any>
	{
    const query = settings.ServiceUrl + 'api/metabolitesummary/'+ data.metaboliteSummaryPk;
    return this.http.put(query, data);
  }

  //  Description: Update Metabolite Summary Note Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  updateMetaboliteSummaryNote(data: IMetaboliteSummaryNote) : Observable<any>
	{
    const query = settings.ServiceUrl + 'api/metabolitesummarynote/'+ data.metaboliteSummaryNotePk;
    return this.http.put(query, data);
  }

  //  Description: Create Metabolite Summary Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  saveMetaboliteSummary(data: IMetaboliteSummary[]) : Observable<any>
	{
    const query = settings.ServiceUrl + 'api/metabolitesummary';
    return this.http.put(query, data);
  }

  //  Description: Create Metabolite Summary Note Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  saveMetaboliteSummaryNote(data: IMetaboliteSummaryNote) : Observable<any>
	{
    const query = settings.ServiceUrl + 'api/metabolitesummarynote';
    return this.http.put(query, data);
  }

  //  Description: Delete Metabolite Summary Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  deleteMetaboliteSummary(id: number) : Observable<any>
	{
    const query = settings.ServiceUrl + 'api/metabolitesummary/' + id;
    return this.http.delete(query);
  }

  //  Description: Delete Metabolite Summary Note Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  deleteMetaboliteSummaryNote(id: number) : Observable<any>
	{
    const query = settings.ServiceUrl + 'api/metabolitesummarynote/' + id;
    return this.http.delete(query);
  }

  //  Description: Get Metabolite Summary Columns Def
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  getMetaboliteSummaryColumsDefs() : Observable<any>
	{
    return this.http.get("../../../assets/column_defs/metabolite_summary/ColumnsDef.json")
          .pipe(map((colDef: any) => {
            return colDef.map( item => {
              item.children?.map(child => {
                if(child.filter == "ListboxFilter") child.filter = ListboxFilter;
                if(child.filter == "DropdownFilter") child.filter = DropdownFilter;
              })
              return item;
            })
          }));
  }
  
  //  Description: Get Metabolite Summary Detail Columns Def
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  getDetailMetaboliteSummaryColumsDefs() : Observable<any>
	{
    return this.http.get("../../../assets/column_defs/metabolite_summary/DetailColumnsDef.json")  
    .pipe(map((colDef: any) => {
            return colDef.map( item => {
              if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
              if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
              return item;
            })
          }));
  }

  //#endregion


  //#region Methods

  //  Description: Save Metabolite Summary Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  saveMetaboliteSummaryData(data: IMetaboliteSummary[])
  {
    return this.saveMetaboliteSummary(data);
  }

  //  Description: Save Metabolite Summary Note Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  saveMetaboliteSummaryNoteData(data: IMetaboliteSummaryNote)
  {
    return this.saveMetaboliteSummaryNote(data);
  }
  
  //  Description: Delete Metabolite Summary Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  deleteMetaboliteSummaryData(data: IMetaboliteSummary)
  {
    return this.deleteMetaboliteSummary(data.metaboliteSummaryPk)
  }

  //  Description: Transform Data from API
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  transformAPIData(data: IResponse) : IMetaboliteSummary[]
  {
    return data.value.map(item => {
      return this.transformItem(item)
    });
  }

  //  Description: Transform Item from API Data
  //  Author: Juan Carlos Arce
  //  Creation date: 09/Mar/2023    
  //  <history>
  //     <update> H00 - RD-93
  //              Description: Creation.
  //     </update>
  // </history>
  transformItem(item: any): IMetaboliteSummary {
    item.conmpoungFoundIn = 'Expand';
    item.children = this.getChildren(item.metaboliteRelevanceBiosystem);
    item.haveChanges = false;
    return item;
  }

  public getChildren(data: any[])
  {
    let values = [];
    data.map(element => {
      let row = {
        PK: element.metaboliteRelevanceBiosystemPk,
        levelI: null,
        levelII: null,
        levelIII: null,
        levelIV: null,
        levelV: null,
        haveChanges: false,
        relevance: null,
        OECDRelevancy: null,
    };
      element.metaboliteRelevanceBiosystemDetail.map(({ listdropdownFieldValuePk, dropdownFieldCode }) => {
        switch (dropdownFieldCode){
          case 'MERE': {
            row.relevance = listdropdownFieldValuePk;
          break; }
          case 'BMLI': {
            row.levelI = listdropdownFieldValuePk;
          break; }
          case 'BMLII': {
            row.levelII = listdropdownFieldValuePk;
          break; }
          case 'BMLIII': {
            row.levelIII = listdropdownFieldValuePk;
          break; }
          case 'BMLIV': {
            row.levelIV = listdropdownFieldValuePk;
          break; }
          case 'BMLV': {
            row.levelV = listdropdownFieldValuePk;
          break; }
          case 'OERE': {
            row.OECDRelevancy = listdropdownFieldValuePk;
          break; }
        };
      });
      values.push(row);
    });
    return values;
  }

  //#endregion

  /// <summary>
  //  Description: Update GLPStudy specifics
  //  Author: Karilla Villalobos & Tomas Samuels
  //  Creation date: 21/Abr/2023  
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-116
  //              Description: Creation.
  // </history>
  UpdateGLPStudySpecifics(data: IGlpStudySpecifics[]): Observable<any> {
    const query = settings.ServiceUrl + 'api/glpstudyspecifics/update';
    return this.http.post(query, data);
  }
  
}

