import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ValueBuildCombinationsService } from './value-build-combinations.service';
import { IDropDownFieldCombination } from '../../models/value-build-combinations-data.model';
import { threadId } from 'worker_threads';
import { IResponse } from '../../models/response.model';
import { GridService } from '../shared/grid-components/grid/grid.service';
import { ActionButtonService } from '../shared/action-button/action-button.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-value-build-combinations',
  templateUrl: './value-build-combinations.component.html',
  styleUrls: ['./value-build-combinations.component.scss'],
  providers: [ConfirmationService, MessageService]
})

export class ValueBuildCombinationsComponent implements OnInit, OnDestroy {

  public rowData: IDropDownFieldCombination[];
  public rowSelection = 'single';
  public columnDefs: (ColDef)[] = [];
  public rowHeight: number;
  public deleteVisible: boolean = false;
  public selectedRows: IDropDownFieldCombination;


  colDefSubscription: Subscription;
  GetValueBuildCombinationsSubscription: Subscription;
  CreateValueBuildCombinationsSubscription: Subscription;
  DeleteValueBuildCombinationsSubscription: Subscription;

  subscriptions: Subscription[] = [];
  public path: string;


  /// <summary>
  ///     Description: Constructor
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  //     <update> H01 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Add ActionButton Service
  //     </update>
  /// </history>
  constructor(private valueBuildCombinationsService: ValueBuildCombinationsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private gridService: GridService,
    //Ref. H01
    private actionButtonService: ActionButtonService,
    private route: ActivatedRoute) { }

  /// <summary>
  ///     Description: Drop Down Combination On Init Get Data method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  //     <update> H01 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Add ActionButtonService Edit
  //     </update>
  /// </history>
  ngOnInit(): void {
    this.path = this.route.snapshot.url[0].path.toString();
    //Ref. H01
    this.actionButtonService.UseEditMode = false;
    this.actionButtonService.Editable = true;
    this.rowHeight = 130;
    this.colDefSubscription = this.valueBuildCombinationsService.getValueBuildCombinationsColumsDefs()
      .pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefs = ColDefs;
      });
    this.subscriptions.push(this.colDefSubscription);

    this.getGridData();
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  public getGridData(): void {
    this.GetValueBuildCombinationsSubscription = this.valueBuildCombinationsService.getValueBuildCombinationsData()
      .pipe(take(1))
      .subscribe((response: IResponse) => {
        this.rowData = response.value;
      });
    this.subscriptions.push(this.GetValueBuildCombinationsSubscription);
  }

  /// <summary>
  ///     Description: Drop Down Combination add new Row method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  public AddOnClick(): void {
    const newRow: IDropDownFieldCombination = {
      dropdownFieldCombination: [], module_dropdown_field_value_pk: [], source_dropdown_field_pk: 0,
      source_dropdown_field_value_pk: [], destination_dropdown_field_pk: 0, destination_dropdown_field_value_pk: [], combination_group: "",
      haveChanges: true, moduleCode: undefined
    };

    this.rowData.unshift(newRow);
    this.gridService.addTransaction([newRow]);
  }
  //#region Methods

  confirmation = (target: EventTarget, callback) => {
    this.confirmationService.confirm({
      target: target,
      message: 'Are you sure that you want to Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        callback(true);
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
      },
      reject: () => {
        callback(false);
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
      }
    });
  }

  //#endregion

  //#region Events

  /// <summary>
  ///     Description: Drop Down Combination select Row method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  onSelectionChanged(event) {
    this.selectedRows = event as IDropDownFieldCombination;
    let tempData = this.rowData.filter(element => element.selected == true);
    if (tempData.length > 0)
      this.deleteVisible = true;
    else
      this.deleteVisible = false;
  }

  /// <summary>
  ///     Description: Drop Down Combination save method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  /// </history>
  public SaveOnClick(): void {
    let tempData = this.rowData;

    tempData.forEach(item => {
      item.source_dropdown_field_pk = Number(item.source_dropdown_field_pk);
      item.destination_dropdown_field_pk = Number(item.destination_dropdown_field_pk);
    });

    this.CreateValueBuildCombinationsSubscription = this.valueBuildCombinationsService.createValueBuildCombinationsData(tempData)
      .pipe(take(1))
      .subscribe(data => {
        this.messageService.add({ severity: "success", summary: "Save", detail: "Success Service Message", });
        this.getGridData();
      });
    this.subscriptions.push(this.CreateValueBuildCombinationsSubscription);

  }


  /// <summary>
  ///     Description: Drop Down Combination delete method
  ///     Author: Karina Villalobos S.
  ///     Date: 21/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-38
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-90 - 20/Feb/2023 - Karina Villalobo s
  ///         Description: Delete multiple selected rows.
  ///     </update>
  /// </history>
  DeleteOnClick(event: Event) {
    this.confirmation(event.target, (confirmed) => {
      if (confirmed) {
        //Ref. H01
        let tempData = this.rowData.filter(element => element.selected == true);
        let last = tempData[tempData.length - 1]
        let lastdropdownFieldCombination = last.dropdownFieldCombination[last.dropdownFieldCombination.length - 1]
        tempData.forEach(element => {

          element.dropdownFieldCombination.forEach(elementId => {
            this.DeleteValueBuildCombinationsSubscription = this.valueBuildCombinationsService.deleteValueBuildCombinationsData(elementId)
              .pipe(take(1))
              .subscribe(() => {
                if (lastdropdownFieldCombination == elementId) {
                  this.deleteVisible = false;
                  this.getGridData();
                }
              });
            this.subscriptions.push(this.DeleteValueBuildCombinationsSubscription);
          });
        });
      }
    });
  }
  //#endregion
}
