import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { settings } from 'src/settings/settings';
import { map } from 'rxjs/operators';
import {
  IGlpStudySpecifics,
  IGlpStudySpecificsDetail,
} from 'src/app/models/glp-study-specifics.model';
import { IResponse } from 'src/app/models/response.model';
import { ActionButtonService } from '../../shared/action-button/action-button.service';
import { UserService } from 'src/app/providers/user.service';

@Injectable({
  providedIn: 'root',
})
export class GlpStudySpecificsResiduesService {
  private nonEditedDataValue? = undefined;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    private actionButtonService: ActionButtonService,
    private userService: UserService
  ) {}

  getGLPStudySpecificsColumnsDef(
    studySection?: string,
    editable: boolean = false,
    dossierSection?: string
  ): Observable<any> {
    let colDefLocation =
      `../../../assets/column_defs/glp_study_specifics_` +
      studySection +
      `/${studySection}`;
    if (dossierSection != undefined) {
      colDefLocation =
        `../../../assets/column_defs/glp_study_specifics_` +
        studySection +
        `/${studySection + dossierSection}`;
    }
    if (editable) colDefLocation += `Editable`;
    colDefLocation += `ColumnsDef.json`;
    return this.http.get(colDefLocation);
  }

  getGLPStudySpecificsDetailColumnsDef(
    studySection: string,
    editable: boolean = false,
    dossierSection?: string
  ): Observable<any> {
    let colDefLocation =
      `../../../assets/column_defs/glp_study_specifics_` +
      studySection +
      `/${studySection}`;
    if (dossierSection != undefined) {
      colDefLocation =
        `../../../assets/column_defs/glp_study_specifics_` +
        studySection +
        `/${studySection + dossierSection}`;
    }
    colDefLocation += `Editable`;
    colDefLocation += `DetailColumnsDef.json`;
    return this.http.get(colDefLocation);
  }

  getGLPStudySpecificsDataByMaterialID(
    materialId: number,
    studySection: string
  ): Observable<any> {
    if (!materialId) return;
    let query =
      settings.ServiceUrl +
      'api/glpstudyspecifics/params?MaterialIdCore=' +
      materialId +
      '&Section=' +
      studySection;
    return this.http.get(query).pipe(
      map((response) => {
        return this.transformAPIData(response, studySection);
      })
    );
  }

  deleteGLPStudySpecificsDataByID(Id: number): Observable<any> {
    let query = settings.ServiceUrl + 'api/glpstudyspecifics/' + Id;
    return this.http.delete(query);
  }

  //<summary>
  //  Description: Method to save StudySpecifis
  //  Author: Allam Barrera G && Reinier Rodriguez V
  //  Date: 14/Nov/2022
  //</summary>
  //<history>
  //   <update> H00 - RD-42
  //            Description: Creation.
  //   </update>
  //</history>
  addGLPStudySpecifics(data: IGlpStudySpecifics[]): Observable<any> {
    const query = settings.ServiceUrl + 'api/glpstudyspecifics/add';
    return this.http.post(query, data);
  }

  //<summary>
  //  Description: Method to get transform data to an object
  //  Author: Allam Barrera G && Reinier Rodriguez V
  //  Date: 14/Nov/2022
  //</summary>
  //<history>
  //  <update> H00 - RD-50
  //      Description: Creation.
  //  </update>
  //     <update> H01 - RD-69 - 09/Feb/2022 - Juan C Arce
  //              Description: - Add Residues support.
  //     </update>
  //</history>
  transformAPIData(
    { value }: IResponse,
    section: string
  ): IGlpStudySpecifics[] {
    //Ref. H02
    if (section == 'residues') {
      this.nonEditedDataValue = value;
      return this.actionButtonService.Editable
        ? this.transformEditableData(value)
        : this.transformReadOnlyData(value);
    }

    return value.map((item) => {
      return this.transformItem(item);
    });
  }

  transformItem(item: any): IGlpStudySpecifics {
    item.pk = item.glp_study_specifics_pk;
    item.haveChanges = false;
    return item;
  }

  //  Description: Transform Read Only Data
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-78 - 23/Feb/2022 - George Ulecia
  //              Description: - Add Residues list support and Method Refactor.
  //     </update>
  //     <update> H02 - RD-120 - 22/March/2023 - George Ulecia
  //              Description: Add metabolite relevance usage.
  //     </update>
  // </history>
  transformReadOnlyData(dataValue: any[]): IGlpStudySpecifics[] {
    let result: IGlpStudySpecifics[] = [];
    dataValue?.forEach(async (item) => {
      let newItem = {
        pk: item.glp_study_specifics_pk,
        expanded: false,
        haveChanges: false,
        dossier_point: item.dossier_point,
        sectionHTML: item.sectionHTML,
        sectionHTMLSummary: item.sectionHTML ? [item.sectionHTML] : undefined,
        descriptor: item.descriptor,
        is_external: item.is_external,
        material_id_core: item.material_id_core,
        study_section: item.study_section,
        study_status_dropdown_field_value_pk:
        item.study_status_dropdown_field_value_pk,
        study_status: item.study_status,
        study_title: item.study_title,
        study_year: item.study_year,
        study_number: item.study_number,
        method_type_dropdown_field_value_pk:0,
      };

      if (item.listGLPStudySpecificsResiduesDTO?.length > 0) {
        item.listGLPStudySpecificsResiduesDTO?.forEach((residue: any) => {
          newItem.method_type_dropdown_field_value_pk= residue.method_type_dropdown_field_value_pk;
          let newResidueItem = {
            ...newItem,
            biosystemLevel1DropdownFieldValuePk:
              residue.biosystemLevel1DropdownFieldValuePk,
            biosystemLevel1: residue.biosystemLevel1,
            biosystemLevel2DropdownFieldValuePk:
              residue.biosystemLevel2DropdownFieldValuePk,
            biosystemLevel2: residue.biosystemLevel2,
            biosystemLevel3DropdownFieldValuePk:
              residue.biosystemLevel3DropdownFieldValuePk,
            biosystemLevel3: residue.biosystemLevel3,
            biosystemLevel4DropdownFieldValuePk:
              residue.biosystemLevel4DropdownFieldValuePk,
            biosystemLevel4: residue.biosystemLevel4,
            analyte: residue.analyte,
            analyteMaterialId: residue.analyteMaterialId,
            analyteMaterialName: residue.analyteMaterialName,
            formulation: residue.formulation,
            samplingEvent: residue.samplingEvent,
            residueMin: residue.residueMin,
            residueMax: residue.residueMax,
            residueMedian: residue.residueMedian,
            sampleSize: residue.sampleSize,
            metabolitesRelevanceList: residue.metabolitesRelevanceList, //REF H03

            method_typeDropdownFieldValuePk:
              item.methodTypeDropdownFieldValuePk,
            method_type: residue.method_type,
            validation_data: residue.validation_data,
            validationDataDropdownFieldValuePk: Number(residue.validationDataDropdownFieldValuePk),

            loq: residue.loq,
            fortification: residue.fortification,
            recoveryMean: residue.recoveryMean,
            recoveryRange: residue.recoveryRange,
            sd: residue.sd,
            rsd: residue.rsd,
            n: residue.n,

            massTransition: residue.massTransition,
            compound: residue.compound,
            daysOfStorage: residue.daysOfStorage,
          };
          result.push(newResidueItem);
          newResidueItem = null;
        });
      } else {
        result.push(newItem);
      }
    });
    return result;
  }

  transformEditableData(dataValue: any[]): IGlpStudySpecifics[] {
    let result: IGlpStudySpecifics[] = [];
    dataValue?.forEach((item) => {
      item.pk = item.glp_study_specifics_pk;
      item.expanded = false;
      item.haveChanges = false;
      item.children = [];
      item.readOnly = item.is_external == undefined ? false : item.is_external;
      item.sectionHTMLSummary = item.sectionHTML
        ? [item.sectionHTML]
        : undefined;
      if (
        item.listGLPStudySpecificsResiduesDTO &&
        item.listGLPStudySpecificsResiduesDTO.length > 0
      ) {
        item.listGLPStudySpecificsResiduesDTO.map((residue: any) => {
          item.method_type_dropdown_field_value_pk = residue.methodTypeDropdownFieldValuePk;
          residue.validationDataDropdownFieldValuePk = Number(residue.validationDataDropdownFieldValuePk);
          item.children.push(this.getResiduesAsChildren(residue, item));
        });
      }

      let found = result.find(
        (x) =>
          x.dossier_point == item.dossier_point &&
          x.study_number == item.study_number
      );
      if (!found) {
        result.push(item);
      } else {
        found.haveChanges = true;
        if (!found.children) found.children = [];
        if (item.children?.length > 0) {
          item.children.forEach((child) => {
            found.children.push(child);
          });
        }
        if (item.sectionHTMLSummary) {
          found.sectionHTMLSummary
            ? found.sectionHTMLSummary.push(item.sectionHTML)
            : (found.sectionHTMLSummary = item.sectionHTMLSummary);
          found.sectionHTMLSummary = [...new Set(found.sectionHTMLSummary)];
        }
      }
    });
    return result;
  }

  transformSaveData(dataValue: any[]): any[] {
    let result: IGlpStudySpecifics[] = [];
    dataValue?.forEach(item => {
      item.listGLPStudySpecificsMetabolismDTO = [];
      item.listGLPStudySpecificsResiduesDTO = [];
      item.toxicology_specific_data = [];
      item.study_status = "";
      item.dossier_point = "";
      result = result.concat(this.assignChildrenObjects(item));
    });
    return result;
  }

  //  Description: Get Toxcicology Data as Children
  //  Author: George Ulecia
  //  Date: 22/March/2023
  //  <history>
  //     <update> H00 - RD-69
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-120
  //              Description: Add Metabolite Relevance Usage
  //     </update>
  // </history>
  getResiduesAsChildren(residues: any, item: any): IGlpStudySpecificsDetail {
    let newChildren: IGlpStudySpecificsDetail = {
      ...residues,
      haveChanges: false,
      readOnly: item.is_external,
      external_study_pk:item.external_study_pk,
      forcedReadOnly: item.is_external,
      daysOfStorage: residues.daysOfStorage,
      validationDataDropdownFieldValuePk: Number(residues.validationDataDropdownFieldValuePk),
      metabolitesRelevanceList: residues.metabolitesRelevanceList ?? [],
    };
    return newChildren;
  }

  //  Description: Get Children Data as Object Method
  //  Author: Juan Carlos Arce
  //  Date: 09/Feb/2023
  //  <history>
  //     <update> H00 - RD-69
  //              Description: Creation.
  //     </update>
  // </history>

  assignChildrenObjects(item: any): any {

    let results: IGlpStudySpecifics[] = [];

    if (item.children?.length > 0 && item.study_section == 'residues') {
      item.children.map((child: any) => {
        if(child.metabolitesRelevanceList.length <= 0) return;

        if(child.external_study_pk == item.external_study_pk){
          const newResidues = {
            ...child,
            glpStudySpecificsPk : item.glp_study_specifics_pk,
            validationDataDropdownFieldValuePk:Number(child.validationDataDropdownFieldValuePk)
          };
          if(!item.listGLPStudySpecificsResiduesDTO) item.listGLPStudySpecificsResiduesDTO = [];
          item.listGLPStudySpecificsResiduesDTO.push(newResidues);
          
          results.push(item);
        }else
        {
          let foundResidue = this.nonEditedDataValue.find(x => x.external_study_pk == child.external_study_pk);
          foundResidue.created_by =  item.created_by;
          foundResidue.created_date = item.created_date;
          foundResidue.updated_by = item.updated_by;
          foundResidue.updated_date = item.updated_date;

          if(foundResidue){

            const newResidues = {
              ...child,
              glpStudySpecificsPk : foundResidue.glp_study_specifics_pk,
              validationDataDropdownFieldValuePk:Number(child.validationDataDropdownFieldValuePk)
            };
            if(!foundResidue.listGLPStudySpecificsResiduesDTO) foundResidue.listGLPStudySpecificsResiduesDTO = [];
            foundResidue.listGLPStudySpecificsResiduesDTO.push(newResidues);
            foundResidue.listGLPStudySpecificsResiduesDTO = foundResidue.listGLPStudySpecificsResiduesDTO.filter(x => x.metabolitesRelevanceList?.length > 0);
            results.push(foundResidue);
          }
        }
      });
    }
    return results;
  }

  saveGLPStudySpecifics(data: IGlpStudySpecifics[]) {
    const query = settings.ServiceUrl + 'api/glpstudyspecifics';
    return this.http.put(query, data);
  }

  saveGLPStudySpecificsData(data: IGlpStudySpecifics[]) {
    return this.saveGLPStudySpecifics(this.transformSaveData(data));
  }
}
