import { Injectable } from '@angular/core';
import { IQuickFilter, IFilter } from 'src/app/models/quick-filter.model';
import { DropDownListService } from '../drop-down-list/drop-down-list.service';

@Injectable({
  providedIn: 'root'
})
export class QuickFilterService {

  constructor(private dropDownListService: DropDownListService) { }

  async getQuickFilters(columns: any){
    let quickFilters: IQuickFilter[] = [];
    for (let column of columns)
    {
      let quickFilter: IQuickFilter = {
        title: `${column.colId}:`,
        field: column.colId,
        filters: []
      };
      const response = await this.dropDownListService.getDropDownListData(column.cellRendererParams).toPromise();
      response.map(element => {
        let filter: IFilter = {
          value: element.value,
          label: element.text,
          checked: false
        }
        quickFilter.filters.push(filter);
      });
      quickFilters.push(quickFilter);
    };
    return quickFilters;
  }
}
