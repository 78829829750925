import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { INSPECT_MAX_BYTES } from 'buffer';
import { MenuService } from 'src/app/providers/menu.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

//  Description: Variables.
//  Author: Team tucanes
//  Date: 09/Mar/2023
//  <history>
//     <update> H00
//              Description: Creation.
//     </update>
//     <update> H01 - RD-89 - 03/Mar/2023 - Reinier Rodriguez V
//              Description: 
//                          1) Add new varibles to set new ShowHide button characteristics.
//                          2) Onclick event trigger emmitter.
//     </update>
// </history>

  @Input() element: string;
  @Input() deleteVisible: string;
  @Input() isExcelLoading: boolean= false;
  @Input() GLPVisible: string;
  @Input() IsResidue: boolean; //Ref. H01.1
  @Input() IsProposed: boolean; //Ref. H01.1
  @Input() IsToxicology: boolean; //Ref. H02.1
  @Input() ToxicologyActionButtons: any; //Ref. H02.1
  @Input() IsResidues: boolean; //Ref. H02.1
  @Input() ResiduesActionButtons: any; //Ref. H02.1
  @Input() path: string;
  @Input() data: any;
  @Input() module: string = '';

  @Output() RequestUnlockClickEvent = new EventEmitter<any>();
  @Output() SaveEvent = new EventEmitter<any>();
  @Output() DeleteEvent = new EventEmitter<any>();
  @Output() AddEvent = new EventEmitter<any>();
  @Output() CancelEvent = new EventEmitter<any>();
  @Output() ShowHideEvent = new EventEmitter<any>(); //Ref. H01.2
  @Output() GLPClickEvent = new EventEmitter<any>();
  @Output() ExcelEvent = new EventEmitter<any>();
  
  currentPage: string = '';
  headerText: string = '';
  public showDDL: boolean = false;
  public showActionButtons: boolean = false;

  menuServiceSubscription: Subscription

  //  Description: constructor
  //  Author: Juan Carlos Arce
  //  Date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  constructor(private menuService: MenuService,
              private route: ActivatedRoute) { }


  
  //  Description: Header Class on Init
  //  Author: Juan Carlos Arce
  //  Date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  ngOnInit(): void {
    this.currentPage = this.route.snapshot.url[this.route.snapshot.url.length -1].path.toString();
    this.loadData();  
  }
  
  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {
    this.menuServiceSubscription?.unsubscribe();
  }

  loadData(){
    this.menuServiceSubscription = this.menuService.getMenu().pipe(take(1)).subscribe(data => {
      data.forEach(optionlvl1 => {
        if (optionlvl1.routerLink){
          let link = optionlvl1.routerLink[0];
          if(optionlvl1.routerLink[0].includes(this.currentPage)){
            this.headerText = optionlvl1.headerText;
            this.showDDL = optionlvl1.showDDL;
            this.showActionButtons = optionlvl1.showActionButtons;
          }
        }
        if (optionlvl1.items){
          optionlvl1.items.forEach(optionlvl2 => {
            if (optionlvl2.routerLink){
              let link = optionlvl2.routerLink[0];
              if(optionlvl2.routerLink[0].includes(this.currentPage)){
                this.headerText = optionlvl2.headerText;
                this.showDDL = optionlvl2.showDDL;
                this.showActionButtons = optionlvl2.showActionButtons;
              }
            }
          });
        }
      });
    });
  }

  
  //  Description: On Save Button Event
  //  Author: Juan Carlos Arce
  //  Date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  onSaveClicked(event){
    this.SaveEvent.emit(event);
  }


  onExcelClicked(event){
    this.ExcelEvent.emit(event);
  }
  onRequestUnlockClicked(event){
    this.RequestUnlockClickEvent.emit(event);
  }
  //  Description: On Delete Button Event
  //  Author: Juan Carlos Arce
  //  Date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  onDeleteClicked(event){
    this.DeleteEvent.emit(event);

  }

  //  Description: On Add Button Event
  //  Author: Juan Carlos Arce
  //  Date: 01/Nov/2022    
  //  <history>
  //     <update> H00 - RD-45
  //              Description: Creation.
  //     </update>
  // </history>
  onAddClicked(event){
    this.AddEvent.emit(event);
  }

  //  Description: On Cancel Button Event
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  onCancelClicked(event){
    this.CancelEvent.emit(event);
  }

  //  Description: On ShowHide Event
  //  Author: Reinier Rodriguez V
  //  Date: 05/Mar/2023
  //  <history>
  //     <update> H00 - RD-89
  //              Description: Creation.
  //     </update>
  // </history>
  onShowHideEventClicked(event){
    this.ShowHideEvent.emit(event);
  }

  OnGLPClicked(event){
    this.GLPClickEvent.emit(event);
  }

}