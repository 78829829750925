import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionButtonService } from './action-button.service';
import { UserService } from 'src/app/providers/user.service';
import { PrivilegeModule, PrivilegeType } from 'src/app/shared/constants';
import { ExportExcel } from '../../../providers/export-excel.service';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
  isAdmin: boolean = false;

//  Description: Constructor/Variables.
//  Author: Team tucanes
//  Date: 09/Mar/2023
//  <history>
//     <update> H00
//              Description: Creation.
//     </update>
//     <update> H01 - RD-89 - 03/Mar/2023 - Reinier Rodriguez V
//              Description: 
//                          1) Add new varibles to set new ShowHide button characteristics.
//                          2) Add new varibles to validate if confirmed module is selected.
//                          3) Onclick event trigger emmitter.
//     </update>
// </history>
  constructor(public actionButtonService: ActionButtonService,
              public userService: UserService,
              public exportExcel: ExportExcel) { }

  public btnDefaultClass: string = 'p-button-secondary p-button-outlined p-button-sm mr-2';
  public btnSaveIcon: string = 'pi pi-save';

  public btnDeleteIcon: string = 'pi pi-trash';

  public btnAddIcon: string = 'pi pi-plus';

  public btnEditIcon: string = 'pi pi-pencil';

  public btnCancelIcon: string = 'pi pi-times';

  //Ref. H01.1 starts
  public btnShowHideIcon: string = 'pi pi-eye-slash';
  public btnShowHideLabel: string = 'Hide';
  public showHidden: boolean = false;

  public btnExportExcelIcon: string = 'pi pi-file-excel';
  public btnunlockIcon: string = 'pi pi-unlock';


  public isPrivilegeFull: boolean;

  //#region Inputs
  @Input() saveVisible: boolean;
  @Input() isExcelLoading: boolean= false;
  @Input() GLPVisible: boolean = false;
  @Input() deleteVisible: boolean;
  @Input() addVisible: boolean;
  @Input() IsProposed: boolean; //Ref. H01.2
  @Input() IsToxicology: boolean; //Ref. H02.1
  @Input() IsResidue: boolean;
  @Input() ToxicologyActionButtons: any; //Ref. H02.1
  @Input() IsResidues: boolean; //Ref. H02.1
  @Input() ResiduesActionButtons: any; //Ref. H02.1
  @Input() path: string = '';
  @Input() module: string = '';
  //#endregion

  //#region Outputs
  @Output() SaveEvent = new EventEmitter<any>();
  @Output() DeleteEvent = new EventEmitter<any>();
  @Output() AddEvent = new EventEmitter<any>();
  @Output() CancelEvent = new EventEmitter<any>();
  @Output() ShowHideEvent = new EventEmitter<any>(); //Ref. H01.3
  @Output() GLPClickEvent = new EventEmitter<any>();
  @Output() RequestUnlockEvent = new EventEmitter<any>();
  @Output() ExcelEvent = new EventEmitter<any>();

  //#endregion


//  Description: NgOninit
//  Author: Teams tucanes
//  Date: 
//  <history>
//     <update> H00
//              Description: Creation.
//     </update>
//     <update> H01 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
//              Description: Use actionButtonService UseEditMode component
//     </update>
// </history>
  ngOnInit(): void {
    this.isAdmin = this.userService.profile.role.roleName.toLowerCase()=="administrator";
    if(this.actionButtonService.UseEditMode == undefined) this.actionButtonService.UseEditMode = false; //Ref. H01
    if(this.actionButtonService.Editable == undefined) this.actionButtonService.Editable = true;
    this.isPrivilegeFull = this.userService.isPrivilegeFull(PrivilegeModule.Buttons, this.path);

    this.actionButtonService.resetHideButton$.subscribe(val => {
      this.toggleIsHidden(val);
    })
  }

  // <sumary>
  //  Description: Save All Changes Button Event
  //  Author: Yun Wei
  //  Date: 11/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  public SaveOnClick(event: Event): void{
    this.SaveEvent.emit(event);
  }

  // <sumary>
  // Description: Delete Btn Event
  // Author: Yun Wei
  // Date: 11/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  public DeleteOnClick(event: Event){
    this.DeleteEvent.emit(event);
  }

  // <sumary>
  //  Description: Add new dropdown field value
  //  Author: Yun Wei
  //  Date: 11/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-41
  //              Description: Creation
  //     </update>
  // </history>
  public AddOnClick(event: Event): void{
    this.AddEvent.emit(event);
  }

  //<sumary>
  // Description: On Edit Button Event
  // Author: Juan Carlos Arce
  // Date: 04/Jan/2023
  //</sumary>
  //<history>
  //    <update> H00 - RD-55
  //             Description: Creation.
  //    </update>
  //</history>
  public EditOnClick(event: Event): void{
    this.actionButtonService.Editable = true;
  }

  // <sumary>
  //  Description: On Cancel Button Event
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  // </sumary>
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  public CancelOnClick(event: Event): void{
    this.CancelEvent.emit(event);
  }

  public GLPOnClick(event: Event): void{
    this.GLPClickEvent.emit(event);
  }

  public RequestUnlockOnClick(event: Event): void{
    this.RequestUnlockEvent.emit(event);
  }
  // <sumary>
  //  Description: On Show/Hide Button Event
  //  Author: Reinier Rodriguez V.
  //  Date: 03/Mar/2023
  // </sumary>
  //  <history>
  //     <update> H00 - RD-89
  //              Description: Creation.
  //     </update>
  // </history>
  public ShowHideOnClick(event: Event): void{
    this.toggleIsHidden();
  }

  // <summary>
  //  Description: On Show/Hide Button Event
  //  Author: Reinier Rodriguez V.
  //  Date: 03/Mar/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-89
  //              Description: Creation.
  //     </update>
  // </history>
  public toggleIsHidden(reset?: boolean): void{
    this.showHidden = reset ? !reset : !this.showHidden;
    this.btnShowHideIcon = this.showHidden ? 'pi pi-eye' : 'pi pi-eye-slash';
    this.btnShowHideLabel = this.showHidden ? 'Show' : 'Hide';
    this.actionButtonService.RowColor = this.showHidden;
    this.ShowHideEvent.emit(this.showHidden);
  }

  // <sumary>
  //  Description: 
  //  Author: Juan Carlos Arce
  //  Date: 04/May/2023
  // </sumary>
  //  <history>
  //     <update> H00 - 
  //              Description: Creation.
  //     </update>
  // </history>
  public getButtonVisibility(button: string){
    switch(button){
      case ('edit'):
        return !this.actionButtonService.Editable && this.actionButtonService.UseEditMode && this.isPrivilegeFull;
      case('cancel'):
        return this.actionButtonService.Editable && this.actionButtonService.UseEditMode &&  this.isPrivilegeFull;
      case('delete'):
        return this.deleteVisible && this.actionButtonService.Editable &&  this.isPrivilegeFull;
      case('save'):
        return this.saveVisible && this.actionButtonService.Editable &&  this.isPrivilegeFull;
      case('add'):
        return this.addVisible && this.actionButtonService.Editable && !this.IsToxicology && !this.IsResidues &&  this.isPrivilegeFull;
      case('toxicologyAdd'):
        return this.addVisible && this.actionButtonService.Editable && this.IsToxicology &&  this.isPrivilegeFull;
      case('residuesAdd'):
        return this.addVisible && this.actionButtonService.Editable && this.IsResidues &&  this.isPrivilegeFull;
      case ('exportExcel'):
        return true;
      case('proposed'):
        return this.IsProposed;
      case('GLP'):
        return this.GLPVisible;
      case ('residue'):
        return (this.IsResidue && !this.isAdmin) ;
    }
  }

  // <summary>
  //  Description: Export to excel method.
  //  Author: Reinier Rodriguez V.
  //  Date: 02/May/2023
  // </summary>
  //  <history>
  //     <update> H00 - RD-133
  //              Description: Creation.
  //     </update>
  // </history>
  public exportToExcel(event: Event):void{
    console.log(this.module);
    switch(this.module){
      case 'proposed': this.ExcelEvent.emit(event); break;
      case 'confirmed': this.ExcelEvent.emit(event); break;
      case 'summary': this.ExcelEvent.emit(event); break;
      default: this.actionButtonService.ExportExcel = true; break;
    }
  }
}
