import { RowDataTransaction } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActiveIngredientService } from '../../active-ingredient-selector/active-ingredient.service';
import { IQuickFilter } from 'src/app/models/quick-filter.model';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  //Properties
  public materialID: Number = 0;

  //Main Grid Transaction
  private _transaction: RowDataTransaction; // Make this private so we can expose it via a get/set
  private transactionChange$ = new Subject<RowDataTransaction>(); // This will be used to create our Observable
  public transaction$ = this.transactionChange$.asObservable(); // This is our Observable

  // Detail Grid Transaction
  private _detailTransaction: RowDataTransaction; // Make this private so we can expose it via a get/set
  private detailTransactionChange$ = new Subject<RowDataTransaction>(); // This will be used to create our Observable
  public detailTransaction$ = this.detailTransactionChange$.asObservable(); // This is our Observable
  
  // Saves the state of the column groups in a table(open, closed)
  private columnGroupState:any;

  
  // Detail Grid Transaction
  private _quickFilter: IQuickFilter; // Make this private so we can expose it via a get/set
  private quickFilterChange$ = new Subject<IQuickFilter>(); // This will be used to create our Observable
  public quickFilter$ = this.quickFilterChange$.asObservable(); // This is our Observable

  private _redrawRows: boolean; // Make this private so we can expose it via a get/set
  private redrawRowsChange$ = new Subject<boolean>(); // This will be used to create our Observable
  public redrawRows$ = this.redrawRowsChange$.asObservable(); // This is our Observable

  constructor(public AiService: ActiveIngredientService) { 
    this.AiService.selectedActiveIngredient$.subscribe(selectedActiveIngredient => {
      this.materialID = selectedActiveIngredient?.material_id;
    });
  }

  set RedrawRows(val: boolean) {
    this._redrawRows = val; // Set the new value
    this.redrawRowsChange$.next(val); // Trigger the subject, which triggers the Observable
  }
  
  set Transaction(val: RowDataTransaction) {
    this._transaction = val; // Set the new value
    this.transactionChange$.next(val); // Trigger the subject, which triggers the Observable
   
  }

  get Transaction() {
    return this._transaction;
  }

  getColumnGroupState(componentId):any{
    let state = localStorage.getItem(`agGridState-${componentId}`);
    return state ? JSON.parse(state) : null; 
  }

  setColumnGroupState(componentId:any,state:any){
    localStorage.setItem(`agGridState-${componentId}`, JSON.stringify(state))
  }

  set DetailTransaction(val: RowDataTransaction) {
    this._detailTransaction = val; // Set the new value
    this.detailTransactionChange$.next(val); // Trigger the subject, which triggers the Observable
   
  }

  get DetailTransaction() {
    return this._detailTransaction;
  }

  set QuickFIlter(val: any) {
    this._quickFilter = val; // Set the new value
    this.quickFilterChange$.next(val); // Trigger the subject, which triggers the Observable
  }

  get QuickFIlter() {
    return this._quickFilter;
  }

  public setRedrawRows(data: boolean){
    this.RedrawRows = data;
  }

  public addTransaction(data: any[]){
    this.Transaction = { addIndex: 0, add : data };
  }

  public updateTransaction(data: any[]){
    this.Transaction = { update : data };
  }

  public removeTransaction(data: any[]){
    this.Transaction = { remove : data };
  }

  public addDetailTransaction(data: any[]){
    this.DetailTransaction = { addIndex: 0,  add : data };
  }

  public updateDetailTransaction(data: any[]){
    this.DetailTransaction = { update : data };
  }

  public removeDetailTransaction(data: any[]){
    this.DetailTransaction = { remove : data };
  }
}
