import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionButtonService {

  private _editable: boolean = false;
  private editableChange$ = new Subject<boolean>();
  public editable$ = this.editableChange$.asObservable();

  private _exportExcel: boolean;
  private exportExcelChange$ = new Subject<boolean>();
  public exportExcel$ = this.exportExcelChange$.asObservable();
   



//  Description: Use Observable properties
//  Author: Juan Carlos Arce
//  Date: 15/Feb/2023
//  <history>
//     <update> H00 - RD-88
//              Description: Creation.
//     </update>
// </history>
  private _useEditMode: boolean;
  private useEditModeChange$ = new Subject<boolean>();
  public useEditMode$ = this.useEditModeChange$.asObservable();
  
  private _rowColor: boolean;
  private rowColorChange$ = new Subject<boolean>();
  public rowColor$ = this.rowColorChange$.asObservable();
  
  private _resetHideButton: boolean;
  private resetHideButtonChange$ = new Subject<boolean>();
  public resetHideButton$ = this.resetHideButtonChange$.asObservable();

  constructor() { }

  set ResetHideButton(val: boolean) {
    this._resetHideButton = val;
    this.resetHideButtonChange$.next(val);
  }

  set ExportExcel(val: boolean) {
    this._exportExcel = val;
    this.exportExcelChange$.next(val);
  }
  get getRowColor() {
    return this._rowColor;
  }
  set RowColor(val: boolean) {
    this._rowColor = val;
    this.rowColorChange$.next(val);
  }


  set Editable(val: boolean) {
    this._editable = val;
    this.editableChange$.next(val);
  }

  get Editable() {
    return this._editable;
  }

  get getExportExcel() {
    return this._exportExcel;
  }



//  Description: Set Use Edit Mode
//  Author: Juan Carlos Arce
//  Date: 15/Feb/2023
//  <history>
//     <update> H00 - RD-88
//              Description: Creation.
//     </update>
// </history>
  set UseEditMode(val: boolean) {
    this._useEditMode = val;
    this.useEditModeChange$.next(val);
  }

//  Description: Get Use Edit Mode
//  Author: Juan Carlos Arce
//  Date: 15/Feb/2023
//  <history>
//     <update> H00 - RD-88
//              Description: Creation.
//     </update>
// </history>
  get UseEditMode() {
    return this._useEditMode;
  }

}
