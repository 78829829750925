import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { IResponse } from '../../../../models/response.model';

@Component({
  selector: 'app-group-cell',
  templateUrl: './group-cell.component.html',
  styleUrls: ['./group-cell.component.scss']
})
export class GroupCellComponent implements ICellRendererAngularComp {
  public params: any;
  public expanded: boolean = false;
  public parent;
  public destination: string;

  constructor() { }

  //  Description: Class Refresh
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }
  
  //  Description: Class Init
  //  Author: Juan Carlos Arce
  //  Date: 04/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  agInit(params: any): void {
    this.params = params;
    this.parent = params.context.componentParent;
    this.destination = params.destination;
  }

  //  Description: Expanded Toogle Method
  //  Author: Juan Carlos Arce
  //  Date: 16/Jan/2023
  //  <history>
  //     <update> H00 - RD-55
  //              Description: Creation.
  //     </update>
  // </history>
  expandedToogle() {
    this.expanded = !this.expanded;
    const linkParams = { columns: [this.destination], rowNodes: [this.params.node] };
    const params = {
      destination : this.destination,
      innerGridWidth: this.params.innerGridWidth,
      linkParams,
      api: this.params.api,
      columnApi: this.params.columnApi,
      value: this.expanded,
      node: this.params.node,
      data: this.params.data
    };
    this.parent.groupCellToogle(params);
  }
}
