import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { settings } from 'src/settings/settings';

@Injectable({
  providedIn: 'root'
})
export class GlpStudySpecificsSearchService {

  
  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient) {


  }
  getGLPStudySpecificsSearchColumnsDef(): Observable<any> {
    return this.http.get("../../../assets/column_defs/glp_study_specifics_allSections/allSectionsColumnsDef.json");
  }
  
  /// <summary>
  ///     Description: Method to get data from GLP Study Specifics by Material ID
  ///     Author: Allam Barrera G && Reinier Rodriguez V
  ///     Date: 14/Nov/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-50
  ///         Description: Creation.
  ///     </update>
  /// </history>
  getGLPStudySpecificsSearchData(): Observable<any> {

    let query = settings.ServiceUrl + 'api/glpstudyspecifics/allsections';
     
    return this.http.get(query).pipe(map(items => (items)));
    
  }
}
