import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ValidatorService } from 'src/app/providers/validator.service';

//  Description: date_picker Component
//  Author: Reinier Rodriguez V && Allam Barrera B
//  Date: 15/Nov/2022    
//  <history>
//     <update> H00 - RD-50
//              Description: Creation.
//     </update>
// </history>
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements ICellRendererAngularComp {

  public params: any;
  public value: Date;
  public dates: Date[];
  public rangeDates: Date[];
  public minDate: Date;
  public maxDate: Date;
  public es: any;
  public invalidDates: Array<Date>

  public listItems: Array<any>;
  public parent;
  public columnThatModifies;
  public readOnly: boolean = false;
  public isGroupRendered: boolean;
  public dateFormat: string;
  public view: string;

  constructor(private validatorService: ValidatorService) { }


//  Description: date_picker AgInit
//  Author: Reinier Rodriguez V && Allam Barrera B
//  Date: 15/Nov/2022    
//  <history>
//     <update> H00 - RD-50
//              Description: Creation.
//     </update>
//     <update> H01 - RD-99
//         Description: If its Read-Only Data, (External Data), Disable edit mode for the fields as needed
//     </update>
//     <update> H02 - RD-112 - Tomas Samuels
//         Description: Add full date option
//     </update>
//     <update> H03 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
//         Description: Set readonly with validator service
//     </update>
// </history>
  agInit(params: any): void {
    this.params = params;
    this.view = params.view; //Ref: H02
    this.value = this.getDate(); //Ref: H02
    this.parent = params.context.componentParent;
    this.columnThatModifies = params.colDef;
    this.parent = params.context.componentParent;
    this.isGroupRendered = params.node.group;
    this.dateFormat = params.dateFormat; //Ref: H02
    //Ref H03
    this.readOnly = this.validatorService.isComponentReadOnly(params);
    
    if(!params.value) this.onSelect(this.value)
    
    this.columnThatModifies = params.colDef;
      this.es = {
          firstDayOfWeek: 1,
          dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
          dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
          dayNamesMin: [ "D","L","M","X","J","V","S" ],
          monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],
          monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
          today: 'Hoy',
          clear: 'Borrar'
      };

      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      let prevMonth = (month === 0) ? 11 : month -1;
      let prevYear = (prevMonth === 11) ? year - 1 : year;
      let nextMonth = (month === 11) ? 0 : month + 1;
      let nextYear = (nextMonth === 0) ? year + 1 : year;
      this.minDate = new Date();
      this.minDate.setMonth(prevMonth);
      this.minDate.setFullYear(prevYear);
      this.maxDate = new Date();
      this.maxDate.setMonth(nextMonth);
      this.maxDate.setFullYear(nextYear);
  }

  //  Description: refresh method implemented from ICellRendererAngularComp
  //  Author: Reinier Rodriguez V && Allam Barrientos B
  //  Date: 15/Nov/2022    
  //  <history>
  //     <update> H00 - RD-50
  //              Description: Creation.
  //     </update>
  // </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
     return false;
  }

//  Description: Methos to get information from DatePicker option.
//  Author: Reinier Rodriguez V && Allam Barrera B
//  Date: 15/Nov/2022    
//  <history>
//     <update> H00 - RD-50
//              Description: Creation.
//     </update>
//     <update> H02 - RD-112 - Tomas Samuels
//         Description: Add full date option
//     </update>
// </history>
  onSelect(value){
    this.returnSelectedDate();
  }

//  Description: Get date when the user enter data by input
//  Author: Tomas Samuels
//  Date: 23/Mar/2023   
//  <history>
//     <update> H00 - RD-112
//              Description: Creation.
//     </update>
// </history>
  onBlur(event: any) {
    const dateTimestamp = Date.parse(event.target.value);
    const date = new Date(dateTimestamp);
    
    if(isNaN(date.getTime()))
      this.value = null;
    else
      this.value = date
    this.returnSelectedDate();
  }

//  Description: Return selected date to the grid
//  Author: Tomas Samuels
//  Date: 23/Mar/2023   
//  <history>
//     <update> H00 - RD-112
//              Description: Creation.
//     </update>
// </history>
  returnSelectedDate(): void{
    this.parent.datePickerChanged(
      this.columnThatModifies,
      this.value,
      this.params.data,
      this.params.node.id,
      this.params.view
    );
  }

//  Description: Get date depending of the view
//  Author: Tomas Samuels
//  Date: 23/Mar/2023   
//  <history>
//     <update> H00 - RD-112
//              Description: Creation.
//     </update>
// </history>
  getDate(): Date{
    if(this.params.view == 'year')
      return this.params.value ? new Date(this.params.value,1,1) : new Date();
    else
      return this.params.value ? new Date(this.params.value) : undefined;
  }

//  Description: Clean the date when the input is focus
//  Author: Tomas Samuels
//  Date: 23/Mar/2023   
//  <history>
//     <update> H00 - RD-112
//              Description: Creation.
//     </update>
// </history>
  onFocus(event: any){
    this.value = null;
  }

}
