import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, Input } from '@angular/core';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-popup-button',
  templateUrl: './popup-button.component.html',
  styleUrls: ['./popup-button.component.scss']
})
export class PopupButtonComponent implements ICellRendererAngularComp {

  public text: string;
  public iconClass: string;
  public selectedValue: any;
  public parent;
  public colDef;
  public field: any;
  public readOnly: boolean = false;
  public params;
  public draggable: boolean = true;


  constructor(private validatorService: ValidatorService) { 
    this.text = 'View';
    this.iconClass = '';
  }

//  Description: agInit
//  Author: Juan Carlos Arce
//  Date: 07/Nov/2022    
//  <history>
//     <update> H00 - RD-34
//              Description: Creation.
//     </update>
// </history>
agInit(params: any): void {
  this.params = params;
  this.selectedValue = params.value;
  this.readOnly = this.validatorService.isComponentReadOnly(params);
  this.parent = params.context.componentParent;
  this.field = params.field
  this.colDef = params.colDef;
  this.draggable = params.draggable;
  this.initValue();
  this.setButtonText();
}

//  Description: refresh
//  Author: Juan Carlos Arce
//  Date: 07/Nov/2022    
//  <history>
//     <update> H00 - RD-34
//              Description: Creation.
//     </update>
// </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  //  Description: Popup Button Component
  //  Author: Juan Carlos Arce
  //  Date: 07/Nov/2022    
  //  <history>
  //     <update> H00 - RD-34
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-60 17/Nov/2022 Karina Villalobos Salazar
  //              Description: on PopUp Show Structure Imagen SVG.
  //     </update>
  //     <update> H02 - RD-52 08/Feb/2023 Reinier Rodriguez V
  //              Description: on PopUp Show RadiolabeledSamples.
  //     </update>
  // </history>
  onClick(event): void {
    const params = {
      data:  this.selectedValue,
      field: this.field,
      event: event,
      readOnly: this.readOnly,
      draggable: this.draggable
    };
    this.parent.onPopUpShow(params);
  }

  /// <summary>
  /// Description: Set Button Text
  /// Author: Tomas Samuels Brenes.
  /// Date: 13/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-111
  ///              Description: Creation
  ///     </update>
  /// </history>
  setButtonText(): void{
    if(this.field == 'molecular_structure'){
      if(this.selectedValue == 'new'){
        this.text = ' Upload';
        this.iconClass = 'fa fa-cloud-upload';
      }
      else{
        this.text = ' Edit';
        this.iconClass = 'fa fa-pencil';
      }
    } 
  }

  initValue(){
    if(this.colDef.field == "identity_structure_imagen" && this.selectedValue)
    {
      this.convertSVGtoPNG(this.selectedValue);
    }
  }

  /// <summary>
  /// Description: transform img to base64 to be included in the export excel process.
  /// Author: Karina Villalobos.
  /// Date: 13/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-144
  ///              Description: Creation
  ///     </update>
  /// </history>
  convertSVGtoPNG(svgXml: string) {
    const img = new Image();
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgXml, 'image/svg+xml');
    const svgElement = doc.getElementsByTagName('svg')[0];
    const rect = doc.getElementsByTagName('rect')[0];
    const rectHeight = rect ? rect.getAttribute('height') : '0';
    const rectWidth = rect ? rect.getAttribute('width') : '0';
    
    const width = Number(rectWidth);
    const height = Number(rectHeight);

    if(!svgElement) return;
    const svgString = new XMLSerializer().serializeToString(svgElement);
    img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, width, height);
      const base64Data = canvas.toDataURL('image/png').replace(/^data:image\/svg\+xml;base64,/, 'data:image\/png;base64,');
      this.selectedValue = base64Data;
      this.params.data[this.field] = base64Data;
      };
  } 
}
