import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Settings } from './settings/settings';
// import { LicenseManager } from 'ag-grid-enterprise/dist/lib/licenseManager';

// LicenseManager.setLicenseKey('CompanyName=SHI International Corp_on_behalf_of_Pioneer Hi-Bred International, Inc.,LicensedGroup=DSI,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=25,LicensedProductionInstancesCount=0,AssetReference=AG-017754,ExpiryDate=3_September_2022_[v2]_MTY2MjE1OTYwMDAwMA==3da5f49be5ca957ab7f50d9452bb7bed');

// ModuleRegistry.registerModules(AllModules);

if (environment.production) {
  enableProdMode();
}

//Settings.IdentifyEnvironment();
const settingsProviders = Settings.initializeProviders();

platformBrowserDynamic(settingsProviders).bootstrapModule(AppModule);
