
/// <summary>  
//  Description: Degradation Pathway Service
//  Author: Karina Villalobos S
//  Creation date: 27/Oct/2022   
/// </summary>   
//  <history>
//     <update> H00 - RD-43
//              Description: Creation.
//     </update>
// </history>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDegradationPathway } from '../../models/degradation-pathway-data.model';
import { settings } from 'src/settings/settings';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/models/response.model';
import { CompoundService } from 'src/app/providers/compound.service';
import { ListboxFilter } from '../shared/grid-components/list-box/listbox-filter/listbox-filter.component';
import { UserService } from 'src/app/providers/user.service';

@Injectable({
  providedIn: 'root'
})
export class DegradationPathwayService {

  headers = new HttpHeaders({
    'x-rapidapi-host': 'random-facts2.p.rapidapi.com',
    'x-rapidapi-key': 'your-api-key'
  });

  constructor(private http: HttpClient,
              private readonly compoundService: CompoundService,
              private userService: UserService) { }

  //#region API Calls

  /// <summary>
  ///     Description: Method to get colunm defs from Combination table
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  /// </history>
  getDegradationPathwayColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/degradation_pathway/DegradationPathwayColumnsDef.json");
  }

  /// <summary>
  ///     Description: Method to get colunm defs from Combination table
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  /// </history>
  getDegradationPathwayDetailColumsDefs(): Observable<any> {
    return this.http.get("../../../assets/column_defs/degradation_pathway/DegradationPathwayDetailColumnsDef.json")
    //Ref. H01
    .pipe(map((colDef: any) => {
      return colDef.map( item => {
        if(item.filter == "ListboxFilter") item.filter = ListboxFilter;
        return item;
      })
    }));
  }

  /// <summary>
  ///     Description: Method to get data from Degradation Pathway table
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  /// </history>
  getDegradationPathwayData(materialId: number): Observable<any> {

    let query = settings.ServiceUrl + 'api/degradation/params?MaterialIdCore=' + materialId;
    return this.http.get(query).pipe(map(response => this.transformAPIData(response)));
  }

  transformAPIData(data: IResponse) {
    return data.value.map(item => {
      return item
    });
  }
  /// <summary>  
  //  Description: Transform Item from API Data
  //  Author: Juan Carlos Arce
  //  Creation date: 10/Oct/2022 
  /// </summary>     
  //  <history>
  //     <update> H00 - RD-42
  //              Description: Creation.
  //     </update>
  // </history>
  transformItem(item: IDegradationPathway) {
    item.degradation_pathway_pk = item.degradation_pathway_pk;
    item.metabolite = item.metabolite;
    item.precursor = item.precursor;
   // item.biosystems = 'Expand';
    item.material_id_core = item.material_id_core;
    item.diagram = item.diagram;
    item.created_by = item.created_by;
    item.updated_by = item.updated_by;
    item.created_date = item.created_date;
    item.updated_date = item.updated_date;
    
    return item;
  }

  /// <summary>
  ///     Description: Method to update data from Degradation Pathway table
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  /// </history>
  saveDegradationPathway(data: IDegradationPathway[], materialId: number): Observable<any> {
    const transformedData: any[] = [...data];
    
    transformedData.map(element => {
      element.metabolite = String(element.metabolite);
      element.precursor = String(element.precursor);
      if(element.metabolite)
      element.metaboliteRelevance=[];
      element.created_by = element.degradation_pathway_pk == 0 ? this.userService.profile.displayName : element.created_by;
      element.created_date = element.degradation_pathway_pk == 0 ? new Date() : element.created_date;
      element.updated_by = this.userService.profile.displayName;
      element.updated_date = new Date();

    });

    const query = settings.ServiceUrl + 'api/DegradationPathway';
    return this.http.put(query, transformedData);
  }

  /// <summary>
  ///     Description: Method to delete data from Degradation Pathway table
  ///     Author: Karina Villalobos S.
  ///     Date: 27/Oct/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-43
  ///         Description: Creation.
  ///     </update>
  /// </history>
  deleteDegradationPathwayData(data: IDegradationPathway): Observable<any> {
    const query = settings.ServiceUrl + 'api/DegradationPathway/' + data.degradation_pathway_pk;
    return this.http.delete(query);
  }
  
  /// <summary>
  /// Description: Get filter and visor show hide data
  /// Author: Tomas Samuels Brenes.
  /// Date: 15/Feb/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-75
  ///              Description: Creation
  ///     </update>
  /// </history>
  getFilterAndVisorShowHideData(materialId: number): Observable<any> {
    let query = settings.ServiceUrl + 'api/degradation/getFilterAndVisorShowHideData/params?MaterialIdCore=' + materialId;
    return this.http.get(query).pipe(map((response: IResponse) => response.value));
  }

  /// <summary>
  /// Description: Get structure image
  /// Author: Tomas Samuels Brenes.
  /// Date: 06/Mar/2023  
  /// </summary> 
  /// <history>
  ///     <update> H00 - RD-95
  ///              Description: Creation
  ///     </update>
  /// </history>
  getStructureImage(metabolite: string): Observable<any> {
      let metaboliteName: string;
      if (metabolite != undefined)
        metaboliteName = metabolite.replace("X", "");
      return this.compoundService.GetIdentity(metaboliteName).pipe(map(response => (response as any).value));
  }

}