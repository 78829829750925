
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDropDownOption } from '../models/dropdown-option.model';
import { CompoundService } from './compound.service';
import { ParOptionListService } from './par-option-list.service';

@Injectable({
  providedIn: 'root'
})
export class DropDownOptionService {

  private dropdownOption: Map<string, any>;


  constructor(public parOptionListService: ParOptionListService,
              public compoundService: CompoundService) { 
              this.dropdownOption = new Map<string, string>();
  }

  // <sumary>
  // Description: Get Dropdown option list
  // Author: Yun Wei
  // Date: 20/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-32
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-107 - 01/Mar/2023 - Juan C Arce
  //              Description: Add XNumber type verification.
  //     </update>
  // </history>
  GetList(type: string, keyword: string) : Observable<string[]>
	{
    type = type.toLowerCase();
    if(type == "synthesis"){
      return this.parOptionListService.GetSynthesisList(keyword);
    }
    if(type == "analysis"){
      return this.parOptionListService.GetAnalysisList(keyword);
    }
    if(type == "analyte" || type == "xnumber"){
      return this.compoundService.searchAnalyte(keyword);
    }
  }

  // <sumary>
  // Description: Get Dropdown option list
  // Author: Yun Wei
  // Date: 20/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-32
  //              Description: Creation
  //     </update>
  // </history>
  GetObjectList(type: string) : Observable<IDropDownOption[]>
	{
    let result: any;
    return result;
  }
}
