import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ValidatorService } from 'src/app/providers/validator.service';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements ICellRendererAngularComp {

  public params: any;
  public value;
  public readOnly: boolean = false;
  public field: any;
  public rows: number = 2;
  public height: number = 110;
  public isGroupRendered: any;

  constructor(private validatorService: ValidatorService) { }

  /// <summary>
  ///     Description: Text Box Refresh Method
  ///     Author: Juan Carlos Arce
  ///     Date: 04/Jan/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-55
  ///         Description: Creation.
  ///     </update>
  /// </history>
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  /// <summary>
  ///     Description: Text Box AG Init Method
  ///     Author: Juan Carlos Arce
  ///     Date: 04/Jan/2022
  /// </summary>
  /// <history>
  ///     <update> H00 - RD-55
  ///         Description: Creation.
  ///     </update>
  //      <update> H01 - RD-126 - Tomas Samuels, Yun Wei, George Ulecia
  //          Description: Set readonly with validator service
  //      </update>
  /// </history>
  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.field = params.colDef.field;
    this.rows = params.rows;
    this.height = params.height ?? this.height;
    this.isGroupRendered = params.node.group;
    //Ref: H01
    this.readOnly = this.validatorService.isComponentReadOnly(params);
  }

  onInput(value){
    this.params.data[this.field] = value;
    this.params.data.haveChanges = true;
    this.params.data;
  }

}

