import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IActiveIngredient } from '../../../models/active-ingredient.model';
import { settings } from 'src/settings/settings';

@Injectable({
  providedIn: 'root'
})

export class ActiveIngredientService {

  private _selectedActiveIngredient: IActiveIngredient; // Make this private so we can expose it via a get/set
  private selectedActiveIngredientChange$ = new Subject<IActiveIngredient>(); // This will be used to create our Observable
  public selectedActiveIngredient$ = this.selectedActiveIngredientChange$.asObservable(); // This is our Observable
  public showSpinner:boolean = false;





  //  Description: Active Ingredient Service
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  constructor(private http: HttpClient) {
  }
  

  //  Description: Search Core Active Ingredient
  //  Author: Allam Barrera G
  //  Date: 10/Oct/2022
  //  <history>
  //     <update> H00 - RD-25
  //              Description: Creation.
  //     </update>
  // </history>
  search(filter:string) {
     const query = settings.ServiceUrl +'api/compoundnamescache/params?CompoundType=active_ingredient&filter='+ filter;
     return this.http.get(query);
  }

  set SelectedActiveIngredient(val: IActiveIngredient) {
    this._selectedActiveIngredient = val; // Set the new value
    this.selectedActiveIngredientChange$.next(val); // Trigger the subject, which triggers the Observable
   
  }

//  Description: Active Ingredient Service
//  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  get SelectedActiveIngredient() {
    return this._selectedActiveIngredient;
  }
}
