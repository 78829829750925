import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { IDropDownField, IDropDownValue } from '../../models/dropdown-field.model';
import { settings } from 'src/settings/settings';
import { map } from 'rxjs/operators';
import { DropdownFilter } from '../shared/grid-components/drop-down-list/dropdown-filter/dropdown-filter.component';

@Injectable({
  providedIn: 'root'
})
export class ValueBuildsService {

  constructor(private http: HttpClient) { }

  // <sumary>
  // Description: Get Value Builds Grid Columns Definitions
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  ///     <update> H01 - RD-76 -27/Feb/2023 - Juan Carlos Arce
  ///              Description: Implement Filters
  ///     </update>
  // </history>
  getColumsDefs() : Observable<any>
	{
    return this.http.get("../../../assets/column_defs/value_builds/ValueBuildsColumnsDef.json")
    //Ref.H01
    .pipe(map((colDef: any) => {
      return colDef.map( item => {
        if(item.filter == "DropdownFilter") item.filter = DropdownFilter;
        return item;
      })
    }));
  }

  // <sumary>
  // Description: Create Dropdown Field Value Data
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-41 - Yun Wei - 07/Oct/2022
  //              Description: Connect the endpoint
  //     </update>
  // </history>
  SaveData(data: IDropDownValue[]) : Observable<any>
  {
    //Ref.H01
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = settings.ServiceUrl + 'api/dropdownvalue';
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data));
  }

  // <sumary>
  // Description: Delete Dropdown Field Value Data
  // Author: Yun Wei
  // Date: 03/Oct/2022
  // </sumary>
  // <history>
  //     <update> H00 - RD-26
  //              Description: Creation
  //     </update>
  //     <update> H01 - RD-41 - Yun Wei - 07/Oct/2022
  //              Description: Connect the endpoint
  //     </update>
  // </history>
  DeleteData(data: IDropDownValue) : Observable<any>
  {
    //Ref.H01
    const query = settings.ServiceUrl + 'api/dropdownvalue/' + data.dropdown_field_value_pk;
    return this.http.delete(query)
      .pipe(map((data: any) => data));
  }
}
