
//  Description: Delete Button Component
//  Author: Juan Carlos Arce
//  Date: 03/Oct/2022    
//  <history>
//     <update> H00 - RD-24
//              Description: Creation.
//     </update>
// </history>
 

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
})
export class DeleteButtonComponent implements ICellRendererAngularComp {
  public params: any;
  public parent;
  public columnThatModifies;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.parent = params.context.componentParent;
    this.columnThatModifies = params.colDef;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onClick(event): void {
    this.parent.onDeletedClicked(this.params.data);
  }
}
